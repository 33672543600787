import React, { Component } from "react";
import axios from "axios";
import {
  deleteAllCookies,
  showErrAlert,
  blobPdfFromBase64String,
  startFresh,
} from "../../../utils/common";
import { awsUrl } from "../../../config";
import { Auth } from "aws-amplify";
import $ from "jquery";
import QuoteTable from "../../common/QuoteTable";
import NewProgress from "../../../newcomponent/common/NewProgress";
import moment from "moment";

class FormCmprQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      visible: false,
      uuid_carrier: [],
    };
    this.getAllDataWRTCarrier = this.getAllDataWRTCarrier.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.QuoteView = this.QuoteView.bind(this);
  }
  componentWillMount() {
    if (!window.location.hash) {
      window.location = window.location + "#loaded";
      window.location.reload();
    }
  }
  componentDidMount() {
    $("html, body").animate({ scrollTop: 0 }, 250);
    var formStage = sessionStorage.getItem("formStage");
    if (formStage === "two" || formStage === "three" || formStage === "four") {
      $("#loader").css("display", "block");
      let currProspectDetails = JSON.parse(
        sessionStorage.getItem("currProspect")
      );
      let email = sessionStorage.getItem("quoteData")
        ? JSON.parse(sessionStorage.getItem("quoteData")).uuid
        : "";

      if (sessionStorage.getItem("getButtonClicked")) {
        let { em, date } = JSON.parse(sessionStorage.getItem("userDetails"));
        axios
          .get(
            awsUrl +
              "/api/getUserDataStatus/" +
              em +
              "/quote/date:" +
              moment.utc(date, "MM/DD/YYYY").format("YYYY-MM-DD")
          )
          .then((response) => {
            if (
              response.data &&
              response.data.uuid &&
              response.data.uuid.length > 0
            ) {
              console.log("response: ", response.data);
              this.setState({
                uuid_carrier: response.data.uuid,
                data: response.data.carrierBasedData,
              });
              this.prepareDataToShow(response.data);
            } else {
              $("#loader").css("display", "none");
            }
          })
          .catch((error) => {
            console.log("Error in API in handleGetButtonClick: ", error);
            $("#loader").css("display", "none");
          })
          .finally(() => {
            $("#loader").css("display", "none");
          });
      } else if (currProspectDetails !== null) {
        let user_email = currProspectDetails.companyProfile.emailId.value
          ? currProspectDetails.companyProfile.emailId.value
          : email;

        currProspectDetails.companyProfile.emailId.value = user_email;
        sessionStorage.setItem(
          "currProspect",
          JSON.stringify(currProspectDetails)
        );

        // Auth.currentSession().then((res) => {
        // const USER_TOKEN = res.idToken.jwtToken;
        // const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
        axios
          .get(awsUrl + "/api/getUserDataStatus/" + user_email + "/quote/None")
          .then((response) => {
            this.setState({
              uuid_carrier: response.data.uuid,
              data: response.data.carrierBasedData,
            });
            this.prepareDataToShow(response.data);
            $("#loader").css("display", "none");
          })
          .catch((err) => {
            showErrAlert(err);
            $("#loader").css("display", "none");
          });
        // });
      } else {
        sessionStorage.setItem("formStage", "one");
        deleteAllCookies();
        sessionStorage.clear();
        window.location.reload();
      }
    }
  }

  prepareDataToShow(data) {
    let { setCurrProspect } = this.props;
    let currProspect = {};
    let isOverallLevel, isSalesforce;
    try {
      if (
        data.carrierBasedData &&
        (data.carrierBasedData.carrier_a ||
          data.carrierBasedData.carrier_b ||
          data.carrierBasedData.carrier_o)
      ) {
        let carrierObject = Object.values(data.carrierBasedData)[0];
        for (let line in carrierObject) {
          let lineObject = carrierObject[line];
          if (lineObject) {
            isSalesforce = lineObject.isSalesforce ? true : false;
            currProspect = lineObject.currProspect;
            if (isOverallLevel === undefined || isOverallLevel === null) {
              isOverallLevel = lineObject.isOverallLevel;
              this.setState({
                isOverallLevel:
                  isOverallLevel !== undefined && isOverallLevel !== null
                    ? isOverallLevel
                    : true,
              });
            }
          }
        }
        let {
          companyProfile,
          childrenLoc,
          emodStatesData,
          historicalClaims,
          historicalClaimsCheck,
        } = currProspect;

        let stateSet = {};
        for (let addressBlockNo in childrenLoc) {
          if (childrenLoc[addressBlockNo].state.value) {
            stateSet[childrenLoc[addressBlockNo].state.value] =
              childrenLoc[addressBlockNo].state.value;
          }
        }

        sessionStorage.setItem("stateSet", JSON.stringify(stateSet));
        sessionStorage.setItem("currProspect", JSON.stringify(currProspect));
        if (isSalesforce) sessionStorage.setItem("isSalesforce", "true");
        setCurrProspect(currProspect);

        this.setState({
          companyProfile,
          childrenLoc,
          emodStatesData,
          historicalClaims,
          historicalClaimsCheck,
          currProspect,
        });
      }
    } catch (error) {
      console.log("error in prepareDataToShow method");
    }
  }

  open() {
    this.setState({
      visible: true,
    });
  }
  close() {
    this.setState({
      visible: false,
    });
  }
  QuoteView(event) {
    let carrierTemplate = event.target.id.split("#+");
    let carrier = carrierTemplate[0];
    let timestamp = carrierTemplate[1];
    let getQuoteDetails = {};
    let email = JSON.parse(sessionStorage.getItem("currProspect")).emailAddress;
    getQuoteDetails.email = email;
    getQuoteDetails.timestamp = timestamp;
    getQuoteDetails.carrier = carrier;
    Auth.currentSession().then((res) => {
      const USER_TOKEN = res.idToken.jwtToken;
      const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
      axios
        .post(
          awsUrl + "/api/generateQuotePDF",
          JSON.stringify(getQuoteDetails),
          header
        )
        .then((res) => {
          let pdf = res.data.data;
          let fileName = "Quote.pdf";
          let file = blobPdfFromBase64String(pdf);
          var fileURL = URL.createObjectURL(file);
          let newWindow = window.open("./QuotePDF", "_blank");
          newWindow.onload = () => {
            newWindow.location = fileURL;
          };
        })
        .catch((err) => {
          console.log("Err", err);
          showErrAlert("View Quote is not available due to error");
        });
    });
  }
  getAllDataWRTCarrier(carrier, data) {
    let calculated_data = "";
    let requireData = {};
    let places = [];
    let teapSum = 0;
    for (let addres in data) {
      requireData["status"] = data[addres].quote_status;
      requireData["timestamp"] = data[addres].date;
      places.push(addres);
      teapSum += Number(data[addres].teap);
      calculated_data = data[addres].calculated_data;
    }

    requireData["places"] = places;
    requireData["teapSum"] = teapSum;
    requireData["calculated_data"] = calculated_data;
    return requireData;
  }
  render() {
    let { isOverallLevel, currProspect, uuid_carrier, data } = this.state;

    let calculated_data = "";
    if (data !== undefined && data !== null) {
      for (let carrier in data) {
        let requireData = this.getAllDataWRTCarrier(carrier, data[carrier]);
        calculated_data = requireData.calculated_data;
      }
    }

    return (
      <div id="cmprQts" className="container-fluid per98">
        {/* <NewProgress /> */}
        <div id="compareQuote">
          {data && uuid_carrier && isOverallLevel !== undefined && (
            <QuoteTable
              updatePopUpData={this.props.updatePopUpData}
              data={data}
              uuid_carrier={uuid_carrier}
              currProspect={currProspect}
              isOverallLevel={isOverallLevel}
            />
          )}

          {/* <div className="text-center newQuoteLink">
            <button
              type="button"
              className="linkLike btnInvisible"
              onClick={startFresh}
            >
              Start New Quote
            </button>
          </div>
          {this.state.data !== undefined && this.state.data !== null && (
            <div>
              {!this.state.visible && (
                <button type="button" onClick={this.open} className="btnGen">
                  Open
                </button>
              )}
              {this.state.visible && (
                <button type="button" onClick={this.close} className="btnGen">
                  Close
                </button>
              )}
              {this.state.visible && (
                <div>
                  <pre id="json">
                    {JSON.stringify(calculated_data, undefined, 10)}
                  </pre>
                </div>
              )}
            </div>
          )} */}
          <div> </div>
        </div>
      </div>
    );
  }
}
export default FormCmprQuote;
