import React, { useState } from "react";
import { Tab, Col, Row, Nav } from "react-bootstrap";
import FormCompPro from "../../component/subcompo/sections-quote/FormCompPro";
import FormCompareRate from "../../component/subcompo/sections-quote/FormCompareRate";
import FormUnderQues from "../../component/subcompo/sections-quote/FormUnderQues";
import FormCmprQuote from "../../component/subcompo/sections-quote/FormCmprQuote";
import Binder from "../../component/subcompo/sections-quote/Binder";
import { versionNumber } from "../../config";
import HeaderSubPart from "../../component/common/HeaderSubPart";

let isMobile = window.innerWidth < 992;
const stages = {
  one: 1,
  two: 2,
  // three: 3,
};

function TabNavItem(props) {
  let currProspect = sessionStorage.getItem("currProspect");
  let isHistoryVariant;
  if (currProspect) {
    isHistoryVariant = JSON.parse(currProspect).historicalQuote;
  }

  return (
    <Nav.Item>
      <Nav.Link
        eventKey={props.eventKey}
        className={`d-flex flex-row align-items-center ${
          props.disabled ? "disabled" : ""
        } ${props.active ? "active-tab" : ""}`}
      >
        <img src={props.image} width="30" height="30" alt={props.label} />
        <p className={`sidebar-stagename mb-0 ${props.active ? "active" : ""}`}>
          {props.label}
        </p>
        {props.dash && (
          <p className="ml-auto mr-2 mb-3 tick">
            <img src={require("../../images/tick_bw.png")} alt="Tick" />
          </p>
        )}
        {!props.dash && (
          <p
            className={`ml-auto mr-2 mb-3 ${
              props.completed ? "tick" : "disabled"
            }`}
          >
            <img src={require("../../images/tick.png")} alt="Tick" />
          </p>
        )}
      </Nav.Link>
    </Nav.Item>
  );
}

function SideTab(props) {
  const windowPath = window.location.pathname.toLowerCase().includes("/quote");
  let formStage = sessionStorage.getItem("formStage");
  let currProspect = sessionStorage.getItem("currProspect");
  const [currProspectState, setCurrProspect] = useState();

  let isHistoryVariant;
  if (currProspect) {
    isHistoryVariant = JSON.parse(currProspect).historicalQuote;
  }
  if (!formStage) {
    formStage = "one";
  }
  const tabLinks = [
    { label: "Company Profile", image: require("../../images/comPro2.png") },
    { label: "Get Quotes", image: require("../../images/getQuotes2.png") },
    // { label: "View Proposal", image: require("../../images/bind2.png") },
  ];

  let stageNumber = stages[formStage];
  for (let i = 0; i < tabLinks.length; i++) {
    let tabLink = tabLinks[i];
    tabLink.eventKey = Object.keys(stages)[i];
    // Disable if this stage is later than the current stage.
    tabLink.disabled = i >= stageNumber;
    // Completed if stateNuber is more than this stage.
    tabLink.completed = stageNumber > i + 1;
    if (isHistoryVariant) {
      if (i < 4) {
        tabLink.disabled = true;
        tabLink.completed = false;
        tabLink.dash = true;
      }
    }
    // Active tab if stageNumber equals this stage.
    tabLink.active = stageNumber === i + 1;
  }

  const navigationContent = (
    <Col
      md="auto"
      className="newquote-sidebar d-flex flex-column justify-content-between"
    >
      <Nav className="flex-column">
        <Nav.Item>
          <Nav.Link className="sidebar-heading" disabled>
            <span>
              Complete the process in a few easy steps and get your quote
            </span>
          </Nav.Link>
        </Nav.Item>
        {tabLinks.map((tabLink, index) => (
          <TabNavItem key={index} {...tabLink} />
        ))}
      </Nav>
      <div className="ml-4">Version {versionNumber}</div>
    </Col>
  );

  const NavigationSubcontent = (
    <Nav.Item>
      <Nav.Link>
        <HeaderSubPart />
      </Nav.Link>
    </Nav.Item>
  );

  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey={formStage}>
      <div
        className={`   ${isMobile ? " bg-white" : "row"}
         ${windowPath ? "position-relative" : ""} no-gutters  `}
        style={{ marginTop: "-5px" }}
      >
        {!isMobile && windowPath ? (
          <>{navigationContent}</>
        ) : (
          <nav className="navwidth newquote-sidebar">
            <input
              type="checkbox"
              id="menu"
              name="menu"
              className="m-menu__checkbox bg-white"
            />
            <label className="m-menu__toggle" for="menu">
              <svg
                width="35"
                height="35"
                viewBox="0 0 24 24"
                fill="none"
                stroke="#000"
                stroke-width="2"
                stroke-linecap="butt"
                stroke-linejoin="arcs"
              >
                <line x1="3" y1="12" x2="21" y2="12"></line>
                <line x1="3" y1="6" x2="21" y2="6"></line>
                <line x1="3" y1="18" x2="21" y2="18"></line>
              </svg>
            </label>
            <label className="m-menu__overlay" for="menu"></label>

            <div className="m-menu">
              <div className="m-menu__header">
                <label className="m-menu__toggle" for="menu">
                  <svg
                    width="35"
                    height="35"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="#000000"
                    stroke-width="2"
                    stroke-linecap="butt"
                    stroke-linejoin="arcs"
                  >
                    <line x1="18" y1="6" x2="6" y2="18"></line>
                    <line x1="6" y1="6" x2="18" y2="18"></line>
                  </svg>
                </label>
                <span></span>
              </div>

              {windowPath ? navigationContent : ""}
              <hr
                style={{
                  width: "90%",
                  backgroundColor: "grey",
                }}
              />
              {NavigationSubcontent}
            </div>
          </nav>
        )}
        {!windowPath ? (
          ""
        ) : (
          <Col className="newquote-content">
            <Tab.Content>
              <Tab.Pane eventKey="one">
                <FormCompPro
                  updatePopUpData={props.updatePopUpData}
                  getData={currProspectState}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="two">
                <FormCmprQuote
                  updatePopUpData={props.updatePopUpData}
                  setCurrProspect={setCurrProspect}
                />
              </Tab.Pane>
              {/* <Tab.Pane eventKey="three">
        <Blank />
      </Tab.Pane> */}
            </Tab.Content>
          </Col>
        )}
      </div>
    </Tab.Container>
  );
}

export default SideTab;
