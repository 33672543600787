import React, { Component } from "react";
import Newcode from "./Newcode";
import DownloadLink from "../../subcompo/DownloadLink";
class FormCompLoc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      addressChildNodes: {},
      flage: true,
    };
  }

  componentDidUpdate() {
    if (this.props.locInfoParent !== this.state.locInfo) {
      this.setState({ locInfo: this.props.locInfoParent });
    }
    if (this.props.locInfoErrorStoreParent !== this.state.locInfoErrorStore) {
      this.setState({ locInfoErrorStore: this.props.locInfoErrorStoreParent });
    }
  }

  render() {
    const {
      addressBlockNo,
      state,
      updateLocInfo,
      handleMinMax,
      minMax,
      updateLocInfoErrorStore,
    } = this.props;
    const { locInfo, locInfoErrorStore } = this.state;

    let dupAddressChildNodes = {};
    let addressChildNodes = {};
    if (locInfo) {
      for (let i in locInfo[addressBlockNo]) {
        dupAddressChildNodes[i] = (
          <Newcode
            state={state}
            minMax={minMax}
            handleMinMax={handleMinMax}
            key={i}
            addressChildNo={i}
            addressBlockNo={addressBlockNo}
            rmvChild={this.onRemoveChildCode}
            updateLocInfo={updateLocInfo}
            locInfoParent={locInfo}
            locInfoErrorStoreParent={locInfoErrorStore}
            updateLocInfoErrorStore={updateLocInfoErrorStore}
          />
        );
      }
      addressChildNodes = dupAddressChildNodes;
    }

    return (
      <div className="bottomSpace" id={"addBlock" + addressBlockNo}>
        <div className="row">
          <div className="col-sm-8">
            <p className="locationName d-flex">
              <span className="addressFull addressRate"></span>
              <DownloadLink state={state} />
            </p>
          </div>
          <div className="col-sm-4 d-flex justify-content-center">
            <p className="locationName addressRate block">
              Manual Premium Range
            </p>
          </div>
        </div>
        <ParentComponentCode addChildCode={this.onAddChildCode}>
          {Object.values(addressChildNodes)}
        </ParentComponentCode>
      </div>
    );
  }
  onAddChildCode = () => {
    const { addressBlockNo, updateLocInfo } = this.props;
    const { locInfo } = this.state;
    let codeCount = Object.keys(locInfo[addressBlockNo]).reduce((prev, val) => {
      return Math.max(Number(val), Number(prev));
    });
    updateLocInfo(addressBlockNo, Number(codeCount) + 1, undefined, undefined);
  };

  onRemoveChildCode = (addressChildNo) => {
    const { addressBlockNo, removeLocInfo } = this.props;
    removeLocInfo(addressBlockNo, addressChildNo);
  };
}

const ParentComponentCode = (props) => (
  <div className="calculator">
    <div>
      <div id="addressDiv2">{props.children}</div>
    </div>
    <div>
      <button
        type="button"
        className="btnInvisible linkLike addLoc transpButton"
        onClick={props.addChildCode}
      >
        {/* Add New Code */}
        <img src={require("../../../images/plus4.png")} />
      </button>
    </div>
  </div>
);

export default FormCompLoc;
