import React, { Component } from "react";
import numeral from "numeral";

class TableSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getRows = (headers, highlightIndex) => {
    let rows = [];
    if (highlightIndex) {
      highlightIndex = Number(highlightIndex);
    }
    let headerIndex = 1;
    for (let header of headers) {
      let cells = [];
      for (let i = 1; i <= 5; i++) {
        cells.push(
          <td
            key={i}
            style={
              highlightIndex === i && highlightIndex === headerIndex
                ? { backgroundColor: "#ffff00" }
                : {}
            }
          ></td>
        );
      }

      rows.push(
        <tr key={`${header}`}>
          <td className="left-header">{header}</td>
          {cells}
        </tr>
      );

      headerIndex++;
    }

    return rows;
  };

  createTable = () => {
    let { table, tableSelectionData } = this.props;
    let { individualTableSelections, tableWeightagePercentage } =
      tableSelectionData;
    let { clientRelationValue, hazardGroupValue, stateRiskFactorValue } =
      individualTableSelections ? individualTableSelections : {};
    let tableBody, tableData;
    let clientRelationRows, hazardRows, stateRiskRows;
    let clientRelationHeaders = [
      "New Client",
      "1st Renewal",
      "2nd Renewal",
      "3rd Renewal+",
    ];
    let hazardHeaders = [
      "Low (1 to 3)",
      "Medium (4 to 5)",
      "High (6 to 9)",
      "Very High (10)",
    ];
    let stateRiskHeaders = ["Low", "Medium", "High", "Very High"];

    clientRelationRows = this.getRows(
      clientRelationHeaders,
      clientRelationValue
    );
    hazardRows = this.getRows(hazardHeaders, hazardGroupValue);
    stateRiskRows = this.getRows(stateRiskHeaders, stateRiskFactorValue);

    tableBody = (
      <tbody>
        <tr>
          <th rowSpan={2} className="align-middle">
            TABLE
          </th>
          <td style={table === 1 ? { backgroundColor: "#92d050" } : {}}>1</td>
          <td style={table === 2 ? { backgroundColor: "#92d050" } : {}}>2</td>
          <td style={table === 3 ? { backgroundColor: "#92d050" } : {}}>3</td>
          <td style={table === 4 ? { backgroundColor: "#92d050" } : {}}>4</td>
          <td></td>
        </tr>
        <tr>
          <td>Mean</td>
          <td>70%</td>
          <td>80%</td>
          <td>90%</td>
          <th className="text-center">WEIGHT</th>
        </tr>
        <tr>
          <th colSpan={5}>Client Relationship</th>
          <td>
            {tableWeightagePercentage &&
            tableWeightagePercentage.clientRelationship
              ? `${tableWeightagePercentage.clientRelationship}%`
              : ""}
          </td>
        </tr>
        {clientRelationRows}
        <tr>
          <th colSpan={5}>AM Best Hazard</th>
          <td>
            {tableWeightagePercentage && tableWeightagePercentage.hazard
              ? `${tableWeightagePercentage.hazard}%`
              : ""}
          </td>
        </tr>
        {hazardRows}
        <tr>
          <th colSpan={5}>State Risk Class</th>
          <td>
            {tableWeightagePercentage && tableWeightagePercentage.stateRisk
              ? `${tableWeightagePercentage.stateRisk}%`
              : ""}
          </td>
        </tr>
        {stateRiskRows}
      </tbody>
    );

    tableData = (
      <table className="table table-sm table-bordered">
        <thead>
          <tr>
            <th className="text-center main-header" colSpan={6}>
              TABLE SELECTION
            </th>
          </tr>
        </thead>
        {tableBody}
      </table>
    );

    this.setState({ tableData });
  };

  componentDidMount = () => {
    this.createTable();
  };

  componentDidUpdate = (prevProps) => {
    let { tableSelectionData, table } = this.props;
    if (
      table !== prevProps.table ||
      JSON.stringify(prevProps.tableSelectionData) !==
        JSON.stringify(tableSelectionData)
    ) {
      this.createTable();
    }
  };

  render() {
    let { tableData } = this.state;
    return <div className="table-section">{tableData}</div>;
  }
}

export default TableSection;
