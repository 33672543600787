import React, {Component} from 'react';
import Papa from 'papaparse';
import { Auth } from 'aws-amplify'; 
//var FileInput = require('react-file-input');
class FileUpload extends Component {
  // handleChange =(event) => {
  //   console.log('Selected file:', event.target.files[0]);
  // }
  // render() {
  //   return (
  //      {/* <FileInput name="myImage"
  //                  accept=".csv"
  //                  placeholder="My Image"
  //                  className="inputFile"
  //   onChange={this.handleChange} />*/}
  //   );
  // }

  constructor(props) {
    super(props);
    this.state = {
      csvfile: undefined,
      carrier : ""
    };
    this.updateData = this.updateData.bind(this);
  }

  componentDidMount(){
    Auth.currentSession().then(res=>{
      let userName = res.accessToken.payload.username.split("@")[0];
      this.setState({
          carrier : userName
      })
    }).catch(err=>{
      console.log("error: ", err);
    })
  }
  handleChange = event => {
    this.setState({
      csvfile: event.target.files[0]
    });
  };

  importCSV = () => {
    const { csvfile } = this.state;
   
    // var Papa = require("papaparse/papaparse.min.js");
    if(csvfile !== undefined && csvfile !== ""){
      Papa.parse(csvfile, {
        complete: this.updateData,
        header: true,
        skipEmptyLines: true
      });
    }
  };

  updateData(result) {
    var data = result.data;
  
   
    if(this.props.fileName === "GeneralFactorTable" || this.props.fileName === "DeductibleTable" || this.props.fileName === "LcmTable"){
      let tempData = [];
      for(let dict of data){
        dict.carrier = this.state.carrier;
        tempData.push(dict);
      }
      data = tempData;
    }
    this.props.sendData(data);
  }

  render() {
    return (
      <div className="file-uploader">
        <input
          className="csv-input"
          accept=".csv"
          type="file"
          ref={input => {
            this.filesInput = input;
          }}
          name="file"
          placeholder={null}
          onChange={this.handleChange}
        ></input>
        <p />
        <button onClick={this.importCSV}> Upload now!</button>
      </div>
    );
  }
  
}
export default FileUpload;