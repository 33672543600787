import React, { Component } from "react";

class QuoteComparision extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
      currProspectData,
      sortedCarriers,
      quoteStatusCarrier,
      allBoundFlag,
      handleCarrierChecked,
      quoteTable,
    } = this.props;
    let carrierMap = {
      carrier_a: process.env.REACT_APP_MODE == "beta" ? "LD" : "CHUBB",
      carrier_b: process.env.REACT_APP_MODE == "beta" ? "GC" : "FIC",
      carrier_o: process.env.REACT_APP_MODE == "beta" ? "SF" : "State Fund",
    };

    if (sortedCarriers) sortedCarriers.sort();

    return (
      <div>
        <div className="quote-header d-flex justify-content-between">
          <div>
            <h3>
              <b>Quote Comparision</b>
            </h3>
          </div>
          <div className="d-flex flex-column align-items-end">
            <b>
              <span>
                Client Name:{" "}
                {currProspectData ? currProspectData.companyName : ""}
              </span>
            </b>
            <b>
              <span>
                FEIN:{" "}
                {currProspectData ? currProspectData.federalEmployeeId : ""}
              </span>
            </b>
          </div>
        </div>
        <div style={{ backgroundColor: "black", marginTop: "-10px" }}>
          <hr />
        </div>
        <div className="row no-gutters body-row table-responsive">
          <table className="table table-borderless Quote-head">
            <thead>
              <tr>
                <th></th>
                <th className="text-center ">
                  <span>GC</span>
                </th>
                <th className="text-center">
                  <span>Charge</span>
                </th>
                <th className="text-center">
                  <span>Cost</span>
                </th>
                <th className="text-center">
                  <span>Matched</span>
                </th>
              </tr>
            </thead>
            <tbody>
              {quoteTable &&
                sortedCarriers.map((c, index) => (
                  <tr key={index}>
                    <td style={{ textTransform: "capitalize" }}>
                      <div className="d-flex flex-row align-items-center">
                        <input
                          id={`carrierName-${c}`}
                          disabled={
                            quoteStatusCarrier[c].disableCheckBox ||
                            allBoundFlag
                              ? true
                              : false
                          }
                          type="checkbox"
                          className="mr-1"
                          checked={
                            quoteTable[c] && quoteTable[c].state
                              ? quoteTable[c].state.selected
                              : false
                          }
                          name={c}
                          onChange={handleCarrierChecked}
                        />{" "}
                        <label for={`carrierName-${c}`}>{carrierMap[c]}</label>
                      </div>
                    </td>
                    <td>
                      {quoteTable[c].state && quoteTable[c].state.quoted ? (
                        <div className="d-flex flex-row align-items-center justify-content-center">
                          <label style={{ color: "#1463ac" }}>
                            $
                            {quoteTable[
                              c
                            ].state.quote.guaranteed.toLocaleString("en")}
                          </label>
                          <span className="d-flex flex-column">
                            {quoteStatusCarrier &&
                              (quoteStatusCarrier[c].state === "uwportal" ||
                                quoteStatusCarrier[c].state === "hold") && (
                                <span className="uw text-danger">
                                  UW Referral
                                </span>
                              )}
                            {quoteStatusCarrier &&
                              quoteStatusCarrier[c].state === "reject" && (
                                <span className="uw text-danger">Rejected</span>
                              )}
                          </span>
                        </div>
                      ) : (
                        <p> - </p>
                      )}
                    </td>
                    <td>
                      {quoteTable[c].state && quoteTable[c].state.quoted ? (
                        <div className="d-flex flex-row align-items-center justify-content-center">
                          <label style={{ color: "#1463ac" }}>
                            $
                            {quoteTable[c].state.quote.charge.toLocaleString(
                              "en"
                            )}
                          </label>
                          <span className="d-flex flex-column">
                            {quoteStatusCarrier &&
                              (quoteStatusCarrier[c].state === "uwportal" ||
                                quoteStatusCarrier[c].state === "hold") && (
                                <span className="uw text-danger">
                                  UW Referral
                                </span>
                              )}
                            {quoteStatusCarrier &&
                              quoteStatusCarrier[c].state === "reject" && (
                                <span className="uw text-danger">Rejected</span>
                              )}
                          </span>
                        </div>
                      ) : (
                        <p> - </p>
                      )}
                    </td>
                    <td>
                      {quoteTable[c].state && quoteTable[c].state.quoted ? (
                        <div className="d-flex flex-row align-items-center justify-content-center">
                          <label>
                            $
                            {quoteTable[c].state.quote.cost.toLocaleString(
                              "en"
                            )}
                          </label>
                          <span className="d-flex flex-column">
                            {quoteStatusCarrier &&
                              (quoteStatusCarrier[c].state === "uwportal" ||
                                quoteStatusCarrier[c].state === "hold") && (
                                <span className="uw text-danger">
                                  UW Referral
                                </span>
                              )}
                            {quoteStatusCarrier &&
                              quoteStatusCarrier[c].state === "reject" && (
                                <span className="uw text-danger">Rejected</span>
                              )}
                          </span>
                        </div>
                      ) : (
                        <p> - </p>
                      )}
                    </td>
                    <td>
                      {quoteTable[c].state && quoteTable[c].state.quoted ? (
                        <div className="d-flex flex-row align-items-center justify-content-center">
                          <label>
                            $
                            {quoteTable[c].state.quote.rateVal !== 0
                              ? quoteTable[
                                  c
                                ].state.quote.rateVal.toLocaleString("en")
                              : quoteTable[c].state.quote.charge.toLocaleString(
                                  "en"
                                )}
                          </label>
                          <span className="d-flex flex-column">
                            {quoteStatusCarrier &&
                              (quoteStatusCarrier[c].state === "uwportal" ||
                                quoteStatusCarrier[c].state === "hold") && (
                                <span className="uw text-danger">
                                  UW Referral
                                </span>
                              )}
                            {quoteStatusCarrier &&
                              quoteStatusCarrier[c].state === "reject" && (
                                <span className="uw text-danger">Rejected</span>
                              )}
                          </span>
                        </div>
                      ) : (
                        <p> - </p>
                      )}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  }
}

export default QuoteComparision;
