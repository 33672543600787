import React, { Component, forwardRef } from "react";

import numeral from "numeral";
import MaterialTable, { MTableToolbar } from "material-table";
import { TablePagination } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Clamp from "react-multiline-clamp";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";

const arrowOrNot = (flag) => {
  if (flag)
    return (
      <div class="arrow1">
        <div class="point"></div>
        <div class="line"></div>
      </div>
    );
  return <></>;
}

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
};

export default class DashboardGrid extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboard_data: this.props.dashboard_data || [],
      isLoading: true,
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props.selectedMetric !== prevProps.selectedMetric)
      this.setState({ dashboard_data: this.props.dashboard_data });
  }

  setTableData = () => {
    let columns = [
      {
        title: "State",
        field: "state",
        cellStyle: {
          padding: "6px 0px 6px 10px",
        },
        headerStyle: {
          padding: "6px 0px 6px 10px",
        },
        render: (rowData) => (
          <div className="fw-bold">{rowData.state.toUpperCase()}</div>
        ),
      },
      {
        title: "Submitted",
        field: "submitted",
        cellStyle: {
          padding: "6px 0 6px 0",
        },
        defaultSort: "desc",
        render: (rowData) => (
          <div className="fw-bold">
            {this.props.selectedMetric === "volume" ? rowData.submitted : numeral(rowData.submitted).format("$0,0")}
          </div>
        ),
      },
      {
        title: "Quoted",
        field: "quote",
        cellStyle: {
          padding: "6px 0 6px 0",
        },
        render: (rowData) => (
          <div className="fw-bold">{this.props.selectedMetric === "volume" ? rowData.quote : numeral(rowData.quote).format("$0,0")}</div>
        ),
      },
      {
        title: "Bound",
        field: "bound",
        cellStyle: {
          padding: "6px 0 6px 0",
        },
        render: (rowData) => (
          <div className="fw-bold">{this.props.selectedMetric === "volume" ? rowData.bound : numeral(rowData.bound).format("$0,0")}</div>
        ),
      },
      {
        title: "Quoted/\nSubmitted",
        field: "q_s",
        cellStyle: (e, rowData) => {
          return {
            padding: "6px 0 6px 0",
            // color:
            //   this.props.total_data_grid.q_s < rowData.q_s
            //     ? "#49b341"
            //     : "inherit",
            // backgroundColor:
            //   this.props.total_data_grid.q_s < rowData.q_s
            //     ? "#b0eb9a"
            //     : "inherit",
          };
        },
        render: (rowData) => {
          let flag = this.props.total_data_grid.q_s < rowData.q_s;
          return <div className="fw-bold">
            <div id="fw-bold-text">{rowData.q_s + "%"}</div>
            <div style={{ marginLeft: "5px" }}>
              {arrowOrNot(flag)}
            </div>
          </div>
        },
      },
      {
        title: "Bound/\nQuoted",
        field: "b_q",
        cellStyle: (e, rowData) => {
          return {
            padding: "6px 0 6px 0",
            // color:
            //   this.props.total_data_grid.b_q < rowData.b_q
            //     ? "#49b341"
            //     : "inherit",
            // backgroundColor:
            //   this.props.total_data_grid.b_q < rowData.b_q
            //     ? "#b0eb9a"
            //     : "inherit",
          };
        },
        render: (rowData) => {
          let flag = this.props.total_data_grid.b_q < rowData.b_q;
          return <div className="fw-bold">
            <div id="fw-bold-text">{rowData.b_q + "%"}</div>
            <div style={{ marginLeft: "5px" }}>
              {arrowOrNot(flag)}
            </div>
          </div>
        },
      },
      {
        title: "Bound/\nSubmitted",
        field: "b_s",
        cellStyle: (e, rowData) => {
          return {
            padding: "6px 0 6px 10px",
            // color:
            //   this.props.total_data_grid.b_s < rowData.b_s
            //     ? "#49b341"
            //     : "inherit",
            // backgroundColor:
            //   this.props.total_data_grid.b_s < rowData.b_s
            //     ? "#b0eb9a"
            //     : "inherit",
          };
        },
        render: (rowData) => {
          let flag = this.props.total_data_grid.b_s < rowData.b_s;
          return <div className="fw-bold">
            <div id="fw-bold-text">{rowData.b_s + "%"}</div>
            <div style={{ marginLeft: "5px" }}>
              {arrowOrNot(flag)}
            </div>
          </div>
        }
      },
    ];

    return (
      <MaterialTable
        icons={tableIcons}
        data={this.state.dashboard_data}
        columns={columns}
        options={{
          tableLayout: "auto",
          padding: "default",
          showTitle: false,
          thirdSortClick: false,
          searching: false,
          pageSize: 50,
          pageSizeOptions: [50, 100],
          headerStyle: {
            position: "sticky",
            top: 0,
            fontSize: "15px",
            backgroundColor: "#003764",
            color: "#FFF",
            padding: "6px 0 6px 0",
          },
          rowStyle: (rowData) => ({
            fontSize: "12px",
            fontWeight: "700",
          }),
        }}
      />
    );
  };

  render() {
    return (
      <div className="extensis-grid">
        <div>{this.setTableData()}</div>
      </div>
    );
  }
}
