import React from "react";
import axios from "axios";
import { Form } from "react-bootstrap";
import { awsUrl2 } from "../config";
import LoadingOverlay from "react-loading-overlay";
import Navbar from "../component/common/Navbar";
import DatePicker from "react-datepicker";
import { Auth } from "aws-amplify";
import SideTab from "./common/SideTab";
import folder from "../images/download_folder (2).png";
const moment = require("moment");
let ismobile = window.innerWidth < 992;

export default class DownloadExistingData extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isDownloadClicked: false,
      downloadingInProgress: false,
      error: {
        show: false,
        message: "",
      },
    };
  }
  linkRef = React.createRef();

  componentWillMount() {
    let dTo, dFrom;
    dTo = moment.utc().format("YYYY-MM-DD");
    dFrom = moment.utc().subtract(1, "years").format("YYYY-MM-DD");
    this.setState({ dTo, dFrom });
  }

  getDataLink = async (userEmail, time) => {
    return new Promise((resolve, reject) => {
      axios
        .post(
          awsUrl2 + `/api/downloadFileStatus/get/existing`,
          JSON.stringify({
            email: userEmail,
            date: time,
          })
        )
        .then((response) => {
          resolve({ file: response.data[0].file });
        })
        .catch((err) => {
          console.log("error: ", err);
          reject({ error: err });
        });
    });
  };

  downloadFile = async (file) => {
    axios
      .get(file, {
        responseType: "blob",
      })
      .then((response) => {
        const href = window.URL.createObjectURL(new Blob([response.data]));
        const a = this.linkRef.current;
        a.download = "Existing_File.csv";
        a.href = href;
        a.click();
        a.href = "";
        this.setState({ downloadingInProgress: false });
      })
      .catch((error) => {
        console.log("error in download file: ", error);
        this.setState({ downloadingInProgress: false });
      });
  };

  isValidDateRange = () => {
    let { dFrom, dTo } = this.state;
    let diff = moment
      .utc(dTo, "YYYY-MM-DD")
      .diff(moment.utc(dFrom, "YYYY-MM-DD"), "years", true);
    if (diff <= 2) {
      return true;
    }
    return false;
  };

  onClickDownload = async () => {
    let { isDownloadClicked, dTo, dFrom, error } = JSON.parse(
      JSON.stringify(this.state)
    );
    if (isDownloadClicked) return;
    let validRange = this.isValidDateRange();
    if (!validRange) {
      error.message = "The date range cannot be greater than 2 years";
      error.show = true;
      this.setState({ error });
      return;
    }
    error.show = false;
    this.setState({ downloadingInProgress: true, error });
    let time = Date.now();
    let isLoggedIn = await Auth.currentAuthenticatedUser();
    let userEmail = isLoggedIn.attributes.email;
    await axios
      .post(
        awsUrl2 + `/api/downloadFileStatus/put/existing`,
        JSON.stringify({
          email: userEmail,
          date: time,
          dTo,
          dFrom,
        })
      )
      .then(async (res) => {
        let file_url;
        while (!file_url) {
          let obj = await this.getDataLink(userEmail, time);
          if (obj && obj.file) {
            await this.downloadFile(obj.file);
            file_url = obj.file;
            this.setState({ isDownloadClicked: false });
            break;
          }
          let timeID = await new Promise((resolve, reject) => {
            let myTimeout = setTimeout(() => {
              resolve(myTimeout);
            }, 5000);
          });
          clearTimeout(timeID);
        }
      })
      .catch((err) => {
        console.log("error: ", err);
        this.setState({ downloadingInProgress: false });
      });
  };

  render() {
    let { dFrom, dTo, downloadingInProgress, error } = this.state;
    return (
      <>
        <div>
          <Navbar />
        </div>

        <div className="position-relative">
          {ismobile ? <SideTab /> : " "}
          <div
            id="download-existing"
            className="d-flex align-items-center justify-content-center"
            style={{
              marginTop: "-7px",
              flexDirection: "column",
            }}
          >
            <LoadingOverlay
              className="w-100"
              active={downloadingInProgress}
              spinner
            >
              <div className="card">
                <div className="card-body-download">
                  <div className="card-img-top">
                    <img
                      className="card-img-top"
                      src={folder}
                      alt="Download Image"
                    />
                  </div>
                  <div className="d-flex flex-column text-center align-items-center">
                    <div className="download-heading">
                      <h5>
                        <b>Existing Clients Data</b>
                      </h5>
                    </div>

                    <div className="container ">
                      <span className="m-0">Max range (2 years)</span>
                      <div className="download_folder ">
                        <div className="From_Download ">
                          <div>
                            <Form.Label
                              className="m-0"
                              htmlFor="date-from"
                              title="Form"
                              style={{ color: "dimgrey", borderRadius: "20px" }}
                            >
                              <h6 className="m-2">From: </h6>
                            </Form.Label>
                          </div>

                          <div>
                            <span id="date-from">
                              <DatePicker
                                selected={moment.utc(dFrom, ["YYYY-MM-DD"])}
                                onChange={(e) => {
                                  this.setState({
                                    dFrom: moment.utc(e).format("YYYY-MM-DD"),
                                  });
                                }}
                                maxDate={moment.utc(dTo, ["YYYY-MM-DD"])}
                                showYearDropdown
                                showMonthDropdown
                                useShortMonthInDropdown
                              />
                            </span>
                          </div>
                        </div>

                        <div className="From_Download ">
                          <div>
                            <Form.Label
                              className="m-0"
                              htmlFor="date-to"
                              title="To"
                              style={{ color: "dimgrey", borderRadius: "20px" }}
                            >
                              <h6 className="m-2">To: </h6>
                            </Form.Label>
                          </div>

                          <span id="date-to">
                            <DatePicker
                              selected={moment.utc(dTo, ["YYYY-MM-DD"])}
                              onChange={(e) => {
                                this.setState({
                                  dTo: moment.utc(e).format("YYYY-MM-DD"),
                                });
                              }}
                              minDate={moment.utc(dFrom, ["YYYY-MM-DD"])}
                              showYearDropdown
                              showMonthDropdown
                              useShortMonthInDropdown
                            />
                          </span>
                        </div>
                      </div>
                      {error.show && (
                        <div className="errMsg">{error.message}</div>
                      )}

                      <button
                        className="align-items-center"
                        style={{
                          width: "84%",
                          backgroundColor: "#1463AC",
                          margin: "20px",
                          height: "auto",
                          marginTop: "0px",
                          paddingTop: "10px",
                          color: "WHITE",
                          // borderRadius: "20px",
                          border: "none",
                        }}
                        onClick={this.onClickDownload}
                      >
                        <h6 className="Download-button">Download</h6>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </LoadingOverlay>
            <a ref={this.linkRef} />
          </div>
        </div>
      </>
    );
  }
}
