import React from "react";
import BinderChild from "./BinderChild";
class BinderDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount(){
    if (
      sessionStorage.getItem("require") !== undefined &&
      sessionStorage.getItem("require") !== null &&
      sessionStorage.getItem("currProspect") !== null &&
      sessionStorage.getItem("currProspect") !== undefined
    ) {
      let require = sessionStorage.getItem("require");
      let email = JSON.parse(sessionStorage.getItem("currProspect"))
        .emailAddress;
      let timestamp = require.split("@")[0];
      let body = [];
     
      let carrierObj = {};

      require.split("!!").map(val1 => {
        let carrierName =
          "carrier_" +
          val1
            .split("+")[0]
            .split("_")
            .reverse()[0];
        let stateName = val1.split("_").reverse()[1];
        if (!(carrierName in carrierObj)) {
          carrierObj[carrierName] = [];
        }
        if (!carrierObj[carrierName].includes(stateName)) {
          carrierObj[carrierName].push(stateName);
        }
      });
      
      for (let carrier in carrierObj) {
        body.push({
          email: email,
          timestamp: timestamp,
          carrier: carrier,
          state: carrierObj[carrier]
        });
      }
      
      console.log("++data binder", body);
      if (!this.state.binderData) {
        this.setState({ binderData: body });
      }
    }
  }
  render() {
    const {binderData} = this.state;
    return(
      <div>
        {binderData && binderData.map(d => {
          let rand = Math.random();
						return <BinderChild key={rand} data={d}/>
					})}
      </div>
    );
  }
}
export default BinderDownload;
