import { tempURL, apiURL, localURL, awsUrl } from "../config";
import { setCookie } from "./session_data";
import { Auth } from "aws-amplify";
import axios from "axios";
import $ from "jquery";
import { from } from "zen-observable";
import { getUserAuthStatus, showErrAlert } from "./common";
import { awsUrl2 } from "../config";
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require("bootstrap");

export function selectComponentToRender(userGroups) {
  let userGroupPriorityOrder = [
    process.env.REACT_APP_DASHBOARD_TABLES_GROUP,
    process.env.REACT_APP_DASHBOARD_STATS_GROUP,
    process.env.REACT_APP_EXTENSIS_USER_GROUP,
    process.env.REACT_APP_DOWNLOAD_EXISTING_GROUP,
    process.env.REACT_APP_COMPLIANCE_GROUP,
    process.env.REACT_APP_UPLOAD_EXISTING_GROUP,
    process.env.REACT_APP_UPLOAD_NEW_GROUP,
    process.env.REACT_APP_UPLOAD_RENEWAL_GROUP,
  ];

  let userGroupComponentMapping = {
    [process.env.REACT_APP_DASHBOARD_TABLES_GROUP]: "./Dashboard",
    [process.env.REACT_APP_DASHBOARD_STATS_GROUP]: "./Dashboard",
    [process.env.REACT_APP_EXTENSIS_USER_GROUP]: "./Quote",
    [process.env.REACT_APP_DOWNLOAD_EXISTING_GROUP]: "./Download-e",
    [process.env.REACT_APP_COMPLIANCE_GROUP]: "./PanelCompliance",
    [process.env.REACT_APP_UPLOAD_EXISTING_GROUP]: "./Upload-e",
    [process.env.REACT_APP_UPLOAD_NEW_GROUP]: "./Upload-n",
    [process.env.REACT_APP_UPLOAD_RENEWAL_GROUP]: "./Upload-r",
  };

  let response;

  try {
    for (let group of userGroupPriorityOrder) {
      if (userGroups.includes(group)) {
        response = userGroupComponentMapping[group];
        break;
      }
    }

    if (!response) {
      throw "no group found";
    }

    return response;
  } catch (error) {
    console.log("error in selectComponentToRender", error);
    throw {
      code: "NotAuthorizedException",
      message: "User is not authorized!",
    };
  }
}

export async function signIn(e) {
  e.preventDefault();
  let validForm = validateForm();
  if (validForm) {
    $("#loader").css("display", "block");
    const username = $("#userId").val();
    const password = $("#userPassword").val();
    try {
      var userTemp = await Auth.signIn(username.toLowerCase(), password);

      console.log("UserTemp: ", userTemp);
      if (userTemp.challengeName === "NEW_PASSWORD_REQUIRED") {
        $("#link").click();
        $("#loader").css("display", "none");
      } else {
        if (userTemp.signInUserSession !== null) {
            const userData = {
              email_id: username.toLowerCase(),
              stage: process.env.REACT_APP_MODE,
              client: "Extensis"
            }
            try {
              await axios.post(awsUrl2 + "/api/trackUserSession", userData)
              console.log("UserSessionManagement table is updated")
            } catch (error) {
              console.log("Error in updating the UserSessionManagement table")
            }
          let componentToRender = selectComponentToRender(
            userTemp.signInUserSession.accessToken.payload["cognito:groups"]
          );
          sessionStorage.setItem("isLoggedIn", true);
          window.location.href = componentToRender;
        } else {
          sessionStorage.clear();
          localStorage.clear();
          throw {
            code: "NotAuthorizedException",
            message: "User is not authorized!",
          };
        }
      }
    } catch (err) {
      let errMsg;
      sessionStorage.clear();
      localStorage.clear();
      $("#loader").css("display", "none");
      console.log("error signing in! :", err);
      if (
        err.code === "UserNotFoundException" ||
        err.code === "NotAuthorizedException"
      ) {
        errMsg = err.message;
      } else {
        errMsg =
          "Unable to login! Please contact admin@insurecomp.com for more details!";
      }
      $("#loginSubmit")
        .siblings(".errMsg")
        .html("<br /> " + errMsg);
    }
  }
}

function validateForm() {
  var form = true;
  if (
    $("#userId").val() === "" ||
    document.getElementById("userId").style.borderColor === "red"
  ) {
    $("#userId").css("border-color", "rgb(255, 0, 0)");
    $("#userId").siblings(".errMsg").html("Email is required");
    $("#userId").focus();
    form = false;
  }
  if (
    $("#userPassword").val() === "" ||
    document.getElementById("userId").style.borderColor === "red"
  ) {
    $("#userPassword").css("border-color", "rgb(255, 0, 0)");
    $("#userPassword").siblings(".errMsg").html("Password is required");
    $("#userPassword").focus();
    form = false;
  }
  return form;
}
