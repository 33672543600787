import React, { Component } from "react";
import ReactDOM from "react-dom";
import Loader from "../common/Loader";
import Navbar from "../common/Navbar";
import FormLogin from "../subcompo/sections-login/FormLogin";
import FormReset from "../subcompo/sections-login/FormReset";
import Footer from "../common/Footer";
import FormChangePass from "../subcompo/sections-login/FormChangePass";
import ImageBlock from "../subcompo/sections-login/ImageBlock";
const loginImage = require("../../images/loginExtensis.webp");

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = { form: "login" };
  }
  componentDidMount() {
    ReactDOM.findDOMNode(this).scrollTop = 0;
  }

  changeForm = (value) => {
    this.setState({ form: value });
  };

  render() {
    let { form } = this.state;
    let toRender;
    if (form === "login") toRender = <FormLogin changeForm={this.changeForm} />;
    else if (form === "reset") toRender = <FormReset />;
    else if (form === "change") toRender = <FormChangePass />;

    return (
      <div>
        <Navbar variant="large-logo" />
        <div className="tab-content tab-content-login">
          <div className="loginPage">
            <ImageBlock
              scrollToElement="#formLogin"
              imageURL={loginImage}
              altText="loginImageExtensis"
            />
            {toRender}
            <Loader />
          </div>
          <Footer />
        </div>
      </div>
    );
  }
}

export default Login;
