import { MapControl, withLeaflet } from "react-leaflet";
import L from "leaflet";

class Legend extends MapControl {
  createLeafletElement(props) { }

  // componentDidUpdate() {
  //   this.createLeafletElement(this.props);
  // }
  componentDidMount() {
    let divToUse;
    const getColor = (d) => {
      if (this.props.filter === "submitted") {
        return d >= 90
          ? "#191970"
          : d >= 80
            ? "#000080"
            : d >= 70
              ? "#0000CD"
              : d >= 60
                ? "#0000FF"
                : d >= 50
                  ? "#4169E1"
                  : d >= 40
                    ? "#1E90FF"
                    : d >= 30
                      ? "#00BFFF"
                      : d >= 20
                        ? "#87CEFA"
                        : d >= 10
                          ? "#B0E0E6"
                          : d > 0
                            ? "#B0C4DE"
                            : "grey";
      } else {
        return d >= 90
          ? "#006400"
          : d >= 80
            ? "#026117"
            : d >= 70
              ? "#6B8E23"
              : d >= 60
                ? "#009e28"
                : d >= 50
                  ? "#079c02"
                  : d >= 40
                    ? "#12bf02"
                    : d >= 30
                      ? "#009e28"
                      : d >= 20
                        ? "#47f403"
                        : d >= 10
                          ? "#7cf246"
                          : d > 0
                            ? "lightgreen"
                            : "grey";
      }
    };

    const legend = L.control({ position: "bottomright" });

    legend.onAdd = () => {
      const div = L.DomUtil.create("div", "info legend");

      const grades = [
        0, 10, 20, 30, 40, 50, 60, 70, 80, 90
      ];
      let labels = [];
      let labels2 = [];
      let from;
      let to;

      for (let i = 0; i < grades.length; i++) {
        if (i === grades.length - 1) {
          labels.push(
            '<i style="background:' +
            getColor(grades[i]) +
            '" id="tooltip-legend">' +
            `<div class="tooltiptext">${(grades[i] * this.props.max) / 100}+</div>` +
            '</i> '
          );
          break;
        }
        if (i === 0) {
          labels.push(
            '<i style="background:' +
            getColor(grades[i]) +
            '" id="tooltip-legend">' +
            `<div class="tooltiptext">0</div>` +
            '</i> '
          );
        }
        from = grades[i] && this.props.max ? ((grades[i] * this.props.max) / 100) : 0;
        to = grades[i + 1] && this.props.max ? ((grades[i + 1] * this.props.max) / 100) : undefined;

        labels.push(
          '<i style="background:' +
          getColor(grades[i] + 1) +
          '" id="tooltip-legend">' +
          `<div class="tooltiptext">${(from) + " " + (to ? ("&ndash; " + to) : "+")}</div>` +
          '</i> '
        );
      }

      if (this.props.filter === "submitted") {
        labels2.push('<i style="background:#6cdef5"></i>');
        labels2.push('<i style="background:#03A9F4"></i>');
        labels2.push('<i style="background:#005A9E"></i>');
        labels2.push('<i style="background:#00344D"></i>');
      } else {
        labels2.push('<i style="background:#7fe97a"></i>');
        labels2.push('<i style="background:#7cf246"></i>');
        labels2.push('<i style="background:#026117"></i>');
        labels2.push('<i style="background:#00420b"></i>');
      }

      div.innerHTML = labels.join("");
      div.innerHTML += "</br>";
      div.innerHTML += "<label>Low</label>";
      div.innerHTML += '<x style="position: absolute; right: 8px;">High</x>';

      divToUse = div;
      // console.log("div: ", div);

      return div;
    };

    const { map } = this.props.leaflet;
    legend.addTo(map);
    this.setState({ divToUse, legend });
  }
  componentDidUpdate() {
    let { divToUse, legend } = this.state;
    const getColor = (d) => {
      if (this.props.filter === "submitted") {
        return d >= 90
          ? "#191970"
          : d >= 80
            ? "#000080"
            : d >= 70
              ? "#0000CD"
              : d >= 60
                ? "#0000FF"
                : d >= 50
                  ? "#4169E1"
                  : d >= 40
                    ? "#1E90FF"
                    : d >= 30
                      ? "#00BFFF"
                      : d >= 20
                        ? "#87CEFA"
                        : d >= 10
                          ? "#B0E0E6"
                          : d > 0
                            ? "#B0C4DE"
                            : "grey";
      } else {
        return d >= 90
          ? "#006400"
          : d >= 80
            ? "#026117"
            : d >= 70
              ? "#6B8E23"
              : d >= 60
                ? "#009e28"
                : d >= 50
                  ? "#079c02"
                  : d >= 40
                    ? "#12bf02"
                    : d >= 30
                      ? "#009e28"
                      : d >= 20
                        ? "#47f403"
                        : d >= 10
                          ? "#7cf246"
                          : d > 0
                            ? "lightgreen"
                            : "grey";
      }
    };

    // const legend = L.control({ position: "bottomright" });

    legend.onAdd = () => {
      const div = divToUse;
      const grades = [
        0, 10, 20, 30, 40, 50, 60, 70, 80, 90
      ];
      let labels = [];
      let labels2 = [];
      let from;
      let to;

      for (let i = 0; i < grades.length; i++) {
        if (i === grades.length - 1) {
          labels.push(
            '<i style="background:' +
            getColor(grades[i]) +
            '" id="tooltip-legend">' +
            `<div class="tooltiptext">${(grades[i] * this.props.max) / 100}+</div>` +
            '</i> '
          );
          break;
        }
        if (i === 0) {
          labels.push(
            '<i style="background:' +
            getColor(grades[i]) +
            '" id="tooltip-legend">' +
            `<div class="tooltiptext">0</div>` +
            '</i> '
          );
        }
        from = grades[i] && this.props.max ? ((grades[i] * this.props.max) / 100) : 0;
        to = grades[i + 1] && this.props.max ? ((grades[i + 1] * this.props.max) / 100) : undefined;

        labels.push(
          '<i style="background:' +
          getColor(grades[i] + 1) +
          '" id="tooltip-legend">' +
          `<div class="tooltiptext">${(from) + " " + (to ? ("&ndash; " + to) : "+")}</div>` +
          '</i> '
        );
      }

      if (this.props.filter === "submitted") {
        labels2.push('<i style="background:#6cdef5"></i>');
        labels2.push('<i style="background:#03A9F4"></i>');
        labels2.push('<i style="background:#005A9E"></i>');
        labels2.push('<i style="background:#00344D"></i>');
      } else {
        labels2.push('<i style="background:#7fe97a"></i>');
        labels2.push('<i style="background:#7cf246"></i>');
        labels2.push('<i style="background:#026117"></i>');
        labels2.push('<i style="background:#00420b"></i>');
      }

      div.innerHTML = labels.join("");
      div.innerHTML += "</br>";
      div.innerHTML += "<label>Low</label>";
      div.innerHTML += '<x style="position: absolute; right: 8px;">High</x>';
      // console.log("div: ", div);

      return div;
    };

    const { map } = this.props.leaflet;
    legend.addTo(map);
  }
}

export default withLeaflet(Legend);
