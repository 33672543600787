import React, { Component } from "react";
import { Link } from "react-router-dom";
// import { proceedBind, getInsuranceCompaneyDetails } from '../../../utils/form_comp_quote';
import {
  deleteAllCookies,
  showErrAlert,
  blobPdfFromBase64String,
} from "../../utils/common";
import axios from "axios";
import { Auth } from "aws-amplify";
// import { awsUrl, localURL } from '../../../config';
import { awsUrl } from "../../config";
import Footer from "../common/Footer";
import Loader from "../common/Loader";
import Navbar from "../common/Navbar";
import $ from "jquery";
import QuoteTable from "../common/QuoteTable";
class QuotesHistory extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      visible: false,
      carrierStatus: "",
      calculated_data: "",
      uuid_carrier: "",
      userName: "",
    };
  }

  prepTableData = (respData) => {
    let tableData = [];
    let index = 0;
    const data = respData.data;
    console.log("++data=-=", data);
    for (let timestamp in data) {
      tableData.push({ [timestamp]: {} });
      for (let fein in data[timestamp]) {
        for (let carrier in data[timestamp][fein]) {
          tableData[index][timestamp][carrier] = {};
          for (let carrierData of data[timestamp][fein][carrier]) {
            // console.log("++data", carrierData);
            tableData[index][timestamp][carrier][
              carrierData.carrier_location_data.name
            ] = carrierData;
          }
        }
      }
      index++;
    }
    this.setState({ tableData });
  };

  componentDidMount() {
    $("#loader").css("display", "block");
    var formStage = sessionStorage.getItem("formStage");
    console.log("Form stage: ", formStage);
    var globalData = "";
    Auth.currentSession()
      .then((res) => {
        console.log("Current Authenticated Response User: ", res);
        // userRole = res.accessToken.payload["cognito:groups"][0];
        let userName = res.accessToken.payload.username;
        this.setState({ userName });
        const USER_TOKEN = res.idToken.jwtToken;
        const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
        axios
          .get(
            awsUrl + "/api/getUserDataStatus/" + userName + "/history",
            header
          )
          .then((response) => {
            // console.log("");

            this.prepTableData(response.data);
            this.setState({
              carrierStatus: response.data.data,
              uuid_carrier: response.data.uuid,
            });
            this.setState({ disp_message: true });
            $("#loader").css("display", "none");
          })
          .catch((err) => {
            showErrAlert(err);
            $("#loader").css("display", "none");
            console.log("Error in getting status :(", err);
          });
      })
      .catch((err) => {
        $("#loader").css("display", "none");
        if (err === "No current user") {
          showErrAlert(err);
          console.log("Current authenticated user error: ", err);
        }
      });
  }
  getSum(data) {
    let sum = 0;
    for (let teap of data) {
      sum += Number(teap.teap);
    }
    return Math.round(sum).toLocaleString("en");
  }
  getCarriersUUID(date, fein) {
    let uuid_carrier = [];
    for (let carrier in this.state.carrierStatus[date][fein]) {
      for (let obj of this.state.carrierStatus[date][fein][carrier]) {
        uuid_carrier.push(obj.uuid_carrier);
      }
    }
    return uuid_carrier;
  }
  getTimestampAndAdress(data1) {
    let timstampAndAdress = {};
    let address = [];
    let timestamp = "";

    for (let data of data1) {
      let add = data.carrier.split("+")[1];
      timestamp = data.uuid_carrier.split("@")[0];
      address.push(add);
    }
    timstampAndAdress.timestamp = timestamp;
    timstampAndAdress.address = address;
    return timstampAndAdress;
  }
  QuoteView(event) {
    console.log("carrier: ", event.target.id);
    let carrierTemplate = event.target.id.split("#+");
    let carrier = carrierTemplate[0];
    let timestamp = carrierTemplate[1];
    let getQuoteDetails = {};
    Auth.currentSession().then((res) => {
      let userName = res.accessToken.payload.username;
      getQuoteDetails.email = userName;
      getQuoteDetails.timestamp = timestamp;
      getQuoteDetails.carrier = carrier;
      const USER_TOKEN = res.idToken.jwtToken;
      const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
      axios
        .post(
          awsUrl + "/api/generateQuotePDF",
          JSON.stringify(getQuoteDetails),
          header
        )
        .then((res) => {
          console.log("Ressponse:", res.data);
          let pdf = res.data.data;
          let fileName = "Quote.pdf";
          let file = blobPdfFromBase64String(pdf);
          var fileURL = URL.createObjectURL(file);
          let newWindow = window.open("./QuotePDF", "_blank");
          newWindow.onload = () => {
            newWindow.location = fileURL;
          };
        })
        .catch((err) => {
          console.log("Err", err);
          showErrAlert("View Quote is not available due to error");
        });
    });
  }
  render() {
    const tableData = this.state.tableData;
    return (
      <div>
        <div id="quoteMain">
          <Navbar />
          <div
            id="contentHolder"
            className="container-fluid contentHolder-history"
          >
            <div id="cmprQts" className="container-fluid">
              <div id="compareQuote" className="container-fluid">
                {console.log("++data table", tableData)}
                {tableData &&
                  tableData.map((obj, index) => {
                    return (
                      <QuoteTable
                        key={index}
                        data={obj[Object.keys(obj)[0]]}
                        variant="history"
                      />
                    );
                  })}
                {(!tableData || tableData.length === 0) &&
                  this.state.disp_message && (
                    <div>
                      <h2>No quotes found</h2>
                      <p>
                        Click{" "}
                        <Link to={"/Quote"}>
                          <a href="#">here to start a new one</a>.
                        </Link>
                      </p>
                    </div>
                  )}
                <div />
              </div>
            </div>
          </div>
          <Loader />
        </div>
        <Footer />
      </div>
    );
  }
}

export default QuotesHistory;
