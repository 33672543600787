import React from "react";
import axios from "axios";
import { Auth } from "aws-amplify";
import { awsUrl } from "../../config";
import moment from "moment";
class BinderDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentWillMount() {
    if (
      sessionStorage.getItem("require") !== undefined &&
      sessionStorage.getItem("require") !== null &&
      sessionStorage.getItem("currProspect") !== null &&
      sessionStorage.getItem("currProspect") !== undefined
    ) {
      let etQuoteDetails = this.props.data;
      window.scrollTo(0, 0);
      Auth.currentSession().then((res) => {
        const USER_TOKEN = res.idToken.jwtToken;
        const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
        axios
          .post(awsUrl + "/api/getQuoteDetails", etQuoteDetails, header)
          .then((res2) => {
            console.log("Log details:", res2.data);
            this.setState({
              data: res2.data,
            });
          })
          .catch((err) => {
            console.log("Error:", err);
          });
      });
    }
  }
  render() {
    let teap = [];
    let addresses = "";
    let signature = "";
    let bound_date = "";
    let policyNumber = "";
    let companyName = "";
    let empll;
    let emplMap;

    function sortData(data) {
      let mData = data;
      for (let carrier in data) {
        let statesList = [];
        for (let location in data[carrier]) {
          if (
            !statesList.includes(
              data[carrier][location].carrier_location_data.state
            )
          ) {
            statesList.push(
              data[carrier][location].carrier_location_data.state
            );
          } else {
            // for(let element in data[carrier][location].carrier_location_data){
            // console.log("current data", mData[carrier][location].carrier_location_data);
            let index = statesList.indexOf(
              data[carrier][location].carrier_location_data.state
            );
            let key = Object.keys(data[carrier])[index];
            // console.log("old data", key, mData[carrier][key].carrier_location_data);
            for (let element in mData[carrier][location]
              .carrier_location_data) {
              if (
                typeof mData[carrier][location].carrier_location_data[
                element
                ] === "number"
              ) {
                mData[carrier][key].carrier_location_data[element] +=
                  data[carrier][location].carrier_location_data[element];
              }
            }
            delete mData[carrier][location];
            // console.log("new data", mData[carrier]);
          }
        }
      }
      return mData;
    }

    if (
      sessionStorage.getItem("require") !== undefined &&
      sessionStorage.getItem("require") !== null &&
      sessionStorage.getItem("currProspect") !== null &&
      sessionStorage.getItem("currProspect") !== undefined &&
      this.state.data !== undefined &&
      this.state.data !== "No data found"
    ) {
      console.log("Dataisthis", this.state.data);
      const locData = this.state.data[Object.keys(this.state.data)[0]];
      if (locData) {
        empll = locData[Object.keys(locData)[0]].liability_limitId.split("/");
        emplMap = { "500": 500000, "100": 100000, "1000": 1000000 };
        companyName = locData[Object.keys(locData)[0]].company_name;
        console.log("company name", companyName);
        console.log("----////", empll);
      }

      let mData = sortData(this.state.data);
      for (let carr in mData) {
        let i = 0;
        let sum = 0;
        for (let place in this.state.data[carr]) {
          addresses = this.state.data[carr][place].mail_address;
          signature = this.state.data[carr][place].sign_name;
          bound_date = moment.utc(
            this.state.data[carr][place].bound_date,
            "DD/MM/YYYY"
          ).format("MM/DD/YYYY");
          sum += Math.round(
            Number(
              this.state.data[carr][place].carrier_location_data
                .total_estimated_annual_premium
            )
          );
          teap.push(
            <div key={i}>
              <p key={i}>
                <span key={i} id={"carrierName" + i}>
                  <b>
                    {"Location: " +
                      this.state.data[carr][
                        place
                      ].carrier_location_data.state.toUpperCase()}
                    ;
                  </b>{" "}
                  <br />
                  Total estimated annual premium:{" "}
                  <b>
                    {" "}
                    $
                    {Math.round(
                      this.state.data[carr][place].carrier_location_data
                        .total_estimated_annual_premium
                    ).toLocaleString("en")}
                  </b>
                </span>
              </p>
            </div>
          );
          policyNumber = this.state.data[carr][place].uuid_carrier
            .split("@")[1]
            .split("-")[0];
          i++;
        }
        teap.push(<hr key={i} />);
        teap.push(
          <div key={i + 1}>
            {" "}
            <p key={i}>
              <span key={i} id={"carrierName" + i}>
                {" "}
                Policy Total Estimated Annual Premium:{" "}
                <b>${Math.round(sum).toLocaleString("en")}</b>
              </span>
            </p>
          </div>
        );
      }
    } else if (
      this.state.data !== undefined &&
      this.state.data === "No data found"
    ) {
      alert(this.state.data);
    }
    return (
      <div id="downloadable">
        <div className="logoHolder text-center">
          {/* <img src={require("../../images/new-logo.png")} alt="" /> */}
        </div>
        <div className="sectionHolder">
          <div className="row">
            <div className="col-sm-6">
              <p>
                <span>Date:</span> <span id="binderDate">{bound_date}</span>
              </p>
            </div>
            <div className="col-sm-6 text-right">
              <p>
                <span>Policy No: </span>{" "}
                <span id="binderDate">{policyNumber}</span>
              </p>
            </div>
          </div>
          <div className="row">
            {addresses !== undefined && (
              <div className="col-sm-6 ">
                <p>
                  <strong>Submitted Risk Information:</strong>
                </p>
                <p>
                  <span id="sName">{companyName}.</span>
                  <br />
                  <span id="sAddressL1">{addresses.streetAddress1}</span>
                  <br />
                  {addresses.streetAddress2 !== "None" && (
                    <div>
                      <span id="sAddressL2">{addresses.streetAddress2}</span>
                      <br />
                    </div>
                  )}
                  <span id="sCity">{addresses.cityName}</span>,{" "}
                  <span id="sState">{addresses.stateCode}</span>{" "}
                  <span id="sZip">{addresses.zipCode}</span>
                </p>
              </div>
            )}
            <div className="col-sm-6 text-right">
              <p>
                <strong>Producer Information:</strong>
              </p>
              <p>
                <span id="pName">InsureComp</span>
                <br />
                <span id="pAddressL1">5038 W Parsons Rd</span>
                <br />
                <span id="pCity">Phoenix</span>, <span id="pState">AZ</span>{" "}
                <span id="pZip">85083</span>
                {/* <span id="pAddressL1">7780 Elmnwood Avenue</span>
                <br />
                <span id="pAddressL2">Suit No 130</span>
                <br />
                <span id="pCity">Middleton</span>, <span id="pState">WI</span>{" "}
                <span id="pZip">53562</span> */}
              </p>
            </div>
          </div>
          {signature && (
            <div className="row">
              <div className="col-sm-6">
                <p>
                  <span>Attn:</span>{" "}
                  <span id="attnName">
                    <strong>{signature}</strong>
                  </span>
                </p>
              </div>
            </div>
          )}
        </div>
        <h5 className="text-center">
          This is to certify that, in accordance with your instructions, the
          carrier listed below has bound coverage as follows.
        </h5>
        <div className="sectionHolder text-center">
          {/* <strong><span id='carrierName'>Nautilus Insurance Company (A.M. Best Rating A + XV)</span></strong> */}
          {teap}
        </div>
        <h5 className="text-center">
          <strong>Coverage Form: </strong>{" "}
          <span id="covFormName">Environmental Combined Policy - </span>{" "}
          <span id="binderDate">{policyNumber}</span>
        </h5>
        <div className="sectionHolder">
          <p className="text-center">
            LIMITS OF LIABILITY, RETENTIONS AND RETROSPECTIVE DATES
          </p>
          <hr />
          <div className="row">
            <div className="col-sm-6 text-left">
              <p>
                <strong>
                  Worker's Compensation &amp; Employer's Liability
                </strong>
              </p>
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3">
                  <p>
                    <strong>
                      $
                      <span id="eachAccidVal" className="lValues">
                        {empll ? emplMap[empll[0]].toLocaleString("en-US") : ""}
                      </span>
                    </strong>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p>Each Accident</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 text-right">
              <p />
            </div>
            <div className="col-sm-6">
              <div className="row">
                <div className="col-sm-3">
                  <p>
                    <strong>
                      $
                      <span id="eachDiseaseVal" className="lValues">
                        {empll ? emplMap[empll[1]].toLocaleString("en-US") : ""}
                      </span>
                    </strong>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p>Disease - Each Employee</p>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-6 text-right">
              <p />
            </div>
            <div className="col-sm-6 text">
              <div className="row">
                <div className="col-sm-3">
                  <p>
                    <strong>
                      $
                      <span id="eachDiseaseVal" className="lValues">
                        {empll ? emplMap[empll[2]].toLocaleString("en-US") : ""}
                      </span>
                    </strong>
                  </p>
                </div>
                <div className="col-sm-6">
                  <p>Disease - Policy Limit</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default BinderDownload;
