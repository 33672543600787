import React, { Component, forwardRef } from "react";
import $ from "jquery";
import axios from "axios";
import { awsUrl, awsUrl2 } from "../../config";
import Clamp from "react-multiline-clamp";
import { Auth } from "aws-amplify";
import moment from "moment";
import numeral from "numeral";
import MaterialTable, { MTableToolbar } from "material-table";
import { TablePagination } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";
import AddBox from "@material-ui/icons/AddBox";
import DeleteOutline from "@material-ui/icons/DeleteOutline";
import Edit from "@material-ui/icons/Edit";
import Remove from "@material-ui/icons/Remove";
import ViewColumn from "@material-ui/icons/ViewColumn";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { Container, Row, Col } from "react-bootstrap";
import { em } from "react-dom-factories";
import toast, { Toaster } from "react-hot-toast";
import { SaveAlt } from "@material-ui/icons";
import { createTimeStamp } from "../common/timeStampHelper";

let ismobile_font = window.innerWidth < 1600;

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  // Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  Filter: () => <div />,
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};
const carrierMap = {
  carrier_a: process.env.REACT_APP_MODE == "beta" ? "LD" : "CHUBB",
  carrier_b: process.env.REACT_APP_MODE == "beta" ? "GC" : "FIC",
  carrier_o: process.env.REACT_APP_MODE == "beta" ? "SF" : "State Fund",
};

const reverseCarrierMap = {
  chubb: "carrier_a",
  ld: "carrier_a",
  fic: "carrier_b",
  gc: "carrier_b",
  "state fund": "carrier_o",
  sf: "carrier_o",
};

let selectedRow = [];

const handleClick = (rows) => {
  selectedRow = rows;
};

const toastSuccess = (text) =>
  toast.success(text, {
    duration: 3000,
  });

const toastError = (text) =>
  toast.error(text, {
    duration: 3000,
  });

class DashboardExtensis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      dashboardTotal: {
        total_gc: 0,
        total_cost: 0,
        total_charge: 0,
        total_payroll: 0,
        pom: 0,
        mix_rate: 0,
        margin_percent: 0,
      },
      bindClientId: null,
    };
  }

  componentDidMount = () => {
    sessionStorage.clear();
    let { tabName } = this.props;
    if (tabName === "tab1") {
      this.updateNewDisplay();
    } else if (tabName === "tab2") {
      this.updateRenewalDisplay();
      this.getTotalData();
    } else if (tabName === "tab3") {
      this.updateExistingDisplay();
      this.getTotalData();
    }
  };

  camelCase = (str) => {
    return str
      .trim()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  getExistingStatesList = (payrollData) => {
    let stateList = [];
    for (let stateIndex in payrollData) {
      let state;
      try {
        state = payrollData[stateIndex].state.value.toLowerCase();
        if (!stateList.includes(state)) {
          stateList.push(state);
        }
      } catch (error) {}
    }

    return stateList;
  };

  onClickLinkRenewal = (rowData) => {
    rowData.companyProfile.effectiveDate = {
      value: rowData.companyProfile.effective_date.value,
    };
    rowData.companyProfile.emailId = {
      value: rowData.companyProfile.opportunity_id.value,
    };
    let currProspect = {
      companyProfile: rowData.companyProfile,
      emodStatesData: rowData.emodStatesData,
      childrenLoc: rowData.payrollData,
    };
    if (rowData.historicalClaims && rowData.historicalClaimsCheck) {
      currProspect.historicalClaims = rowData.historicalClaims;
      currProspect.historicalClaimsCheck = rowData.historicalClaimsCheck;
    } else {
      currProspect.historicalClaims = {};
      currProspect.historicalClaimsCheck = {};
    }

    if (rowData.payrollData) {
      let stateSet = {};
      let childrenLoc = rowData.payrollData;
      for (let addressBlockNo in childrenLoc) {
        if (childrenLoc[addressBlockNo].state.value) {
          stateSet[childrenLoc[addressBlockNo].state.value] =
            childrenLoc[addressBlockNo].state.value;
        }
      }

      sessionStorage.setItem("stateSet", JSON.stringify(stateSet));
    }

    sessionStorage.setItem(
      "compositeChargeRate",
      JSON.stringify(rowData.composite_charge_rate)
    );
    sessionStorage.setItem(
      "programManualRate",
      JSON.stringify(rowData.program_manual_rate)
    );
    sessionStorage.setItem("currProspect", JSON.stringify(currProspect));
    sessionStorage.setItem("renewalClient", true);
    sessionStorage.setItem(
      "selectedCarrier",
      reverseCarrierMap[rowData.selectedCarrier.toLowerCase()]
    );
    if (rowData.renewalConfirmed) {
      sessionStorage.setItem("renewalConfirmed", true);
    }

    if (rowData.status.toLowerCase().includes("quote generated")) {
      sessionStorage.setItem("formStage", "two");
      window.location.reload();
    }
  };

  onClickLinkExisting = (rowData) => {
    if (!rowData.companyProfile.effectiveDate) {
      rowData.companyProfile.effectiveDate =
        rowData.companyProfile.effective_date;
    }
    rowData.companyProfile.emailId =
      rowData.companyProfile.client_id || rowData.companyProfile.opportunity_id;

    let currP = {
      emodStatesData: rowData.emodStatesData ? rowData.emodStatesData : {},
      childrenLoc: rowData.childrenLoc ? rowData.childrenLoc : {},
      companyProfile: rowData.companyProfile,
    };

    if (rowData.costEppInputValue) {
      currP.costEppInputValue = rowData.costEppInputValue;
    }

    if (rowData.childrenLoc) {
      let stateSet = {};
      let childrenLoc = rowData.childrenLoc;
      for (let addressBlockNo in childrenLoc) {
        if (childrenLoc[addressBlockNo].state.value) {
          stateSet[childrenLoc[addressBlockNo].state.value] =
            childrenLoc[addressBlockNo].state.value;
        }
      }

      sessionStorage.setItem("stateSet", JSON.stringify(stateSet));
    }

    if (rowData.historicalClaims) {
      currP.historicalClaims = rowData.historicalClaims;
    } else {
      currP.historicalClaims = {};
    }
    if (rowData.historicalClaimsCheck) {
      currP.historicalClaimsCheck = rowData.historicalClaimsCheck;
    } else {
      currP.historicalClaimsCheck = {};
    }

    let existingStateList = this.getExistingStatesList(rowData.childrenLoc);

    sessionStorage.setItem(
      "effDropDown",
      JSON.stringify([
        moment
          .utc(rowData.companyProfile.effectiveDate.value)
          .format("MM/DD/YYYY"),
      ])
    );
    sessionStorage.setItem("isEffDropDown", true);
    sessionStorage.setItem(
      "selectedCarrier",
      reverseCarrierMap[rowData.selectedCarrier.toLowerCase()]
    );
    sessionStorage.setItem("existingClient", true);
    sessionStorage.setItem(
      "compositeChargeRate",
      JSON.stringify(rowData.compositeChargeRate)
    );
    sessionStorage.setItem(
      "programManualRate",
      JSON.stringify(rowData.programManualRate)
    );
    sessionStorage.setItem(
      "existingStateList",
      JSON.stringify(existingStateList)
    );
    sessionStorage.setItem("secondAPI", true);
    sessionStorage.setItem("currProspect", JSON.stringify(currP));
  };

  fetchMainTableData = (id) => {
    return new Promise(async (resolve, reject) => {
      await axios
        .post(awsUrl2 + "/api/getMainTableData/getDataByOpportunityIndex", {
          opportunityId: id?.toLowerCase(),
        })
        .then((res) => {
          resolve({ res });
        })
        .catch((err) => {
          reject({ err });
        });
    });
  };

  onClickLinkNewBoundStatus = async (rowData) => {
    let { res, err } = await this.fetchMainTableData(rowData.opportunityId);
    if (res && res.data) {
      let tempRow = {
        selectedCarrier: res.data.selectedCarrier
          ? carrierMap[res.data.selectedCarrier]
          : "-",
        companyName: res.data.companyProfile.companyName.value,
        effectiveDate: res.data.effective_date,
        clientId: res.data.client_id,
        businessStartDate:
          res.data.companyProfile.yearBusinessEstablished.value,
        date: res.data.timestamp,
        emodStatesData: res.data.emodStatesData,
        childrenLoc: res.data.payrollData,
        companyProfile: res.data.companyProfile,
        programManualRate: res.data.program_manual_rate,
        compositeChargeRate: res.data.composite_charge_rate,
        historicalClaims: res.data.historicalClaims || {},
        historicalClaimsCheck: res.data.historicalClaimsCheck || {},
        existing: res.data.existing || "",
      };

      if (res.data.charge_epp) {
        tempRow.costEppInputValue = {
          chargePEPM: res.data.charge_epp,
        };
      }

      if (res.data.cost_epp) {
        if (!tempRow.costEppInputValue) tempRow.costEppInputValue = {};

        tempRow.costEppInputValue = {
          ...tempRow.costEppInputValue,
          costPEPM: res.data.cost_epp,
        };
      }

      // console.log(tempRow);
      this.onClickLinkExisting(tempRow);
      window.location.href = "/Quote";
    }
  };

  onClickLinkNew = async (rowData) => {
    if (rowData.status === "Submitted") {
      if (!rowData.companyProfile.effectiveDate)
        rowData.companyProfile.effectiveDate =
          rowData.companyProfile.effective_date;

      if (rowData.isSalesforce && rowData.isSalesforce === "true") {
        sessionStorage.setItem("isSalesforce", "true");
      }

      if (!rowData.companyProfile.emailId)
        rowData.companyProfile.emailId = rowData.companyProfile.opportunity_id;

      let currP = {
        emodStatesData: rowData.emodStatesData || {},
        childrenLoc: rowData.childrenLoc || {},
        companyProfile: rowData.companyProfile,
      };

      if (rowData.historicalClaims) {
        currP.historicalClaims = rowData.historicalClaims;
      }
      if (rowData.historicalClaimsCheck) {
        currP.historicalClaimsCheck = rowData.historicalClaimsCheck;
      }

      if (rowData.childrenLoc) {
        let stateSet = {};
        let childrenLoc = rowData.childrenLoc;
        for (let addressBlockNo in childrenLoc) {
          if (childrenLoc[addressBlockNo].state.value) {
            stateSet[childrenLoc[addressBlockNo].state.value] =
              childrenLoc[addressBlockNo].state.value;
          }
        }

        sessionStorage.setItem("stateSet", JSON.stringify(stateSet));
      }

      sessionStorage.setItem(
        "effDropDown",
        JSON.stringify([
          moment
            .utc(rowData.companyProfile.effectiveDate.value)
            .format("MM/DD/YYYY"),
        ])
      );
      sessionStorage.setItem("isEffDropDown", true);
      sessionStorage.setItem("currProspect", JSON.stringify(currP));
    } else {
      sessionStorage.setItem(
        "userDetails",
        JSON.stringify({
          date: moment
            .utc(rowData.effectiveDate, ["M/D/YYYY", "YYYY-MM-DD"])
            .format("MM/DD/YYYY"),
          em: rowData.opportunityId,
        })
      );
      sessionStorage.setItem(
        "effDropDown",
        JSON.stringify([
          moment
            .utc(rowData.effectiveDate, ["M/D/YYYY", "YYYY-MM-DD"])
            .format("MM/DD/YYYY"),
        ])
      );
      sessionStorage.setItem("isEffDropDown", true);
      sessionStorage.setItem("getButtonClicked", true);
      sessionStorage.setItem("formStage", "two");
      window.location.reload();
    }
  };

  uploadRenewalData = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/uploadRenewalData", data)
        .then((res) => {
          resolve();
        })
        .catch((err) => {
          console.log("error: ", err);
          reject();
        });
    });
  };

  putExistingAndUploadMainData = (data) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/putExistingAndUploadMainData", data)
        .then((res) => {
          resolve();
        })
        .catch((err) => {
          console.log("error: ", err);
          reject();
        });
    });
  };

  updateUserStatusOnConfirmAll = (selectedRow, updatePromiseList) => {
    let listOfUuidCarrier = selectedRow.listOfUuidCarrier;

    for (let uuid of listOfUuidCarrier) {
      updatePromiseList.push(
        new Promise((resolve, reject) => {
          let params = {
            Key: {
              user_email_id: selectedRow.clientId.toLowerCase(),
              uuid_carrier: uuid,
            },
            UpdateExpression: "set existing= :oflg",
            ExpressionAttributeValues: {
              ":oflg": "true",
            },
            ReturnValues: "UPDATED_NEW",
          };
          axios
            .post(awsUrl + "/api/updateUsersData/", params)
            .then((response) => {
              resolve();
            })
            .catch((error) => {
              console.log("error in API call(updateUserData) ", error);
              reject();
            });
        }).catch((error) => console.log(`error while updating uuid: ${uuid}`))
      );
    }
  };

  onClickConfirmAll = async () => {
    $("#loader").css("display", "block");
    let { rowDataList } = JSON.parse(JSON.stringify(this.state));
    let updatePromiseList = [];
    let indicesToDelete = [];
    let errorIndices = [];
    let email, userName;

    try {
      let currAuthUser = await Auth.currentAuthenticatedUser();
      email = currAuthUser.attributes.email;
      userName = currAuthUser.attributes.name;
    } catch (error) {
      console.log("error in fetching current user", error);
    }

    for (let key in selectedRow) {
      if (
        !selectedRow[key].status ||
        !selectedRow[key].status.toLowerCase().includes("quote generated")
      )
        continue;

      indicesToDelete.push(selectedRow[key].tableData.id);

      this.updateUserStatusOnConfirmAll(selectedRow[key], updatePromiseList);

      updatePromiseList.push(
        this.putExistingAndUploadMainData(
          JSON.parse(
            JSON.stringify({
              client_id: selectedRow[key].clientId,
              effective_date: selectedRow[key].effectiveDate,
              timestamp: selectedRow[key].timestamp,
              status: selectedRow[key].status,
              oldClient: "renewal",
              quoteid: selectedRow[key].quoteid,
              companyProfile: selectedRow[key].companyProfile,
              emodStatesData: selectedRow[key].emodStatesData,
              historicalClaims: selectedRow[key].historicalClaims,
              historicalClaimsCheck: selectedRow[key].historicalClaimsCheck,
              payrollData: selectedRow[key].payrollData,
              program_manual_rate: selectedRow[key].program_manual_rate,
              program_manual_rate_prev:
                selectedRow[key].program_manual_rate_prev,
              composite_charge_rate: selectedRow[key].composite_charge_rate,
              composite_charge_rate_prev:
                selectedRow[key].composite_charge_rate_prev,
              uploadTimestamp: selectedRow[key].uploadTimestamp,
              selectedCarrier:
                reverseCarrierMap[
                  selectedRow[key].selectedCarrier.toLowerCase()
                ],
              modifiedBy: email,
              modifiedByName: userName,
            })
          )
        ).catch((err) => {
          errorIndices.push(selectedRow[key].tableData.id);
          console.log("error in the put existing data");
        })
      );

      if (updatePromiseList.length >= 100) {
        await Promise.all(updatePromiseList)
          .then((res) => {
            updatePromiseList = [];
          })
          .catch((err) => {
            console.log("error in the deletion");
            updatePromiseList = [];
          });
      }
    }
    if (updatePromiseList.length > 0) {
      await Promise.all(updatePromiseList)
        .then((res) => {})
        .catch((err) => {
          console.log("error in the deletion");
        });
    }
    // this is very important as it prevents the index from changing after a delete operation.
    indicesToDelete.sort().reverse();

    for (let index of indicesToDelete) {
      if (!errorIndices.includes(index)) {
        rowDataList.splice(index, 1);
      }
    }
    this.setState({ rowDataList });
    $("#loader").css("display", "none");
    window.location.reload();
  };

  onclickConfirm = (selectedRow) => {
    let { rowDataList } = JSON.parse(JSON.stringify(this.state));
    let updatePromiseList = [];
    let listOfUuidCarrier = selectedRow.listOfUuidCarrier;
    let selectedIndex = selectedRow.tableData.id;

    updatePromiseList.push(
      this.uploadRenewalData({
        client_id: selectedRow.clientId,
        effective_date: selectedRow.effectiveDate,
        renewalConfirmed: "true",
      }).catch((err) => {
        console.log("error in the deletion");
      })
    );

    for (let uuid of listOfUuidCarrier) {
      updatePromiseList.push(
        new Promise((resolve, reject) => {
          let params = {
            Key: {
              user_email_id: selectedRow.clientId.toLowerCase(),
              uuid_carrier: uuid,
            },
            UpdateExpression: "set renewalConfirmed= :flg",
            ExpressionAttributeValues: {
              ":flg": "true",
            },
            ReturnValues: "UPDATED_NEW",
          };
          axios
            .post(awsUrl + "/api/updateUsersData/", params)
            .then((response) => {
              resolve();
            })
            .catch((error) => {
              console.log("error in API call(updateUserData) ", error);
              reject();
            });
        }).catch((error) => console.log(`error while updating uuid: ${uuid}`))
      );
    }

    Promise.all(updatePromiseList)
      .then((res) => {
        rowDataList[selectedIndex].renewalConfirmed = true;
        this.setState({ rowDataList });
      })
      .catch((err) => {
        console.log("error while deleting rows");
        this.setState({ rowDataList });
      });
  };

  onclickDelete = (selectedRow) => {
    let { rowDataList } = JSON.parse(JSON.stringify(this.state));
    let updatePromiseList = [];
    let indicesToDelete;
    let errorIndices;
    indicesToDelete = selectedRow.tableData.id;
    updatePromiseList.push(
      this.uploadRenewalData({
        client_id: selectedRow.clientId,
        effective_date: selectedRow.effectiveDate,
        deleted: "true",
      }).catch((err) => {
        console.log("error in the deletion");
        errorIndices = selectedRow.tableData.id;
      })
    );

    if (!errorIndices && indicesToDelete) {
      rowDataList.splice(indicesToDelete, 1);
    }

    Promise.all(updatePromiseList)
      .then((res) => {
        this.setState({ rowDataList });
      })
      .catch((err) => {
        console.log("error while deleting rows");
        this.setState({ rowDataList });
      });
  };

  bindHandler = async (rowData) => {
    let email, userName, toastErrorText;
    let { opportunityId, client_ID, selectedCarrier } = rowData;
    let { rowDataList } = JSON.parse(JSON.stringify(this.state));
    let index = rowData?.tableData?.id;

    // validations
    if (!opportunityId) {
      toastErrorText = "Opportunity ID missing";
    } else if (!selectedCarrier) {
      toastErrorText = "Carrier missing";
    } else if (!client_ID) {
      toastErrorText = "Client ID missing";
    }

    if (toastErrorText) {
      toastError(toastErrorText);
      return;
    }

    try {
      toastSuccess("Binding...");
      let currAuthUser = await Auth.currentAuthenticatedUser();

      email = currAuthUser.attributes.email;
      userName = currAuthUser.attributes.name;
      let res = {
        dataarray: [
          {
            opportunity_id: opportunityId.toLowerCase(),
            client_id: client_ID.toLowerCase(),
            carrier: selectedCarrier.toLowerCase(),
          },
        ],
        email: email,
      };

      axios
        .post(awsUrl2 + "/api/newBussinessBlendId", res)
        .then((response) => {
          rowDataList[index].status = "Bound";
          rowDataList[index].cellColor = "rgb(25, 101, 1)";
          rowDataList[index].bindDate = moment().format("x");
          this.setState({ rowDataList });
          toastSuccess("Bound!");
        })
        .catch((error) => {
          console.log("error in API call(newBussinessBlendID) ", error);
          toastError("Unable to bind!");
        });
    } catch (error) {
      console.log("error in fetching current user", error);
      toastError("Unable to bind!");
    }
  };

  fontfunction = () => {
    if (window.innerWidth < 1600 && window.innerWidth > 1500) {
      return "0.86rem";
    }
    if (window.innerWidth < 1500 && window.innerWidth > 1410) {
      return "0.8.2rem";
    }
    if (window.innerWidth < 1410 && window.innerWidth > 1310) {
      return "0.8rem";
    }
    if (window.innerWidth < 1300) {
      return "0.76rem";
    }
  };

  updateClientId = (rowData, clientId) => {
    let { rowDataList } = JSON.parse(JSON.stringify(this.state));
    let index = rowData?.tableData?.id;

    if (!isNaN(index)) {
      rowDataList[index].client_ID = clientId;
      this.setState({ rowDataList });
    }
  };

  customFilterfunction = (term, rowData, field) => {
    let customFieldName = field["field"];

    let excludeFirstCharacter = term.substring(1);
    let excludeFirstTwoCharacter = term.substring(2);
    let firstCharacter = term.substring(1, 0);
    let firstTwoCharacter = term.substring(2, 0);
    let operationMap = {
      ">=": ">=",
      "<=": "<=",
      "<": "<",
      ">": ">",
      "=": "===",
    };
    try {
      if (firstTwoCharacter in operationMap) {
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstTwoCharacter]} ${excludeFirstTwoCharacter}`
        );
      } else if (firstCharacter in operationMap) {
        return eval(
          `${rowData[customFieldName]} ${operationMap[firstCharacter]} ${excludeFirstCharacter}`
        );
      } else {
        return rowData[customFieldName]?.toString()?.startsWith(term);
      }
    } catch (err) {
      return rowData[customFieldName]?.toString()?.startsWith(term);
    }

    // if (term[0] == ">" && term[1] == "=") {
    //   return firstTwoCharacter <= rowData[customFieldName];
    // } else if (term[0] == "<" && term[1] == "=") {
    //   return firstTwoCharacter >= rowData[customFieldName];
    // } else if (term[0] == ">") {
    //   return firstCharacter < rowData[customFieldName];
    // } else if (term[0] == "<") {
    //   return firstCharacter > rowData[customFieldName];
    // } else if (term[0] == "=") {
    //   return firstCharacter == rowData[customFieldName];
    // } else {
    //   return rowData[customFieldName]?.toString()?.startsWith(term);
    // }
  };

  customFilterAll = (term, rowData, field) => {
    let customFieldName = field["field"];

    return rowData[customFieldName]
      ?.toString()
      ?.toLowerCase()
      ?.startsWith(term?.toLowerCase());
  };

  custonFilterForCreatedDate = (term, rowData, field) => {
    let customFieldName = field["field"];
    return (
      moment
        ?.utc(Number(rowData?.[customFieldName]))
        ?.utcOffset("-0500")
        ?.format("M/D/YYYY - hh:mm A")
        ?.toString()
        ?.toLowerCase()
        ?.startsWith(term?.toLowerCase()) || ""
    );
  };

  setTableData = () => {
    let { rowDataList, isLoading } = JSON.parse(JSON.stringify(this.state));

    let { tabName } = this.props;

    var rowCount = 25;

    var rowArray = [25, 50, 75, 100];

    var rowLength = rowDataList ? rowDataList.length : 25;

    if (rowLength < 25) {
      rowCount = rowLength;
      rowArray = [rowCount, ...rowArray];
    }

    let columns = {
      tab1: [
        {
          title: "Created Date (ET)",
          field: "uploadTimestamp",
          defaultSort: "desc",
          customFilterAndSearch: (term, rowData, field) =>
            this.custonFilterForCreatedDate(term, rowData, field),
          render: (rowData) => {
            let visitDate = moment
              .utc(Number(rowData.uploadTimestamp))
              .utcOffset("-0500")
              .format("M/D/YYYY - hh:mm A");
            let date = visitDate.split("-")[0];
            let time = visitDate.split("-")[1];
            return (
              <span>
                {date}
                <br></br>
                {time}
              </span>
            );
          },
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "80px",
            maxWidth: "80px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "170px",
            maxWidth: "170px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              {rowData.status === "Bound" ? (
                <span
                  className="link-new-bound-status"
                  onClick={() => {
                    this.props.updatePopUpData({
                      show: true,
                      title: "Do you wish to continue?",
                      children: (
                        <>
                          <br></br>
                          <span style={{ fontWeight: "bold" }}>
                            You will be routed to existing client flow.
                          </span>
                        </>
                      ),
                      acceptBtn: "Yes",
                      acceptBtnCallback: () => {
                        this.onClickLinkNewBoundStatus(rowData);
                      },
                    });
                  }}
                  style={{
                    cursor: "pointer",
                    color: "#007BFF",
                  }}
                >
                  {this.camelCase(rowData.companyName)}
                </span>
              ) : (
                <span onClick={() => this.onClickLinkNew(rowData)}>
                  <Link to={"/Quote"}>
                    {this.camelCase(rowData.companyName)}
                  </Link>
                </span>
              )}
            </Clamp>
          ),
        },
        {
          title: "Effective Date",
          field: "effectiveDate",
          // defaultSort: "desc",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
          render: (rowData) => <Clamp lines={1}>{rowData.effectiveDate}</Clamp>,
        },
        {
          title: "Opportunity ID / IComp ID",
          field: "opportunityId",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            maxWidth: "50px",
            minWidth: "300px",
          },
          render: (rowData) => <Clamp lines={2}>{rowData.opportunityId}</Clamp>,
        },
        {
          title: "Business Start Date",
          field: "businessStartDate",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "80px",
          },
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => (
            <Clamp lines={1}>{rowData.businessStartDate}</Clamp>
          ),
        },
        {
          title: "Carrier",
          field: "selectedCarrier",
          render: (rowData) => rowData.selectedCarrier || "-",
          cellStyle: {
            padding: "6px",
            wordBreak: "break-all",
            minWidth: "50px",
            width: "80px",
            maxWidth: "80px",
          },
          headerStyle: {
            padding: "6px",
          },
        },
        {
          title: "Modified by",
          field: "modifiedBy",
          render: (rowData) =>
            <Clamp lines={1}>{rowData.modifiedBy}</Clamp> || "-",
          cellStyle: {
            padding: "6px 20px 6px 0px",
            minWidth: "100px",

            //wordBreak: "break-all",
          },
          headerStyle: {
            padding: "6px",
          },
        },
        {
          title: "New Client ID",
          field: "client_ID",
          render: (rowData) => {
            return (
              <input
                type="text"
                onBlur={(e) => {
                  this.updateClientId(rowData, e.target.value);
                }}
                defaultValue={rowData.client_ID}
              ></input>
            );
          },
          cellStyle: {
            padding: "6px 20px 6px 0px",
            minWidth: "90px",

            //wordBreak: "break-all",
          },
          headerStyle: {
            padding: "6px",
          },
        },
        {
          title: "Bind",
          field: "bind",
          render: (rowData) => {
            return (
              <>
                <button
                  disabled={rowData.status !== "To Salesforce"}
                  onClick={() => {
                    console.log(rowData, "RowData");
                    this.bindHandler(rowData);
                  }}
                  className="btn btn-warning btn-sm btn-warning-shadow"
                  style={{ width: "100px", height: "30px" }}
                >
                  Bind
                </button>
                <Toaster />
              </>
            );
            // if (
            //   rowData.status === "To Salesforce"
            // ) {
            //   if (rowData.existingStatus) {
            //     return (
            //       <button
            //         className="btn btn-warning btn-sm btn-warning-shadow"
            //         style={{ width: "100px", height: "30px" }}
            //         disabled
            //       >
            //         Bind
            //       </button>
            //     );
            //   } else if (!rowData.existing || null) {
            //     return blindbutton;
            //   }
            // }
          },
          cellStyle: {
            padding: "6px 20px 6px 0px",
            minWidth: "100px",
          },
          //wordBreak: "break-all",
          headerStyle: {
            padding: "6px",
          },
        },
        {
          title: "Status",
          field: "status",
          render: (rowData) => {
            let date = "";
            if (rowData.status === "Bound") {
              let tempDate = rowData.bindDate || rowData.date;
              date = moment.utc(Number(tempDate)).format("M/D/YYYY");
            } else if (rowData.status === "To Salesforce") {
              let tempDate = rowData.sendToSalesforceDate || rowData.date;
              date = moment.utc(Number(tempDate)).format("M/D/YYYY");
            } else if (rowData.status !== "Submitted") {
              date = moment.utc(Number(rowData.date)).format("M/D/YYYY");
            }

            return (
              <div className="d-flex align-items-center">
                <span
                  className="mr-2"
                  style={{
                    height: "18px",
                    width: "18px",
                    backgroundColor: rowData.cellColor,
                    color: rowData.textColor,
                    borderRadius: "50%",
                    display: "inline-block",
                  }}
                ></span>
                <span>
                  {rowData.status}
                  <br></br>
                  {date}
                </span>
              </div>
            );
          },
          cellStyle: (e, rowData) => {
            let style = { padding: "6px 15px 6px 10px" };
            return style;
          },
        },
      ],
      tab2: [
        {
          title: "Company Name",
          field: "companyName",
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
          headerStyle: {
            padding: "6px 15px 6px 10px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              <span>
                <Link
                  to={"/Quote"}
                  onClick={() => this.onClickLinkRenewal(rowData)}
                >
                  {rowData.companyName
                    ? this.camelCase(rowData.companyName)
                    : "EMPTY_COMPANY_NAME"}
                </Link>
              </span>
            </Clamp>
          ),
        },
        {
          title: "Payroll",
          field: "payroll",
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
          headerStyle: {
            padding: "6px 15px 6px 10px",
          },
          render: (rowData) => numeral(rowData?.payroll).format("$0,0"),
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
        },
        {
          title: "Business Start Date",
          field: "businessStartDate",
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
          headerStyle: {
            padding: "6px 15px 6px 10px",
          },

          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => (
            <Clamp lines={1}>{rowData.businessStartDate}</Clamp>
          ),
        },
        {
          title: `${
            process.env.REACT_APP_MODE == "beta" ? "Carrier" : "CHUBB"
          } Program Expense`,
          field: "chubbProgramExpense",
          cellStyle: {
            padding: "6px 0px 6px 0px",
          },
          headerStyle: {
            padding: "6px 0px 6px 0px",
          },
          render: (rowData) => rowData.programFactorData.chubbProgramExpense,
        },
        {
          title: `${
            process.env.REACT_APP_MODE == "beta" ? "PEO" : "Extensis"
          } Program Expense`,
          field: "extensisProgramExpense",
          cellStyle: {
            padding: "6px 0px 6px 0px",
          },
          headerStyle: {
            padding: "6px 0px 6px 0px",
          },
          render: (rowData) => rowData.programFactorData.extensisProgramExpense,
        },
        {
          title: `${
            process.env.REACT_APP_MODE == "beta" ? "PEO" : "Extensis"
          }  Risk Margin`,
          field: "extensisRiskMargin",
          cellStyle: {
            padding: "6px 0px 6px 0px",
          },
          headerStyle: {
            padding: "6px 0px 6px 0px",
          },
          render: (rowData) => rowData.programFactorData.extensisRiskMargin,
        },
        {
          title: "E-18",
          field: "eVsNonE",
          cellStyle: {},
          headerStyle: {
            padding: "6px 0",
          },
          render: (rowData) =>
            rowData.wcCalculatedFactorData.eVsNonE
              ? this.camelCase(
                  rowData.wcCalculatedFactorData.eVsNonE.split("_").join(" ")
                )
              : "",
        },
        {
          title: "POM",
          field: "pom",
          cellStyle: {
            padding: "6px 0",
          },
          headerStyle: {
            padding: "6px 0",
          },
          render: (rowData) => rowData.wcCalculatedFactorData.pom,
        },
        {
          title: "Margin %",
          field: "margin",
          cellStyle: {
            padding: "6px 0",
          },
          headerStyle: {
            padding: "6px 0",
          },
          render: (rowData) =>
            rowData.wcCalculatedFactorData.margin
              ? `${rowData.wcCalculatedFactorData.margin}%`
              : "",
        },
        {
          title: "Current",
          field: "current",
          cellStyle: {
            padding: "6px 0",
          },
          headerStyle: {
            padding: "6px 0",
          },
          render: (rowData) =>
            rowData.wcCalculatedFactorData.currentCompositeRate,
        },
        {
          title: "Previous",
          field: "previous",
          cellStyle: {
            padding: "6px 0",
          },
          headerStyle: {
            padding: "6px 0",
          },
          render: (rowData) => rowData.previousCompositeRate,
        },
        {
          title: "Carrier",
          field: "selectedCarrier",
          render: (rowData) => rowData.selectedCarrier,
          cellStyle: {
            padding: "6px 0",
            wordBreak: "break-word",
          },
          headerStyle: {
            padding: "6px 0",
          },
        },
        {
          title: "Status",
          field: "status",
          cellStyle: {
            padding: "6px 0",
            // wordBreak: "break-word",
            minWidth: "150px",
          },
          headerStyle: {
            padding: "6px 0",
            minWidth: "150px",
          },
        },
        // {
        //   title: "Upload status",
        //   render: (rowData) => {
        //     if (typeof rowData.errorStatus === "object") {
        //       if (rowData.errorStatus.length === 0) {
        //         return "Uploaded";
        //       } else {
        //         let ul = Array.from(new Set(rowData.errorStatus)).map((ele) => {
        //           return <li key="ele">{this.firstCaps(ele)}</li>;
        //         });
        //         return (
        //           <ul
        //             style={{ listStyleType: "none", margin: "0", padding: "0" }}
        //           >
        //             {ul}
        //           </ul>
        //         );
        //       }
        //     } else {
        //       return "-";
        //     }
        //   },
        //   cellStyle: {
        //     wordBreak: "break-word",
        //     minWidth: "150px",
        //   },
        //   headerStyle: {
        //     minWidth: "150px",
        //   },
        // },
        {
          title: "",
          // field: "status",
          render: (rowData) => (
            <button
              className="btn btn-sm btnConfirm"
              disabled={
                !rowData.status.toLowerCase().includes("quote generated")
                // || "true" === rowData.renewalConfirmed
              }
              onClick={() => {
                this.props.updatePopUpData({
                  show: true,
                  title: "Do you wish to continue?",
                  children: (
                    <>
                      <br></br>
                      <span style={{ fontWeight: "bold" }}>
                        Record will be confirmed
                      </span>
                    </>
                  ),
                  acceptBtn: "Yes",
                  acceptBtnCallback: () => {
                    this.onclickConfirm(rowData);
                  },
                });
              }}
            >
              Confirm
            </button>
          ),
          cellStyle: {
            padding: "6px 0",
          },
          headerStyle: {
            padding: "6px 0",
          },
        },
        {
          title: "",
          render: (rowData) => (
            <Delete
              style={{ cursor: "pointer" }}
              onClick={() => {
                this.props.updatePopUpData({
                  show: true,
                  title: "Do you wish to continue?",
                  children: (
                    <>
                      <br></br>
                      <span style={{ fontWeight: "bold" }}>
                        Record will be deleted
                      </span>
                    </>
                  ),
                  acceptBtn: "Yes",
                  acceptBtnCallback: () => {
                    this.onclickDelete(rowData);
                  },
                });
              }}
            >
              Delete
            </Delete>
          ),
        },
      ],
      tab3: [
        {
          title: "Created Date (ET)",
          field: "uploadTimestamp",
          defaultSort: "desc",
          customFilterAndSearch: (term, rowData, field) =>
            this.custonFilterForCreatedDate(term, rowData, field),
          render: (rowData) => {
            let visitDate = moment
              .utc(Number(rowData.uploadTimestamp))
              .utcOffset("-0500")
              .format("M/D/YYYY - hh:mm A");
            let date = visitDate.split("-")[0];
            let time = visitDate.split("-")[1];
            return (
              <span>
                {date}
                <br></br>
                {time}
              </span>
            );
          },
          headerStyle: {
            padding: "6px 0px 6px 10px",
            minWidth: "90px",
            width: "90px",
            maxWidth: "90px",
          },
          cellStyle: {
            padding: "6px 0px 6px 10px",
            minWidth: "90px",
            width: "90px",
            maxWidth: "90px",
          },
        },
        {
          title: "Company Name",
          field: "companyName",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "120px",
            width: "120px",
            maxWidth: "120px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              <span onClick={() => this.onClickLinkExisting(rowData)}>
                <Link to={"/Quote"}>
                  {this.camelCase(rowData.companyName) || "EMPTY_COMPANY_NAME"}
                </Link>
              </span>
            </Clamp>
          ),
        },
        {
          title: "Effective Date",
          field: "effectiveDate",
          // defaultSort: "desc",
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => <Clamp lines={1}>{rowData.effectiveDate}</Clamp>,
        },
        {
          title: "Client ID",
          field: "clientId",
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
          render: (rowData) => (
            <Clamp lines={2}>{this.camelCase(rowData.clientId)}</Clamp>
          ),
        },
        {
          title: "Payroll",
          field: "clientPayroll",
          cellStyle: {
            padding: "6px 0px 6px 10px",
            minWidth: "90px",
            width: "90px",
            maxWidth: "90px",
          },
          headerStyle: {
            padding: "6px 0px 6px 10px",
            minWidth: "90px",
            width: "90px",
            maxWidth: "90px",
          },

          render: (rowData) =>
            numeral(rowData.clientPayroll).format("$0,0[.]00"),
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterfunction(term, rowData, field),
        },
        {
          title: "Business Start Date",
          field: "businessStartDate",
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
          render: (rowData) => (
            <Clamp lines={2}>
              {moment.utc(rowData.businessStartDate).format("M/D/YYYY")}
            </Clamp>
          ),
        },
        {
          title: "Carrier",
          field: "selectedCarrier",
          render: (rowData) => rowData.selectedCarrier || "-",
          cellStyle: {
            padding: "6px 0",
            wordBreak: "break-word",
          },
          headerStyle: {
            padding: "6px 0",
          },
        },
        {
          title: "Modified by",
          field: "modifiedBy",
          render: (rowData) =>
            <Clamp lines={1}>{rowData.modifiedBy}</Clamp> || "-",
          cellStyle: {
            padding: "6px 0",
            //wordBreak: "break-all",
            minWidth: "180px",
            width: "180px",
            maxWidth: "180px",
          },
          headerStyle: {
            padding: "6px 0",
          },
        },

        // {
        //   title: "Upload status",
        //   render: (rowData) => {
        //     if (typeof rowData.errorStatus === "object") {
        //       if (rowData.errorStatus.length === 0) {
        //         return "Uploaded";
        //       } else {
        //         let ul = Array.from(new Set(rowData.errorStatus)).map((ele) => {
        //           return <li key="ele">{this.firstCaps(ele)}</li>;
        //         });
        //         return (
        //           <ul
        //             style={{ listStyleType: "none", margin: "0", padding: "0" }}
        //           >
        //             {ul}
        //           </ul>
        //         );
        //       }
        //     } else {
        //       return "-";
        //     }
        //   },
        //   cellStyle: {
        //     wordBreak: "break-word",
        //     minWidth: "150px",
        //   },
        //   headerStyle: {
        //     minWidth: "150px",
        //   },
        // },
        // },
        {
          title: "Status",
          field: "status",
          cellStyle: {
            padding: "6px 10px 6px 0",
            // wordBreak: "break-word",
          },
          headerStyle: {
            padding: "6px 10px 6px 0",
          },
        },
      ],
    };

    return (
      <MaterialTable
        isLoading={isLoading}
        icons={tableIcons}
        data={rowDataList}
        columns={columns[tabName]}
        onSelectionChange={(rows) => {
          handleClick(rows);
        }}
        options={{
          paginationPosition: "both",
          padding: "dense",
          showTitle: false,
          thirdSortClick: false,
          pageSize: rowCount,
          pageSizeOptions: rowArray,
          filtering: true,
          exportButton: {
            csv: true,
            pdf: false,
          },
          headerStyle: {
            fontSize: ismobile_font ? this.fontfunction() : "0.9rem",
            backgroundColor: "#003764",
            color: "#FFF",
            padding: tabName !== "tab2" ? "2px 15px 2px 10px" : "",
          },
          rowStyle: {
            fontSize: "12px",
          },
          searchFieldStyle: {
            fontSize: "12px",
          },
          selection: tabName === "tab2" ? true : false,
        }}
        actions={
          tabName === "tab2"
            ? [
                {
                  tooltip: "Confirm All",
                  icon: () => (
                    <button
                      className="btn btn-sm btnConfirmAll"
                      disabled={
                        process.env.REACT_APP_MODE === "beta" ? false : true
                      }
                    >
                      Confirm All
                    </button>
                  ),
                  onClick: () => {
                    this.props.updatePopUpData({
                      show: true,
                      title: "Do you wish to continue?",
                      children: (
                        <>
                          <br></br>
                          <span style={{ fontWeight: "bold" }}>
                            Selected records will be confirmed
                          </span>
                        </>
                      ),
                      acceptBtn: "Yes",
                      acceptBtnCallback: () => {
                        this.onClickConfirmAll();
                      },
                    });
                  },
                },
              ]
            : []
        }
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
            </div>
          ),
          Pagination: (props) => (
            <TablePagination
              {...props}
              labelRowsPerPage={
                <span style={{ fontSize: 12 }}>{props.labelRowsPerPage}</span>
              }
              labelDisplayedRows={(row) => (
                <span style={{ fontSize: 12 }}>
                  {props.labelDisplayedRows(row)}
                </span>
              )}
              SelectProps={{
                style: {
                  fontSize: 12,
                },
              }}
            />
          ),
        }}
      />
    );
  };

  fetchData = async (id) => {
    let responseData;
    axios
      .get(awsUrl2 + `/api/getTotalsData/${id}`)
      .then((res) => {
        if (res.data === "processing") {
          const myTimeout = setTimeout(() => this.fetchData(id), 5000);
        } else {
          responseData = res.data;
          this.setState({ dashboardTotal: responseData });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  getTotalData = async () => {
    let { tabName } = this.props;
    const client =
      tabName === "tab1" ? "new" : tabName === "tab2" ? "renewal" : "existing";
    const res = await axios.get(
      awsUrl2 + `/api/getTotalsDataId/${client}/aachman@mobiux.in`
    );

    let id = res.data.id;

    this.fetchData(id);
  };

  updateRenewalDisplay = () => {
    axios
      .get(awsUrl2 + "/api/getRenewalClientDashboardData")
      .then((res) => {
        let rowDataList = [];
        let dataFromAPI = res.data;

        for (let key of dataFromAPI) {
          let payroll = 0;
          try {
            let payrollData = key.payrollData || {};
            for (let stateIndex in payrollData) {
              let classCodesInfo = payrollData[stateIndex].classCodesInfo || {};
              for (let classCodeIndex in classCodesInfo) {
                payroll = numeral(classCodesInfo[classCodeIndex].payroll.value)
                  .add(payroll)
                  .value();
              }
            }
          } catch (error) {}
          try {
            let tempRow = {
              selectedCarrier: key.selectedCarrier
                ? carrierMap[key.selectedCarrier]
                : "-",
              companyName:
                key.companyProfile && key.companyProfile.companyName
                  ? key.companyProfile.companyName.value
                  : "",
              effectiveDate: key.effective_date,
              clientId: key.client_id,
              businessStartDate: key?.companyProfile?.yearBusinessEstablished
                ?.value
                ? moment
                    .utc(key?.companyProfile?.yearBusinessEstablished?.value)
                    .format("M/D/YYYY")
                : "-",
              date: key.timestamp,
              status: key.status,
              payroll,
              errorMsg: key.errorMsg,
              programFactorData: key.programFactorData || {},
              wcCalculatedFactorData: key.wcCalculatedFactorData || {},
              client_id: key.client_id,
              quoteid: key.quoteid,
              errorStatus: key.errorMsg,
              lastGenerated: key.lastGenerated,
              companyProfile: key.companyProfile,
              emodStatesData: key.emodStatesData,
              historicalClaims: key.historicalClaims,
              historicalClaimsCheck: key.historicalClaimsCheck,
              payrollData: key.payrollData,
              previousCompositeRate: key.previousCompositeRate,
              renewalConfirmed: key.renewalConfirmed,
              existing: key.existing,
              deleted: key.deleted,
              program_manual_rate: key.program_manual_rate,
              program_manual_rate_prev: key.program_manual_rate_prev,
              composite_charge_rate: key.composite_charge_rate,
              composite_charge_rate_prev: key.composite_charge_rate_prev,
              uploadTimestamp: key.uploadTimestamp,
              listOfUuidCarrier: key.listOfUuidCarrier,
              histStatus: key.histStatus,
              eVsNonE: key.wcCalculatedFactorData.eVsNonE
                ? this.camelCase(
                    key.wcCalculatedFactorData.eVsNonE.split("_").join(" ")
                  )
                : "",
              pom: key.wcCalculatedFactorData.pom,
              margin: key.wcCalculatedFactorData.margin
                ? `${key.wcCalculatedFactorData.margin}%`
                : "",
              current: key.wcCalculatedFactorData.currentCompositeRate,

              previous: key.previousCompositeRate,
              extensisRiskMargin: key.programFactorData.extensisRiskMargin,
              chubbProgramExpense: key.programFactorData.chubbProgramExpense,
              extensisProgramExpense:
                key.programFactorData.extensisProgramExpense,
              updatedBusinessStartDate:
                key.companyProfile && key.companyProfile.yearBusinessEstablished
                  ? key.companyProfile.yearBusinessEstablished.value.split(
                      "T"
                    )[0]
                  : "",
            };

            if (
              tempRow.status &&
              tempRow.status.toLowerCase() !== "validation_error"
            ) {
              if (
                tempRow.status.toLowerCase() === "quote_generated" &&
                typeof tempRow.errorStatus === "object" &&
                tempRow.errorStatus.length > 0 &&
                tempRow.errorStatus.includes("Details have been defaulted")
              ) {
                tempRow.status = "Quote Generated, Details Have Been Defaulted";
              }
              tempRow.status = this.camelCase(
                tempRow.status.split("_").join(" ")
              ).trim();
            } else if (typeof tempRow.errorStatus === "object") {
              if (tempRow.errorStatus.length === 0) {
                if (tempRow.histStatus) {
                  tempRow.status = "Historical Claims Validation Error";
                } else {
                  tempRow.status = "Generating Quote";
                }
              } else {
                let ss = "";
                Array.from(new Set(tempRow.errorStatus)).map((ele) => {
                  if (ss === "") ss = this.camelCase(ele);
                  else ss = ss + ", " + this.camelCase(ele);
                });
                tempRow.status = ss.trim();
              }
            } else {
              tempRow.status = "Validation Error";
            }

            rowDataList.push(tempRow);
          } catch (error) {}
        }

        this.setState({ rowDataList });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  updateExistingDisplay = () => {
    axios
      .get(awsUrl2 + "/api/getExistingClientDashboardData")
      .then((res) => {
        let rowDataList = [];
        let dataFromAPI = res.data;

        for (let key of dataFromAPI) {
          try {
            let clientPayroll = 0;
            for (let stateIndex in key.payrollData) {
              try {
                for (let classCodeIndex in key.payrollData[stateIndex]
                  .classCodesInfo) {
                  clientPayroll = numeral(
                    key.payrollData[stateIndex].classCodesInfo[classCodeIndex]
                      .payroll.value
                  )
                    .add(clientPayroll)
                    .value();
                }
              } catch (error) {
                console.log("++ep", error);
                continue;
              }
            }

            let tempRow = {
              selectedCarrier: key.selectedCarrier
                ? carrierMap[key.selectedCarrier]
                : "-",
              companyName: key.companyProfile.companyName.value,
              effectiveDate: key.effective_date
                ? moment.utc(key.effective_date).format("M/D/YYYY")
                : "-",
              clientId: key.client_id,
              status: key.status,
              businessStartDate: key?.companyProfile?.yearBusinessEstablished
                ?.value
                ? moment
                    .utc(key?.companyProfile?.yearBusinessEstablished?.value)
                    .format("M/D/YYYY")
                : "-",
              date: key.timestamp,
              emodStatesData: key.emodStatesData,
              childrenLoc: key.payrollData,
              companyProfile: key.companyProfile,
              programManualRate: key.program_manual_rate,
              compositeChargeRate: key.composite_charge_rate,
              historicalClaims: key.historicalClaims || {},
              historicalClaimsCheck: key.historicalClaimsCheck || {},
              errorStatus: key.errorMsg,
              uploadTimestamp: key.uploadTimestamp,
              modifiedBy: key.modifiedByName || key.modifiedBy || "-",
              clientPayroll,
              updatedBusinessStartDate:
                key.companyProfile &&
                key.companyProfile.yearBusinessEstablished &&
                key.companyProfile.yearBusinessEstablished.value
                  ? key.companyProfile.yearBusinessEstablished.value.split(
                      "T"
                    )[0]
                  : "",
            };

            createTimeStamp(tempRow, "uploadTimestamp");

            if (key.charge_epp) {
              tempRow.costEppInputValue = {
                chargePEPM: key.charge_epp,
              };
            }

            if (key.cost_epp) {
              if (!tempRow.costEppInputValue) tempRow.costEppInputValue = {};

              tempRow.costEppInputValue = {
                ...tempRow.costEppInputValue,
                costPEPM: key.cost_epp,
              };
            }

            // set the upload status
            if (
              tempRow.status &&
              tempRow.status.toLowerCase() !== "validation_error"
            ) {
              if (
                tempRow.status.toLowerCase() === "quote_generated" &&
                typeof tempRow.errorStatus === "object" &&
                tempRow.errorStatus.length > 0 &&
                tempRow.errorStatus.includes("Details have been defaulted")
              ) {
                tempRow.status = "Quote Generated, Details Have Been Defaulted";
              }
              tempRow.status = this.camelCase(
                tempRow.status.split("_").join(" ")
              ).trim();
            } else if (typeof tempRow.errorStatus === "object") {
              if (tempRow.errorStatus.length === 0) {
                tempRow.status = "Generating Quote";
              } else {
                let ss = "";
                Array.from(new Set(tempRow.errorStatus)).map((ele) => {
                  if (ss === "") ss = this.camelCase(ele);
                  else ss = ss + ", " + this.camelCase(ele);
                });
                tempRow.status = ss.trim();
              }
            } else {
              tempRow.status = "Validation Error";
            }

            rowDataList.push(tempRow);
          } catch (error) {}
        }

        this.setState({ rowDataList });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  firstCaps = (str) => {
    return str ? str[0].toUpperCase() + "" + str.slice(1).toLowerCase() : "";
  };

  updateNewDisplay = () => {
    let requestBody = {};
    axios
      .post(
        awsUrl2 + "/api/putOrUpdateOpportunityData/getAll/getAll",
        requestBody
      )
      .then((res) => {
        let rowDataList = [];
        let dataFromAPI = res.data;

        for (let key of dataFromAPI) {
          try {
            if (!key.uploadTimestamp) continue;
            let cellColor;
            let textColor;
            let status = key.status;
            if (key.existing === "true") {
              status = "Bound";
              cellColor = "rgb(25, 101, 1)";
              textColor = "white";
            } else if (status === "sent_to_salesforce") {
              status = "To Salesforce";
              cellColor = "rgb(255, 214, 0)";
            } else if (status === "quote_generated") {
              status = "In Progress";
              cellColor = "#00b7eb";
            } else {
              status = "Submitted";
              cellColor = "rgb(1, 0, 138)";
              textColor = "white";
            }

            let tempRow = {
              selectedCarrier: key.selectedCarrier
                ? carrierMap[key.selectedCarrier]
                : "-",
              modifiedBy: key.modifiedByName || key.modifiedBy || "-",
              companyName:
                key.companyProfile &&
                key.companyProfile.companyName &&
                key.companyProfile.companyName.value
                  ? key.companyProfile.companyName.value
                  : "N/A",
              effectiveDate: key.effective_date
                ? moment.utc(key.effective_date).format("M/D/YYYY")
                : "-",
              opportunityId:
                key.companyProfile && key.companyProfile.emailId
                  ? key.companyProfile.emailId.value
                  : "",
              businessStartDate: key?.companyProfile?.yearBusinessEstablished
                ?.value
                ? moment
                    .utc(key?.companyProfile?.yearBusinessEstablished?.value)
                    .format("M/D/YYYY")
                : "-",
              status,
              cellColor,
              textColor,
              date: key.timestamp,
              emodStatesData: key.emodStatesData,
              childrenLoc: key.payrollData,
              companyProfile: key.companyProfile,
              historicalClaims: key.historicalClaims || {},
              historicalClaimsCheck: key.historicalClaimsCheck || {},
              uploadTimestamp: key?.uploadTimestamp,
              sendToSalesforceDate: key.sendToSalesforceDate,
              bindDate: key.bindDate,
              isSalesforce: key.isSalesforce,
              existingStatus: key.existing,
              client_ID: key.client_id || "",
              updatedBusinessStartDate:
                key.companyProfile &&
                key.companyProfile.yearBusinessEstablished &&
                key.companyProfile.yearBusinessEstablished.value
                  ? key.companyProfile.yearBusinessEstablished.value.split(
                      "T"
                    )[0]
                  : "",
            };
            createTimeStamp(tempRow, "uploadTimestamp");

            rowDataList.push(tempRow);
          } catch (error) {
            console.log("**error ", error);
          }
        }

        this.setState({ rowDataList });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    let { selected, tabName } = this.props;
    let { dashboardTotal, rowDataList } = this.state;
    console.log(dashboardTotal);
    return (
      <div
        className="Extensis-Dashboard"
        style={
          tabName !== selected ? { display: "none", fontSize: "12px" } : {}
        }
      >
        {tabName != "tab1" && (
          <div className="justify-content-between mt-3 d-flex mb-3 mobile-font-size">
            <div style={{ flex: 1 }} className="d-flex">
              <Box
                padding="pr-2 pl-0"
                color="black"
                text="Total GC"
                value={dashboardTotal.total_gc}
                upArrow={true}
                maximumFractionDigits={0}
              />
              <Box
                padding="pr-2 pl-0"
                color="black"
                text="Total Charge"
                value={dashboardTotal.total_charge}
                upArrow={false}
                maximumFractionDigits={0}
              />
              <Box
                padding="pr-2 pl-0"
                color="black"
                text="Total Cost"
                value={dashboardTotal.total_cost}
                upArrow={true}
                maximumFractionDigits={0}
              />
              <Box
                padding="pr-2 pl-0"
                color="#4472c4"
                text="Total Payroll"
                value={dashboardTotal.total_payroll}
                upArrow={true}
                maximumFractionDigits={0}
              />
            </div>
            <div style={{ flex: 1 }} className="d-flex justify-content-end">
              <Box
                padding="pl-2 pr-0"
                color="#b0f711"
                text="POM"
                value={dashboardTotal.pom}
                upArrow={false}
                type="value"
                maximumFractionDigits={2}
                textColor="black"
              />
              <Box
                padding="pl-2 pr-0"
                color="#b0f711"
                text="Margin %"
                value={dashboardTotal.margin_percent}
                upArrow={true}
                type="percent"
                maximumFractionDigits={0}
                textColor="black"
              />
              <Box
                padding="pl-2 pr-0"
                color="#b0f711"
                text="Mix Rate"
                value={dashboardTotal.mix_rate}
                upArrow={true}
                type="value"
                maximumFractionDigits={2}
                textColor="black"
              />
            </div>
          </div>
        )}
        <div>
          {rowDataList ? (
            this.setTableData()
          ) : (
            <div className="d-flex justify-content-center align-items-center h-100 w-100">
              <div className="blink_me" style={{ fontSize: "35px" }}>
                {" "}
                Loading Tables...{" "}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const Box = ({
  color,
  text,
  value,
  upArrow,
  textColor,
  padding,
  maximumFractionDigits,
  type,
}) => {
  return (
    <Col style={{ maxWidth: 130 }} className={`${padding}`}>
      <div
        style={{ backgroundColor: color }}
        className="rounded text-light p-2 h-100"
      >
        <p className="mb-1" style={{ color: textColor }}>
          {text}
        </p>
        <p className="mb-1" style={{ color: textColor }}>
          {type === "value"
            ? Intl.NumberFormat("en-US", {
                maximumFractionDigits,
              }).format(value)
            : type === "percent"
            ? Intl.NumberFormat("en-US", {
                maximumFractionDigits,
              }).format(value) + "%"
            : Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits,
              }).format(value)}
        </p>
        {/* <div className="clearfix">
          {upArrow ? (
            <ArrowDropUpIcon
              className="float-right"
              style={{ color: "green" }}
              fontSize="large"
            />
          ) : (
            <ArrowDropDownIcon
              className="float-right"
              style={{ color: "red" }}
              fontSize="large"
            />
          )}
        </div> */}
      </div>
    </Col>
  );
};

export default DashboardExtensis;
