import React, { Component } from "react";

class BinSection extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  getCellValue = (data) => {
    let response = "-";
    try {
      if (!data.from) {
        response = `< ${data.to}`;
      } else if (data.to === "-") {
        response = `> ${data.from}`;
      } else if (data.to && data.from) {
        response = `${data.from} to ${data.to}`;
      }
    } catch (error) {
      console.log("error", error);
    }

    return response;
  };

  createTable = () => {
    let { data, bin, binSelectionData } = this.props;
    let { individualBinSelections } = binSelectionData;
    let { frequencyBinValue, manualPremiumBinValue } = individualBinSelections
      ? individualBinSelections
      : {};
    let rowData = [[], [], [], [], [], []];
    let tableBody;
    let tableData;

    for (let i = 1; i <= 5; i++) {
      let frequencyValue = this.getCellValue(data.frequencyData[i]);
      let premiumValue = this.getCellValue(data.premiumData[i]);
      rowData[0].push(
        <td
          style={bin === i ? { backgroundColor: "#92d050" } : {}}
          key={`bin-${i}`}
        >
          {i}
        </td>
      );
      rowData[1].push(
        <td
          key={`frequency-${i}`}
          style={
            Number(frequencyBinValue) === i
              ? { backgroundColor: "#ffff00" }
              : {}
          }
        >
          {frequencyValue}
        </td>
      );
      rowData[2].push(
        <td
          key={`premium-${i}`}
          style={
            Number(manualPremiumBinValue) === i
              ? { backgroundColor: "#ffff00" }
              : {}
          }
        >
          {premiumValue}
        </td>
      );
    }
    for (let i = 6; i <= 10; i++) {
      let frequencyValue = this.getCellValue(data.frequencyData[i]);
      let premiumValue = this.getCellValue(data.premiumData[i]);
      rowData[3].push(
        <td
          style={bin === i ? { backgroundColor: "#92d050" } : {}}
          key={`bin-${i}`}
        >
          {i}
        </td>
      );
      rowData[4].push(
        <td
          key={`frequency-${i}`}
          style={
            Number(frequencyBinValue) === i
              ? { backgroundColor: "#ffff00" }
              : {}
          }
        >
          {frequencyValue}
        </td>
      );
      rowData[5].push(
        <td
          key={`premium-${i}`}
          style={
            Number(manualPremiumBinValue) === i
              ? { backgroundColor: "#ffff00" }
              : {}
          }
        >
          {premiumValue}
        </td>
      );
    }

    tableBody = (
      <tbody>
        <tr>
          <th>BIN</th>
          {rowData[0]}
        </tr>
        <tr>
          <th>Frequency @ 10k MP</th>
          {rowData[1]}
        </tr>
        <tr>
          <th>Premium Size</th>
          {rowData[2]}
        </tr>
        <tr>
          <th>BIN</th>
          {rowData[3]}
        </tr>
        <tr>
          <th>Frequency @ 10k MP</th>
          {rowData[4]}
        </tr>
        <tr>
          <th>Premium Size</th>
          {rowData[5]}
        </tr>
      </tbody>
    );

    tableData = (
      <div className="table-responsive">
        <table className="table table-sm table-bordered">
          <thead>
            <tr>
              <th className="text-center main-header" colSpan={6}>
                BIN SELECTION
              </th>
            </tr>
          </thead>
          {tableBody}
        </table>
      </div>
    );

    this.setState({ tableData });
  };

  componentDidMount = () => {
    this.createTable();
  };

  componentDidUpdate = (prevProps) => {
    let { binSelectionData, bin } = this.props;
    if (
      bin !== prevProps.bin ||
      JSON.stringify(binSelectionData) !==
        JSON.stringify(prevProps.binSelectionData)
    ) {
      this.createTable();
    }
  };

  render() {
    let { tableData } = this.state;
    return <div className="bin-section">{tableData}</div>;
  }
}

export default BinSection;
