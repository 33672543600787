import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Button from "react-bootstrap/Button";
import { awsUrl2 } from "../../../config";
import ClassCodeRateMatch from "./ClassCodeRateMatch";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import numeral from "numeral";
import BootstrapSwitchButton from "bootstrap-switch-button-react";
import { rgb } from "d3";
import { Row } from "react-bootstrap";

class RateMatch extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSection: true,
    };
  }

  handleGoalSeek = () => {
    let { goalSeekConfig, rateMatchValues, setRateMatchValues } = this.props;
    let { targetType, targetValue } = rateMatchValues;
    if (targetType && targetValue) {
      this.setState({ loaderActive: true });
      let requestBodyData = { ...goalSeekConfig };
      requestBodyData.calculation = {
        targetType,
        targetValue,
      };
      axios
        .post(awsUrl2 + "/api/goalSeek", requestBodyData)
        .then((response) => {
          setRateMatchValues("excessRiskMarginFactor", response.data);
        })
        .catch((error) => {
          console.log("error", error);
          setRateMatchValues("excessRiskMarginFactor", "error");
        })
        .finally(() => {
          this.setState({ loaderActive: false });
        });
    }
  };

  render() {
    let {
      rateMatchValues,
      setRateMatchValues,
      clearRateMatchValues,
      timestamp,
      uuid,
      currProspectData,
      setClassCodeRateMatchData,
      setClassCodeCompositeRateMatchData,
      setIsOverallLevel,
      isOverallLevel,
      currentSelectedCarrier,
      classCodeCompositeRateMatchData,
      setClassCodeCompositeRateData,
      setClassCodeCompositeRateMatchData_copy,
      setSalesforceMatchedRateData,
    } = this.props;

    let { showSection, loaderActive } = JSON.parse(JSON.stringify(this.state));
    let excessRiskMarginFactor, targetAppendText;
    if (rateMatchValues.excessRiskMarginFactor === "error") {
      excessRiskMarginFactor = "error";
    } else if (rateMatchValues.excessRiskMarginFactor !== undefined) {
      excessRiskMarginFactor =
        Math.round(rateMatchValues.excessRiskMarginFactor * 100 * 1000) / 1000;
    } else {
      excessRiskMarginFactor = "";
    }

    if (rateMatchValues.targetType === "target_margin_$") {
      targetAppendText = "$";
    } else if (rateMatchValues.targetType === "target_margin_%") {
      targetAppendText = "%";
    }

    return (
      <div className="rate-match mt-2">
        <div className="section-header d-flex align-items-center justify-content-between">
          <div className="d-flex align-items-center">
            <span className="header-name mr-3">Rate Match</span>
            <Form className="d-flex">
              <Form.Group style={{ marginBottom: "0" }}>
                <div className="bootstrapSwitchButtonRateMatch">
                  {currentSelectedCarrier === "carrier_a" && (
                    <BootstrapSwitchButton
                      checked={isOverallLevel}
                      onlabel="Overall"
                      offlabel="Class Code"
                      style="rate-match-switch"
                      onChange={setIsOverallLevel}
                    />
                  )}
                </div>
              </Form.Group>
            </Form>
          </div>
          <button
            className="close-button btn"
            onClick={() => this.setState({ showSection: !showSection })}
            style={{
              backgroundColor: "rgb(20, 99, 172)",
              color: "white",
              borderRadius: "0 20px 0 20px",
            }}
          >
            {showSection ? "Close" : "Open"}
          </button>
        </div>
        <div style={{ backgroundColor: "black", marginTop: "-10px" }}>
          <hr />
        </div>
        <div className={!showSection ? "d-none" : ""}>
          <div className="section-body d-flex mt-3 justify-content-center ">
            <Form className="section-form w-100">
              <div className="d-flex justify-content-between align-items-end price_modifier_form">
                <Form.Group controlId="rateMatchTarget">
                  <Form.Label>
                    <b>Rate Match - Overall Level</b>
                  </Form.Label>

                  <InputGroup>
                    <InputGroup.Prepend className="mr-2">
                      <select
                        value={
                          currentSelectedCarrier === "carrier_a"
                            ? rateMatchValues.targetType
                            : ""
                        }
                        id="targetTypeDropDown"
                        type="text"
                        onChange={(e) =>
                          setRateMatchValues("targetType", e.target.value)
                        }
                        autoComplete="off"
                        className="form-control pink-arrow"
                        disabled={currentSelectedCarrier !== "carrier_a"}
                      >
                        <option key={1} value={"target_margin_$"}>
                          Target Margin ($)
                        </option>
                        <option key={2} value={"target_margin_%"}>
                          Target Margin (%)
                        </option>
                        <option key={3} value={"target_pom"}>
                          Target POM
                        </option>
                        <option key={4} value={"target_pom_ex_tax"}>
                          Target POM ex T$S
                        </option>
                      </select>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="number"
                      value={
                        currentSelectedCarrier === "carrier_a"
                          ? rateMatchValues.targetValue
                            ? numeral(rateMatchValues.targetValue).value()
                            : ""
                          : ""
                      }
                      onChange={(e) =>
                        setRateMatchValues(
                          "targetValue",
                          numeral(e.target.value).value()
                        )
                      }
                      placeholder="Target value"
                      disabled={currentSelectedCarrier !== "carrier_a"}
                      className="rateMatchOverallInput"
                    />
                    {targetAppendText && (
                      <InputGroup.Append>
                        <InputGroup.Text>{targetAppendText}</InputGroup.Text>
                      </InputGroup.Append>
                    )}
                    <InputGroup.Append>
                      <Button
                        onClick={this.handleGoalSeek}
                        disabled={currentSelectedCarrier !== "carrier_a"}
                        style={{
                          borderRadius: "0 20px 20px 0",
                          backgroundColor: rgb(20, 99, 172),
                        }}
                      >
                        Seek
                      </Button>
                    </InputGroup.Append>
                  </InputGroup>
                </Form.Group>

                <Form.Group
                  controlId="excessRiskMargin"
                  className="excess-risk-margin"
                >
                  <Form.Label
                    className="text-center w-100"
                    style={{ borderRadius: "0 20px" }}
                  >
                    <b>Excess Risk Margin</b>
                  </Form.Label>
                  <LoadingOverlay active={loaderActive} text="Loading...">
                    <InputGroup style={{ marginTop: "6px" }}>
                      <InputGroup.Prepend>
                        <Button
                          // variant="success"
                          style={{
                            backgroundColor: "#E9ECEF",
                            color: "black",
                            fontWeight: "bolder",
                          }}
                          onClick={clearRateMatchValues}
                          disabled={currentSelectedCarrier !== "carrier_a"}
                        >
                          X
                        </Button>
                      </InputGroup.Prepend>
                      <Form.Control
                        disabled
                        value={
                          currentSelectedCarrier === "carrier_a"
                            ? excessRiskMarginFactor
                            : ""
                        }
                      />
                      <InputGroup.Append>
                        <InputGroup.Text className="excessRiskMarginappend">
                          %
                        </InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </LoadingOverlay>
                </Form.Group>
              </div>
            </Form>
          </div>
          {timestamp && uuid && currProspectData && (
            <ClassCodeRateMatch
              timestamp={timestamp}
              uuid={uuid}
              email={currProspectData.email}
              classCodeCompositeRateMatchData={classCodeCompositeRateMatchData}
              setClassCodeRateMatchData={setClassCodeRateMatchData}
              setClassCodeCompositeRateMatchData={
                setClassCodeCompositeRateMatchData
              }
              setClassCodeCompositeRateMatchData_copy={
                setClassCodeCompositeRateMatchData_copy
              }
              setSalesforceMatchedRateData={setSalesforceMatchedRateData}
              setClassCodeCompositeRateData={setClassCodeCompositeRateData}
              currentSelectedCarrier={currentSelectedCarrier}
            />
          )}
        </div>
      </div>
    );
  }
}

export default RateMatch;
