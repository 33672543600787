import React, { Component } from "react";
import ReactDOM from "react-dom";
import Navbar from "../common/Navbar";
import Progress from "../common/Progress";
import FormCompPro from "../subcompo/sections-quote/FormCompPro";
import FormCompareRate from "../subcompo/sections-quote/FormCompareRate";
import FormUnderQues from "../subcompo/sections-quote/FormUnderQues";
import Footer from "../common/Footer";
import Loader from "../common/Loader";
import FormCmprQuote from "../subcompo/sections-quote/FormCmprQuote";
import FormPayment from "../subcompo/sections-quote/FormPayment";
import Binder from "../subcompo/sections-quote/Binder";

class Quote extends Component {
  componentDidUpdate() {
    ReactDOM.findDOMNode(this).scrollTop = 0;
  }
  render() {
    return (
      <div id="quoteMain">
        {/* <img src={require('../../images/ban_main_fade.jpg')} alt=''/> */}
        <Navbar />
        <div id="contentHolder">
          <Progress />
          <div id="mainForm" className="tab-content tab-content-quote">
            <FormCompPro />
            <FormCompareRate />
            <FormUnderQues />
            <FormCmprQuote />
            <FormPayment />
            <Binder />
          </div>
        </div>
        <Footer />
        <Loader />
      </div>
    );
  }
}

export default Quote;
