import React, { Component } from "react";
import { clearErr } from "../../utils/common";
var stateCity = require("../../utils/state_city.json");
let stateCityExtra = require("../../utils/state_city_extra.json");

class StateList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extraStates: this.props.extraStates
    };
  }

  componentDidUpdate(prevProps) {
    const { resetClassCodeDescriptionData, addressBlockNo } =
      this.props;
    const { extraStates } = this.state;
    if (this.state.locationInfo !== this.props.locationInfo) {
      this.setState({ locationInfo: this.props.locationInfo });
    }
    if (this.props.state !== this.state.state) {
      this.setState({
        state: this.props.state,
      });
      //resetClassCodeDescriptionData(addressBlockNo);
    }

    if (JSON.stringify(this.props.extraStates) !== JSON.stringify(extraStates)) {
      this.setState({ extraStates: this.props.extraStates });
    }

    // if (extraStates &&
    //   prevProps &&
    //   prevProps.extraStates &&
    //   JSON.stringify(extraStates) !== JSON.stringify(prevProps.extraStates)) {
    //   if (extraStates && extraStates.value === "Yes") {
    //     stateCollection = [...stateCity, ...stateCityExtra];
    //   } else {
    //     stateCollection = stateCity;
    //   }
    //   this.setState({ stateCollection });
    // }
  }

  render() {
    let stateSet = sessionStorage.getItem("stateSet")
      ? JSON.parse(sessionStorage.getItem("stateSet"))
      : {};
    const { updateLocationInfo, resetClassCodeDescriptionData } = this.props;
    const { locationInfo, extraStates } = this.state;
    let stateCollection;
    if (extraStates && extraStates.value === "Yes") {
      stateCollection = [...stateCity, ...stateCityExtra];
    } else {
      stateCollection = stateCity;
    }

    const number = locationInfo ? locationInfo.number : null;
    const stateList = [
      <option key={0} value="">
        State
      </option>,
    ];

    stateCollection.sort((a, b) => {
      if (a.Code < b.Code) {
        return -1;
      } else if (a.Code > b.Code) {
        return 1;
      } else {
        return 0;
      }
    })

    for (var item of stateCollection) {
      if (locationInfo && item.Code === locationInfo.state.value) {
        stateList.push(
          <option key={item.Code} value={item.Code} selected>
            {item.Code}
          </option>
        );
      } else if (!stateSet[item.Code.toUpperCase()]) {
        stateList.push(
          <option key={item.Code} value={item.Code}>
            {item.Code}
          </option>
        );
      }
    }
    return (
      <select
        name="stateCodes"
        className="form-control"
        onChange={(event) => {
          updateLocationInfo(event.target.value, number, "state");
          resetClassCodeDescriptionData(number);
        }}
        style={
          locationInfo && locationInfo.state.error ? { borderColor: "red" } : {}
        }
      >
        {stateList}
      </select>
    );
  }
}
export default StateList;
