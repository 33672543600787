import React, { Component } from "react";
import { showErrAlert, onlyNum, clearErr } from "../../../utils/common";
import moment from "moment";
import axios from "axios";
import { awsUrl } from "../../../config";
import { Auth } from "aws-amplify";
import { getHeader } from "../../../utils/common";
import SmallLoader from "../../common/SmallLoader";
import { Form, InputGroup, FormControl, Button, Col } from "react-bootstrap";

class ReviewItem extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hold: false,
      approve: false,
      reject: false,
      buttonActive: true,
      bound: false,
      review: "",
      creditChecked: true,
    };
    this.carrierReply = this.carrierReply.bind(this);
    this.getButtonActive = this.getButtonActive.bind(this);
  }
  componentDidMount() {
    if (this.props.data.quote_status === "hold") {
      this.setState({
        hold: true,
      });
    } else if (this.props.data.quote_status === "proceed_uw") {
      this.setState({
        approve: true,
      });
    } else if (this.props.data.quote_status === "reject") {
      this.setState({
        reject: true,
      });
    } else if (this.props.data.quote_status === "bound") {
      this.setState({
        bound: true,
      });
    }
  }
  carrierReply = (resStrig, event) => {
    var data = this.props.data;

    let keys = data.uuid_carrier;

    if (resStrig === "uwportal") {
      data.review = this.props.data.review;
    }
    if (
      resStrig === "hold" ||
      resStrig === "reject" ||
      resStrig === "approve"
    ) {
      data.review = this.state.review;
    }

    for (let uuid of keys) {
      let data2send = {
        Key: {
          user_email_id: data.user_email_id.toLowerCase(),
          uuid_carrier: uuid,
        },
        UpdateExpression:
          "set review = :review, quote_status = :quote_status, uw = :uw",
        ExpressionAttributeValues: {
          ":review": data.review,
          ":quote_status": resStrig,
          ":uw": true,
        },
        ReturnValues: "UPDATED_NEW",
      };

      Auth.currentSession().then((res) => {
        const USER_TOKEN = res.idToken.jwtToken;
        const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
        console.log("Users data:", USER_TOKEN, header);
        axios
          .post(awsUrl + "/api/updateUsersData/", data2send, header)
          .then((response) => {
            if (resStrig === "hold") {
              this.setState({
                hold: true,
              });
            }
            if (resStrig === "reject") {
              this.setState({
                reject: true,
              });
            }
            if (resStrig === "uwportal") {
              this.setState({
                hold: false,
                buttonActive: true,
              });
            }
            if (resStrig === "proceed_uw") {
              this.setState({
                approve: true,
              });
            }
            console.log("Success responce: ", response);
          })
          .catch((err) => {
            showErrAlert(err);
            console.log("Error in responce: ", err);
          });
      });
    }
  };

  reCalculateQuote = async () => {
    let { sdr_factor, creditChecked } = this.state;
    let { data } = this.props;

    let state = data.uuid_carrier[0]
      .split("+")
      .reverse()[0]
      .split("_")
      .reverse()[1]
      .toLowerCase();

    if (sdr_factor) {
      this.setState({ scheduleRangeError: false, showUploadProgress: true });
      const header = await getHeader();
      axios
        .get(awsUrl + "/api/getScheduleDebitCreditRange/" + state, header)
        .then((response) => {
          let range = response.data;
          let errMessage, rangeVal, check, new_sdr_factor;
          if (creditChecked) {
            errMessage = `Your credit range is 0 - ${range[1]}`;
            rangeVal = range[1];
            check = sdr_factor <= rangeVal && sdr_factor >= 0;
            new_sdr_factor = -parseInt(sdr_factor).toString();
          } else {
            errMessage = `Your debit range is 0 - ${range[0]}`;
            rangeVal = range[0];
            check = sdr_factor <= rangeVal && sdr_factor >= 0;
            new_sdr_factor = parseInt(sdr_factor).toString();
          }
          if (!check) {
            this.setState({
              scheduleRangeError: true,
              scheduleRange: range,
              showUploadProgress: false,
              errorMessage: errMessage,
            });
          } else {
            let body;
            body = {
              uuid_carrier: data.uuid_carrier,
              user_email_id: data.user_email_id,
              sdr_factor: new_sdr_factor,
            };
            axios
              .post(awsUrl + "/api/reCalculateQuote", body, header)
              .then((response) => {
                this.setState({ showUploadProgress: false });
                console.log("++data recalc", response);
              })
              .catch((err) => {
                alert("Unable to re-calculate");
                this.setState({ showUploadProgress: false });
                console.log("Error", err);
              });
          }
        })
        .catch((err) => {
          console.log("err", err);
          this.setState({
            scheduleRangeError: true,
            scheduleRange: null,
            showUploadProgress: false,
            errorMessage: "Unable to get range!",
          });
        });
    } else {
      this.setState({
        scheduleRangeError: true,
        scheduleRange: null,
        errorMessage: "Please enter a value!",
      });
    }
  };

  getButtonActive(e) {
    this.setState({ review: e.target.value });
    if (e.target.value !== "") {
      this.setState({
        buttonActive: false,
      });
    } else {
      this.setState({
        buttonActive: true,
      });
    }
  }

  handleScheduleOnchange = (e) => {
    clearErr(e);
    this.setState({ scheduleRangeError: false });
  };

  switchClick = (e) => {
    this.setState({ creditChecked: e.target.checked });
    const { scheduleRange } = this.state;
    if (scheduleRange) {
      if (e.target.checked) {
        this.setState({
          errorMessage: `Your credit range is 0 - ${scheduleRange[1]}`,
        });
      } else {
        this.setState({
          errorMessage: `Your debit range is 0 - ${scheduleRange[0]}`,
        });
      }
    }
  };

  render() {
    const {
      scheduleRangeError,
      scheduleRange,
      showUploadProgress,
      creditChecked,
      errorMessage,
    } = this.state;
    const timestamp = this.props.data.uuid_carrier[0].split("@")[0];
    let a = moment.utc(parseInt(timestamp));
    let b = moment.utc();

    return (
      <tr>
        <td className="per10">
          {this.props.data !== undefined ? this.props.data.effective_date : ""}
          <br />
          <span className="compNameSmall">
            {this.props.data !== undefined ? this.props.data.company_name : ""}
          </span>
        </td>
        <td className="per10 text-center">
          <span className="">
            {timestamp !== undefined ? `${30 - b.diff(a, "days")} days` : ""}
          </span>
        </td>
        <td className="per5 text-center">
          <button
            className="btn pt-0"
            type="button"
            data-image={"acord.jpg"}
            onClick={(event) => {
              this.props.displayDocument("quote-pdf", this.props.data);
            }}
          >
            <img src={require("../../../images/pdf.png")} alt="" />
          </button>
        </td>
        <td className="per5 text-center">
          <button
            className="btn pt-0"
            type="button"
            data-image={"acord.jpg"}
            onClick={(event) => {
              this.props.displayDocument("acord-form", this.props.data);
            }}
          >
            <img src={require("../../../images/pdf.png")} alt="" />
          </button>
        </td>
        <td className="per5 text-center">
          <button
            className="btn pt-0"
            type="button"
            data-image={"lr.jpg"}
            onClick={(event) => {
              this.props.displayDocument("loss-run", this.props.data);
            }}
          >
            <img src={require("../../../images/pdf.png")} alt="" />
          </button>
        </td>
        <td className="per5 text-center">
          <button
            className="btn pt-0"
            type="button"
            data-image={"hc.png"}
            onClick={(event) => {
              this.props.displayDocument("loss-summary", this.props.data);
            }}
          >
            <img src={require("../../../images/view.png")} alt="" />
          </button>
        </td>
        <td className="per20 text-center">
          <Form>
            <Form.Row>
              <Col xs="auto" style={{ paddingTop: "6px" }}>
                <Form.Check
                  type="switch"
                  id={"credit-switch-" + this.props.item}
                  checked={creditChecked}
                  onChange={this.switchClick}
                  label={creditChecked ? "Credit" : "Debit"}
                  disabled={this.state.approve}
                />
              </Col>
              <Col>
                <InputGroup size="sm">
                  <FormControl
                    type="number"
                    style={
                      this.state.scheduleRangeError
                        ? { borderColor: "red" }
                        : {}
                    }
                    disabled={this.state.approve}
                    onChange={this.handleScheduleOnchange}
                    onKeyDown={onlyNum}
                    onKeyUp={(e) =>
                      this.setState({ sdr_factor: e.target.value })
                    }
                    placeholder={creditChecked ? "Credit" : "Debit"}
                    aria-label="Debit or Credit"
                    aria-describedby="basic-addon2"
                    max="100"
                  />
                  <InputGroup.Append>
                    <InputGroup.Text>%</InputGroup.Text>
                    <Button
                      variant="primary"
                      id="re-calculate-button"
                      onClick={() => this.reCalculateQuote()}
                      disabled={this.state.approve}
                    >
                      Calculate
                    </Button>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
            </Form.Row>
          </Form>
          <div className="d-flex debit-credit-error">
            {showUploadProgress && <SmallLoader />}
            {scheduleRangeError &&
              (!scheduleRange ? (
                <span style={{ fontSize: "0.8rem", color: "red" }}>
                  {errorMessage}
                </span>
              ) : (
                <span style={{ fontSize: "0.8rem", color: "red" }}>
                  {errorMessage}
                </span>
              ))}
          </div>
        </td>
        <td className="per20 text-center">
          {!this.state.hold &&
            !this.state.reject &&
            !this.state.approve &&
            !this.state.bound && (
              <div className="uw-decision">
                <button
                  type="button"
                  onClick={(event) => this.carrierReply("proceed_uw", event)}
                  className="btn btn-success mr-1 btn-sm"
                >
                  Approve
                </button>
                <button
                  data-toggle="tooltip"
                  disabled={this.state.buttonActive}
                  type="button"
                  className="btn btn-warning mr-1 btn-sm"
                  onClick={(event) => this.carrierReply("hold", event)}
                  title="Reason Required in Remarks"
                >
                  Hold
                </button>
                <button
                  data-toggle="tooltip"
                  disabled={this.state.buttonActive}
                  type="button"
                  className="btn btn-danger btn-sm"
                  onClick={(event) => this.carrierReply("reject", event)}
                  title="Reason Required in Remarks"
                >
                  Reject
                </button>
              </div>
            )}
          {this.state.approve && (
            <div className="uw-decision">
              <button type="button" className="btn btn-success btn-sm" disabled>
                Approved
              </button>
            </div>
          )}
          {this.state.hold && (
            <div className="uw-decision">
              <button
                type="button"
                onClick={(event) => this.carrierReply("uwportal", event)}
                className="btn btn-warning"
              >
                In Hold
              </button>
            </div>
          )}
          {this.state.reject && (
            <div className="uw-decision">
              <button type="button" className="btn btn-danger" disabled>
                Rejected
              </button>
            </div>
          )}
          {this.state.bound && (
            <div className="uw-decision">
              <button type="button" className="btn btn-secondary" disabled>
                Bound
              </button>
            </div>
          )}
        </td>
        <td>
          <textarea
            disabled={
              this.state.approve ||
              this.state.reject ||
              this.state.hold ||
              this.state.bound
            }
            rows="5"
            name="review"
            style={{ width: "100%" }}
            placeholder={
              this.state.approve ||
                this.state.reject ||
                this.state.bound ||
                this.state.hold
                ? this.props.data.review
                : "Add comments here. If risk is being rejected or placed on hold, kindly specify reason(s)."
            }
            onKeyUp={this.getButtonActive}
          ></textarea>
        </td>
      </tr>
    );
  }
}

export default ReviewItem;
