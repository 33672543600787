import React, { Component } from "react";

class StateAggr extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate() {
    if (this.props.stateAggregateParent !== this.state.stateAggregate) {
      this.setState({ stateAggregate: this.props.stateAggregateParent });
    }
  }
  render() {
    const { stateCode, locations } = this.props;
    const { stateAggregate } = this.state;
    let stateCodeIndexList = [];
    let minVal;
    let maxVal;
    for (let location in locations) {
      let state = locations[location].stateCode;
      if (state === stateCode) {
        stateCodeIndexList.push(location);
      }
    }
    for (let index of stateCodeIndexList) {
      if (
        stateAggregate &&
        stateAggregate[index] &&
        stateAggregate[index].min !== "N/A" &&
        stateAggregate[index].max !== "N/A"
      ) {
        if (!minVal && !maxVal) {
          minVal = 0;
          maxVal = 0;
        }
        minVal += stateAggregate[index].min;
        maxVal += stateAggregate[index].max;
      }
    }
    return (
      <div>
        <div className="row statePrem" id={"statePrem" + stateCode}>
          <div className="col-sm-1 clearfix"></div>
          <div className="col-sm-3 clearfix"></div>
          <div className="col-sm-3 clearfix"></div>
          <div className="col-sm-1 text-center clearfix m-auto">
            <span className="stateAggName" id={"stateAggName" + stateCode}>
              {stateCode}
            </span>
          </div>
          <div className="col-sm-4 relDiv rangeSlider">
            <div className="row justify-content-center">
              <div id="minVal" className="premVal">
                <span className="minValueCC">
                  {minVal
                    ? Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                      }).format(minVal)
                    : "-"}
                </span>
              </div>
              <div className="barBG"></div>
              <div id="maxVal" className="premVal">
                <span className="manValueCC">
                  {maxVal
                    ? Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                        minimumFractionDigits: 0,
                      }).format(maxVal)
                    : "-"}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default StateAggr;
