import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Navbar from '../common/Navbar'
import ItWorks from '../subcompo/sections-home/ItWorks';
import Footer from '../common/Footer';
import Loader from '../common/Loader';
import $ from 'jquery';

class HowItWorksRates extends Component {
  componentDidMount() {
    // ReactDOM.findDOMNode(this).scrollTop = 0
    $("html, body").animate({ scrollTop: 0 }, 250);
  }
  render() {
     return (
       <div>
        <Navbar/>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="text-center">Rates</h2>
              <p className="text-center">
              <img src={require('../../images/work_1.jpg')} className="text-center" alt='' />
              </p>
              <p className="secDesc" style={{marginTop: "30px"}}>
              Our live rating tool takes minimal inputs and provides you with quotes from multiple carriers for comparison. If you have locations across states, we provide you options to compare and select quotes from different carriers in different states. Select the best quotes from carriers and save big.
              </p>
            </div>
          </div>
        </div>
        <ItWorks/>
        <Footer/>
        <Loader/>
      </div>
    );
  }
}

export default HowItWorksRates;
