import React, { Component } from "react";
import { Link } from "react-router-dom";

class ItWorks extends Component {
  render() {
    return (
      <div id="itWorks" className="container-fluid">
        <div className="row row-3steps">
          <div className="col-md-5 text-center my-auto col-simplesteps">
            <div className="ml-md-auto mr-md-auto ml-5 simple-steps-wrapper">
              <h2 className="step-count">3</h2>
              <h3 className="step-count">Simple Steps!</h3>
              <div className="line-below-3"></div>
            </div>
          </div>
          <div className="col-md-7 col-simplesteps-cards">
            <div className="card border-0">
              <div className="row no-gutters">
                <div className="col-md-2 col-card-image">
                  <img
                    src={require("../../../images/black-card-1.png")}
                    className="card-img"
                    alt="..."
                  />
                </div>
                <div className="col-md-9 col-card-text">
                  <div className="card-body">
                    <h5 className="card-title">Compare Rates</h5>
                    <p className="card-text">
                      Our ‘live’ rating tool helps you compare rates instantly
                      at a state and class code level, giving you ball-park
                      estimates with very minimal input.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card border-0">
              <div className="row no-gutters">
                <div className="col-md-2 col-card-image">
                  <img
                    src={require("../../../images/black-card-2.png")}
                    className="card-img"
                    alt="..."
                  />
                </div>
                <div className="col-md-9 col-card-text">
                  <div className="card-body">
                    <h5 className="card-title">Get Quotes</h5>
                    <p className="card-text">
                      With little more info, our quote engine will provide you
                      full-blown ‘bindable’ quotes from all interested carriers,
                      allowing you to choose most preferred option.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="card border-0">
              <div className="row no-gutters">
                <div className="col-md-2 col-card-image">
                  <img
                    src={require("../../../images/black-card-3.png")}
                    className="card-img"
                    alt="..."
                  />
                </div>
                <div className="col-md-9 col-card-text">
                  <div className="card-body">
                    <h5 className="card-title">Final Bind</h5>
                    <p className="card-text">
                      Our integrated platform allows you to electronically
                      upload all required documents and get your binder
                      instantly from your preferred carrier.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row row-our-mission">
          <div className="col-md-12">
            <h1>Our Mission</h1>
            <h2>
              To provide an easy-to-use digital platform that offers a small
              business owners multiple rates, quote and bind options for
              securing worker's comp coverage instantly
            </h2>
          </div>
        </div>

        <div className="row row-everything-you-need">
          <div className="col-md-12">
            <h1>Got everything you need</h1>
            <div className="card-deck card-row">
              <div className="card border-0">
                <img
                  src={require("../../../images/blue-card-1.png")}
                  className="card-img-top d-block mx-auto"
                  alt="img1"
                />
                <div className="card-body">
                  <h5 className="card-title">Simple interface</h5>
                </div>
              </div>
              <div className="card border-0">
                <img
                  src={require("../../../images/blue-card-2.png")}
                  className="card-img-top d-block mx-auto"
                  alt="img2"
                />
                <div className="card-body">
                  <h5 className="card-title">Flexible payment plans</h5>
                </div>
              </div>
              <div className="card border-0">
                <img
                  src={require("../../../images/blue-card-3.png")}
                  className="card-img-top d-block mx-auto"
                  alt="img3"
                />
                <div className="card-body">
                  <h5 className="card-title">Instant payment & bind options</h5>
                </div>
              </div>
            </div>
            {/* row 2 */}
            <div className="card-deck card-row">
              <div className="card border-0">
                <img
                  src={require("../../../images/blue-card-4.png")}
                  className="card-img-top d-block mx-auto"
                  alt="img1"
                />
                <div className="card-body">
                  <h5 className="card-title">
                    Many participating insurance carriers
                  </h5>
                </div>
              </div>
              <div className="card border-0">
                <img
                  src={require("../../../images/blue-card-5.png")}
                  className="card-img-top d-block mx-auto"
                  alt="img2"
                />
                <div className="card-body">
                  <h5 className="card-title">
                    Self-administer your own eligibility criteria
                  </h5>
                </div>
              </div>
              <div className="card border-0">
                <img
                  src={require("../../../images/blue-card-6.png")}
                  className="card-img-top d-block mx-auto"
                  alt="img3"
                />
                <div className="card-body">
                  <h5 className="card-title">
                    Underwrite online 'within the box' and offline via a
                    qualified team
                  </h5>
                </div>
              </div>
            </div>
            {/* row 2 end */}
            <div className="card-deck card-row">
              <div className="card border-0">
                <img
                  src={require("../../../images/blue-card-7.png")}
                  className="card-img-top d-block mx-auto"
                  alt="img1"
                />
                <div className="card-body">
                  <h5 className="card-title">
                    Comparative rates within seconds
                  </h5>
                </div>
              </div>
              <div className="card border-0">
                <img
                  src={require("../../../images/blue-card-8.png")}
                  className="card-img-top d-block mx-auto"
                  alt="img2"
                />
                <div className="card-body">
                  <h5 className="card-title">Quick API integrations</h5>
                </div>
              </div>
              <div className="card border-0">
                <img
                  src={require("../../../images/blue-card-9.png")}
                  className="card-img-top d-block mx-auto"
                  alt="img3"
                />
                <div className="card-body">
                  <h5 className="card-title">Intelligent analytics</h5>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row row-video">
          <div className="col-md-12">
            <h1>Digital Insurance Agent For Your</h1>
            <h1>Worker’s Comp Needs</h1>
            <br />
            <h2>
              Secure your worker's compensation coverage online with Insure
              Comp's hassle-free interface. We provide you multiple options to
              choose from, based on your preferences. Compare rates, get quotes
              and bind coverage seamlessly, and at jet speed!
            </h2>
            <iframe
              width="100%"
              height="546"
              src="https://www.youtube.com/embed/rfKtaX25Uhg?modestbranding=1"
              frameborder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowfullscreen
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default ItWorks;
