import React, { Component } from "react";
import { proceedBind2, quoteView } from "../../../utils/form_comp_quote";
import ParseStatusCheck from "../../subcompo/sections-quote/ParseStatusCheck";
import SmallLoader from "../../common/SmallLoader";
import axios from "axios";
import { Auth } from "aws-amplify";
class BindQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showUploadProgress: false,
      lossParseError: false,
      lossParseSuccess: false,
      disableBind: false, //change to true when working on this!
      showLoader: true,
      parseStatus: "Waiting for loss file parsing!",
      parseStatusCss: "loss-parse-error",
    };
    this.quoteView = quoteView.bind(this);
  }

  componentDidMount() {
    // this.s3Poller();
  }

  s3Poller = () => {
    Auth.currentSession().then((res) => {
      const USER_TOKEN = res.idToken.jwtToken;
      const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
      axios
        .get(
          "http://localhost:3000" +
            "/api/s3Poller/loss-run-ocr/41b096e4-2134-4299-ace0-73d52daae3e8/us-east-1",
          header
        )
        .then((result) => {
          if (result.data.fileType) {
            this.handleLossOcrParse(result.data);
          } else {
            this.s3Poller();
          }
        })
        .catch((err) => {
          console.log("Error", err);
        });
    });
  };

  handleLossOcrParse = (data) => {
    this.setState({ showLoader: false });
    if (!data.fileType) {
      this.setState({ disableBind: true });
    } else {
      if (data.fileType === "success") {
        this.setState({
          disableBind: false,
          parseStatus: "Loss file matches!",
          parseStatusCss: "loss-parse-success",
        });
      } else {
        this.setState({
          disableBind: true,
          parseStatus:
            "Issues in loss file, please re-upload or edit your historical claims!",
          parseStatusCss: "loss-parse-error",
        });
      }
    }
  };

  render() {
    const {
      quoteTable,
      userName,
      currProspectData,
      quoteStatusCarrier,
      viewQuoteDisable,
      isHistoryVariant,
    } = this.props;
    const {
      disableBind,
      showUploadProgress,
      showLoader,
      parseStatus,
      parseStatusCss,
    } = this.state;
    return (
      <div className="col-sm-12 text-center">
        <button
          onClick={() =>
            this.quoteView(
              quoteTable,
              currProspectData,
              userName,
              quoteStatusCarrier
            )
          }
          disabled={viewQuoteDisable}
          className={
            viewQuoteDisable
              ? "view-quote btn btn-secondary btnView mt-0"
              : "view-quote btn btn-primary btnView mt-0"
          }
        >
          VIEW QUOTE
        </button>
        <button
          onClick={() =>
            proceedBind2(
              quoteTable,
              userName,
              currProspectData,
              isHistoryVariant
            )
          }
          className="btn btn-primary btnSubmits mt-0"
          disabled={disableBind || viewQuoteDisable}
        >
          CONTINUE
        </button>
        {showUploadProgress && (
          <div style={{ display: "inline-block", marginLeft: "1rem" }}>
            <SmallLoader />
          </div>
        )}
        {/* <div style={{ marginTop: "1rem" }}>
          <ParseStatusCheck
            showLoader={showLoader}
            parseStatus={parseStatus}
            id={parseStatusCss}
          />
        </div> */}
        <hr />
      </div>
    );
  }
}

export default BindQuote;
