import React, { Component } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Home from "./component/pages/Home";
import HowItWorksRates from "./component/pages/HowItWorksRates";
import HowItWorksQuotes from "./component/pages/HowItWorksQuotes";
import HowItWorksBind from "./component/pages/HowItWorksBind";
import HowItWorksBrochure from "./component/pages/HowItWorksBrochure";
import FAQPage from "./component/pages/FAQPage";
import Login from "./component/pages/Login";
import DataUpload from "./component/pages/DataUpload";
import Quote from "./component/pages/Quote";
import PanelUW from "./component/pages/PanelUW";
import PanelCompliance from "./component/pages/PanelCompliance";
import BinderDownload from "./component/common/BinderDownload";
import QuotesHistory from "./component/pages/QuotesHistory";
import UsMap from "./component/pages/Map";
import DashboardTabs from "./component/pages/DashboardTabs";
import DashboardTabsApiTracking from "./component/pages/DashboardTabsApiTracking";
import DashboardWithMap from "./component/pages/DashboardWithMap";
import DashboardLogin from "./component/pages/DashboardLogin";
import NewQuote from "./newcomponent/NewQuote";
import UploadRenewalFile from "./newcomponent/UploadRenewalFile";
import UploadExistingFlowFiles from "./newcomponent/UploadExistingFlowFiles";
import UploadNewBusinessFiles from "./newcomponent/UploadNewBusinessFiles";
import DownloadExistingData from "./newcomponent/DownloadExistingData";
import DownloadNewData from './newcomponent/DownloadNewData'
import PrivateRoute from "./component/common/PrivateRoute";
import AntiPrivateRoute from "./component/common/AntiPrivateRoute";
import DemoRouteHandler from "./component/pages/DemoRouteHandler";

class App extends Component {
  render() {
    return (
      <Router>
        <Switch>
          <Route path="/demo" component={DemoRouteHandler} />
          <PrivateRoute
            allowedGroups={[process.env.REACT_APP_UPLOAD_NEW_GROUP]}
            exact
            path="/Upload-n"
            component={UploadNewBusinessFiles}
          />
          <PrivateRoute
            exact
            path="/dashboard"
            component={DashboardTabs}
            allowedGroups={[
              process.env.REACT_APP_DASHBOARD_TABLES_GROUP,
              process.env.REACT_APP_DASHBOARD_STATS_GROUP,
            ]}
          />
          <PrivateRoute
            exact
            path="/apitracking"
            component={DashboardTabsApiTracking}
            allowedGroups={[
              process.env.REACT_APP_DASHBOARD_TABLES_GROUP,
              process.env.REACT_APP_DASHBOARD_STATS_GROUP,
            ]}
          />
          <PrivateRoute
            exact
            path="/Quote"
            component={NewQuote}
            allowedGroups={[process.env.REACT_APP_EXTENSIS_USER_GROUP]}
          />
          <PrivateRoute
            allowedGroups={[process.env.REACT_APP_UPLOAD_RENEWAL_GROUP]}
            exact
            path="/Upload-r"
            component={UploadRenewalFile}
          />
          <PrivateRoute
            allowedGroups={[process.env.REACT_APP_UPLOAD_EXISTING_GROUP]}
            exact
            path="/Upload-e"
            component={UploadExistingFlowFiles}
          />
          <PrivateRoute
            allowedGroups={[process.env.REACT_APP_DOWNLOAD_EXISTING_GROUP]}
            exact
            path="/Download-e"
            component={DownloadExistingData}
          />
          <PrivateRoute
            allowedGroups={[process.env.REACT_APP_DOWNLOAD_EXISTING_GROUP]}
            exact
            path="/Download-new"
            component={DownloadNewData}
          />
          <PrivateRoute
            allowedGroups={[process.env.REACT_APP_COMPLIANCE_GROUP]}
            exact
            path="/PanelCompliance"
            component={PanelCompliance}
          />
          <AntiPrivateRoute path="/" component={Login} />
          {/* <Route exact path="/Login" component={Login} /> */}
          {/* <Route exact path="/map" component={UsMap} /> */}
          {/* <Route exact path='/newprofile' component={NewQuote} /> */}
          {/* <Route exact path="/dashboard-login" component={DashboardLogin} /> */}
          {/* <Route exact path="/how-it-works/rates" component={HowItWorksRates} /> */}
          {/* <Route
            exact
            path="/how-it-works/quotes"
            component={HowItWorksQuotes}
          /> */}
          {/* <Route exact path="/how-it-works/bind" component={HowItWorksBind} /> */}
          {/* <Route exact path="/faq" component={FAQPage} /> */}
          {/* <Route
            exact
            path="/how-it-works/brochure"
            component={HowItWorksBrochure}
          /> */}
          {/* {isLoggedIn && (
            <Route exact path="/DataUpload" component={DataUpload} />
          )} */}
          {/* {isLoggedIn && <Route exact path="/PanelUW" component={PanelUW} />} */}
          {/* {isLoggedIn && (
            <Route exact path="/PanelCompliance" component={PanelCompliance} />
          )} */}
          {/* {isLoggedIn && (
            <Route exact path="/BinderDownload" component={BinderDownload} />
          )} */}
          {/* {isLoggedIn && (
            <Route exact path="/QuotesHistory" component={QuotesHistory} />
          )} */}
          {/* <Route exact path="/group1" component={Home} />
          <Route exact path="/group2" component={Home} />
          <Route exact path="/group3" component={Home} /> */}
        </Switch>
      </Router>
    );
  }
}

export default App;
