import React from "react";
import CustomDropZone from "../component/common/CustomDropZone";
import axios from "axios";
import { awsUrl2 } from "../config";
import LoadingOverlay from "react-loading-overlay";
import Navbar from "../component/common/Navbar";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import SideTab from "./common/SideTab";
let ismobile = window.innerWidth < 992;
export default class UploadNewBusinessFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: {},
      uploadingInProgress: false,
      uploadMsg: undefined,
      errMsg: undefined,
    };
  }

  updateFileList = (newFile, flag) => {
    let { fileList } = this.state;
    let files;
    if (flag === "first") {
      files = fileList;
    }
    if (files[newFile.name] && files[newFile.name] === "#empty") {
      if (flag === "first") {
        this.setState({
          errMsg: "File already exist",
          uploadMsg: undefined,
        });
      }
      return;
    }
    files[newFile.name] = newFile;

    if (flag === "first") {
      fileList = files;
    }

    this.setState({
      fileList,
      errMsg: undefined,
      uploadMsg: undefined,
    });
  };

  removeFile = (fileName, flag) => {
    let { fileList } = this.state;
    let files;
    if (flag === "first") {
      files = fileList;
    }

    delete files[fileName];

    if (flag === "first") {
      fileList = files;
    }

    this.setState({ fileList });
  };

  uploadFile = async (fileData, postDataS3) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/uploadNewBusinessFile", postDataS3)
        .then((response) => {
          let returnData = response.data;
          let lossSignedURL = returnData.lossSignedURL;
          let options = { header: { "Content-Type": postDataS3.fileType } };
          console.log("fileData: ", fileData);
          console.log("");
          axios
            .put(lossSignedURL, fileData, options)
            .then((result) => {
              console.log("file upload res: ", result);
              resolve(returnData.url);
            })
            .catch((error) => {
              console.log("error in uploading", error);
              reject();
            });
        })
        .catch((error) => {
          console.log("error in uploadLossForm ", error);
          reject();
        });
    });
  };

  putExtensisNewBusinessTable = (dataToSend, flag) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/putExtensisNewBusinessTable", dataToSend)
        .then((res) => {
          if (flag === "first") {
            this.setState({
              uploadMsg: "files uploaded",
              errMsg: undefined,
            });
          }
          resolve();
        })
        .catch((error) => {
          console.log("error in API-putExtensisNewBusinessTable", error);
          reject();
        });
    });
  };

  handleUpload = async (flag) => {
    try {
      let { fileList } = this.state;
      let files;
      let isLoggedIn = await Auth.currentAuthenticatedUser();
      let userEmail = isLoggedIn.attributes.email;

      if (flag === "first") {
        files = fileList;
        this.setState({ uploadingInProgress: true });
      }

      for (let currFileName in files) {
        if (currFileName && files[currFileName] !== "#empty") {
          let fileType = currFileName.split(".").reverse()[0];

          const postDataS3 = {
            fileName: currFileName,
            fileType,
            flag,
          };

          let url = await this.uploadFile(files[currFileName], postDataS3);

          await this.putExtensisNewBusinessTable(
            {
              uuid: uuidv4(),
              status: "NEW",
              emailTo: userEmail,
              filename: `${currFileName}`,
              timestamp: Math.floor(Date.now()).toString(),
              url,
              flag,
            },
            flag
          );
          files[currFileName] = "#empty";
        }
      }

      if (flag === "first") {
        this.setState({
          uploadingInProgress: false,
          fileList: files,
        });
      }
    } catch (error) {
      console.log("error: ", error);
      if (flag === "first") {
        this.setState({
          uploadingInProgress: false,
          uploadMsg: undefined,
          errMsg: "error in uploading",
        });
      }
    }
  };

  render() {
    let { fileList, uploadingInProgress, uploadMsg, errMsg } = this.state;
    return (
      <>
        <div>
          <Navbar />
        </div>

        <div className="position-relative" style={{ marginTop: "25px" }}>
          {ismobile ? <SideTab /> : " "}

          <div className="side_bar">
            <div className="upload-heading ">
              <h4>
                <b>Upload Documents - NB - Existing Mapping</b>
              </h4>
              <div style={{ backgroundColor: "black", marginTop: "-10px" }}>
                <hr />
              </div>
            </div>
            <div className="dropZonesContainer">
              <div className="row">
                <div className="col-sm-12 col-md-2"></div>
                <div className="col-sm-12 col-md-8 p-4">
                  <LoadingOverlay active={uploadingInProgress} spinner>
                    <CustomDropZone
                      fileList={fileList}
                      updateFileList={this.updateFileList}
                      removeFile={this.removeFile}
                      heading={<b>Company Profile Data</b>}
                      uploadMsg={uploadMsg}
                      flag={"first"}
                      downloadFileName={"newBusiness_to_existing.csv"}
                    />
                    <DropButton
                      uploadMsg={uploadMsg}
                      errMsg={errMsg}
                      handleUpload={this.handleUpload}
                      uploadingInProgress={uploadingInProgress}
                      flag={"first"}
                    />
                  </LoadingOverlay>
                </div>
                <div className="col-sm-12 col-md-2"></div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const DropButton = (props) => {
  return (
    <div className="d-flex flex-column">
      <pre
        className={
          "text-center text-capitalize " +
          (props.uploadMsg ? "text-success" : "text-danger")
        }
      >
        {props.uploadMsg || props.errMsg}
      </pre>
      <button
        className="btn upload-docs"
        style={{
          width: "100%",
          backgroundColor: "#1463AC",
          alignSelf: "center",
          color: "white",
        }}
        onClick={() => props.handleUpload(props.flag)}
        disabled={props.uploadingInProgress}
      >
        Upload
      </button>
    </div>
  );
};
