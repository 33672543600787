import { awsUrl, awsUrl2 } from "../config";
import $ from "jquery";
import axios from "axios";
import moment from "moment";
import { deleteAllCookies, showErrAlert, getHeader } from "./common";
import { v4 as uuidv4 } from "uuid";
var sizeof = require("object-sizeof");
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require("bootstrap");

export async function submitQues(quesList, quesListAdd, displayMoreClicked) {
  var data = [];
  let validQuesListAdd = validateQuesListAdd(this, quesListAdd);

  for (let question of quesList) {
    let remarks = !question.remarks || question.remarks.trim() === "" ? "none" : question.remarks;
    let dataQues = {
      resp: question.response ? "Y" : "N",
      remarks: remarks
    };
    data.push(dataQues);
  }

  let emod = [];
  for (let i = 0; i < 3; i++) {
    let question = quesListAdd[i];
    let dataQues = {};
    let remarks = !question.remarks || question.remarks.trim() === "" ? "none" : question.remarks;
    dataQues = {
      resp: question.response ? "Y" : "N",
      remarks: remarks
    };
    data.push(dataQues);
  }
  for (let i = 3; i < quesListAdd.length; i++) {
    let question = quesListAdd[i];
    let dataQues = {};
    dataQues.resp = question.response ? "Y" : "N";
    if (question.response) {
      if (question.rates.rate1 && question.rates.rate1 !== "" && question.rates.date1) {
        dataQues.stateList = question.stateList;
        dataQues.rate1 = question.rates.rate1;
        dataQues.date1 = question.rates.date1;
        let emodData = {
          value_1: question.rates.rate1,
          effective_date_1: moment.utc(question.rates.date1, "MM-DD-YYYY").format("YYYY-MM-DD"),
          stateList: question.stateList
        }
        if (displayMoreClicked && question.rates.rate2 && question.rates.rate2 !== "" && question.rates.date2) {
          dataQues.rate2 = question.rates.rate2;
          dataQues.date2 = question.rates.date2;
          emodData.value_2 = question.rates.rate2;
          emodData.effective_date_2 = moment.utc(question.rates.date2, "MM-DD-YYYY").format("YYYY-MM-DD");
        }
        emod.push(emodData);
      }
    }
    data.push(dataQues);
  }

  let questions = [];
  if (data[24].resp === "Y") {
    questions.push(1);
  } else {
    questions.push(0);
  }

  let currProspectDetails = JSON.parse(sessionStorage.getItem("currProspect"));
  currProspectDetails.uwQues = data;
  currProspectDetails.pushed = false;
  sessionStorage.setItem("currProspect", JSON.stringify(currProspectDetails));

  if (currProspectDetails !== null) {
    if (validQuesListAdd) {
      $("#loader").css("display", "block");
      let classCodesWithDesc = {};
      let addressValues = Object.values(currProspectDetails.prospectAddresses);
      let classCodeElig = {};
      for (let place of addressValues) {
        let tempPlace =
          place.streetLineOne +
          "_" +
          place.streetLineTwo +
          "_" +
          place.city +
          "_" +
          place.stateCode +
          "_" +
          place.zipCode;
        if (classCodeElig[tempPlace] === undefined) {
          classCodeElig[tempPlace] = [];
        }
        if (classCodesWithDesc[tempPlace] === undefined) {
          classCodesWithDesc[tempPlace] = {};
        }
        for (let class_code of Object.values(place.classCodes)) {
          let classDict = {};
          classDict.code = class_code.code;
          classDict.state = place.stateCode.toLowerCase();
          classCodeElig[tempPlace].push(classDict);
          classCodesWithDesc[tempPlace][class_code.code] = class_code.desc;
        }
      }
      currProspectDetails.classCodesWithDesc = classCodesWithDesc;
      sessionStorage.setItem("currProspect", JSON.stringify(currProspectDetails));
      const header = await getHeader();
      let lossRatioBody = {};
      lossRatioBody.loss_data = currProspectDetails.lossRatioData;
      lossRatioBody.effective_date = currProspectDetails.effectiveDate;
      axios
        .post(awsUrl2 + "/api/getLossRatioEligibility/", lossRatioBody)
        .then((lossRatioResponse) => {
          let lossRatioEligibility = lossRatioResponse.data;
          axios
            .post(
              awsUrl + "/api/getCarrierElig",
              JSON.stringify(classCodeElig),
              header
            )
            .then(async function (classCodeEligiResponse) {
              var date_from = currProspectDetails.effectiveDate.split("/");
              var date_to = currProspectDetails.expirationDate.split("/");
              date_from = date_from[2] + "-" + date_from[0] + "-" + date_from[1];
              date_to = date_to[2] + "-" + date_to[0] + "-" + date_to[1];
              var lll = currProspectDetails.liabilityLimitId.split("/");
              lll = lll[0] + "-" + lll[1] + "-" + lll[2];
              var data = {
                common: {
                  el_limits: lll,
                  deductible: 2000,
                  date_from: date_from,
                  date_to: date_to,
                  emod: emod,
                  questions: questions,
                  sr_carriers: lossRatioEligibility.srFactor,
                },
                offices: [],
              };
              let uwCarriers = [];
              let checkCarrier = false;
              for (var i = 0; i < addressValues.length; i++) {
                var dict = {
                  name: "",
                  state: "",
                  employees: [],
                  carriers: [],
                };
                dict.state = addressValues[i].stateCode.toLowerCase();
                let placeIndex =
                  addressValues[i].streetLineOne +
                  "_" +
                  addressValues[i].streetLineTwo +
                  "_" +
                  addressValues[i].city +
                  "_" +
                  addressValues[i].stateCode +
                  "_" +
                  addressValues[i].zipCode;
                dict.name = placeIndex;
                if (classCodeEligiResponse.data[placeIndex].length > 0) {
                  for (let carr of classCodeEligiResponse.data[placeIndex]) {
                    let dupCarr = Object.keys(carr)[0];
                    let uwOrYes = Object.values(carr)[0];
                    if (lossRatioEligibility.eligibility[dupCarr] !== "reject") {
                      if (uwOrYes === "uw" || lossRatioEligibility.eligibility[dupCarr] === "uw") {
                        uwCarriers.push(dupCarr);
                      }
                      dict.carriers.push(dupCarr);
                      checkCarrier = true;
                    }
                  }
                }
                if (addressValues[i].classCodes !== undefined) {
                  let class_codes = Object.values(addressValues[i].classCodes);
                  for (var j = 0; j < class_codes.length; j++) {
                    dict.employees.push(class_codes[j]);
                  }
                  data.offices.push(dict);
                }
              }
              var uuid = uuidv4();
              currProspectDetails.uuid = uuid;
              var currentDate = Math.floor(Date.now()).toString();

              if (checkCarrier) {
                var lossData = {
                  newFileName: currentDate,
                  oldFileName: currProspectDetails.emailAddress,
                };
                await axios
                  .post(awsUrl + "/api/renameLossForm/", lossData, header)
                  .then((response) => {
                    console.log("Succesfully renamed file", response);
                    axios
                      .post(
                        awsUrl + "/api/getQuote",
                        JSON.stringify(data),
                        header
                      )
                      .then(async function (res) {
                        if (!currProspectDetails.pushed) {
                          var userStatus = {};
                          var CarrierWithUser = [];
                          currProspectDetails.pushed = true;

                          let tempLossURL = currProspectDetails.lossData.url.split(
                            "/"
                          );
                          tempLossURL.splice(
                            tempLossURL.length - 1,
                            1,
                            currentDate
                          );
                          currProspectDetails.lossData.url = tempLossURL.join(
                            "/"
                          );

                          sessionStorage.setItem(
                            "currProspect",
                            JSON.stringify(currProspectDetails)
                          );
                          let j = 0;
                          let dataOfMRPList = [];
                          for (var place in res.data) {
                            var flag = false;
                            for (var carrier in res.data[place]) {
                              if (res.data[place][carrier] !== "Dropped") {
                                flag = true;
                                var tempData = {
                                  uuid_carrier:
                                    currentDate +
                                    "@" +
                                    currProspectDetails.uuid +
                                    "_" +
                                    carrier +
                                    "+" +
                                    place,
                                  date: currentDate,
                                  effective_date: date_from,
                                  user_email_id: currProspectDetails.emailAddress.toLowerCase(),
                                  bussiness_type_id:
                                    currProspectDetails.businessTypeId,
                                  company_name: currProspectDetails.companyName,
                                  contact_person:
                                    currProspectDetails.contactPerson,
                                  created_by: currProspectDetails.createdBy,
                                  description: currProspectDetails.description,
                                  expiration_date: date_to,
                                  fein: Number(
                                    currProspectDetails.federalEmployeeId
                                  ),
                                  lossData: currProspectDetails.lossData,
                                  historical_claims:
                                    currProspectDetails.historicalClaims,
                                  liability_limitId:
                                    currProspectDetails.liabilityLimitId,
                                  phone_number: currProspectDetails.phoneNumber,
                                  classCodesWithDesc: classCodesWithDesc,
                                  prospect_addresses: Object.values(
                                    addressValues
                                  ),
                                  carrier: carrier + "+" + place,
                                  teap:
                                    res.data[place][carrier]
                                      .total_estimated_annual_premium,
                                  carrier_email_id:
                                    res.data[place][carrier].carrier_email,
                                  uw_ques: currProspectDetails.uwQues,
                                  calculated_data: res.data,
                                  carrier_location_data: res.data[place][carrier],
                                  review: "none",
                                  lId: currProspectDetails.lId,
                                };
                                if (
                                  res.data[place][carrier]
                                    .total_estimated_annual_premium !== null
                                ) {
                                  if (
                                    currProspectDetails.uwQues[7].resp === "Y" &&
                                    currProspectDetails.uwQues[18].resp === "Y"
                                  ) {
                                    tempData.quote_status = "proceed";
                                  } else {
                                    tempData.quote_status = "uwportal";
                                  }
                                  if (uwCarriers.includes(carrier)) {
                                    tempData.quote_status = "uwportal";
                                  }
                                  CarrierWithUser.push(tempData);

                                  let dataOfMRP = {};
                                  dataOfMRP.carrier = carrier;
                                  for (let addre of tempData.prospect_addresses) {
                                    let tempPlace =
                                      addre.streetLineOne +
                                      "_" +
                                      addre.streetLineTwo +
                                      "_" +
                                      addre.city +
                                      "_" +
                                      addre.stateCode +
                                      "_" +
                                      addre.zipCode;
                                    if (tempPlace === place) {
                                      dataOfMRP.state = addre.stateCode.toLowerCase();
                                      dataOfMRP.employees = Object.values(
                                        addre.classCodes
                                      );
                                      dataOfMRP.date_from =
                                        tempData.effective_date;
                                      dataOfMRP.carrier = carrier;
                                      dataOfMRP.location = tempPlace;
                                    }
                                    if (!dataOfMRPList.includes(dataOfMRP)) {
                                      dataOfMRPList.push(dataOfMRP);
                                    }
                                  }
                                }
                                j++;
                              }
                            }
                            if (!flag) {
                              $("#loader").css("display", "none");
                              showErrAlert("No quotes could be generated! Please contact admin@insurecomp for further clarifications!");
                            }
                          }

                          await axios
                            .post(
                              awsUrl + "/api/getManualRateData",
                              dataOfMRPList,
                              header
                            )
                            .then((res) => {
                              for (let tempData of CarrierWithUser) {
                                tempData.manual_premium_sum =
                                  res.data[tempData.carrier].manual_premium_sum;
                                tempData.total_payroll =
                                  res.data[tempData.carrier].total_payroll;
                                tempData.manual_rate_sum =
                                  res.data[tempData.carrier].manual_rate_sum;
                              }
                            })
                            .catch((err) => {
                              $("#loader").css("display", "none");
                              console.log(
                                "Error in getManualRateData",
                                err
                              );
                            });

                          let prevIndex = 0;
                          let nextIndex = 100;
                          let carrierWithUserData;
                          for (
                            let i = 0;
                            i < Math.floor(CarrierWithUser.length / 100) + 1;
                            i++
                          ) {
                            carrierWithUserData = CarrierWithUser.slice(
                              prevIndex,
                              nextIndex
                            );
                            axios
                              .post(
                                awsUrl + "/api/postUsersData/",
                                carrierWithUserData,
                                header
                              )
                              .then(async (response) => {
                                if (i === 0) {
                                  let propectData = currProspectDetails;
                                  propectData.formStage = "four";
                                  let tableName = "ProspectTable";
                                  await axios
                                    .post(
                                      awsUrl + "/api/postTablesData/" + tableName,
                                      JSON.stringify(propectData),
                                      header
                                    )
                                    .then((response) => {
                                      try {
                                        sessionStorage.setItem(
                                          "formStage",
                                          "four"
                                        );
                                        window.location.reload();
                                      } catch (err) {
                                        $("#loader").css("display", "none");
                                        console.log("error in setting session storage", err);
                                        // showErrAlert(err.message);
                                      }
                                    })
                                    .catch((err) => {
                                      $("#loader").css("display", "none");
                                      console.log("error in posting tables data", err);
                                      // showErrAlert(err);
                                    });
                                }
                              })
                              .catch((err) => {
                                $("#loader").css("display", "none");
                                console.log("error in posting users data", err);
                                // showErrAlert(err);
                              });
                            prevIndex = nextIndex;
                            nextIndex += 100;
                          }
                        }
                      })
                      .catch((err) => {
                        // showErrAlert(err);
                        $("#loader").css("display", "none");
                        console.log(err);
                      });
                  })
                  .catch((err) => {
                    $("#loader").css("display", "none");
                    // showErrAlert(err);
                    console.log(err);
                  });
              } else {
                $("#loader").css("display", "none");
                showErrAlert("No carriers are eligible for your class codes. Please contact admin@insurecomp.com for further clarificaions.");
              }
            })
            .catch((err) => {
              // showErrAlert(err);
              $("#loader").css("display", "none");
              console.log(err);
            });
        })
        .catch((err) => {
          console.log(err);
          $("#loader").css("display", "none");
          // showErrAlert(err.message);
        });
    }
  } else {
    sessionStorage.setItem("formStage", "one");
    deleteAllCookies();
    sessionStorage.clear();
    window.location.reload();
    // alert("Please fill the all require fields");
  }
}

function validateQuesListAdd(self, quesListAdd) {
  let emodError = [];
  let validFlag = true;

  for (let i = 3; i < quesListAdd.length; i++) {
    let emodQues = quesListAdd[i];
    emodError[i] = { rate: false, date: false };
    if (emodQues.response) {
      if (!emodQues.rates.rate1) {
        emodError[i].rate = true;
        validFlag = false;
      }
      if (!emodQues.rates.date1) {
        emodError[i].date = true;
        validFlag = false;
      }
    }
  }

  self.setState({ emodError });
  return validFlag;
}

export function updateQuesList(e, key, value) {
  let { quesList } = this.state;
  let index = e.target.id.split("-")[1];
  quesList[index][key] = value;
  this.setState({ quesList });
}

export function updateAddQuesList(e, key, value) {
  let { quesListAdd } = this.state;
  let index = Number(e.target.id.split("-")[1]) - 25;
  quesListAdd[index][key] = value;
  this.setState({ quesListAdd });
}

export function appendAddQuesList(index, questionsList) {
  let { quesListAdd } = this.state;
  questionsList.forEach(question => {
    quesListAdd[index] = question;
    index++;
  })
  this.setState({ quesListAdd });
}

export function updateEmodRates(i, value, key) {
  let { quesListAdd } = this.state;
  if (!quesListAdd[i].rates) {
    quesListAdd[i].rates = {}
  }
  quesListAdd[i].rates[key] = value;
  this.setState({ quesListAdd });
}