import React, { Component } from "react";
import ReactDOM from "react-dom";
import Navbar from "../common/Navbar";
import BanMain from "../subcompo/sections-home/BanMain";
import ItWorks from "../subcompo/sections-home/ItWorks";
import Footer from "../common/Footer";
import Loader from "../common/Loader";
import axios from "axios";
import { awsUrl2 } from "../../config";

class Home extends Component {
  componentDidMount() {
    ReactDOM.findDOMNode(this).scrollTop = 0;
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let user = urlParams.get("user");
    let generalDomainNames = [
      "gmail",
      "yahoo",
      "aol",
      "mac",
      "cox",
      "frontier",
      "msn",
      "gammana",
    ];
    let domain;
    // if (user) {
    //   let tempBusinessName = user.split("@")[1].split(".");
    //   tempBusinessName.pop();
    //   let business_name = tempBusinessName.join(".");
    //   if (generalDomainNames.includes(business_name)) {
    //     domain = user.split("@")[0];
    //   } else {
    //     domain = business_name;
    //   }
    // let currDomain = sessionStorage.getItem("domain");
    // let timestamp = Math.floor(Date.now()).toString();
    // sessionStorage.setItem("visitTimestamp", timestamp);
    // if (currDomain !== domain) {
    //   let requestBody = {
    //     email: user.toLowerCase(),
    //     domain: domain.toLowerCase(),
    //     timestamp: timestamp,
    //   };

    //   axios
    //     .post(
    //       awsUrl2 + "/api/updatePrideTrackingData",
    //       JSON.stringify(requestBody)
    //     )
    //     .then((response) => {
    //       // console.log(response);
    //     })
    //     .catch((error) => {
    //       // console.log("error while creating entry", error);
    //     });
    // }
    //   sessionStorage.setItem("domain", domain);
    // }
  }
  render() {
    return (
      <div>
        <Navbar variant="large-logo" />
        <div className="home-page pt-5 d-flex flex-column">
          <BanMain path={this.props.location.pathname.split("/")[1]} />
          {/* <ItWorks /> */}
          <Footer />
        </div>
        <Loader />
      </div>
    );
  }
}

export default Home;
