import React, { Component } from "react";
import axios from "axios";
import { awsUrl2 } from "../../../config";
import ComputationTable from "../../common/ComputationTable";
import LoadingOverlay from "react-loading-overlay";

class WcCalculatedFactors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loaderActive: false,
    };
  }

  componentDidMount = () => {
    let { carrier, uuid, timestamp, email, setWcData } = this.props;
    this.setState({ loaderActive: true });
    axios
      .get(
        awsUrl2 +
          `/api/apcCalculatedFactors/${email}/${carrier}/${uuid}/${timestamp}`
      )
      .then((res) => {
        setWcData(res.data, carrier);
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        this.setState({ loaderActive: false });
      });
  };

  render() {
    let { display, data } = this.props;
    console.log(data, "wc");
    let { loaderActive } = this.state;
    let tableHeaders = ["WC Calculated Factors", "Value"];
    let keyMap = {
      governingClassCode: "Governing Class Code",
      governingState: "Governing State",
      defaultCarrier: "Default Carrier",
      "e_vs_non-e":
        process.env.REACT_APP_MODE == "beta"
          ? "Preferred Industry? "
          : "E-18 Industry?",
      key:
        process.env.REACT_APP_MODE == "beta"
          ? "Preferred Account?"
          : "Key Account?",
      table_bin: "Table and Bin",

      compositeRateFactor: "Composite Rate Factor",
      total_margin: "Total Margin",
      total_margin_percentage: "Total Margin %",
      pom_with_taxes: "POM",
      pom_without_taxes: "POM ex. TAX",
      chubb_pom_with_taxes:
        process.env.REACT_APP_MODE == "beta" ? "Carrier POM" : "CHUBB's POM",
      chubb_pom_without_taxes:
        process.env.REACT_APP_MODE == "beta"
          ? "Carrier POM EX. Tax "
          : "CHUBB's POM ex. TAX",
    };
    try {
      data.table_bin = `${data.table}, ${data.bin}`;
    } catch (error) {}

    return (
      <div style={{ display: display ? "block" : "none" }}>
        <LoadingOverlay active={loaderActive} spinner>
          <ComputationTable
            headers={tableHeaders}
            data={data}
            keyMap={keyMap}
          />
        </LoadingOverlay>
      </div>
    );
  }
}

export default WcCalculatedFactors;
