import React from "react";
import { iframe } from "react-dom-factories";
import NumberFormat from "react-number-format";

const prepDisplayData = (data, keyMap) => {
  let displayData = {};
  for (let key in keyMap) {
    //adding check since data[key] will filter out value 0.
    displayData[keyMap[key]] =
      data && typeof data[key] !== "undefined" && data[key] !== null
        ? data[key]
        : "-";
  }

  return displayData;
};

const ComputationTable = (props) => {
  const {
    headers,
    data,
    keyMap,
    costEppInputValue,
    EppCostValuesHandler,
    EppChargeValuesHandler,
    costPEMPValue,
    ChargePEMPValue,
  } = props;

  const displayData = prepDisplayData(data, keyMap);
  let tableBody = [];
  let tableHeader = [];
  let index = 0;

  for (let header of headers) {
    tableHeader.push(
      <th
        key={header}
        style={header === "Value" ? { textAlign: "center" } : {}}
      >
        {header}
      </th>
    );
  }

  for (let key in displayData) {
    tableBody.push(
      <tr
        key={key}
        style={{
          backgroundColor: "white",
          fontWeight: "bolder",
        }}
        className="epphBodyRow"
      >
        <td>{key}</td>
        <td
          style={{ textAlign: "center" }}
          className={
            key === "Key Account?" || key === "E-18 Industry?"
              ? "text-capitalize"
              : ""
          }
        >
          {displayData[key]}
        </td>
      </tr>
    );
  }

  if (props.isEpp) {
    tableBody.push(
      <tr style={{ backgroundColor: "white" }}>
        <td> EPP Cost PEPM - Match </td>
        <td className="costEppInputValue">
          <NumberFormat
            name="costPEPM"
            id={"cost_PEPM"}
            fixedDecimalScale={true}
            // maxLength="4"
            autoComplete="off"
            className="text-center"
            type="text"
            value={costEppInputValue?.costPEPM}
            onBlur={(e) => EppCostValuesHandler(e.target.value, e.target.name)}
            prefix={"$"}
            placeholder="$0.00"
            decimalScale={2}
            style={{
              height: "30px",
              backgroundColor: "white",
              // borderRadius: "15px",
              border: "2px solid black",
            }}
          />
        </td>
      </tr>
    );
    tableBody.push(
      <tr
        style={{
          backgroundColor: "white",
        }}
      >
        <td
          style={
            {
              // fontWeight: "bolder",
              // marginLeft: "20px",
            }
          }
        >
          {" "}
          EPP Charge PEPM - Match{" "}
        </td>
        <td
          className="chargeEppInputValue"
          // style={{ borderRadius: "0 0 15px 0" }}
        >
          <NumberFormat
            name="chargePEPM"
            id={"chargePEPM"}
            // maxLength="4"
            fixedDecimalScale={true}
            autoComplete="off"
            className="text-center"
            type="text"
            decimalScale={2}
            value={costEppInputValue?.chargePEPM}
            onBlur={(e) =>
              EppChargeValuesHandler(e.target.value, e.target.name)
            }
            prefix={"$"}
            placeholder="$0.00"
            style={{
              height: "30px",
              backgroundColor: "white",
              // borderRadius: "15px",
              border: "2px solid black",
            }}
          />
        </td>
      </tr>
    );
  }

  return (
    <div className="table-responsive">
      <table className="table table-sm quote-table">
        <thead>
          <tr
            style={{
              backgroundColor: "#1463ac",
              color: "white",
            }}
            className="epphHeaderRow"
          >
            {tableHeader}
          </tr>
        </thead>
        <tbody>
          {" "}
          {tableBody.map((row, index) => (
            <tr
              key={index}
              style={{ backgroundColor: index % 2 === 0 ? "white" : "#9ABFE2" }}
            >
              {row.props.children.map((cell, cellIndex) => (
                <td key={cellIndex}>{cell}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default ComputationTable;
