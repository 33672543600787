import React, { Component } from "react";
// import {submitLogin} from '../../../utils/log_in'
import { signIn } from "../../../utils/login_dashboard";
import {
  clearErr,
  validEmail,
  validPassword,
  changeText
} from "../../../utils/common";
// import Tooltip from 'react-tooltip'
class DashFormLogin extends Component {

    constructor(){
        super();
        this.state  ={
            userId : '',
            userPassword : ''
        }
    }
    handleChange=(event)=>{
        let user = event.target.name;
        let val = event.target.value;
        this.setState({
            [user]:val})
        
    }
    
  render() {
    return (
      <div id="lgin" className="tab-pane fade in active">
        {/* <Tooltip placement="left" trigger={['click']} overlay={<span>tooltip</span>}>
    <a href="#">hover</a>
  </Tooltip> */}
        <div id="formLogin">
          <p className="formTitle text-center">DASHBOARD LOGIN</p>
          <hr />
          <form onSubmit={(e) => signIn(e, this.state.userId, this.state.userPassword)}>
            <div>
              <span className="errMsg" />
              <input
                autoComplete="off"
                id="userId"
                name = "userId"
                type="email"
                placeholder="Enter Email Address"
                onBlur={validEmail}
                onChange={this.handleChange}
              />
            </div>
            <div>
              <span className="errMsg" />
              <input
                autoComplete="off"
                name="userPassword"
                id="userPassword"
                type="password"
                placeholder="Enter Password"
                onBlur={validPassword}
                onChange={this.handleChange}
              />
            </div>
            <input
              autoComplete="off"
              id="doRemember"
              type="checkbox"
              defaultChecked
            />{" "}
            <span className="inptSideText">Remember Me</span>
            <span className="errMsg" />
            <button
              //   type="button"
              id="loginSubmit"
              className="btnFormFinal"
              //   onClick={signIn}
            >
              SUBMIT
            </button>
          </form>
          <div className="formBottomLinks">
            <a id="link" data-toggle="tab" href="#chngpwd" onClick={changeText}>
              Change Password
            </a>{" "}
            |{" "}
            <a data-toggle="tab" href="#fgtpwd" aria-expanded="true">
              Forgot Password?
            </a>
          </div>
        </div>
      </div>
    );
    
  }
  
}


export default DashFormLogin;
