import React, { Component } from 'react';

class Progress extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formStage: ""
        }
    }
    render() {
        // const { formStage } = this.state;
        return (
            <div className='container'>
                <ul id='progressStatus'>
                    <li><div id='step1' className='progressUnit'></div></li>
                    <li><div className='progressLine'></div></li>
                    <li><div id='step2' className='progressUnit'></div></li>
                    <li><div className='progressLine'></div></li>
                    <li><div id='step3' className='progressUnit'></div></li>
                    <li><div className='progressLine'></div></li>
                    <li><div id='step4' className='progressUnit'></div></li>
                    <li><div className='progressLine'></div></li>
                    <li><div id='step5' className='progressUnit'></div></li>
                    <li><div className='progressLine'></div></li>
                    <li><div id='step6' className='progressUnit'></div></li>
                </ul>
                <ul id='stepNames' className="nav nav-tabs">
                    <li className="nav-item"><a className="active nav-link" data-toggle="tab" href="#compPro">Company<br />Profile</a></li>
                    <li className="nav-item"><a className="blocked nav-link" data-toggle="tab" href="#cmprRate">Compare<br />Rate</a></li>
                    <li className="nav-item"><a className="blocked nav-link" data-toggle="tab" href="#undrQues">Underwriting<br />Questions</a></li>
                    <li className="nav-item"><a className='blocked nav-link' data-toggle="tab" href="#cmprQts">Get<br />Quotes</a></li>
                    <li className="nav-item"><a className='blocked nav-link' data-toggle="tab" href="#payBind">Review<br />&amp; Pay</a></li>
                    <li className="nav-item"><a className='blocked nav-link' data-toggle="tab" href="#binder">Bind</a></li>
                </ul>
                <ul id='stepNamesSmall' className="nav nav-tabs">
                    <li className="nav-item"><a className="active" data-toggle="tab" href="#compPro"><span className='progressUnit'></span> Company Profile</a></li>
                    <li className="nav-item"><a className='blocked' data-toggle="tab" href="#cmprRate">Compare Rate</a></li>
                    <li className="nav-item"><a className='blocked' data-toggle="tab" href="#undrQues">Underwriting Questions</a></li>
                    <li className="nav-item"><a className='blocked' data-toggle="tab" href="#cmprQts">Get Quotes</a></li>
                    <li className="nav-item"><a className='blocked' data-toggle="tab" href="#payBind">Review &amp; Pay</a></li>
                    <li className="nav-item"><a className='blocked' data-toggle="tab" href="#binder">Bind</a></li>
                </ul>
            </div >


        );
    }
}

export default Progress;