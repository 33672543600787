import React, { Component } from "react";
import UsMap from "./Map";
import { Doughnut, Bar, Pie } from "react-chartjs-2";
import "./DashBoardWithMap.scss";
import PoliciesDueExpiry from "./PoliciesDueExpiry";
import QuotesDueExpiry from "./QuotesDueExpiry";
import AgentBindRatio from "./AgentBindRatio";
import { getHeader } from "../../utils/common";
import { dashboardUrl } from "../../config";
import LoaderOverlay from "react-loading-overlay";
import { Auth } from "aws-amplify";
import Navbar from "../common/Navbar";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
// import numeral from 'numeral'

const axios = require("axios");

class DashboardWithMap extends Component {
  constructor() {
    super();
    this.state = {
      testData: {
        quotes: { Bound: 0, "In Progress": 0, Declined: 0, Lost: 0 },
        payroll: { Bound: 0, "In Progress": 0, Declined: 0, Lost: 0 },
        premium: {
          Bound: 0,
          "In Progress": 0,
          Declined: 0,
          Lost: 0,
          // "quoted": 0,
          // "written": 0,
          // "carrier_wise": { "carrier_a": 0, "carrier_b": 0, "carrier_c": 0 }
        },
        business: 0,
      },
      user: "",
      user_type: "",
      isLoader: true,
      filter: "premium",
      filter3: "",
      pie_carrier_all: {},
      data1: [],
      data2: [],
      data3: [],
      eff: [],
      selectedOption: [],
      dateList: [],
      addAllPrev: true,
    };
  }

  async componentDidMount() {
    // Auth.currentSession()
    // .then(
    // .catch(err=> console.log(err))

    await Auth.currentUserInfo()
      .then((data) => {
        // console.log(data);
        this.setState({
          user: data.username,
        });
        // console.log(data.username);
      })
      .catch((err) => console.log(err));

    await Auth.currentSession().then((res) => {
      var userRole = res.accessToken.payload["cognito:groups"][0];
      this.setState({
        user_type: userRole,
      });
    });

    const header = await getHeader();
    const { user } = this.state;

    if (this.state.user_type == "Carrier") {
      const req1P = axios.get(
        dashboardUrl + "/api/getCarrierQuotes/" + user,
        header
      );
      const req2P = axios.get(
        dashboardUrl + "/api/getCarrierPremium/" + user,
        header
      );
      const req3P = axios.get(dashboardUrl + "/api/getPie/" + user, header);
      const req4P = axios.get(
        dashboardUrl + "/api/getBarGraphAll/" + user,
        header
      );
      const r1 = await req1P;
      const r2 = await req2P;
      const r3 = await req3P;
      const r4 = await req4P;
      let [carrQ, carrPrem, carrPie, carrBar] = await axios
        .all([r1, r2, r3, r4])
        .then(
          axios.spread((...responses) => {
            return [
              responses[0].data,
              responses[1].data,
              responses[2].data,
              responses[3].data,
            ];
          })
        );
      this.setState({
        carrier_all: carrQ.carrier_all,
        carrier_all_prem: carrPrem.carrier_all_prem,
        pie_carrier_all: carrPie.pie_carrier_all,
        bar_carrier_all: carrBar.codes_carrier_all,
        filter3: "carrier",
      });
    }

    const req1Promise = axios.get(
      dashboardUrl + "/api/getPremiumByCarrier/" + user,
      header
    );
    const req2Promise = axios.get(
      dashboardUrl + "/api/getQuotesBoundRatio/" + user,
      header
    );
    const req3Promise = axios.get(
      dashboardUrl + "/api/getTotalQuotedPremium/" + user,
      header
    );
    const req4Promise = axios.get(
      dashboardUrl + "/api/getBarGraph/" + user,
      header
    );

    // const req1Promise = axios.get(dashboardUrl + "/api/getPremiumByCarrier/test@gmail.com");
    // const req2Promise = axios.get(dashboardUrl + "/api/getQuotesBoundRatio/test@gmail.com");
    // const req3Promise = axios.get(dashboardUrl + "/api/getTotalQuotedPremium/test@gmail.com");

    const req1 = await req1Promise;
    const req2 = await req2Promise;
    const req3 = await req3Promise;
    const req4 = await req4Promise;

    let [premium, quotes, prem, bar] = await axios
      .all([req1, req2, req3, req4])
      .then(
        axios.spread((...responses) => {
          return [
            responses[0].data,
            responses[1].data,
            responses[2].data,
            responses[3].data,
          ];
        })
      );

    //set the state with data:
    const pie3Data = {
      volume: premium.count,
      payroll: premium.carrier_payroll,
      premium: premium.carrier_premium,
    };

    const barData = {
      volume: bar.codes_count,
      payroll: bar.codes_payroll,
      premium: bar.codes_premium,
    };

    const testData = {
      quotes: {
        Bound: quotes.bounded,
        "In Progress": quotes.q_inprogress,
        Lost: quotes.quotes_lost,
        Declined: quotes.declined,
      },
      payroll: {
        Bound: quotes.payroll_bound,
        "In Progress": quotes.payroll_unbound - quotes.payroll_lost,
        Lost: quotes.payroll_lost,
        Declined: quotes.payroll_declined,
      },
      premium: {
        Bound: prem.premium_bound,
        "In Progress": prem.premium_inprogress,
        Lost: prem.premium_lost,
        Declined: prem.premium_declined,
      },
    };

    // const vol = {
    //   business: quotes.business,
    //   quote_bound: quotes.bounded,
    //   q_received: quotes.q_received,
    // };
    // const pay = {
    //   business: quotes.total_payroll,
    //   q_received: quotes.total_payroll_received,
    //   quote_bound: quotes.payroll_bound,
    // };
    // const pre = {
    //   business: prem.totalQuotedPremium,
    //   q_received: prem.premium_received,
    //   quote_bound: prem.premium_bound,
    // };
    const lis = quotes.lis;
    const eff = [];
    lis.forEach((each) => {
      var a = {
        label: each,
        value: each,
      };
      eff.push(a);
    });
    this.setState({
      carrier_total: premium.carrier_total,
      codes_all: bar.codes_all,
      total: quotes.total,
      prem_total: prem.prem_total,
      pie3Data: pie3Data,
      barData: barData,
      testData: testData,
      isLoader: false,
      eff: [{ value: "Add all", label: "Add all" }, ...eff],
      selectedOption: [{ value: "Add all", label: "Add all" }, ...eff],
      dateList: lis,
    });
    var data1 = this.returnPieData("premium");
    var data2 = this.returnBarData("premium");
    var data3 = this.returnPie3Data("premium");
    this.setState({ data1: data1, data2: data2, data3: data3 });
    this.filterdata(undefined, undefined, undefined);
  }

  // returnDoughnutData = () => {
  //   let dataList = [];
  //   let { p_labels, p_data } = this.state;
  //   for (let i = 0; i < p_data.length; i++) {
  //     let data = {};
  //     data["labels"] = [p_labels[i],""];
  //     data["datasets"] = [{
  //       data:[p_data[i],0],
  //       backgroundColor: [
  //       '#00344D',
  //       ''
  //       ],
  //       hoverBackgroundColor: [
  //       '#00344D',
  //       ""
  //       ]
  //     }];
  //     dataList.push(data);
  //   }
  //   return dataList;
  // }

  returnPie3Data = (key) => {
    let piedata = {
      datasets: [
        {
          data: [],
          hoverBackgroundColor: [
            "#006400	",
            "#008000",
            "#228B22",
            "#00FF00",
            "#32CD32",
            "#7CFC00",
          ],
          backgroundColor: [
            "#006400	",
            "#008000",
            "#228B22",
            "#00FF00",
            "#32CD32",
            "#7CFC00",
          ],
          // borderColor: ["#006400	","#008000","#228B22","#00FF00", "#32CD32", "#7CFC00"]
        },
      ],
      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: [],
    };
    let { pie3Data } = this.state;
    if (key === "premium") {
      piedata.datasets[0].data = Object.values(pie3Data[key]);
      piedata.labels = Object.keys(pie3Data[key]);
      // donutdata.datasets[0].data = Object.values(testData[key]["carrier_wise"]);
      // donutdata.labels = Object.keys(testData[key]["carrier_wise"]);
    } else if (key === "payroll") {
      piedata.datasets[0].data = Object.values(pie3Data[key]);
      piedata.labels = Object.keys(pie3Data[key]);
    } else {
      piedata.datasets[0].data = Object.values(pie3Data[key]);
      piedata.labels = Object.keys(pie3Data[key]);
    }
    return piedata;
  };

  returnBarData = (key) => {
    let bardata = {
      labels: [],
      datasets: [
        {
          data: [],
          backgroundColor: "darkblue",
          borderColor: "rgba(0,0,0,1)",
          borderWidth: 1,
        },
      ],
    };

    let { barData } = this.state;
    if (key === "premium") {
      bardata.datasets[0].data = Object.values(barData[key]);
      bardata.labels = Object.keys(barData[key]);
      // bar.datasets[0].data = barData[key][1];
      // bar.labels = barData[key][0];
    }
    if (key === "payroll") {
      bardata.datasets[0].data = Object.values(barData[key]);
      bardata.labels = Object.keys(barData[key]);
    }
    if (key === "volume") {
      bardata.datasets[0].data = Object.values(barData[key]);
      bardata.labels = Object.keys(barData[key]);
    }
    return bardata;
  };

  returnPieData = (key) => {
    let donutdata = {
      datasets: [
        {
          data: [],
          backgroundColor: [
            "#006400",
            "#FFD700",
            "#FF4500",
            "#FF0000",
            "#0D68AB",
            "#33A8FF",
            "#012333",
            "#ADD8E6",
          ],
          // borderColor: ["#006400","#FFD700", "#FF0000	","#FF4500","#0D68AB", "#33A8FF","#012333","#ADD8E6"]
        },
      ],
      // These labels appear in the legend and in the tooltips when hovering different arcs
      labels: [],
    };
    let { testData } = this.state;
    if (key === "premium") {
      donutdata.datasets[0].data = Object.values(testData[key]);
      donutdata.labels = Object.keys(testData[key]);
      // donutdata.datasets[0].data = Object.values(testData[key]["carrier_wise"]);
      // donutdata.labels = Object.keys(testData[key]["carrier_wise"]);
    } else if (key === "payroll") {
      donutdata.datasets[0].data = Object.values(testData[key]);
      donutdata.labels = Object.keys(testData[key]);
    } else {
      donutdata.datasets[0].data = Object.values(testData[key]);
      donutdata.labels = Object.keys(testData[key]);
    }
    return donutdata;
  };

  returnCalculatedValues = (key) => {
    let { testData } = this.state;
    if (key === "quotes") {
      let quotes = Object.values(testData["quotes"]);
      let total_quotes = quotes.reduce((a, b) => a + b, 0);
      let bound_quotes = quotes[0];
      let bound_total_quotes_ratio =
        Math.round((bound_quotes / total_quotes) * 100) / 100;
      let business = testData["business"];
      let quotes_business_ratio =
        Math.round((total_quotes / business) * 100) / 100;
      return [quotes_business_ratio, bound_total_quotes_ratio];
    } else if (key === "payroll") {
      let payroll = Object.values(testData["payroll"]);
      let total_payroll = payroll.reduce((a, b) => a + b, 0);
      return total_payroll;
    } else {
      let quoted_premium = testData["premium"]["quoted"];
      let written_premium = testData["premium"]["written"];
      return [quoted_premium, written_premium];
    }
  };

  returnLabelAsDollar = (array) => {
    let newArray = [];
    for (let elem of array) {
      let labelDollar = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(elem);
      newArray.push(labelDollar);
    }
    return newArray;
  };

  returnTotal = (index) => {
    const { p_data } = this.state;
    let total = p_data[index];
    if (index !== 0) {
      total = Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "USD",
        minimumFractionDigits: 0,
      }).format(total);
    }
    return total;
  };

  currencyConverter = (value) => {
    return Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(value);
  };

  filterdata = (
    dateList = this.state.dateList,
    filter = this.state.filter,
    filter3 = this.state.filter3
  ) => {
    let {
      carrier_total,
      user_type,
      total,
      prem_total,
      codes_all,
      testData,
      barData,
      pie3Data,
      carrier_all_prem,
      carrier_all,
      pie_carrier_all,
      bar_carrier_all,
    } = this.state;
    var business = 0,
      q_received = 0,
      quote_bound = 0;
    console.log(dateList);

    if (filter == "volume") {
      if (dateList.length > 0) {
        testData["quotes"]["Bound"] = 0;
        testData["quotes"]["In Progress"] = 0;
        testData["quotes"]["Lost"] = 0;
        testData["quotes"]["Declined"] = 0;
        barData["volume"] = {};
        pie3Data["volume"] = {};
        dateList.forEach((each) => {
          // console.log("+++", total);
          // console.log("+++", each);
          if (user_type == "Carrier") {
            business += carrier_all[each]["volume"]["business"];
          } else {
            business += total[each]["volume"]["business"];
          }
          q_received += total[each]["volume"]["v_received"];
          quote_bound += total[each]["volume"]["v_bound"];
          testData["quotes"]["Bound"] += total[each]["volume"]["v_bound"];
          testData["quotes"]["In Progress"] +=
            total[each]["volume"]["v_inprogress"];
          testData["quotes"]["Lost"] += total[each]["volume"]["v_lost"];
          testData["quotes"]["Declined"] = business - q_received;

          if (filter3 == "all") {
            for (let key in bar_carrier_all[each]["count"]) {
              if (!barData["volume"][key]) {
                barData["volume"][key] = bar_carrier_all[each]["count"][key];
              } else {
                barData["volume"][key] += bar_carrier_all[each]["count"][key];
              }
            }

            for (let key in pie_carrier_all[each]["volume"]) {
              if (!pie3Data["volume"][key]) {
                pie3Data["volume"][key] = pie_carrier_all[each]["volume"][key];
              } else {
                pie3Data["volume"][key] += pie_carrier_all[each]["volume"][key];
              }
            }
          } else {
            for (let key in codes_all[each]["count"]) {
              if (!barData["volume"][key]) {
                barData["volume"][key] = codes_all[each]["count"][key];
              } else {
                barData["volume"][key] += codes_all[each]["count"][key];
              }
            }

            for (let key in carrier_total[each]["volume"]) {
              if (!pie3Data["volume"][key]) {
                pie3Data["volume"][key] = carrier_total[each]["volume"][key];
              } else {
                pie3Data["volume"][key] += carrier_total[each]["volume"][key];
              }
            }
          }
          barData["volume"] = this.sort_top_ten(barData["volume"]);
          pie3Data["volume"] = this.sort_object(pie3Data["volume"]);
          pie3Data["volume"] = this.compress(pie3Data["volume"]);
        });
      } else {
        // const { vol, testData2, barData2, pie3Data2 } = this.state;
        // business = vol.business;
        // q_received = vol.q_received;
        // quote_bound = vol.quote_bound;
        // testData.quotes.Bound = testData2.quotes.Bound;
        // testData.quotes["In Progress"] = testData2.quotes["In Progress"];
        // testData.quotes.Lost = testData2.quotes.Lost;
        // testData.quotes.Declined = testData2.quotes.Declined;
        // barData.volume = barData2.volume;
        // pie3Data.volume = pie3Data2.volume;
      }
      this.setState({ testData, barData, pie3Data });
      var data1 = this.returnPieData("quotes");
      var data2 = this.returnBarData("volume");
      var data3 = this.returnPie3Data("volume");
    } else if (filter == "payroll") {
      if (dateList.length > 0) {
        testData["payroll"]["Bound"] = 0;
        testData["payroll"]["In Progress"] = 0;
        testData["payroll"]["Lost"] = 0;
        testData["payroll"]["Declined"] = 0;
        barData["payroll"] = {};
        pie3Data["payroll"] = {};
        dateList.forEach((each) => {
          if (user_type == "Carrier") {
            business += carrier_all[each]["payroll"]["p_total"];
          } else {
            business += total[each]["payroll"]["p_total"];
          }
          q_received += total[each]["payroll"]["p_received"];
          quote_bound += total[each]["payroll"]["p_bound"];
          testData["payroll"]["Bound"] += total[each]["payroll"]["p_bound"];
          testData["payroll"]["In Progress"] +=
            total[each]["payroll"]["p_inprogress"];
          testData["payroll"]["Lost"] += total[each]["payroll"]["p_lost"];
          testData["payroll"]["Declined"] = business - q_received;
          if (filter3 == "all") {
            for (let key in bar_carrier_all[each]["payroll"]) {
              if (!barData["payroll"][key]) {
                barData["payroll"][key] = bar_carrier_all[each]["payroll"][key];
              } else {
                barData["payroll"][key] +=
                  bar_carrier_all[each]["payroll"][key];
              }
            }
            for (let key in pie_carrier_all[each]["volume"]) {
              if (!pie3Data["payroll"][key]) {
                pie3Data["payroll"][key] =
                  pie_carrier_all[each]["payroll"][key];
              } else {
                pie3Data["payroll"][key] +=
                  pie_carrier_all[each]["payroll"][key];
              }
            }
          } else {
            for (let key in codes_all[each]["payroll"]) {
              if (!barData["payroll"][key]) {
                barData["payroll"][key] = codes_all[each]["payroll"][key];
              } else {
                barData["payroll"][key] += codes_all[each]["payroll"][key];
              }
            }
            for (let key in carrier_total[each]["volume"]) {
              if (!pie3Data["payroll"][key]) {
                pie3Data["payroll"][key] = carrier_total[each]["payroll"][key];
              } else {
                pie3Data["payroll"][key] += carrier_total[each]["payroll"][key];
              }
            }
          }
          barData["payroll"] = this.sort_top_ten(barData["payroll"]);
          pie3Data["payroll"] = this.sort_object(pie3Data["payroll"]);
          pie3Data["payroll"] = this.compress(pie3Data["payroll"]);
        });
      } else {
        // const { pay, testData2, barData2, pie3Data2 } = this.state;
        // business = pay.business;
        // q_received = pay.q_received;
        // quote_bound = pay.quote_bound;
        // testData.payroll.Bound = testData2.payroll.Bound;
        // testData.payroll["In Progress"] = testData2.payroll["In Progress"];
        // testData.payroll.Lost = testData2.payroll.Lost;
        // testData.payroll.Declined = testData2.payroll.Declined;
        // barData.payroll = barData2.payroll;
        // pie3Data.payroll = pie3Data2.payroll;
      }
      this.setState({ testData, barData, pie3Data });
      business = this.currencyConverter(business);
      q_received = this.currencyConverter(q_received);
      quote_bound = this.currencyConverter(quote_bound);
      var data1 = this.returnPieData("payroll");
      var data2 = this.returnBarData("payroll");
      var data3 = this.returnPie3Data("payroll");
    } else if (filter == "premium") {
      if (dateList.length > 0) {
        testData["premium"]["Bound"] = 0;
        testData["premium"]["In Progress"] = 0;
        testData["premium"]["Lost"] = 0;
        testData["premium"]["Declined"] = 0;
        barData["premium"] = {};
        pie3Data["premium"] = {};
        dateList.forEach((each) => {
          if (user_type == "Carrier") {
            business += carrier_all_prem[each]["premium"]["prem_total"];
          } else {
            business += prem_total[each]["premium"]["prem_total"];
          }
          q_received += prem_total[each]["premium"]["total_received"];
          quote_bound += prem_total[each]["premium"]["bound"];
          testData["premium"]["Bound"] += prem_total[each]["premium"]["bound"];
          testData["premium"]["In Progress"] +=
            prem_total[each]["premium"]["inprogress"];
          testData["premium"]["Lost"] += prem_total[each]["premium"]["lost"];
          testData["premium"]["Declined"] = business - q_received;

          if (filter3 == "all") {
            for (let key in bar_carrier_all[each]["premium"]) {
              if (!barData["premium"][key]) {
                barData["premium"][key] = bar_carrier_all[each]["premium"][key];
              } else {
                barData["premium"][key] +=
                  bar_carrier_all[each]["premium"][key];
              }
            }
            for (let key in pie_carrier_all[each]["volume"]) {
              if (!pie3Data["premium"][key]) {
                pie3Data["premium"][key] =
                  pie_carrier_all[each]["premium"][key];
              } else {
                pie3Data["premium"][key] +=
                  pie_carrier_all[each]["premium"][key];
              }
            }
          } else {
            for (let key in codes_all[each]["premium"]) {
              if (!barData["premium"][key]) {
                barData["premium"][key] = codes_all[each]["premium"][key];
              } else {
                barData["premium"][key] += codes_all[each]["premium"][key];
              }
            }
            for (let key in carrier_total[each]["volume"]) {
              if (!pie3Data["premium"][key]) {
                pie3Data["premium"][key] = carrier_total[each]["premium"][key];
              } else {
                pie3Data["premium"][key] += carrier_total[each]["premium"][key];
              }
            }
          }
          barData["premium"] = this.sort_top_ten(barData["premium"]);
          pie3Data["premium"] = this.sort_object(pie3Data["premium"]);
          pie3Data["premium"] = this.compress(pie3Data["premium"]);
        });
      } else {
        // const { pre, testData2, barData2, pie3Data2 } = this.state;
        // business = pre.business;
        // q_received = pre.q_received;
        // quote_bound = pre.quote_bound;
        // testData.premium.Bound = testData2.premium.Bound;
        // testData.premium["In Progress"] = testData2.premium["In Progress"];
        // testData.premium.Lost = testData2.premium.Lost;
        // testData.premium.Declined = testData2.premium.Declined;
        // barData.premium = barData2.premium;
        // pie3Data.premium = pie3Data2.premium;
      }
      this.setState({ testData, barData, pie3Data });
      business = this.currencyConverter(business);
      q_received = this.currencyConverter(q_received);
      quote_bound = this.currencyConverter(quote_bound);
      var data1 = this.returnPieData("premium");
      var data2 = this.returnBarData("premium");
      var data3 = this.returnPie3Data("premium");
    }

    this.setState({ business, q_received, quote_bound, data1, data2, data3 });
  };

  handleTypeChange = async (e) => {
    this.setState({
      filter: e.target.value,
    });
    this.filterdata(undefined, e.target.value, undefined);
  };

  handleFilter = async (e) => {
    this.setState({
      filter3: e.target.value,
    });
    this.filterdata(undefined, undefined, e.target.value);
  };

  sort_top_ten = (obj) => {
    let items = Object.keys(obj).map(function (key) {
      return [key, obj[key]];
    });
    items.sort(function (first, second) {
      return second[1] - first[1];
    });
    // console.log(items)
    let list = [];
    for (var i = 0; i < 10; i++) {
      list.push(items[i]);
    }
    let sorted_obj = {};
    for (var i = 0; i < 10; i++) {
      //get top 10 only
      if (items[i]) {
        let k = items[i][0];
        let v = items[i][1];
        sorted_obj[k] = v;
      }
    }
    return sorted_obj;
  };
  compress = (obj) => {
    //top 5 carriers
    let return_obj = {};
    let z = 0;
    for (let k in obj) {
      if (z < 5) {
        return_obj[k] = obj[k];
      } else {
        if (!return_obj["Others"]) {
          return_obj["Others"] = 0;
        }
        return_obj["Others"] += obj[k];
      }
      z += 1;
    }
    return return_obj;
  };

  sort_object = (obj) => {
    let items = Object.keys(obj).map(function (key) {
      return [key, obj[key]];
    });
    items.sort(function (first, second) {
      return second[1] - first[1];
    });
    // console.log(items)
    let sorted_obj = {};
    for (var i = 0; i < items.length; i++) {
      if (items[i]) {
        let k = items[i][0];
        let v = items[i][1];
        sorted_obj[k] = v;
      }
    }
    return sorted_obj;
  };

  getDateList = (obj, dateList) => {
    obj.forEach((e) => {
      if (e.label !== "Add all") {
        dateList.push(e.label);
      }
    });

    return dateList;
  };

  handleChange = (selected) => {
    let { eff, addAllPrev } = this.state;
    let dateList = [];
    if (selected) {
      let addAll = false;
      let dateLength = 0;
      selected.forEach((e) => {
        if (e.label !== "Add all") {
          dateLength++;
        } else {
          addAll = true;
        }
      });
      if (addAll) {
        if (eff.length === selected.length + 1 && addAllPrev === true) {
          let selectedOption = [];
          selected.forEach((e) => {
            if (e.label !== "Add all") {
              dateList.push(e.label);
              selectedOption.push({
                label: e.label,
                value: e.value,
              });
            }
          });
          addAllPrev = false;
          this.setState({ selectedOption });
        } else if (addAllPrev === true) {
          dateList = this.getDateList(eff, dateList);
          addAllPrev = false;
          this.setState({ selectedOption: eff });
        } else {
          dateList = this.getDateList(eff, dateList);
          addAllPrev = true;
          this.setState({ selectedOption: eff });
        }
      } else if (dateLength < 1) {
        dateList = this.getDateList(eff, dateList);
        this.setState({ selectedOption: [] });
      } else if (addAllPrev && !addAll) {
        dateList = this.getDateList(eff, dateList);
        addAllPrev = false;
        this.setState({ selectedOption: [] });
      } else if (eff.length === selected.length + 1) {
        dateList = this.getDateList(selected, dateList);
        addAllPrev = true;
        this.setState({ selectedOption: eff });
      } else {
        dateList = this.getDateList(selected, dateList);
        this.setState({ selectedOption: selected });
      }
      this.setState({ dateList: dateList, addAllPrev });
      this.filterdata(dateList, undefined, undefined);
    }
  };

  getDropdownButtonLabel = (labelData) => {
    const { value } = labelData;
    const { eff } = this.state;
    let placeHolder;
    if (value.length === 0) {
      placeHolder = "Select...";
    } else if (value.length === eff.length) {
      placeHolder = "All selected";
    } else {
      let selectedCount = 0;
      value.forEach((e) => {
        if (e.value !== "Add all") {
          selectedCount++;
        }
      });
      placeHolder = `${selectedCount.toString()} selected`;
    }
    return placeHolder;
  };

  render() {
    const {
      isLoader,
      user_type,
      filter,
      filter3,
      dateList,
      data1,
      data2,
      data3,
    } = this.state;
    var { business, q_received, quote_bound, selectedOption } = this.state;
    let pie_label = "Split By Agent";
    let box2 = "Sent";
    if (user_type.includes("User")) {
      this.setState({ user_type: "Agent" });
    }
    if (user_type == "Agent") {
      pie_label = "Split By Company";
      box2 = "Received";
    }
    return (
      <LoaderOverlay active={isLoader} spinner text="Loading your content">
        <Navbar />
        <div className="container">
          <div className="dropdow">
            {user_type == "Carrier" && (
              <div className="drop3-container">
                <span>Status</span>
                <select
                  className="form-control drop3"
                  onClick={this.handleFilter}
                >
                  <option value="carrier">Bound</option>
                  <option value="all">All Submissions</option>
                </select>
              </div>
            )}

            <div className="drop1-container">
              <span>Metric</span>
              <select
                className="form-control drop1"
                onClick={this.handleTypeChange}
              >
                <option value="premium">Premium </option>
                <option value="payroll">Payroll</option>
                <option value="volume">Volume</option>
              </select>
            </div>
            <div className="drop2-container">
              <span>Period</span>
              <div className="drop2">
                <ReactMultiSelectCheckboxes
                  className="react-select-checkbox"
                  classNamePrefix="react-select-checkbox"
                  // menuIsOpen={true}
                  getDropdownButtonLabel={(labelData) =>
                    this.getDropdownButtonLabel(labelData)
                  }
                  value={selectedOption}
                  options={this.state.eff}
                  onChange={this.handleChange}
                />
              </div>
            </div>
          </div>
          <div className="block_container">
            <div className="boxes">
              <div className="box1">
                Business <div>Submitted </div>
                <div className="show-data">{business}</div>
              </div>
              <div className="box2">
                Quotes<div>{box2} </div>{" "}
                <div className="show-data">{q_received}</div>
              </div>
              <div className="box3">
                Policies<div>Bound</div>{" "}
                <div className="show-data">{quote_bound}</div>
              </div>
            </div>
            <div className="chart1">
              {filter == "volume" ? (
                <div className="donut-container">
                  <span id="donut-middle-text">{business}</span>
                  <Doughnut
                    data={data1}
                    height={290}
                    options={{
                      maintainAspectRatio: true,
                      legend: {
                        display: true,
                        position: "bottom",
                        labels: {
                          fontSize: 10,
                          boxWidth: 10,
                        },
                      },
                      title: {
                        display: true,
                        text: "Conversion Ratio",
                      },
                      tooltips: {
                        callbacks: {
                          label: function (tooltipItem, data) {
                            var nam = data["labels"][tooltipItem["index"]];
                            return (
                              nam +
                              " : " +
                              data["datasets"][0]["data"][tooltipItem["index"]]
                            );
                          },
                        },
                      },
                    }}
                  />
                </div>
              ) : (
                <div className="donut-container">
                  <span id="donut-middle-text">{business}</span>
                  <Doughnut
                    data={data1}
                    height={290}
                    options={{
                      maintainAspectRatio: true,
                      legend: {
                        display: true,
                        position: "bottom",
                        labels: {
                          fontSize: 10,
                          boxWidth: 10,
                        },
                      },
                      title: {
                        display: true,
                        text: "Conversion Ratio",
                      },
                      tooltips: {
                        callbacks: {
                          label: function (tooltipItem, data) {
                            var val =
                              data["datasets"][0]["data"][tooltipItem["index"]];
                            var nam = data["labels"][tooltipItem["index"]];
                            if (val / 1000000 > 1) {
                              return (
                                nam +
                                " : " +
                                "$" +
                                Intl.NumberFormat().format(val / 1000000) +
                                "M"
                              );
                            } else {
                              return (
                                nam +
                                " : " +
                                "$" +
                                Intl.NumberFormat().format(val)
                              );
                            }
                          },
                        },
                      },
                    }}
                  />
                </div>
              )}
            </div>
            <div className="chart2">
              {filter != "volume" ? (
                <Bar
                  data={data2}
                  height={250}
                  // width = {'90px'}
                  options={{
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            // color: "rgba(0, 0, 0, 0)",
                            drawOnChartArea: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            // color: "rgba(0, 0, 0, 0)",
                            drawOnChartArea: false,
                          },
                          ticks: {
                            beginAtZero: true,
                            callback: function (value, index, values) {
                              return (
                                "$" +
                                Intl.NumberFormat().format(value / 1000000) +
                                "M"
                              );
                            },
                          },
                        },
                      ],
                    },
                    maintainAspectRatio: true,
                    title: {
                      display: true,
                      text: "Top 10 Class Codes",
                    },
                    legend: {
                      display: false,
                      position: "right",
                    },
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItem, data) {
                          if (tooltipItem.yLabel / 1000000 > 1) {
                            return (
                              "$" +
                              Intl.NumberFormat().format(
                                tooltipItem.yLabel / 1000000
                              ) +
                              "M"
                            );
                          } else {
                            return (
                              "$" +
                              Intl.NumberFormat().format(tooltipItem.yLabel)
                            );
                          }
                        },
                      },
                      custom: function (tooltip) {
                        if (!tooltip) return;
                        // disable displaying the color box;
                        tooltip.displayColors = false;
                      },
                    },
                  }}
                />
              ) : (
                <Bar
                  data={data2}
                  height={250}
                  // width = {'90px'}
                  options={{
                    scales: {
                      xAxes: [
                        {
                          gridLines: {
                            // color: "rgba(0, 0, 0, 0)",
                            drawOnChartArea: false,
                          },
                        },
                      ],
                      yAxes: [
                        {
                          gridLines: {
                            // color: "rgba(0, 0, 0, 0)",
                            drawOnChartArea: false,
                          },
                          ticks: {
                            beginAtZero: true,
                            callback: function (value, index, values) {
                              return value;
                            },
                          },
                        },
                      ],
                    },
                    maintainAspectRatio: true,
                    title: {
                      display: true,
                      text: "Top 10 Class Codes",
                    },
                    legend: {
                      display: false,
                      position: "right",
                    },
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItem, data) {
                          return tooltipItem.yLabel;
                        },
                      },
                      custom: function (tooltip) {
                        if (!tooltip) return;
                        // disable displaying the color box;
                        tooltip.displayColors = false;
                      },
                    },
                  }}
                />
              )}
            </div>
            <div className="chart3">
              {filter != "volume" ? (
                <Pie
                  data={data3}
                  height={290}
                  options={{
                    maintainAspectRatio: true,
                    legend: {
                      display: true,
                      position: "bottom",
                      labels: {
                        fontSize: 10,
                        boxWidth: 10,
                      },
                    },
                    title: {
                      display: true,
                      text: pie_label,
                    },
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItem, data) {
                          var val =
                            data["datasets"][0]["data"][tooltipItem["index"]];
                          var nam = data["labels"][tooltipItem["index"]];
                          if (val / 1000000 > 1) {
                            return (
                              nam +
                              ": " +
                              "$" +
                              Intl.NumberFormat().format(val / 1000000) +
                              "M"
                            );
                          } else {
                            return (
                              nam +
                              " : " +
                              "$" +
                              Intl.NumberFormat().format(val)
                            );
                          }
                        },
                      },
                    },
                  }}
                />
              ) : (
                <Pie
                  data={data3}
                  height={290}
                  options={{
                    maintainAspectRatio: true,
                    legend: {
                      display: true,
                      position: "bottom",
                      labels: {
                        fontSize: 10,
                        boxWidth: 10,
                      },
                    },
                    title: {
                      display: true,
                      text: pie_label,
                    },
                    tooltips: {
                      callbacks: {
                        label: function (tooltipItem, data) {
                          return (
                            data["labels"][tooltipItem["index"]] +
                            " : " +
                            data["datasets"][0]["data"][tooltipItem["index"]]
                          );
                        },
                      },
                    },
                  }}
                />
              )}
            </div>
          </div>

          <div className="row4">
            <UsMap type={filter} dateList={dateList} filter3={filter3} />
          </div>

          <div className="tables">
            {this.state.user_type == "Agent" && (
              <div className="row6 left-element">
                <h4>Quotes Expiring in next 30 days</h4>
                <QuotesDueExpiry user={this.state.user} user_type={user_type} />
              </div>
            )}
            {this.state.user_type == "Carrier" && (
              <div className="row6 left-element">
                <h4>Submissions Not Quoted Yet</h4>
                <QuotesDueExpiry user={this.state.user} user_type={user_type} />
              </div>
            )}
            {this.state.user_type && (
              <div className="row5 right-element">
                {/* <h4>Policies Expiring in next 90 days</h4> */}
                <PoliciesDueExpiry
                  user={this.state.user}
                  user_type={user_type}
                />
              </div>
            )}

            {/* {this.state.user_type === "Carrier" && (
              <div className="row7">
                <h4>Agent-Bind Ratio</h4>
                <AgentBindRatio user={this.state.user} />
              </div>
            )} */}
          </div>
        </div>
      </LoaderOverlay>
    );
  }
}

export default DashboardWithMap;
