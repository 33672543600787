import React, { Component } from "react";
import { Link } from "react-router-dom";

class BanMain extends Component {
  render() {
    const pathMap = {
      group1: "Text specific to group 1",
      group2: "Text specific to group 2",
      group3: "Text specific to group 3",
    };
    const { path } = this.props;
    const secondaryText =
      path in pathMap ? pathMap[path] : "You're just a few clicks away";
    return (
      <div id="banMain" className="container section text-center">
        <img
          className="fitLayout"
          src={require("../../../images/ban_main.jpg")}
          alt="Worker's Banner"
        />
        <h2 className="text-center" style={{ marginTop: "40px" }}>
          Get Worker's Comp Coverage Instantly
        </h2>
        <h3>{secondaryText}</h3>
        <p className="text-center" style={{ marginTop: "20px" }}>
          <Link to="/Quote">
            <a className="btn btn-warning btn-lg btn-warning-shadow">
              Confirm Your Quote
            </a>
          </Link>
        </p>
      </div>
    );
  }
}

export default BanMain;
