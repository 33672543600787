import React from "react";

const EMTY_OBJ = {
  title: "",
  children: <></>,
  acceptBtn: "",
  acceptBtnCallback: () => { },
  show: false,
};

class Popup extends React.Component {
  render() {
    let { popupData, updatePopUpData } = this.props;
    let {
      show,
      title,
      children,
      acceptBtn,
      acceptBtnCallback,
      disableNoButton,
      secondButtonText,
      secondButtonCallback
    } = popupData;

    return (
      <div className={show ? "popup d-block" : "popup d-none"}>
        <div className="popup-container">
          <h5 className="header-text">{title}</h5>
          {children}
          <div className="d-flex justify-content-center mt-3">
            <div
              className={`d-flex w-50 ${disableNoButton
                ? "justify-content-center"
                : "justify-content-between"
                }`}
            >
              <button
                className="btn upload-docs-action"
                onClick={() => {
                  acceptBtnCallback
                    ? acceptBtnCallback()
                    : console.log("popup closed");
                  updatePopUpData(EMTY_OBJ);
                }}
              >
                {acceptBtn}
              </button>
              {!disableNoButton && (
                <button
                  className="btn upload-docs-action"
                  onClick={() => {
                    secondButtonCallback ?
                      secondButtonCallback()
                      : console.log("popup closed");
                    updatePopUpData(EMTY_OBJ)
                  }}
                >
                  {secondButtonText ? secondButtonText : "No"}
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Popup;
