import React, { Component } from "react";
import Navbar from "../component/common/Navbar";
import Loader from "../component/common/Loader";
import SideTab from "./common/SideTab";
import Popup from "../component/common/Popup";

class NewQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupData: {
        title: "",
        children: <></>,
        acceptBtn: "",
        acceptBtnCallback: () => {},
        show: false,
      },
    };
  }

  updatePopUpData = (pData) => {
    this.setState({ popupData: pData });
  };

  render() {
    return (
      <div className="newquote-mainbody container-fluid">
        <div>
          <Navbar />
        </div>

        <SideTab updatePopUpData={this.updatePopUpData} />
        <Loader />
        {this.state.popupData.show && (
          <Popup
            popupData={this.state.popupData}
            updatePopUpData={this.updatePopUpData}
          />
        )}
      </div>
    );
  }
}

export default NewQuote;
