import React, { Component, useState, useEffect } from "react";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import Loader from "../common/Loader";
import ReviewItem from "../subcompo/panel/ReviewItem";
import axios from "axios";
import { Auth } from "aws-amplify";
import { awsUrl } from "../../config";
import { showErrAlert } from "../../utils/common";
import $ from "jquery";
import { Modal } from "react-bootstrap";
import { getHeader, blobPdfFromBase64String } from "../../utils/common";

async function loadQuotePDF(data, setIframeContent, setLoading, setError) {
  const { user_email_id, uuid_carrier, carrier, state } = data;
  const timestamp = uuid_carrier[0].split("@")[0];
  const header = await getHeader();
  const getQuoteData = {
    carrier: carrier,
    email: user_email_id,
    state: [state],
    timestamp: timestamp,
  };
  console.log("data", getQuoteData);
  axios
    .post(
      awsUrl + "/api/generateQuotePDF",
      JSON.stringify(getQuoteData),
      header
    )
    .then((res) => {
      let pdf = res.data.data;
      let file = blobPdfFromBase64String(pdf);
      var fileURL = URL.createObjectURL(file);
      setLoading(false);
      setIframeContent(fileURL);
    })
    .catch((err) => {
      setError(err);
    });
}

function loadAcordForm(data, setIframeContent, setLoading, setError) {
  Auth.currentSession().then((res) => {
    const USER_TOKEN = res.idToken.jwtToken;
    const { user_email_id, uuid_carrier, carrier } = data;
    const address = uuid_carrier.map((res) => res.split("+").reverse()[0]);
    const date = uuid_carrier[0].split("@")[0];
    let etQuoteDetails = {
      email: user_email_id,
      timestamp: date,
      carrier: carrier,
      mail_address: "",
      address: address,
      state: [data.state.toUpperCase()],
    };
    const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
    axios
      .post(awsUrl + "/api/generateAccordForm/", etQuoteDetails, header)
      .then((res) => {
        let pdf = res.data.data;
        let file = blobPdfFromBase64String(pdf);
        var fileURL = URL.createObjectURL(file);
        setLoading(false);
        setIframeContent(fileURL);
      })
      .catch((err) => {
        setError(err);
      });
  });
}

function loadLossRun(data, setIframeContent, setLoading, setError) {
  Auth.currentSession().then((res) => {
    const { uuid_carrier, lossData } = data;
    let lossRunData = {};
    if (uuid_carrier.length > 0) {
      lossRunData.date = uuid_carrier[0].split("@")[0];
      lossRunData.fileType = lossData.fileType;
    }
    const USER_TOKEN = res.idToken.jwtToken;
    const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };

    if (lossData) {
      axios
        .post(
          awsUrl + "/api/getLossRunForm",
          JSON.stringify(lossRunData),
          header
        )
        .then((response) => {
          if (response.data) {
            const { signedURL } = response.data;
            axios
              .get(signedURL, { responseType: "blob" })
              .then((res) => {
                const { fileType } = lossData;
                const imageFormats = ["image", "jpeg", "png", "jpg"];

                if (fileType === "pdf") {
                  const newBlob = new Blob([res.data], {
                    type: "application/pdf",
                  });
                  const fileURL = URL.createObjectURL(newBlob);
                  setLoading(false);
                  setIframeContent(fileURL);
                } else if (imageFormats.includes(fileType)) {
                  const newBlob = new Blob([res.data], {
                    type: "image/jpeg/png/jpg",
                  });
                  const fileURL = URL.createObjectURL(newBlob);
                  setLoading(false);
                  setIframeContent(fileURL);
                } else {
                  const newBlob = new Blob([res.data], { type: "text/plain" });
                  const fileURL = URL.createObjectURL(newBlob);
                  setLoading(false);
                  setIframeContent(fileURL);
                }
              })
              .catch((err) => {
                setError(err);
              });
          } else {
            setError("Loss run data not available.");
          }
        })
        .catch((error) => {
          setError(error);
        });
    } else {
      setError("Loss run data not available.");
    }
  });
}

function LossSumaryTable(props) {
  const historical_claims = props.data;
  if (historical_claims.length === 0) {
    return <p>No data found</p>;
  }
  return (
    <table className="table table-bordered">
      <thead>
        <tr>
          <th className="per5 text-center">Year</th>
          <th className="per30">Carrier</th>
          <th className="per10 text-right">Annual Premium</th>
          <th className="per5 text-right"># of Claims</th>
          <th className="per10 text-right">Total Incurred</th>
          <th className="per10 text-right">Total Paid</th>
          <th className="per10 text-right">Total Reserves</th>
        </tr>
      </thead>
      <tbody>
        {historical_claims.map((claim) => (
          <tr key={claim.year}>
            <td className="text-left">{claim.year}</td>
            <td className="text-left">{claim.carrier}</td>
            <td className="text-right">{claim.annPre}</td>
            <td className="text-right">{claim.claim}</td>
            <td className="text-right">{claim.totInc}</td>
            <td className="text-right">{claim.totPaid}</td>
            <td className="text-right">{claim.totRes}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}

function DocumentModal(props) {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [iframeContent, setIframeContent] = useState(null);
  const [lossData, setLossData] = useState(null);

  let modalTitle = "";
  if (props.docProperties) {
    modalTitle = {
      "quote-pdf": "Quote",
      "acord-form": "Acord Form",
      "loss-run": "Loss Runs",
      "loss-summary": "Loss Summary",
    }[props.docProperties.docType];
  }

  useEffect(() => {
    if (props.docProperties === null) {
      setLoading(true);
      setIframeContent(null);
      setLossData(null);
      setError(false);
    } else {
      if (props.docProperties.docType === "quote-pdf") {
        loadQuotePDF(
          props.docProperties.itemData,
          setIframeContent,
          setLoading,
          setError
        );
      } else if (props.docProperties.docType === "acord-form") {
        loadAcordForm(
          props.docProperties.itemData,
          setIframeContent,
          setLoading,
          setError
        );
      } else if (props.docProperties.docType === "loss-run") {
        loadLossRun(
          props.docProperties.itemData,
          setIframeContent,
          setLoading,
          setError
        );
      } else if (props.docProperties.docType === "loss-summary") {
        if (props.docProperties.itemData.historical_claims) {
          setLoading(false);
          setLossData(props.docProperties.itemData.historical_claims);
        } else {
          setError("No loss summary available");
        }
      }
    }
  }, [props.docProperties]);

  if (props.show) {
    let modalContent = "";
    if (error) {
      modalContent = <p>Document not loaded.</p>;
    } else if (loading) {
      modalContent = <p>Loading document ...</p>;
    } else if (iframeContent) {
      modalContent = (
        <iframe
          title="{modalTitle}"
          src={iframeContent}
          style={{ width: "100%", height: "100%" }}
        ></iframe>
      );
    } else if (lossData) {
      modalContent = <LossSumaryTable data={lossData} />;
    }
    return (
      <Modal
        show={props.show}
        onHide={() => props.onHide()}
        dialogClassName="modal-full"
        style={{
          width: "100%",
          height: "100%",
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>{modalTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalContent}</Modal.Body>
      </Modal>
    );
  } else {
    return null;
  }
}

class PanelUW extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show_data: "",
      sortAscending: true,
      displayDocument: false,
      docProperties: null,
    };
  }
  componentDidMount() {
    $("#loader").css("display", "block");
    Auth.currentSession()
      .then((res) => {
        console.log(
          "Current Authenticated Response User in UW ",
          res.idToken.payload["cognito:username"]
        );
        var carrier = res.idToken.payload["cognito:username"];
        const USER_TOKEN = res.idToken.jwtToken;
        const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
        axios
          .get(awsUrl + "/api/getCarrierDataStatus/" + carrier, header)
          .then((response) => {
            this.setState({
              show_data: response.data,
            });
            $("#loader").css("display", "none");
            this.setState({ disp_message: true });
          })
          .catch((err) => {
            console.log("Error in getting status :(", err);
            $("#loader").css("display", "none");
            showErrAlert(err);
          });
      })
      .catch((err) => {
        $("#loader").css("display", "none");
        showErrAlert(err);
      });
  }

  changeSortOrder = () => {
    const { sortAscending } = this.state;
    this.setState({ sortAscending: !sortAscending });
  };

  onDisplayDocument = (docType, itemData) => {
    console.log("Displaying document ", docType);
    this.setState({
      showDocument: true,
      docProperties: {
        docType: docType,
        itemData: itemData,
      },
    });
  };

  onHideDocument = () => {
    this.setState({
      showDocument: false,
      docProperties: null,
    });
  };

  render() {
    const { show_data, sortAscending } = this.state;
    let data2send = {};
    for (let data of show_data) {
      if (data.uw === true || data.quote_status === "uwportal") {
        if (!(data.date in data2send)) {
          data2send[data.date] = {};
        }
        if (!(data.carrier_location_data.state in data2send[data.date])) {
          data2send[data.date][data.carrier_location_data.state] = {
            uuid_carrier: [],
          };
        }
        data2send[data.date][data.carrier_location_data.state].state =
          data.carrier_location_data.state;
        data2send[data.date][data.carrier_location_data.state].carrier =
          data.carrier_location_data.carrier;
        data2send[data.date][data.carrier_location_data.state].quote_status =
          data.quote_status;
        data2send[data.date][data.carrier_location_data.state].user_email_id =
          data.user_email_id;
        data2send[data.date][data.carrier_location_data.state].review =
          data.review;
        data2send[data.date][data.carrier_location_data.state].effective_date =
          data.effective_date;
        data2send[data.date][data.carrier_location_data.state].company_name =
          data.company_name;
        data2send[data.date][
          data.carrier_location_data.state
        ].uuid_carrier.push(data.uuid_carrier);
        data2send[data.date][
          data.carrier_location_data.state
        ].historical_claims = data.historical_claims;
        data2send[data.date][data.carrier_location_data.state].lossData =
          data.lossData;
      }
    }

    let ReviewList = [];
    let index = 0;

    let orderedData2send = {};
    if (sortAscending) {
      Object.keys(data2send)
        .sort()
        .forEach(function (key) {
          orderedData2send[key] = data2send[key];
        });
    } else {
      Object.keys(data2send)
        .sort()
        .reverse()
        .forEach(function (key) {
          orderedData2send[key] = data2send[key];
        });
    }

    for (let timestamp in orderedData2send) {
      for (let state in orderedData2send[timestamp]) {
        ReviewList.push(
          <ReviewItem
            key={index}
            item={index}
            data={orderedData2send[timestamp][state]}
            displayDocument={this.onDisplayDocument}
          />
        );
        index++;
      }
    }

    return (
      <div>
        <Navbar />
        <div id="uwPortal">
          <div class="container-fluid">
            {ReviewList.length > 0 && (
              <table className="table fixedHeader table-sm">
                <thead>
                  <tr>
                    <th className="per10">Effective Date & Insured Name</th>
                    <th
                      className="per10 text-center"
                      onClick={this.changeSortOrder}
                    >
                      Expiration
                    </th>
                    <th className="per5 text-center">Quote PDF</th>
                    <th className="per5 text-center">Acord App</th>
                    <th className="per5 text-center">Loss Runs</th>
                    <th className="per5 text-center">Loss Summary</th>
                    <th className="per20 text-center">
                      Schedule Debit / Credit
                    </th>
                    <th className="per20 text-center">Decision</th>
                    <th className="text-center">Comments</th>
                  </tr>
                </thead>
                <tbody>{ReviewList}</tbody>
              </table>
            )}
            {ReviewList.length <= 0 && this.state.disp_message && (
              <span style={{ margin: "auto" }}>
                "No data found for this carrier"{" "}
              </span>
            )}
          </div>
        </div>
        <DocumentModal
          show={this.state.showDocument}
          docProperties={this.state.docProperties}
          onHide={() => this.onHideDocument()}
        />
        <Footer />
        <Loader />
      </div>
    );
  }
}

export default PanelUW;
