import React, { Component } from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { awsUrl } from "../../config";
import axios from "axios";
import $ from "jquery";
import { showErrAlert } from "../../utils/common";
import { Auth } from "aws-amplify";
class MyGrid extends Component {
  constructor(props) {
    super(props);

    this.state = {
      columnDefs: [],
      rowData: this.props.data,
      columns: this.props.columns,
      isEqual: true,
      fileName: this.props.fileName
    };
    this.confirData = this.confirData.bind(this);
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      isEqual: true
    });
    console.log(nextProps.columns);
    if (
      Object.keys(nextProps.data[0]).length - 1 ===
      nextProps.columns.length
    ) {
      for (let key in nextProps.data[0]) {
        if (key !== "carrier") {
          console.log("keys: ", key);
          if (!nextProps.columns.includes(key.toLocaleLowerCase())) {
            console.log("Comming inside:");
            this.setState({
              isEqual: false
            });
          }
        }
      }
    } else {
      console.log("commintkjs in err");
      this.setState({
        isEqual: false
      });
    }
    let columnDef = [];
    for (let key in nextProps.data[0]) {
      let header = {};
      header.headerName = key;
      header.field = key;
      header.editable = true;
      columnDef.push(header);
    }
    this.setState({
      rowData: nextProps.data,
      // isEqual : nextProps.isEqual,
      columns: nextProps.columns,
      columnDefs: columnDef,
      fileName: nextProps.fileName
    });
  }

  confirData() {
    $("#loader").css("display", "block");
    console.log("FileName: ", this.state.fileName);
    console.log("File Data: ", this.state.rowData);
    Auth.currentSession().then(res => {
      const USER_TOKEN = res.idToken.jwtToken;
      const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
      axios
        .post(
          awsUrl + "/api/postTablesData/" + this.state.fileName,
          JSON.stringify(this.state.data),
          header
        )
        .then(response => {
          if (response["data"] === "Successfulley updated") {
            alert("Successsfulley uploaded");
            $("#loader").css("display", "none");
          }
        })
        .catch(err => {
          console.log("Errir in data uplaod: ", err);
          $("#loader").css("display", "none");
          showErrAlert(err);
        });
    });
  }

  render() {
    console.log("File data is showing in table(Grid)!", this.state.rowData);
    console.log("Equal", this.state.isEqual);

    return (
      <div id="grid-container" className="row">
        <div className="col-xs-12 text-center">
          <div
            id="table-container"
            className="ag-theme-balham"
            style={{ height: "290px", width: "100%", alignSelf: "center" }}
          >
            <AgGridReact
              enableSorting={true}
              enableFilter={true}
              pagination={true}
              paginationPageSize={8}
              columnDefs={this.state.columnDefs}
              rowData={this.state.rowData}
            ></AgGridReact>
          </div>

          {this.state.columns.length > 0 &&
            this.state.data !== "" &&
            this.state.isEqual && (
              <button
                type="button"
                onClick={() => this.confirData()}
                className="btnGen btnSubmits"
              >
                Confirm
              </button>
            )}
          {this.state.columns.length > 0 &&
            this.state.data !== "" &&
            !this.state.isEqual && (
              <p style={{ color: "red" }}>
                Warning: Please be sure your selected table and uploaded table
                both are same?
              </p>
            )}
        </div>
      </div>
    );
  }
}

export default MyGrid;
