import React, { Component } from "react";
import ComputationTable from "../../common/ComputationTable";

class ProgramFactors extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let { data } = this.props;
    console.log(data, "pf");
    let tableHeaders = ["Program Factor", "Value"];
    let keyMap = {
      chubb_program_expenses:
        process.env.REACT_APP_MODE == "beta"
          ? "Carrier Program Expenses"
          : "Chubb Program Expenses",

      extensis_program_expenses:
        process.env.REACT_APP_MODE == "beta"
          ? "PEO Program Expenses"
          : "Extensis Program Expenses",

      extensis_risk_margin:
        process.env.REACT_APP_MODE == "beta"
          ? "PEO Risk Margin"
          : "Extensis Risk Margin",
      lossFundingFactor: "Loss Funding Factor",
    };
    try {
      data.table_bin = `${data.table}, ${data.bin}`;
    } catch (error) {}

    return (
      <div>
        <ComputationTable data={data} headers={tableHeaders} keyMap={keyMap} />
      </div>
    );
  }
}

export default ProgramFactors;
