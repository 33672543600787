import React,{Component} from 'react';
import axios from 'axios';
import { dashboardUrl } from "../../config";
import { getHeader } from "../../utils/common";
import ReactTable from "react-table-6";  
import 'react-table-6/react-table.css'

  


class QuotesDueExpiry extends Component{
    constructor(props){
        super();
        this.state={
            user : props.user,
            user_type : props.user_type,
            count :'',
            columns:[]
            
        }
    }
    async componentDidMount(){
                const {user} = this.state
                const header = await getHeader()
                await axios.get(dashboardUrl+'/api/getQuotesExpiry/'+user,header)
                    .then(response=>{
                        this.setState({rows:response.data.list,
                          count:response.data.count})
                        console.log(response.data.count)
                    })
                    .catch(err=>{
                        console.log(err)
                    })

                let columns =[]
                if(this.state.user_type=='Carrier'){
                  columns = [
                    {
                      Header: 'Insured  Name',
                      accessor: 'business',
                      // sort: 'asc',
                      width: 130,
                      headerClassName: 'table-header lt-align',
                      Cell: row => <div><span title={row.value}>{row.value}</span></div>
                    },
                    {
                      Header: 'St.',
                      accessor: 'state',
                      headerClassName: 'table-header',
                      Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
                      // sort: 'asc',
                      width: 40
                    },
                    {
                      Header: 'Agent',
                      accessor: 'agent',
                      // sort: 'asc',
                      width: 130,
                      headerClassName: 'table-header lt-align',
                      Cell: row => <div><span title={row.value}>{row.value}</span></div>
                    },
                    {
                      Header: 'Eff. Dt.',
                      accessor: 'effective',
                      headerClassName: 'table-header',
                      // sort: 'asc',
                      width: 70,
                      Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                    },
                    {
                      Header: 'Exp. Dt.',
                      accessor: 'expiry',
                      headerClassName: 'table-header',
                      // sort: 'desc',
                      width: 70,
                      Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                    },
                    {
                      Header: 'Premium',
                      accessor: 'premium',
                      headerClassName: 'table-header rt-align',
                      // sort: 'asc',
                      width: 70,
                      Cell: row => <div style={{ textAlign: "right" }}>{row.value}</div>

                    }]
                }
                else{
                  columns = [
                    {
                      Header: 'Insured  Name',
                      accessor: 'business',
                      // sort: 'asc',
                      width: 130,
                      headerClassName: 'table-header lt-align',
                      Cell: row => <div><span title={row.value}>{row.value}</span></div>
                    },
                    {
                      Header: 'St.',
                      accessor: 'state',
                      headerClassName: 'table-header',
                      Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>,
                      // sort: 'asc',
                      width: 40
                    },
                    {
                      Header: 'Issuing Co.',
                      accessor: 'carrier',
                      // sort: 'asc',
                      width: 130,
                      headerClassName: 'table-header lt-align',
                      Cell: row => <div><span title={row.value}>{row.value}</span></div>
                    },
                    {
                      Header: 'Eff. Dt.',
                      accessor: 'effective',
                      headerClassName: 'table-header',
                      // sort: 'asc',
                      width: 70,
                      Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                    },
                    {
                      Header: 'Exp. Dt.',
                      accessor: 'expiry',
                      headerClassName: 'table-header',
                      // sort: 'desc',
                      width: 70,
                      Cell: row => <div style={{ textAlign: "center" }}>{row.value}</div>

                    },
                    {
                      Header: 'Premium',
                      accessor: 'premium',
                      headerClassName: 'table-header rt-align',
                      // sort: 'asc',
                      width: 70,
                      Cell: row => <div style={{ textAlign: "right" }}>{row.value}</div>

                    }]
                }
                this.setState({columns})
            }
            
    render(){
     
        let {rows,count,columns} = this.state
        if(count<20){count=count}
        if(count<10){count=10}
        // console.log(count)
        return(
                
                    <ReactTable
                     data={rows}
                     columns={columns}
                     pageSize={count}
                     defaultSorted={[
                       {
                         id:"expiry",
                         desc : false
                       }
                     ]}
                    //  defaultPageSize={count}
                     style={{height:"293px",width:"535px"}}
                     showPagination={false}
                     resizable ={false}
                     
                  />
                  
                  
                
            

        )
    }
}

// const columns = ['Business','State','Carrier','Effective Date','Expiry Date','Premium']

// class QuotesDueExpiry extends Component {
//     constructor(props){
//         super();
//         this.state ={
//             user : props.user
//         }
//     }

//     generateHeader(){
//         let res=[]
//         for(var i=0;i<columns.length;i++){
//             res.push(<th>{columns[i]}</th>)
//         }
//         return res
//     }

//     generateTable(){
//         let res=[]
//         const {data} = this.state
//         if(data){
//             for(var i=0;i<data.length;i++){
//                 res.push(<tr>
//                     <td>{data[i][0]}</td>
//                     <td>{data[i][1]}</td>
//                     <td>{data[i][2]}</td>
//                     <td>{data[i][3]}</td>
//                     <td>{data[i][4]}</td>
//                     <td>{data[i][5]}</td>

//                 </tr>)
//             }
//         }
//         return res
//     }

//     async componentDidMount(){
//         const {user} = this.state
//         const header = await getHeader()
//         await axios.get(dashboardUrl+'/api/getQuotesExpiry/'+user,header)
//             .then(response=>{
//                 this.setState({data:response.data.list})
//                 console.log(response.data.count)
//             })
//             .catch(err=>{
//                 console.log(err)
//             })
//     }
//     render(){
//     return (
//         <div>
//                  {this.state.data && this.state.data.length>0 ?
//                     ( <div style={{"overflow":"scroll","height":"280px","width":"560px","overflow":"auto"}}>
//                     <table border="1px" className="table table-striped floatRight">
//                      <thead>
//                      <tr>{this.generateHeader()}</tr>
//                      </thead>
                     
//                      <tbody>
                         
//                      {this.generateTable()}
                        
//                      </tbody>
//                      </table>
//                      </div> ): 
//                      (
//                        <div style={{"height":"50px"}} className="text-center">No policies expiring soon </div> 
//                      )
//                      }
//          </div>
//     )}
// }
export default QuotesDueExpiry;