import React, { Component } from "react";
import ReactJson from "react-json-view";
class DetailedQuoteData extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let { detailedQuoteData } = this.props;
    return (
      <div>
        <ReactJson
          src={detailedQuoteData}
          theme="monokai"
          name={false}
          collapsed={3}
          displayDataTypes={false}
          displayObjectSize={false}
          quotesOnKeys={false}
          sortKeys={true}
        />
      </div>
    );
  }
}

export default DetailedQuoteData;
