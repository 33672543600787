import React, { Component } from "react";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import ActionButtons from "./ActionButtons";
import { onlyNum, printDiv } from "../../../utils/common";
import BootstrapSwitchButton from "bootstrap-switch-button-react";

class PriceModifier extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSection: true,
    };
  }

  render() {
    let {
        quoteTable,
        questions,
        setPriceModifierDropDowns,
        handleCalculateButton,
        handleToSalesforceButtonClick,
      } = this.props,
      selectedCarrier = "carrier_a";
    for (let carrier in quoteTable) {
      if (quoteTable[carrier]["state"].selected) {
        selectedCarrier = carrier;
        break;
      }
    }

    let { showSection } = JSON.parse(JSON.stringify(this.state));

    const {
      classDropDown,
      safetyCreditDown,
      alcoholDropDown,
      wosDropDown,
      creditChecked,
      sdr_factor,
    } = questions[selectedCarrier];

    return (
      <div className="price-modifier">
        <div className="section-header d-flex align-items-center justify-content-between">
          <span className="header-name">
            <b>Price Modifiers</b>
          </span>

          <button
            className="close-button btn"
            onClick={() => this.setState({ showSection: !showSection })}
            style={{
              backgroundColor: "rgb(20, 99, 172)",
              color: "white",
              borderRadius: "0 20px 0 20px",
            }}
          >
            {showSection ? "Close" : "Open"}
          </button>
        </div>
        <div style={{ backgroundColor: "black", marginTop: "-10px" }}>
          <hr />
        </div>
        <div className={!showSection ? "d-none" : ""}>
          <div className="section-body d-flex mt-3 justify-content-center">
            <Form className="section-form">
              <Row className="align-items-baseline price_modifier_form">
                <Col>
                  <Form.Group controlId="finalRatingFactor">
                    <Form.Label>
                      <b>
                        {"Final rating factor(" +
                          (creditChecked ? "Credit)" : "Debit)")}
                      </b>
                    </Form.Label>
                    <InputGroup>
                      <BootstrapSwitchButton
                        checked={creditChecked}
                        onChange={() => {
                          let value = creditChecked === true ? false : true;
                          setPriceModifierDropDowns(
                            value,
                            "creditChecked",
                            selectedCarrier
                          );
                        }}
                        onstyle="success"
                        offstyle="danger"
                        onlabel="Cr"
                        offlabel="Dr"
                      />
                      <Form.Control
                        value={sdr_factor ? sdr_factor : ""}
                        onChange={(e) =>
                          setPriceModifierDropDowns(
                            e.target.value,
                            "sdr_factor",
                            selectedCarrier
                          )
                        }
                        min="0"
                        onKeyDown={(e) => {
                          var keyCode = e.keyCode ? e.keyCode : e.which;
                          if (keyCode === 109 || keyCode === 189) {
                            e.preventDefault();
                          }
                        }}
                        type="number"
                        placeholder={creditChecked ? "Credit" : "Debit"}
                      />
                      <InputGroup.Append>
                        <InputGroup.Text>%</InputGroup.Text>
                      </InputGroup.Append>
                    </InputGroup>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="classSelection">
                    <Form.Label>
                      <b>Class</b>
                    </Form.Label>
                    <select
                      value={classDropDown || "Please Select"}
                      id="classDropDown"
                      type="text"
                      onChange={(e) =>
                        setPriceModifierDropDowns(
                          e.target.value,
                          "classDropDown",
                          selectedCarrier
                        )
                      }
                      autoComplete="off"
                      className="form-control pink-arrow"
                    >
                      <option key={1} value={"Please Select"}>
                        Please Select
                      </option>
                      <option key={2} value={"super_preferred"}>
                        Super Preferred
                      </option>
                      <option key={3} value={"preferred"}>
                        Preferred
                      </option>
                      <option key={4} value={"standard"}>
                        Standard
                      </option>
                      <option key={5} value={"sub_standard"}>
                        Sub Standard
                      </option>
                    </select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="align-items-baseline">
                <Col>
                  <Form.Group controlId="alcoholAndDrugFree">
                    <Form.Label>
                      <b>Alcohol &amp; Drug-Free</b>
                    </Form.Label>
                    <select
                      value={alcoholDropDown}
                      id="alcoholDropDown1"
                      type="text"
                      onChange={(e) =>
                        setPriceModifierDropDowns(
                          Number(e.target.value),
                          "alcoholDropDown",
                          selectedCarrier
                        )
                      }
                      autoComplete="off"
                      className="form-control pink-arrow"
                    >
                      <option key={1} value={0}>
                        No
                      </option>
                      <option key={2} value={1}>
                        Yes
                      </option>
                    </select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="safetyCredit">
                    <Form.Label>
                      <b>Safety Credit</b>
                    </Form.Label>
                    <select
                      value={safetyCreditDown}
                      id="safetyCreditDown"
                      type="text"
                      onChange={(e) =>
                        setPriceModifierDropDowns(
                          Number(e.target.value),
                          "safetyCreditDown",
                          selectedCarrier
                        )
                      }
                      autoComplete="off"
                      className="form-control pink-arrow"
                    >
                      <option key={1} value={0}>
                        No
                      </option>
                      <option key={2} value={1}>
                        Yes
                      </option>
                    </select>
                  </Form.Group>
                </Col>
                <Col>
                  <Form.Group controlId="wos">
                    <Form.Label>
                      <b>Blanket Waiver of Subrogation</b>
                    </Form.Label>
                    <select
                      value={wosDropDown}
                      id="wosDropDown"
                      type="text"
                      onChange={(e) =>
                        setPriceModifierDropDowns(
                          Number(e.target.value),
                          "wosDropDown",
                          selectedCarrier
                        )
                      }
                      autoComplete="off"
                      className="form-control pink-arrow"
                    >
                      <option key={1} value={0}>
                        No
                      </option>
                      <option key={2} value={1}>
                        Yes
                      </option>
                    </select>
                  </Form.Group>
                </Col>
              </Row>
            </Form>
          </div>
        </div>
      </div>
      // <div className="col-12 m-2 item-center">
      //          <label className="mandatory" for="ccpapDropDown">
      //            {"CCPAP"}
      //          </label>
      //          <select
      //            value={ccpapDropDown}
      //            id="ccpapDropDown"
      //            type="text"
      //            onChange={(e) =>
      //             setPriceModifierDropDowns(
      //               Number(e.target.value),
      //               "ccpapDropDown"
      //             )
      //           }
      //           autoComplete="off"
      //           className="rectangle1"
      //         >
      //           <option key={1} value={0}>
      //             No
      //           </option>
      //           <option key={2} value={1}>
      //             Yes
      //           </option>
      //         </select>
      //       </div>
    );
  }
}

export default PriceModifier;
