import React from "react";
import DashboardExtensisRevamp from "./DahboardExtensisRevamp";
import DashboardExtensis from "./DashboardExtensis";
import Loader from "../common/Loader";
import Navbar from "../common/Navbar";
import { Nav } from "react-bootstrap";
import Popup from "../common/Popup";
import { Auth } from "aws-amplify";
import SideTab from "../../newcomponent/common/SideTab";
let ismobile = window.innerWidth < 992;

class DashboardTabs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "tab1",
      userRole: [],
      popupData: {
        title: "",
        children: <></>,
        acceptBtn: "",
        acceptBtnCallback: () => {},
        show: false,
      },
    };
  }

  updatePopUpData = (pData) => {
    this.setState({ popupData: pData });
  };

  componentDidMount = () => {
    Auth.currentSession().then((res) => {
      this.setState({
        userEmail: res.idToken.payload.email,
        userRole: res.accessToken.payload["cognito:groups"],
      });
    });
  };

  setSelected = (tab) => {
    let selected = this.state.state;
    if (selected !== tab) {
      this.setState({ selected: tab });
    }
  };

  render() {
    let { selected, popupData, userRole } = this.state;
    const tabsMap = {
      // tab0: "Active Business",
      tab1: "New Business",
      tab2: "Renewal Business",
      tab3: "Existing Business",
    };

    return (
      <div className="dashboard-pride">
        <div>
          <Navbar variant="dashboard" />
        </div>

        <div className="position-relative">
          {ismobile ? <SideTab /> : " "}
          <div className="tabs p-4">
            {/* <div className="d-flex justify-content-center mb-3">
            <h2>
              <b>Prospect Dashboard</b>
            </h2>
          </div> */}
            <Nav
              variant="pills"
              defaultActiveKey={selected}
              style={{ fontSize: "16px" }}
            >
              {Object.keys(tabsMap).map((tab, i) => (
                <Nav.Item key={i}>
                  <Nav.Link
                    eventKey={tab}
                    onClick={() => this.setSelected(tab)}
                  >
                    {tabsMap[tab]}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            {Object.keys(tabsMap).map((tab) => (
              <div key={tab}>
                {
                  <>
                    {userRole.includes(
                      process.env.REACT_APP_DASHBOARD_TABLES_GROUP
                    ) && (
                      <DashboardExtensis
                        tabName={tab}
                        selected={selected}
                        updatePopUpData={this.updatePopUpData}
                      />
                    )}
                    {userRole.includes(
                      process.env.REACT_APP_DASHBOARD_STATS_GROUP
                    ) && (
                      <DashboardExtensisRevamp
                        tabName={tab}
                        selected={selected}
                        updatePopUpData={this.updatePopUpData}
                      />
                    )}
                  </>
                }
              </div>
            ))}
          </div>
        </div>
        <Loader />
        {popupData.show && (
          <Popup popupData={popupData} updatePopUpData={this.updatePopUpData} />
        )}
      </div>
    );
  }
}

export default DashboardTabs;
