/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:3dace2b1-60d2-4e7b-a872-f2be5abf9582",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_a4oiCiRxg",
    "aws_user_pools_web_client_id": "1725gkhijcpm2sv6ud3lpvbrdt",
    "oauth": {}
};


export default awsmobile;
