import React,{Component} from 'react';
import axios from 'axios';
import { dashboardUrl } from "../../config";
import { getHeader } from "../../utils/common";

import { MDBDataTable } from 'mdbreact';

const columns = [
    {
      label: 'Agent',
      field: 'Agent',
      sort: 'asc',
    //   width: 1
    },
    {
      label: 'Bind Ratio',
      field: 'Bind Ratio',
      sort: 'asc',
    //   width: 100
    }]
   

class AgentBindRatio extends Component{
    constructor(props){
        super();
        this.state={
            user : props.user
        }
    }
    async componentDidMount(){
                const {user} = this.state
                const header = await getHeader()
                await axios.get(dashboardUrl+'/api/getAgentBindRatio/'+user,header)
                    .then(response=>{
                        this.setState({rows:response.data.list})
                        console.log(response.data.list)
                    })
                    .catch(err=>{
                        console.log(err)
                    })
            }

    render(){
        const {rows} = this.state
        const data = {}
        data["columns"]=columns
        data["rows"]=rows
        // console.log(data)
        return(
                
                <div style={{"width":"850px"}}>
                    <MDBDataTable
                    striped
                    bordered
                    // scrollY
                    // paging={false}
                    noBottomColumns
                    displayEntries={false}
                    searching={false}
                    data={data}
                  />
                  </div>
                
            

        )
    }
}


// const columns = ['Agent','Bind Ratio']

// class AgentBindRatio extends Component {
//     constructor(props){
//         super();
//         this.state ={
//             user : props.user
//         }
//     }

//     generateHeader(){
//         let res=[]
//         for(var i=0;i<columns.length;i++){
//             res.push(<th>{columns[i]}</th>)
//         }
//         return res
//     }

//     generateTable(){
//         let res=[]
//         const {data} = this.state
//         if(data){
//             for(var i=0;i<data.length;i++){
//                 res.push(<tr>
//                     <td>{data[i][0]}</td>
//                     <td>{data[i][1]}</td>
                    
//                 </tr>)
//             }
//         }
//         return res
//     }

//     async componentDidMount(){
//         const {user} = this.state
//         const header = await getHeader()
//         await axios.get(dashboardUrl+'/api/getAgentBindRatio/'+user,header)
//             .then(response=>{
//                 this.setState({data:response.data.list})
//                 console.log(this.state.data)
//             })
//             .catch(err=>{
//                 console.log(err)
//             })
//     }
//     render(){
//     return (
//         <div >
//                  {this.state.data && this.state.data.length>0 ?
//                     ( <div style={{"overflow":"scroll","height":"280px","width":"100%","overflow":"auto"}}>
//                      <table  width="800" border="0" className="table table-striped">
//                      <thead>
//                      <tr>{this.generateHeader()}</tr>
//                      </thead>
//                      <tbody>
//                      {this.generateTable()}
//                      </tbody>
//                      </table>
//                      </div>) : 
//                      (
//                        <div style={{"height":"50px"}}className="text-center">No data </div> 
//                      )}
//          </div>
//     )}
// }
export default AgentBindRatio;