// link to download state wise class codes and description.
import React, { Component } from "react";
import { awsUrl2 } from "../../config";
import { getHeader } from "../../utils/common";
import { CSVLink } from "react-csv";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";

const axios = require("axios");
class DownloadLink extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stateData: [],
      descList: [],
    };
  }

  componentDidMount() {
    const { state } = this.props;
    this.getStateData();
  }

  getStateData = async () => {
    const { state } = this.props;
    console.log(state);
    const header = await getHeader();
    axios
      .get(awsUrl2 + `/api/getStateCodesAndDesc/${state}`, header)
      .then((res) => {
        console.log("then executed");
        let dbObjsList = res.data.data;
        console.log(dbObjsList);
        this.setState({
          descList: dbObjsList,
        });
      })
      .catch((err) => err);
  };

  render() {
    const { state } = this.props;
    return (
      <div className="col-sm-1 download-link-div">
        <CSVLink
          dataToggle="tooltip"
          title="Click to download state & code list"
          data={this.state.descList}
          download={`${state}_codes_description.csv`}
        >
          <FontAwesomeIcon icon={faFileDownload} color="" />
        </CSVLink>
      </div>
    );
  }
}

export default DownloadLink;
