import React, { Component } from "react";
import WcCalculatedFactors from "./WcCalculatedFactors";
import EppFactors from "./EppFactors";
import ProgramFactors from "./ProgramFactors";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import axios from "axios";
import { awsUrl2 } from "../../../config";
import LoadingOverlay from "react-loading-overlay";
import NumberFormat from "react-number-format";

const carrierMap = {
  carrier_a: "CHUBB",
  carrier_b: "FIC",
  carrier_o: "State Fund",
};
class Computation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wcData: {},
      governingData: {},
      stateDropDownList: [],
      classCodeDropDownList: [],
      loaderActive: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (
      JSON.stringify(prevProps.governingData) !==
      JSON.stringify(this.props.governingData)
    ) {
      this.setState({ governingData: this.props.governingData });
    }
  }

  componentWillMount() {
    let stateDropDownList = [],
      classCodeDropDownList = [];
    let governingData = this.props.governingData;

    if (governingData && governingData.naics) {
      axios
        .post(
          awsUrl2 + "/api/getStateCarrierListByNaics",
          JSON.stringify({ naics: governingData.naics })
        )
        .then((response) => {
          stateDropDownList.push(
            <option key={"mty-state"} value={""} className="text-align-center">
              {"Please Select"}
            </option>
          );
          for (let key in response.data) {
            stateDropDownList.push(
              <option
                key={key}
                value={response.data[key].state}
                className="text-align-center"
              >
                {`${response.data[key].state}`.toUpperCase()}
              </option>
            );
          }
        })
        .catch((error) => {
          console.log("error in API-getStateCarrierListByNaics: ", error);
          this.setState({});
        });

      axios
        .post(
          awsUrl2 + "/api/getStateCarrierListByNaics",
          JSON.stringify({
            naics: governingData.naics,
            state: governingData.state,
          })
        )
        .then((response) => {
          classCodeDropDownList.push(
            <option key={"mty-state"} value={""}>
              {"Please Select"}
            </option>
          );

          for (let key in response.data) {
            classCodeDropDownList.push(
              <option
                key={key}
                value={`${response.data[key].class_code}: ${response.data[key].description_lowercase}`}
              >
                {`${response.data[key].class_code}: ${response.data[key].description_lowercase}`}
              </option>
            );
          }
          this.props.updateGoverningData(governingData);
        })
        .catch((error) => {
          console.log("error in API-getStateCarrierListByNaics: ", error);
        });
    }
    this.setState({
      classCodeDropDownList,
      stateDropDownList,
      governingData,
    });
  }

  setWcData = (data, carrier) => {
    let { wcData } = JSON.parse(JSON.stringify(this.state));
    if (!(carrier in wcData)) {
      wcData[carrier] = {};
    }
    wcData[carrier] = data;
    wcData[carrier].governingState =
      wcData[carrier].governingState.toUpperCase();
    wcData[carrier]["e_vs_non-e"] = wcData[carrier]["e_vs_non-e"]
      .split("_")
      .join(" ");
    wcData[carrier].key = wcData[carrier].key.split("_").join(" ");
    wcData[carrier].total_margin = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 0,
    }).format(wcData[carrier].total_margin);

    wcData[carrier].compositeRateFactor =
      Math.round(Number(wcData[carrier].compositeRateFactor) * 10000) / 10000;

    wcData[carrier].total_margin_percentage =
      Math.round(Number(wcData[carrier].total_margin_percentage) * 10000) /
        100 +
      "" +
      "%";
    wcData[carrier].table = this.props.programFactorData.table;
    wcData[carrier].bin = this.props.programFactorData.bin;

    if (process.env.REACT_APP_MODE === "beta") {
      if (
        wcData[carrier]["e_vs_non-e"] &&
        wcData[carrier]["e_vs_non-e"].toLowerCase().indexOf("non")
      )
        wcData[carrier]["e_vs_non-e"] = "No";
      else wcData[carrier]["e_vs_non-e"] = "Yes";

      if (
        wcData[carrier].key &&
        wcData[carrier].key.toLowerCase().indexOf("non")
      )
        wcData[carrier].key = "No";
      else wcData[carrier].key = "Yes";
    } else {
      wcData[carrier].defaultCarrier =
        carrierMap[wcData[carrier].defaultCarrier];
    }

    //total_margin_percentage
    this.setState({ wcData });
  };

  handleOnChangeOfNaics = (event) => {
    if (!event.target.value || event.target.value.toString().trim() === "") {
      this.props.updateGoverningData({ naics: event.target.value });
    } else {
      this.props.updateGoverningData({ naics: event.target.value });
    }
  };

  handleOnBlurOfNaics = async (e) => {
    let naics = e.target.value;

    if (naics.toString().trim() === "") {
      this.setState({
        stateDropDownList: [],
        classCodeDropDownList: [],
      });
      return;
    }

    if (naics.toString().trim() !== (this.state.prevNaics || "").toString()) {
      this.setState({
        stateDropDownList: [],
        classCodeDropDownList: [],
        loaderActive: true,
      });
      await axios
        .post(
          awsUrl2 + "/api/getStateCarrierListByNaics",
          JSON.stringify({ naics })
        )
        .then((response) => {
          let stateDropDownList = [];
          stateDropDownList.push(
            <option key={"mty-state"} value={""} className="text-align-center">
              {"Please Select"}
            </option>
          );
          for (let key in response.data) {
            stateDropDownList.push(
              <option
                key={key}
                value={response.data[key].state}
                className="text-align-center"
              >
                {`${response.data[key].state}`.toUpperCase()}
              </option>
            );
          }
          this.setState({ stateDropDownList, loaderActive: false });
        })
        .catch((error) => {
          console.log("error in API-getStateCarrierListByNaics: ", error);
          this.setState({ loaderActive: false });
        });
    }
  };

  handleOnChangeOfStateDropDown = async (event) => {
    let { governingData } = this.state;
    let naics = governingData.naics;
    let state = event.target.value;

    this.setState({ loaderActive: true });

    governingData.state = state;
    governingData.classCode = undefined;
    governingData.desc = undefined;

    if (naics && state) {
      await axios
        .post(
          awsUrl2 + "/api/getStateCarrierListByNaics",
          JSON.stringify({ naics, state })
        )
        .then((response) => {
          let classCodeDropDownList = [];
          classCodeDropDownList.push(
            <option key={"mty-state"} value={""}>
              {"Please Select"}
            </option>
          );

          for (let key in response.data) {
            classCodeDropDownList.push(
              <option
                key={key}
                value={`${response.data[key].class_code}: ${response.data[key].description_lowercase}`}
              >
                {`${response.data[key].class_code}: ${response.data[key].description_lowercase}`}
              </option>
            );
          }
          this.setState({
            classCodeDropDownList,
            loaderActive: false,
          });
          this.props.updateGoverningData(governingData);
        })
        .catch((error) => {
          console.log("error in API-getStateCarrierListByNaics: ", error);
          this.setState({ loaderActive: false });
        });
    } else {
      this.setState({ loaderActive: false });
    }
  };

  handleOnChangeOfClassDropDown = (e) => {
    let { governingData } = this.state;
    let val = e.target.value;
    let [cc, dsc] = val.split(":");

    governingData.classCode = cc;
    governingData.desc = (dsc || "").trim();
    this.props.updateGoverningData(governingData);
  };

  render() {
    let {
      quoteTable,
      timestamp,
      uuid,
      carriers,
      currProspectData,
      objectForEpp,
      priceModifierDropDowns,
      programFactorData,
      setPriceModifierDropDowns,
      setProtectionLevelData,
      costEppInputValue,
      EppCostValuesHandler,
      EppChargeValuesHandler,
    } = this.props;

    let {
      wcData,
      governingData,
      classCodeDropDownList,
      stateDropDownList,
      loaderActive,
    } = this.state;

    let protectionLevel, pricingRiskFactor, costPEMPValue, ChargePEMPValue;

    if (priceModifierDropDowns) {
      protectionLevel = priceModifierDropDowns.protectionLevel;
      pricingRiskFactor = priceModifierDropDowns.pricingRiskFactor;
    }

    if (costEppInputValue) {
      costPEMPValue = costEppInputValue.costPEPM;
      ChargePEMPValue = costEppInputValue.chargePEPM;
    }

    console.log(costPEMPValue, ChargePEMPValue, "++++++++++++++++++");

    if (!pricingRiskFactor || pricingRiskFactor === "Please Select") {
      pricingRiskFactor = "-";
    }
    if (!protectionLevel || protectionLevel === "Please Select") {
      protectionLevel = "-";
    }

    if (wcData && programFactorData) {
      for (let carrier in wcData) {
        wcData[carrier].lossFundingFactor = programFactorData.lossFundingFactor;
      }
    }

    return (
      <div className="computation">
        <h4 className="mb-5">
          <b>Computation</b>
          <div style={{ backgroundColor: "black", marginTop: "-10px" }}>
            <hr />
          </div>
        </h4>
        <LoadingOverlay
          active={loaderActive}
          text={
            "Loading" +
            (governingData.state
              ? " class code details..."
              : " state details...")
          }
        >
          <Form className="section-form mb-4">
            <div className="row">
              <div className="col-md-4">
                <Form.Group controlId="NAICS">
                  <Form.Label className="mb-0">
                    <b>NAICS</b>
                  </Form.Label>
                  <input
                    id="naicsNumber"
                    type="number"
                    value={governingData.naics}
                    autoComplete="off"
                    className="form-control"
                    onChange={(e) => this.handleOnChangeOfNaics(e)}
                    onBlur={(e) => this.handleOnBlurOfNaics(e)}
                    onClick={(e) =>
                      this.setState({ prevNaics: e.target.value })
                    }
                    min="1"
                  />
                </Form.Group>
              </div>
              <div className="col-md-4">
                <Form.Group controlId="states-dropdown">
                  <Form.Label>
                    <b>States</b>
                  </Form.Label>
                  <select
                    id="State-dropdown"
                    className="form-control pink-arrow"
                    onChange={(e) => this.handleOnChangeOfStateDropDown(e)}
                    value={governingData.state}
                  >
                    {governingData.naics && stateDropDownList.length > 0
                      ? stateDropDownList
                      : []}
                  </select>
                </Form.Group>
              </div>
              <div className="col-md-4">
                <Form.Group controlId="classCode-dropdown">
                  <Form.Label>
                    <b>Class Code</b>
                  </Form.Label>
                  <select
                    id="classCode-dropdown"
                    className="form-control pink-arrow" // Add the pink-arrow class here
                    onChange={(e) => this.handleOnChangeOfClassDropDown(e)}
                    value={`${governingData.classCode}: ${governingData.desc}`}
                  >
                    {governingData.naics && classCodeDropDownList.length > 0
                      ? classCodeDropDownList
                      : []}
                  </select>
                </Form.Group>
              </div>
            </div>
          </Form>
        </LoadingOverlay>
        <div className="d-flex computation_table_responsive">
          <div className="w-100 d-flex flex-column justify-content-between mr-5">
            <div className="wc-factors w-100">
              {quoteTable &&
                timestamp &&
                uuid &&
                currProspectData &&
                carriers &&
                carriers.map((carrier, index) => (
                  <WcCalculatedFactors
                    data={wcData[carrier]}
                    setWcData={this.setWcData}
                    key={index}
                    timestamp={timestamp}
                    uuid={uuid}
                    display={quoteTable[carrier].state.selected}
                    carrier={carrier}
                    email={currProspectData.email}
                  />
                ))}
            </div>
            {quoteTable &&
              quoteTable["carrier_a"] &&
              quoteTable["carrier_a"].state.selected && (
                <div className="program-factors">
                  <ProgramFactors data={programFactorData} />
                </div>
              )}
          </div>
          <div className="w-100 d-flex flex-column">
            <div className="epp-factors">
              {objectForEpp &&
                objectForEpp.totalEmployees &&
                objectForEpp.totalPayroll &&
                objectForEpp.startDateWithExtensis && (
                  <EppFactors
                    totalEmployees={objectForEpp.totalEmployees}
                    totalPayroll={objectForEpp.totalPayroll}
                    startDateWithExtensis={objectForEpp.startDateWithExtensis}
                    pricingRiskFactor={pricingRiskFactor}
                    protectionLevel={protectionLevel}
                    setProtectionLevelData={setProtectionLevelData}
                    costEppInputValue={costEppInputValue}
                    EppCostValuesHandler={EppCostValuesHandler}
                    EppChargeValuesHandler={EppChargeValuesHandler}
                    costPEMPValue={costPEMPValue}
                    ChargePEMPValue={ChargePEMPValue}
                  />
                )}
            </div>

            <Form>
              <Form.Group controlId="pricingAndRiskAdjustment">
                <Row className="align-items-baseline">
                  <Col>
                    <Form.Label>
                      <b>EPP - Pricing Risk</b>
                    </Form.Label>
                    <select
                      value={pricingRiskFactor}
                      id="pricingRiskFactor"
                      type="text"
                      onChange={(e) =>
                        setPriceModifierDropDowns(
                          e.target.value,
                          "pricingRiskFactor"
                        )
                      }
                      autoComplete="off"
                      className="form-control pink-arrow"
                    >
                      <option key={1} value={undefined}>
                        Please Select
                      </option>
                      <option key={2} value={"super_preferred"}>
                        Super Preferred
                      </option>
                      <option key={3} value={"preferred"}>
                        Preferred
                      </option>
                      <option key={4} value={"standard"}>
                        Standard
                      </option>
                      <option key={5} value={"sub_standard"}>
                        Sub Standard
                      </option>
                    </select>
                  </Col>
                  <Col>
                    <Form.Label>
                      <b>EPP - Protection Level</b>
                    </Form.Label>
                    <select
                      value={protectionLevel}
                      id="protectionLevel"
                      type="text"
                      onChange={(e) =>
                        setPriceModifierDropDowns(
                          e.target.value,
                          "protectionLevel"
                        )
                      }
                      autoComplete="off"
                      className="form-control pink-arrow"
                    >
                      <option key={1} value={undefined}>
                        Please Select
                      </option>
                      <option key={2} value={"primary"}>
                        Primary
                      </option>
                      <option key={3} value={"secondary"}>
                        Secondary
                      </option>
                    </select>
                  </Col>
                </Row>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    );
  }
}

export default Computation;
