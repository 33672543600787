import moment from "moment";

export const createTimeStamp = (tempRow, timeComment) => {
  console.log(tempRow, "row");
  console.log(timeComment, "ggg");
  let formattedDateTime;
  let timestamp_ms;
  if (tempRow?.[timeComment]) {
    tempRow[`${timeComment}_download`] = "";

    if (tempRow?.uploadTimestamp) {
      timestamp_ms = tempRow?.uploadTimestamp;

      if (isNaN(timestamp_ms)) {
        formattedDateTime = "Invalid Date - Time";

        tempRow[`${timeComment}_download`] = formattedDateTime;
      } else {
        let dateData = moment(Number(timestamp_ms))
          .utcOffset("-0500")
          .format("M/D/YYYY - hh:mm A");
        tempRow[`${timeComment}_download`] = dateData;
      }
    }
  }
};
