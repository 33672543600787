import React, { Component } from "react";
import ReactJson from "react-json-view";
import axios from "axios";
import { awsUrl2 } from "../../../config";

class EppData extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  componentDidUpdate = (prevProps) => {
    if (this.props !== prevProps) {
      let {
        totalEmployees,
        totalPayroll,
        startDateWithExtensis,
        pricingRiskFactor,
        protectionLevel,
      } = this.props;

      let requestData = {
        employees: totalEmployees,
        payroll: totalPayroll,
        extensis_start_date: startDateWithExtensis,
      };

      if (pricingRiskFactor !== "-") {
        requestData.pricing_risk_factor = pricingRiskFactor;
      }
      if (protectionLevel !== "-") {
        requestData.protection_level = protectionLevel;
      }

      axios
        .post(awsUrl2 + "/api/eppCalculatedFactors", requestData)
        .then((response) => {
          this.setState({ data: response.data });
        })
        .catch((error) => {
          console.log("error while calling post method ", error);
        });
    }
  };

  render() {
    let { data } = this.state;
    return (
      <div>
        <h5>EPP data</h5>
        <ReactJson
          src={data}
          theme="monokai"
          name={false}
          collapsed={3}
          displayDataTypes={false}
          displayObjectSize={false}
          quotesOnKeys={false}
          sortKeys={true}
        />
      </div>
    );
  }
}

export default EppData;
