import React from "react";
import $ from "jquery";
import axios from "axios";
import { awsUrl, awsUrl2 } from "../config";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
import AWS from "aws-sdk";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
const numeral = require("numeral");
let myConfig = new AWS.Config();
myConfig.update({
  region: "us-east-1",
  credentials: {
    accessKeyId: "AKIAJB3XUTJTLJMI4BHA",
    secretAccessKey: "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",
  },
});
let monopolisticStates = ["nd", "oh", "wa", "wy"];

let validateHistoricalClaims = (historicalClaims, historicalClaimsCheck) => {
  let checkMap = {
    falseOne: false,
    trueMid: false,
    falseTwo: false,
  };

  let validHistoricalClaims;

  for (let year in historicalClaimsCheck) {
    if (historicalClaimsCheck[year] === false) {
      if (!checkMap.falseOne) {
        checkMap.falseOne = true;
      } else if (checkMap.trueMid) {
        checkMap.falseTwo = true;
      }
    } else {
      if (checkMap.falseOne) {
        checkMap.trueMid = true;
      }
    }
  }
  let tempFlag = !(checkMap.falseOne && checkMap.trueMid && checkMap.falseTwo);
  let consecutiveYearErrorFlag = !tempFlag;

  let historicalClaimsErrorStore = {};
  let keys = ["carrier", "claim", "annPre", "totInc", "totPaid", "totRes"];
  let emptyFieldFlag = false;
  for (let year in historicalClaimsCheck) {
    if (!historicalClaimsErrorStore[year]) {
      historicalClaimsErrorStore[year] = {};
    }
    for (let key of keys) {
      if (
        historicalClaims[year] &&
        historicalClaims[year][key] &&
        historicalClaims[year][key].trim() !== ""
      ) {
        historicalClaimsErrorStore[year][key] = false;
      } else {
        if (!historicalClaimsCheck[year]) {
          historicalClaimsErrorStore[year][key] = true;
          emptyFieldFlag = true;
        }
      }
    }
  }

  validHistoricalClaims = !consecutiveYearErrorFlag && !emptyFieldFlag;

  return {
    validHistoricalClaims,
    historicalClaimsError: consecutiveYearErrorFlag,
    historicalClaimsErrorStore,
  };
};

let checkEmodInfoError = (emodStatesData) => {
  let emodInfoError = false;
  for (let state in emodStatesData) {
    if (emodStatesData[state].response) {
      if (!emodStatesData[state].rate1) {
        emodInfoError = true;
        emodStatesData[state].rate1 = { value: "", error: true };
      } else if (
        !emodStatesData[state].rate1.value ||
        isNaN(emodStatesData[state].rate1.value)
      ) {
        emodInfoError = true;
        emodStatesData[state].rate1.error = true;
      }
      if (emodStatesData[state].displayMore) {
        if (!emodStatesData[state].rate2) {
          emodInfoError = true;
          emodStatesData[state].rate2 = { value: "", error: true };
        } else if (
          !emodStatesData[state].rate2.value ||
          isNaN(emodStatesData[state].rate2.value)
        ) {
          emodInfoError = true;
          emodStatesData[state].rate2.error = true;
        }
      }
    }
  }

  return { emodInfoError, emodStatesData };
};

let checkLocationInfoError = (childrenLoc) => {
  let locationInfoError = false;
  let locationKeys = ["state"];
  let classCodeKeys = ["classCodeDescription", "ft", "pt", "payroll"];
  let field;
  let index = 0;
  for (let addressBlockNo in childrenLoc) {
    locationKeys.forEach((fieldName) => {
      if (
        !childrenLoc[addressBlockNo][fieldName].value ||
        childrenLoc[addressBlockNo][fieldName].error
      ) {
        childrenLoc[addressBlockNo][fieldName].error = true;
        locationInfoError = true;
        if (!field) field = "officeLoc" + addressBlockNo;
      }
    });
    for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
      if (!childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["ft"]) {
        childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["ft"] = {};
        childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
          "ft"
        ].error = true;
        locationInfoError = true;
        if (!field) field = "officeLoc" + addressBlockNo;
      } else if (
        !childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["pt"]
      ) {
        childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["pt"] = {};
        childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
          "pt"
        ].error = true;
        locationInfoError = true;
        if (!field) field = "officeLoc" + addressBlockNo;
      } else if (
        !(
          Number(
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["ft"]
              .value
          ) ||
          Number(
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["pt"]
              .value
          )
        )
      ) {
        if (
          !childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["ft"]
            .value
        )
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
            "ft"
          ].error = true;
        if (
          !childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]["pt"]
            .value
        )
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
            "pt"
          ].error = true;
        locationInfoError = true;
        if (!field) field = "officeLoc" + addressBlockNo;
      } else {
        childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
          "ft"
        ].error = false;
        childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
          "pt"
        ].error = false;
      }
      classCodeKeys.forEach((fieldName) => {
        if (
          !(
            fieldName in
            childrenLoc[addressBlockNo].classCodesInfo[addressChildNo]
          )
        ) {
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
            fieldName
          ] = { error: true };
          locationInfoError = true;
          if (!field) field = "officeLoc" + addressBlockNo;
        } else if (
          !childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][fieldName]
            .value ||
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][fieldName]
            .error
        ) {
          childrenLoc[addressBlockNo].classCodesInfo[addressChildNo][
            fieldName
          ].error = true;
          if (!field) field = "officeLoc" + addressBlockNo;
          locationInfoError = true;
        }
      });
    }
    index++;
  }
  return { locationInfoError, childrenLoc, field };
};

let checkCompanyProfileError = (companyProfile) => {
  let dropDownInputList = ["wcProgram", "payroll_In_OH_ND_WA_WY"];
  let companyProfileError = false;
  for (let fieldName in companyProfile) {
    if (
      fieldName !== "emailId" &&
      fieldName !== "entityType" &&
      fieldName !== "fein" &&
      (!companyProfile[fieldName].value ||
        companyProfile[fieldName].error ||
        (dropDownInputList.includes(fieldName) &&
          companyProfile[fieldName].value === "Please Select"))
    ) {
      companyProfile[fieldName].error = true;
      companyProfileError = true;
    } else if (fieldName === "fein") {
      var value = companyProfile.fein.value
        ? companyProfile.fein.value.toString().trim()
        : "";
      value = value.split("-").join("");
      var len = value.length,
        dummystr = "000000000";

      let pattern = /^\d+$/;

      if (!pattern.test(value)) {
        companyProfile.fein.errMsg = "FEIN should be numeric";
        companyProfile.fein.error = true;
        companyProfileError = true;
      } else if (len > 0 && len < 9) {
        value = dummystr.slice(0, 9 - len) + "" + value;
        companyProfile.fein.value = value[0] + value[1] + "-" + value.slice(2);
        companyProfile.fein.errMsg = "";
      } else if (len > 9) {
        companyProfile.fein.errMsg = "FEIN should be 9 digits";
        companyProfile.fein.error = true;
        companyProfileError = true;
      } else if (len === 0) {
        companyProfile.fein.errMsg = "FEIN cannot be empty";
        companyProfile.fein.error = true;
        companyProfileError = true;
      } else {
        companyProfile.fein.value = value[0] + value[1] + "-" + value.slice(2);
        companyProfile.fein.errMsg = "";
      }
    }
  }
  return { companyProfileError, companyProfile };
};

export const validateAll = (
  childrenLoc,
  companyProfile,
  emodStatesData,
  historicalClaims,
  historicalClaimsCheck
) => {
  let checkCompanyProfile = checkCompanyProfileError(companyProfile);
  let companyProfileError = checkCompanyProfile.companyProfileError;
  companyProfile = checkCompanyProfile.companyProfile;

  let checkLocationInfo = checkLocationInfoError(childrenLoc);
  let locationInfoError = checkLocationInfo.locationInfoError;
  childrenLoc = checkLocationInfo.childrenLoc;
  let field = checkLocationInfo.field;

  let checkEmodInfo = checkEmodInfoError(childrenLoc);
  let emodInfoError = checkEmodInfo.emodInfoError;
  emodStatesData = checkEmodInfo.emodStatesData;

  let {
    validHistoricalClaims,
    historicalClaimsError,
    historicalClaimsErrorStore,
  } = validateHistoricalClaims(historicalClaims, historicalClaimsCheck);

  if (emodInfoError) {
    document.getElementById("emod-ques").scrollIntoView({ behavior: "smooth" });
  } else if (historicalClaimsError) {
    document
      .getElementById("claimTable")
      .scrollIntoView({ behavior: "smooth" });
  } else if (companyProfileError) {
    document
      .getElementById("compProfile")
      .scrollIntoView({ behavior: "smooth" });
  } else if (locationInfoError) {
    document.getElementById(field).scrollIntoView({ behavior: "smooth" });
  }

  let monoList = ["OH", "ND", "WA", "WY"];
  if (!companyProfileError && !locationInfoError) {
    let hasMono = false;
    for (let ke in childrenLoc) {
      if (monoList.includes(childrenLoc[ke].state.value)) {
        hasMono = true;
        break;
      }
    }
    if (hasMono) {
      if (
        !companyProfile.payroll_In_OH_ND_WA_WY ||
        !companyProfile.payroll_In_OH_ND_WA_WY.value ||
        companyProfile.payroll_In_OH_ND_WA_WY.value === "No"
      ) {
        companyProfileError = true;
        companyProfile.payroll_In_OH_ND_WA_WY.errMsg =
          "Monopolistic states are entered";
        companyProfile.payroll_In_OH_ND_WA_WY.error = true;
      }
    }
  }

  let flag =
    !companyProfileError &&
    !locationInfoError &&
    !emodInfoError &&
    validHistoricalClaims;

  let data = {
    childrenLoc,
    companyProfile,
    emodStatesData,
    historicalClaims,
    historicalClaimsCheck,
    historicalClaimsError,
    historicalClaimsErrorStore,
  };

  return { flag, data };
};

const createRowInOpportunityTable = async (dataBodyToSend) => {
  try {
    await axios
      .post(awsUrl2 + "/api/getExtensisOpportunityData", {
        opportunityID: dataBodyToSend.opportunity_id,
        effectiveDate: dataBodyToSend.effective_date,
      })
      .then(async (response) => {
        if (
          response.data.status === undefined ||
          response.data.status === null ||
          (response.data.status &&
            response.data.status !== "sent_to_salesforce")
        ) {
          await axios
            .post(
              awsUrl2 + "/api/putOrUpdateOpportunityData/continue/put",
              JSON.stringify(dataBodyToSend)
            )
            .then()
            .catch((error) => {
              console.log("error in putOrUpdateOpportunityData-API ", error);
            });
        }
      });
  } catch (error) {
    console.log("error in API ", error);
  }
};

export const updateRowInOpportunityTable = (dataBodyToSend, buttonType) => {
  return new Promise(async (resolve, reject) => {
    try {
      await axios
        .post(awsUrl2 + "/api/getExtensisOpportunityData", {
          opportunityID: dataBodyToSend.opportunity_id,
          effectiveDate: dataBodyToSend.effective_date,
        })
        .then(async (response) => {
          // console.log("++", response);
          // debugger;
          if (
            buttonType === "salesforce" ||
            response.data.status === undefined ||
            response.data.status === null ||
            (response.data.status &&
              response.data.status !== "sent_to_salesforce")
          ) {
            await axios
              .post(
                awsUrl2 +
                  `/api/putOrUpdateOpportunityData/${buttonType}/update`,
                JSON.stringify(dataBodyToSend)
              )
              .then()
              .catch((error) => {
                console.log("error in putOrUpdateOpportunityData-API ", error);
              });
          }
        });
      resolve();
    } catch (error) {
      console.log("error in API ", error);
      resolve(error);
    }
  });
};

let getClassCodePayrollMap = (childrenLoc) => {
  let classCodePayrollMap = {};
  for (let stateIndex in childrenLoc) {
    let state = childrenLoc[stateIndex].state.value.toLowerCase();
    if (!(state in classCodePayrollMap)) {
      classCodePayrollMap[state] = {};
    }
    for (let classCodeIndex in childrenLoc[stateIndex].classCodesInfo) {
      let classCodeDescription =
        childrenLoc[stateIndex].classCodesInfo[classCodeIndex]
          .classCodeDescription.value;
      let classCode = classCodeDescription.split(":")[0].trim();
      classCodePayrollMap[state][classCode] = numeral(
        childrenLoc[stateIndex].classCodesInfo[classCodeIndex].payroll.value
      ).value();
    }
  }

  return classCodePayrollMap;
};

let getFundRateMap = (
  compositeChargeRate,
  programManualRate,
  childrenLoc,
  data
) => {
  let fundRateMap = {};
  let premiumMap = {};
  let classCodePayrollMap = getClassCodePayrollMap(childrenLoc);
  // console.log("++pmr", programManualRate);
  // console.log("++cpm", classCodePayrollMap);
  // console.log("++ccr", compositeChargeRate);
  // console.log("++data", data);
  for (let location in data) {
    for (let carrier in data[location]) {
      let state = data[location][carrier].guaranteed.state;
      let manualRate = data[location][carrier].guaranteed.manual_rate_values;
      // for (let state in programManualRate) {
      if (!(state in premiumMap)) {
        premiumMap[state] = {
          manualPremium: 0,
          compositePremium: 0,
        };
      }
      for (let classCode in programManualRate[state]) {
        try {
          if (
            classCodePayrollMap[state] &&
            classCodePayrollMap[state][classCode]
          ) {
            let tempManualPremium = numeral(manualRate[classCode])
              .multiply(classCodePayrollMap[state][classCode])
              .divide(100)
              .value();
            let tempCompositePremium = numeral(
              compositeChargeRate[state][classCode]
            )
              .multiply(classCodePayrollMap[state][classCode])
              .divide(100)
              .value();
            premiumMap[state].manualPremium = numeral(
              premiumMap[state].manualPremium
            )
              .add(tempManualPremium)
              .value();
            premiumMap[state].compositePremium = numeral(
              premiumMap[state].compositePremium
            )
              .add(tempCompositePremium)
              .value();
          }
        } catch (error) {}
      }
    }
  }

  // console.log("++pm", premiumMap);
  // debugger;
  for (let state in premiumMap) {
    fundRateMap[state] = numeral(premiumMap[state].compositePremium)
      .divide(premiumMap[state].manualPremium)
      .value();

    if (!fundRateMap[state]) {
      fundRateMap[state] = 0;
    }
  }

  return fundRateMap;
};

let getExistingLocationNewCodeMap = (childrenLoc) => {
  let existingLocationNewCodeMap = {};
  for (let stateIndex in childrenLoc) {
    if (!childrenLoc[stateIndex].newLocation) {
      let state = childrenLoc[stateIndex].state.value.toLowerCase();
      existingLocationNewCodeMap[state] = [];
      for (let classCodeIndex in childrenLoc[stateIndex].classCodesInfo) {
        if (
          childrenLoc[stateIndex].classCodesInfo[classCodeIndex].newClassCode
        ) {
          let classCodeDescription =
            childrenLoc[stateIndex].classCodesInfo[classCodeIndex]
              .classCodeDescription.value;
          let classCode = classCodeDescription.split(":")[0].trim();
          existingLocationNewCodeMap[state].push(classCode);
        }
      }
    }
  }

  return existingLocationNewCodeMap;
};

let getClassCodeCompositeRateMatch = (data, selectedCarrier, childrenLoc) => {
  let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
  let compositeChargeRate = JSON.parse(
    sessionStorage.getItem("compositeChargeRate")
  );
  let programManualRate = JSON.parse(
    sessionStorage.getItem("programManualRate")
  );
  let compositeRateMatch;

  if (compositeChargeRate && programManualRate) {
    compositeRateMatch = { [selectedCarrier]: compositeChargeRate };
    let fundRateMap = getFundRateMap(
      compositeChargeRate,
      programManualRate,
      currProspect.childrenLoc,
      data
    );
    let existingLocationNewCodeMap = getExistingLocationNewCodeMap(childrenLoc);

    for (let location in data) {
      for (let carrier in data[location]) {
        let state = data[location][carrier].charge.state;
        for (let classCode in data[location][carrier].charge
          .manual_rate_values) {
          if (
            state in compositeChargeRate &&
            !(classCode in compositeChargeRate[state])
          ) {
            if (
              state in existingLocationNewCodeMap &&
              existingLocationNewCodeMap[state].includes(classCode)
            ) {
              let tempCompositeRate = numeral(fundRateMap[state])
                .multiply(
                  data[location][carrier].guaranteed.manual_rate_values[
                    classCode
                  ]
                )
                .value();
              // console.log(
              //   "++tcr",
              //   fundRateMap[state],
              //   data[location][carrier].guaranteed.manual_rate_values[
              //     classCode
              //   ],
              //   tempCompositeRate
              // );
              // debugger;
              compositeRateMatch[selectedCarrier][state][classCode] = (
                Math.round(tempCompositeRate * 10000) / 10000
              ).toString();
            }
          }
        }
      }
    }
  }

  return compositeRateMatch;
};

let getGuaranteedRates = (data, carrier) => {
  let fundRateMap = {};
  for (let location in data) {
    if (carrier in data[location]) {
      let guaranteedData = data[location][carrier].guaranteed;
      let {
        total_estimated_annual_premium,
        manual_premium_sum,
        manual_rate_values,
        state,
      } = guaranteedData;
      fundRateMap[state] = {};

      let stateFundRate = numeral(total_estimated_annual_premium)
        .divide(manual_premium_sum)
        .value();

      console.log(
        "++",
        total_estimated_annual_premium,
        manual_premium_sum,
        manual_rate_values,
        state,
        stateFundRate
      );

      for (let classCode in manual_rate_values) {
        let manualRate = manual_rate_values[classCode];
        let guaranteedRate = numeral(stateFundRate)
          .multiply(manualRate)
          .value();
        console.log("--", manualRate);
        fundRateMap[state][classCode] = guaranteedRate;
      }
    }
  }

  console.log("\nfrm", fundRateMap);
  return fundRateMap;
};

let extractQuoteKeys = (quoteData) => {
  let modifiedData = {};
  for (let place in quoteData) {
    if (!modifiedData[place]) modifiedData[place] = {};
    for (let carrier in quoteData[place]) {
      if (!modifiedData[place][carrier]) modifiedData[place][carrier] = {};
      for (let type in quoteData[place][carrier]) {
        let costType = quoteData[place][carrier][type];
        if (!modifiedData[place][carrier][type])
          modifiedData[place][carrier][type] = {};
        if (costType.eNonE) {
          modifiedData[place][carrier][type].eNonE = costType.eNonE;
        }
        if (costType.manual_premium_sum) {
          modifiedData[place][carrier][type].manual_premium_sum =
            costType.manual_premium_sum;
        }
        if (costType.total_estimated_annual_premium) {
          modifiedData[place][carrier][type].total_estimated_annual_premium =
            costType.total_estimated_annual_premium;
        }
        if (costType.programExpenseFactors) {
          modifiedData[place][carrier][type].programExpenseFactors =
            costType.programExpenseFactors;
        }
      }
    }
  }
  return modifiedData;
};

export async function submit(
  childrenLoc,
  companyProfile,
  emodStatesData,
  historicalClaims,
  historicalClaimsCheck,
  client_relation_class,
  questions,
  sdr_factor,
  flag,
  selectedCarrier = undefined,
  quoteDataParameter,
  rateMatchValues,
  priceModifierDropDowns,
  classCodeRateMatchData,
  classCodeCompositeRateMatchData,
  isOverallLevel,
  governingData,
  costEppInputValue,
  updatePopupData
) {
  let currProspect;
  let carrierUserData = [];
  let dynamoDbRuns = {};
  let stateFundStateList = ["ny", "nj", "pa"];
  let stateFundCheck = true;
  sessionStorage.removeItem("excludedClassCodes");
  sessionStorage.removeItem("deletedDataList");
  let isSalesforce = sessionStorage.getItem("isSalesforce") ? true : false;

  for (let stateIndex in childrenLoc) {
    if (
      !stateFundStateList.includes(
        childrenLoc[stateIndex].state.value.toLowerCase()
      )
    ) {
      stateFundCheck = false;
      break;
    }
  }

  let client;
  if (sessionStorage.getItem("renewalClient") === "true") {
    client = "renewal";
  } else if (sessionStorage.getItem("existingClient") === "true") {
    client = "existing";
  } else {
    client = "new";
  }

  let existingManualRateData = {};

  try {
    if (true) {
      let historicalClaimsList = [];
      for (let year in historicalClaims) {
        if (!historicalClaimsCheck[year]) {
          let objRow = {
            year: year,
            carrier: historicalClaims[year].carrier,
            annPre: historicalClaims[year].annPre,
            claim: historicalClaims[year].claim,
            totInc: historicalClaims[year].totInc,
            totPaid: historicalClaims[year].totPaid,
            totRes: historicalClaims[year].totRes,
          };
          historicalClaimsList.unshift(objRow);
        }
      }

      let lossFrequency;
      if (historicalClaimsList.length > 0) {
        let aggClaim = 0;
        let aggPremium = 0;
        for (let historicalClaim of historicalClaimsList) {
          let claim = numeral(historicalClaim.claim).value();
          let premium = numeral(historicalClaim.annPre).value();

          aggClaim = numeral(aggClaim).add(claim).value();
          aggPremium = numeral(aggPremium).add(premium).value();
        }

        if (aggPremium !== 0) {
          lossFrequency = numeral(aggClaim)
            .divide(numeral(aggPremium).divide(10000).value())
            .value();
        }
      }

      currProspect = {
        childrenLoc,
        companyProfile,
        emodStatesData,
        historicalClaims,
        historicalClaimsCheck,
        costEppInputValue,
      };
      sessionStorage.setItem("currProspect", JSON.stringify(currProspect));

      let dataBody = {
        common: {
          el_limits: "1000-1000-1000",
          deductible: 2000,
          date_from: moment
            .utc(companyProfile?.effectiveDate?.value)
            .format("YYYY-MM-DD"),
          date_to: moment
            .utc(companyProfile?.expiryDate?.value)
            .format("YYYY-MM-DD"),
          extensis_start_date: moment
            .utc(companyProfile.startDateWithExtensis.value)
            .format("YYYY-MM-DD"),
          emod: [],
          questions: questions,
          sr_carriers: {},
          client_type: "prospect",
          sdr_factor,
        },
        offices: [],
      };

      if (governingData) {
        dataBody.common.governingData = governingData;
      }
      if (costEppInputValue) {
        dataBody.common.costEppInputValue = costEppInputValue;
      }

      if (lossFrequency !== undefined) {
        dataBody.common.loss_frequency = lossFrequency;
      }

      if (flag === "second") {
        dataBody.common["client_relation_class"] = client_relation_class;
        sessionStorage.setItem("questions", JSON.stringify(questions));
      } else {
        sessionStorage.removeItem("questions");
      }

      if (
        isOverallLevel &&
        rateMatchValues &&
        rateMatchValues.excessRiskMarginFactor &&
        !isNaN(rateMatchValues.excessRiskMarginFactor)
      ) {
        dataBody.common.excess_risk_margin =
          rateMatchValues.excessRiskMarginFactor;
      } else if (!isOverallLevel) {
        dataBody.common.class_code_rate_match = classCodeRateMatchData;
      }

      for (let state in emodStatesData) {
        let emodObj = {};

        //check if the effective date1 is a valid moment object
        if (!moment.isMoment(emodStatesData[state].date1.value)) {
          emodStatesData[state].date1.value = moment.utc(
            emodStatesData[state].date1.value
          );
        }

        if (emodStatesData[state].response) {
          emodObj = {
            value_1: emodStatesData[state].rate1.value,
            effective_date_1: moment
              .utc(emodStatesData[state].date1.value)
              .format("YYYY-MM-DD"),
          };
          emodObj.stateList =
            state === "ncci" ? emodStatesData[state].stateList : [state];
          if (emodStatesData[state].displayMore) {
            if (!moment.isMoment(emodStatesData[state].date2.value)) {
              emodStatesData[state].date2.value = moment.utc(
                emodStatesData[state].date2.value
              );
            }

            emodObj.value_2 = emodStatesData[state].rate2.value;
            emodObj.effective_date_2 = moment
              .utc(emodStatesData[state].date2.value)
              .format("YYYY-MM-DD");
          }

          dataBody.common.emod.push(emodObj);
        }
      }
      let existingClient = sessionStorage.getItem("existingClient") === "true";
      let renewalClient = sessionStorage.getItem("renewalClient") === "true";
      let renewalConfirmed =
        sessionStorage.getItem("renewalConfirmed") === "true";
      let selectedCarrierSession = sessionStorage.getItem("selectedCarrier");

      let carrierEligBody = {};
      for (let addressBlockNo in childrenLoc) {
        if (
          monopolisticStates.includes(
            childrenLoc[addressBlockNo].state.value.toLowerCase()
          )
        )
          continue;
        let name = `line1_line2_${childrenLoc[addressBlockNo].cityName.value}_${childrenLoc[addressBlockNo].state.value}_${childrenLoc[addressBlockNo].zipCode.value}`;
        let officesObj = {
          name,
          state: childrenLoc[addressBlockNo].state.value.toLowerCase(),
          employees: [],
          carriers: [],
        };

        if (existingClient && selectedCarrierSession) {
          officesObj.carriers.push(selectedCarrierSession);
        } else {
          officesObj.carriers.push("carrier_b");
          officesObj.carriers.push("carrier_a");
          if (stateFundCheck) {
            officesObj.carriers.push("carrier_o");
          }
        }

        // send the state-specific policy effective date in the existing client flow for new states
        if (
          existingClient &&
          childrenLoc[addressBlockNo].effectiveDate &&
          childrenLoc[addressBlockNo].effectiveDate.value
        ) {
          officesObj.effective_date = moment
            .utc(childrenLoc[addressBlockNo].effectiveDate.value)
            .format("YYYY-MM-DD");
        }

        if (!(`${name}` in carrierEligBody)) {
          carrierEligBody[`${name}`] = [];
        }

        for (let addressChildNo in childrenLoc[addressBlockNo].classCodesInfo) {
          let classCodeDescArray =
            childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].classCodeDescription.value.split(":");

          let code = classCodeDescArray.shift();
          let desc = classCodeDescArray.join(":").trim();

          if (existingClient) {
            let obj =
              childrenLoc[addressBlockNo].classCodesInfo[addressChildNo];
            if (obj.newClassCode) {
              carrierEligBody[`${name}`].push({
                code,
                description: desc,
                state: childrenLoc[addressBlockNo].state.value.toLowerCase(),
              });
            }
          } else {
            carrierEligBody[`${name}`].push({
              code,
              description: desc,
              state: childrenLoc[addressBlockNo].state.value.toLowerCase(),
            });
          }

          let classCodeObj = {
            code,
            payroll: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].payroll.value
              .split("$")[1]
              .split(",")
              .join(""),
            ft: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].ft.value.trim(),
            pt: childrenLoc[addressBlockNo].classCodesInfo[
              addressChildNo
            ].pt.value.trim(),
            desc: desc.toLowerCase(),
          };
          officesObj.employees.push(classCodeObj);
        }
        dataBody.offices.push(officesObj);
      }

      $("#loader").css("display", "block");

      let teap = 0;
      let loggedInUserDetails;
      try {
        loggedInUserDetails = await Auth.currentAuthenticatedUser();
      } catch (error) {}

      axios
        .post(awsUrl + "/api/getCarrierElig", JSON.stringify(carrierEligBody))
        .then(async (res) => {
          let resp = res.data;
          let eligResponse = resp.eligibility;
          let eligibilityDynamoDbRuns = resp.dynamoDbRuns || {};
          let eligibilityMap = {};
          let deleteIndexMap = {};
          for (let location in eligResponse) {
            for (let carrier in eligResponse[location]) {
              if (
                !eligibilityMap[carrier] ||
                eligibilityMap[carrier] !== "no"
              ) {
                eligibilityMap[carrier] = eligResponse[location][carrier].value;
              }

              // create a map for all the excluded class codes
              if (existingClient) {
                if (eligibilityMap[carrier] === "no") {
                  eligibilityMap[carrier] = "yes";
                }
              }

              if (eligResponse[location][carrier].value === "no") {
                for (let locationIndex in dataBody.offices) {
                  if (
                    dataBody.offices[locationIndex].name === location &&
                    dataBody.offices[locationIndex].carriers.includes(carrier)
                  ) {
                    for (let excludedData of eligResponse[location][carrier]
                      .excludedList) {
                      if (!deleteIndexMap[locationIndex]) {
                        deleteIndexMap[locationIndex] = [];
                      }
                      for (let classCodeIndex in dataBody.offices[locationIndex]
                        .employees) {
                        if (
                          dataBody.offices[locationIndex].employees[
                            classCodeIndex
                          ].code === excludedData.class_code &&
                          dataBody.offices[locationIndex].employees[
                            classCodeIndex
                          ].desc === excludedData.description.toLowerCase()
                        ) {
                          if (
                            !deleteIndexMap[locationIndex].includes(
                              classCodeIndex
                            )
                          ) {
                            deleteIndexMap[locationIndex].push(classCodeIndex);
                          }
                          sessionStorage.setItem("excludedClassCodes", true);
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          // delete all the excluded class codes for existing flow. remove the complete state if all class codes are excluded
          let deletedDataList = [];

          let locationKeysList = Object.keys(deleteIndexMap);
          for (let locationIndex of locationKeysList.reverse()) {
            for (let classCodeIndex of deleteIndexMap[
              locationIndex
            ].reverse()) {
              deletedDataList.push(
                `${dataBody.offices[locationIndex].state.toUpperCase()}-${
                  dataBody.offices[locationIndex].employees[classCodeIndex].code
                }`
              );
              // if (existingClient) {
              //   dataBody.offices[locationIndex].employees.splice(
              //     classCodeIndex,
              //     1
              //   );
              //   try {
              //     delete childrenLoc[locationIndex].classCodesInfo[
              //       classCodeIndex
              //     ];
              //   } catch (error) {}
              // }
            }
            // if (existingClient) {
            //   if (dataBody.offices[locationIndex].employees.length === 0) {
            //     dataBody.offices.splice(locationIndex, 1);
            //     delete childrenLoc[locationIndex];
            //   }
            // }
          }

          // fix the indices of the childrenLoc object
          // let actualLocationIndex = 0;
          // for (let locationIndex in childrenLoc) {
          //   let actualClassCodeIndex = 0;
          //   for (let classCodeIndex in childrenLoc[locationIndex]
          //     .classCodesInfo) {
          //     if (classCodeIndex > actualClassCodeIndex) {
          //       childrenLoc[locationIndex].classCodesInfo[
          //         actualClassCodeIndex
          //       ] = childrenLoc[locationIndex].classCodesInfo[classCodeIndex];
          //       delete childrenLoc[locationIndex].classCodesInfo[
          //         classCodeIndex
          //       ];
          //     }
          //     actualClassCodeIndex++;
          //   }
          //   if (locationIndex > actualLocationIndex) {
          //     childrenLoc[actualLocationIndex] = childrenLoc[locationIndex];
          //     childrenLoc[actualLocationIndex].number = actualLocationIndex;
          //     delete childrenLoc[locationIndex];
          //   }
          //   actualLocationIndex++;
          // }

          if (deletedDataList.length > 0) {
            sessionStorage.setItem(
              "deletedDataList",
              JSON.stringify(deletedDataList)
            );

            // stop the function if declined class codes are present when the client is not existing
            // if (!existingClient) {
            //   $("#loader").css("display", "none");
            //   updatePopupData({
            //     show: true,
            //     title: "The following class codes have been excluded!",
            //     disableNoButton: true,
            //     children: (
            //       <>
            //         <br></br>
            //         <span style={{ fontWeight: "bold" }}>
            //           {deletedDataList.map((e) => (
            //             <>
            //               <div>{e}</div>
            //             </>
            //           ))}
            //         </span>
            //       </>
            //     ),
            //     acceptBtn: "OK",
            //     acceptBtnCallback: () => {
            //       console.log("okay");
            //     },
            //   });
            //   return;
            // }
          }
          const resID = await axios.post(
            awsUrl2 + `/api/getQuotesDataId`,
            JSON.stringify({ body: dataBody })
          );

          let id = resID.data.id;
          let fetchData = async (id) => {
            await axios
              .get(awsUrl2 + `/api/getQuotesData/${id}`)
              .then(async (res) => {
                if (res.data === "processing") {
                  const myTimeout = setTimeout(() => fetchData(id), 5000);
                } else if (res.data === "error") {
                  $("#loader").css("display", "none");
                  this.setState({
                    errorMessage: "Oops!! Timed Out. Please try again.",
                  });
                } else {
                  try {
                    let urlData = res.data;
                    let quoteDynamoDbRuns;

                    let urlResponse = await axios.get(urlData.url);
                    let data = urlResponse.data;

                    if (data) {
                      let prospectAddresses = {};

                      // create the prospect address object
                      for (let stateIndex in childrenLoc) {
                        prospectAddresses[stateIndex] = {
                          stateCode: childrenLoc[stateIndex].state.value,
                          addressId: childrenLoc[stateIndex].number,
                          classCodes: [],
                          newLocation: childrenLoc[stateIndex].newLocation,
                        };
                        for (let classCodeIndex in childrenLoc[stateIndex]
                          .classCodesInfo) {
                          let classCodeDescArray =
                            childrenLoc[stateIndex].classCodesInfo[
                              classCodeIndex
                            ].classCodeDescription.value.split(":");

                          let code = classCodeDescArray.shift();
                          let desc = classCodeDescArray.join(":").trim();
                          prospectAddresses[stateIndex].classCodes.push({
                            code,
                            payroll: numeral(
                              childrenLoc[stateIndex].classCodesInfo[
                                classCodeIndex
                              ].payroll.value
                            )
                              .value()
                              .toString(),
                            pt: childrenLoc[stateIndex].classCodesInfo[
                              classCodeIndex
                            ].pt.value.trim(),
                            ft: childrenLoc[stateIndex].classCodesInfo[
                              classCodeIndex
                            ].ft.value.trim(),
                            description: desc,
                            newClassCode:
                              childrenLoc[stateIndex].classCodesInfo[
                                classCodeIndex
                              ].newClassCode,
                          });
                        }
                      }

                      let currentDate = Math.floor(Date.now()).toString(),
                        uuid,
                        quoteData;
                      if (flag === "first") {
                        uuid = uuidv4();
                        quoteData = {
                          uuid,
                          date: currentDate,
                        };
                        sessionStorage.removeItem("dropDownData");
                        sessionStorage.setItem(
                          "quoteData",
                          JSON.stringify(quoteData)
                        );
                      } else {
                        quoteData = quoteDataParameter;
                        currentDate = quoteData["date"];
                        uuid = quoteData["uuid"];
                      }

                      let extensisId,
                        dateToStore = moment
                          .utc(new Date(Number(currentDate)))
                          .format("YYYYMMDD");

                      let ID_PREFIX =
                        process.env.REACT_APP_MODE === "beta"
                          ? "peo"
                          : "extensis";

                      if (
                        companyProfile.emailId.value === undefined ||
                        companyProfile.emailId.value === null ||
                        companyProfile.emailId.value.trim() === ""
                      ) {
                        companyProfile.emailId.value = `${ID_PREFIX}_${companyProfile.fein.value
                          .split("-")
                          .join("")}_${dateToStore}`;
                        sessionStorage.setItem(
                          "prevEmailId",
                          companyProfile.emailId.value
                        );
                        extensisId = `${ID_PREFIX}_${dateToStore}_${uuid}_NEW`;

                        let dataBodyToSend = {
                          emodStatesData,
                          historicalClaims,
                          historicalClaimsCheck,
                          payrollData: childrenLoc,
                          effective_date: moment
                            .utc(companyProfile.effectiveDate.value)
                            .format("YYYY-MM-DD"),
                          timestamp: currentDate,
                          companyProfile: companyProfile,
                          opportunity_id:
                            companyProfile.emailId.value.toLowerCase(),
                          status: "quote_generated",
                          modifiedBy: loggedInUserDetails.attributes.email,
                          modifiedByName: loggedInUserDetails.attributes.name,
                        };

                        await createRowInOpportunityTable(dataBodyToSend);
                      } else if (
                        companyProfile.emailId.value !== undefined &&
                        companyProfile.emailId.value !== null &&
                        companyProfile.emailId.value.trim() !== "" &&
                        flag === "first"
                      ) {
                        extensisId = `${ID_PREFIX}_${dateToStore}_${uuid}_NEW`;

                        let dataBodyToSend = {
                          emodStatesData,
                          historicalClaims,
                          historicalClaimsCheck,
                          payrollData: childrenLoc,
                          companyProfile: companyProfile,
                          opportunity_id:
                            companyProfile.emailId.value.toLowerCase(),
                          effective_date: moment
                            .utc(companyProfile.effectiveDate.value)
                            .format("YYYY-MM-DD"),
                          status: "quote_generated",
                          modifiedBy: loggedInUserDetails.attributes.email,
                          modifiedByName: loggedInUserDetails.attributes.name,
                        };

                        await updateRowInOpportunityTable(
                          dataBodyToSend,
                          "continue"
                        );
                      } else if (flag !== "first") {
                        let dataBodyToSend = {
                          opportunity_id:
                            companyProfile.emailId.value.toLowerCase(),
                          effective_date: moment
                            .utc(companyProfile.effectiveDate.value)
                            .format("YYYY-MM-DD"),
                          selectedCarrier,
                          modifiedBy: loggedInUserDetails.attributes.email,
                          modifiedByName: loggedInUserDetails.attributes.name,
                        };

                        extensisId = `${ID_PREFIX}_${dateToStore}_${uuid}_OLD`;
                        await updateRowInOpportunityTable(
                          dataBodyToSend,
                          "calculate"
                        );
                      }

                      companyProfile.emailId.value =
                        companyProfile.emailId.value;

                      currProspect = {
                        childrenLoc,
                        companyProfile,
                        emodStatesData,
                        historicalClaims,
                        historicalClaimsCheck,
                        costEppInputValue,
                      };
                      sessionStorage.setItem(
                        "currProspect",
                        JSON.stringify(currProspect)
                      );
                      let class_code_composite_rate_match, guaranteed_rates;

                      let visitTimestamp =
                        sessionStorage.getItem("visitTimestamp");
                      let sortKeyList = [];

                      let defaultCarrier;

                      if (
                        existingClient &&
                        selectedCarrierSession &&
                        flag === "first"
                      ) {
                        try {
                          guaranteed_rates = getGuaranteedRates(
                            data,
                            selectedCarrierSession
                          );
                        } catch (error) {
                          console.log("error in guaranteed rate", error);
                        }

                        try {
                          class_code_composite_rate_match =
                            getClassCodeCompositeRateMatch(
                              data,
                              selectedCarrierSession,
                              childrenLoc
                            );
                        } catch (error) {
                          console.log("error", error);
                        }
                      } else if (renewalClient && selectedCarrierSession) {
                        try {
                          class_code_composite_rate_match = {};
                          class_code_composite_rate_match[
                            selectedCarrierSession
                          ] = JSON.parse(
                            sessionStorage.getItem("compositeChargeRate")
                          );
                        } catch (error) {}
                      }

                      let programManualRate;
                      try {
                        programManualRate = JSON.parse(
                          sessionStorage.getItem("programManualRate")
                        );
                      } catch (error) {}

                      for (let place in data) {
                        if (!defaultCarrier) {
                          if (
                            renewalClient &&
                            selectedCarrierSession &&
                            data[place][selectedCarrierSession] &&
                            data[place][selectedCarrierSession] !== "Dropped"
                            // eligibilityMap[selectedCarrierSession] !== "no"
                          ) {
                            defaultCarrier = selectedCarrierSession;
                          } else {
                            let eNonEvalue;
                            try {
                              eNonEvalue =
                                data[place]["carrier_a"][
                                  "charge" || "cost" || "guaranteed"
                                ].eNonE;
                            } catch (e) {}
                            let wc = companyProfile.wcProgram.value;

                            if (
                              (!data[place]["carrier_a"] ||
                                data[place]["carrier_a"] === "Dropped") &&
                              // eligibilityMap["carrier_a"] === "no")
                              data[place]["carrier_b"] &&
                              data[place]["carrier_b"] !== "Dropped"
                              // eligibilityMap["carrier_b"] !== "no"
                            ) {
                              defaultCarrier = "carrier_b";
                            } else if (
                              data[place]["carrier_a"] &&
                              data[place]["carrier_a"] !== "Dropped" &&
                              (!data[place]["carrier_b"] ||
                                data[place]["carrier_b"] === "Dropped")
                              // eligibilityMap["carrier_b"] === "no"
                              // eligibilityMap["carrier_a"] !== "no"
                            ) {
                              defaultCarrier = "carrier_a";
                            } else if (
                              stateFundCheck &&
                              (data[place]["carrier_b"] === "Dropped" ||
                                !data[place]["carrier_b"]) &&
                              // eligibilityMap["carrier_b"] === "no")
                              (data[place]["carrier_a"] === "Dropped" ||
                                !data[place]["carrier_a"])
                              // eligibilityMap["carrier_a"] === "no")
                            ) {
                              defaultCarrier = "carrier_o";
                            } else if (
                              data[place]["carrier_a"] !== "Dropped" &&
                              data[place]["carrier_b"] !== "Dropped" &&
                              data[place]["carrier_b"] &&
                              data[place]["carrier_a"]
                              // eligibilityMap["carrier_a"] !== "no" &&
                              // eligibilityMap["carrier_b"] !== "no"
                            ) {
                              if (
                                (wc &&
                                  wc.includes("PEO") &&
                                  eNonEvalue === "non_e") ||
                                wc.includes("HRO")
                              ) {
                                defaultCarrier = "carrier_b";
                              } else if (
                                wc &&
                                wc.includes("PEO") &&
                                eNonEvalue === "e"
                              ) {
                                defaultCarrier = "carrier_a";
                              }
                            }
                          }
                        }
                        for (let carrier in data[place]) {
                          // if (eligibilityMap[carrier] === "no") {
                          //   data[place][carrier] = "Dropped";
                          // }
                          console.log(
                            "data[place][carrier]: ",
                            data[place][carrier]
                          );
                          if (data[place][carrier] !== "Dropped") {
                            // fetch the quote dynamoDbRuns map
                            for (let calculationType in data[place][carrier]) {
                              if (
                                data[place][carrier][calculationType]
                                  .dynamoDbRuns
                              ) {
                                quoteDynamoDbRuns =
                                  data[place][carrier][calculationType]
                                    .dynamoDbRuns;
                                break;
                              }
                            }

                            if (
                              existingClient &&
                              (flag === "first" || isOverallLevel) &&
                              programManualRate
                            ) {
                              data[place][
                                carrier
                              ].charge.class_code_rate_match =
                                programManualRate;
                            }
                            if (!existingManualRateData[carrier]) {
                              existingManualRateData[carrier] = {};
                            }
                            existingManualRateData[carrier][
                              data[place][carrier].charge.state
                            ] = data[place][carrier].charge.manual_rate_values;
                            let tempData = {
                              uuid_carrier: `${currentDate}@${uuid}_${carrier}+${place}`,
                              date: currentDate,
                              visitDate: visitTimestamp,
                              effective_date: moment
                                .utc(companyProfile?.effectiveDate?.value)
                                .format("YYYY-MM-DD"),
                              expiration_date: moment
                                .utc(companyProfile?.expiryDate?.value)
                                .format("YYYY-MM-DD"),
                              prospect_addresses: prospectAddresses,
                              user_email_id:
                                companyProfile.emailId.value.toLowerCase(),
                              company_name: companyProfile.companyName.value,
                              liability_limitId: "100/100/500",
                              uuid: uuid,
                              carrier: `${carrier}+${place}`,
                              carrier_email_id:
                                data[place][carrier].carrier_email,
                              carrier_location_data: data[place][carrier],
                              fein: companyProfile.fein.value,
                              year_business_established: moment
                                .utc(
                                  companyProfile.yearBusinessEstablished.value
                                )
                                .format("YYYY-MM-DD"),
                              start_date_with_extensis: moment
                                .utc(companyProfile.startDateWithExtensis.value)
                                .format("YYYY-MM-DD"),
                              entity_type: companyProfile.entityType.value,
                              wc_program: companyProfile.wcProgram.value,
                              payroll_In_OH_ND_WA_WY:
                                companyProfile.payroll_In_OH_ND_WA_WY.value,
                              currProspect,
                              questions,
                              extensisId,
                              selectedCarrier,
                              rateMatchValues,
                              defaultCarrier,
                              priceModifierDropDowns,
                              costEppInputValue,
                              modifiedBy: loggedInUserDetails.attributes.email,
                              modifiedByName:
                                loggedInUserDetails.attributes.name,
                              isOverallLevel:
                                isOverallLevel !== undefined &&
                                isOverallLevel !== null
                                  ? isOverallLevel
                                  : true,
                              client,
                            };
                            if (classCodeCompositeRateMatchData) {
                              try {
                                tempData.carrier_location_data.charge.class_code_composite_rate_match =
                                  classCodeCompositeRateMatchData;
                              } catch (error) {}
                            } else if (class_code_composite_rate_match) {
                              tempData.carrier_location_data.charge.class_code_composite_rate_match =
                                class_code_composite_rate_match;
                            }

                            if (existingClient) {
                              tempData["existing"] = "true";
                              if (guaranteed_rates) {
                                tempData.carrier_location_data.guaranteed.guaranteed_rates =
                                  guaranteed_rates;
                              }
                            }
                            if (isSalesforce) {
                              tempData.isSalesforce = "true";
                            }
                            sortKeyList.push(tempData.uuid_carrier);
                            carrierUserData.push(tempData);
                          }
                        }
                      }

                      dynamoDbRuns.quoteDynamoDbRuns = quoteDynamoDbRuns;
                      dynamoDbRuns.eligibilityDynamoDbRuns =
                        eligibilityDynamoDbRuns;

                      sessionStorage.setItem(
                        "sortKeyList",
                        JSON.stringify(sortKeyList)
                      );
                      // sessionStorage.setItem(
                      //   "carrierLocationData",
                      //   JSON.stringify(carrierUserData)
                      // );

                      let prevIndex = 0;
                      let nextIndex = 100;
                      let uploadData;
                      // if (flag === "first") {
                      for (
                        let i = 0;
                        i < Math.floor(carrierUserData.length / 100) + 1;
                        i++
                      ) {
                        uploadData = carrierUserData.slice(
                          prevIndex,
                          nextIndex
                        );
                        await axios
                          .post(awsUrl + "/api/postUsersData", uploadData)
                          .then(async (res) => {
                            let cost_rate = {},
                              manual_rate = {},
                              charge_program_class = {},
                              charge_rate = {};
                            await axios
                              .get(
                                awsUrl2 +
                                  `/api/getRateMatchData/${companyProfile.emailId.value}/${uuid}/${currentDate}`
                              )
                              .then((res) => {
                                let ratesData = res.data;
                                if (
                                  ratesData &&
                                  ratesData[selectedCarrierSession]
                                ) {
                                  for (let state in ratesData[
                                    selectedCarrierSession
                                  ]) {
                                    let {
                                      chargeCompositeRate,
                                      costCompositeRate,
                                      chargeManualRateValues,
                                      guaranteedManualRateValues,
                                      errData,
                                    } =
                                      ratesData[selectedCarrierSession][state];
                                    if (!(state in charge_rate)) {
                                      charge_rate[state] = {};
                                    }
                                    if (!(state in cost_rate)) {
                                      cost_rate[state] = {};
                                    }
                                    if (!(state in manual_rate)) {
                                      manual_rate[state] = {};
                                    }
                                    if (!(state in charge_program_class)) {
                                      charge_program_class[state] = {};
                                    }

                                    for (let classCode in errData) {
                                      if (!(classCode in charge_rate)) {
                                        charge_rate[state][classCode] = 0;
                                      }
                                      if (!(classCode in cost_rate)) {
                                        cost_rate[state][classCode] = 0;
                                      }
                                      if (!(classCode in manual_rate)) {
                                        manual_rate[state][classCode] = 0;
                                      }
                                      if (
                                        !(classCode in charge_program_class)
                                      ) {
                                        charge_program_class[state][
                                          classCode
                                        ] = 0;
                                      }

                                      let chargeRate =
                                        Math.round(
                                          chargeCompositeRate[classCode] * 10000
                                        ) / 10000;
                                      let costRate =
                                        Math.round(
                                          costCompositeRate[classCode] * 10000
                                        ) / 10000;
                                      let guaranteedManualRate =
                                        Math.round(
                                          guaranteedManualRateValues[
                                            classCode
                                          ] * 100
                                        ) / 100;
                                      let chargeManualRate =
                                        Math.round(
                                          chargeManualRateValues[classCode] *
                                            10000
                                        ) / 10000;

                                      charge_rate[state][classCode] =
                                        chargeRate.toFixed(4);
                                      cost_rate[state][classCode] =
                                        costRate.toFixed(4);
                                      manual_rate[state][classCode] =
                                        guaranteedManualRate.toFixed(2);
                                      charge_program_class[state][classCode] =
                                        chargeManualRate.toFixed(4);
                                    }
                                  }
                                }
                              })
                              .catch((error) => {
                                console.log("error in rates API", error);
                              });
                            if (renewalClient && !renewalConfirmed) {
                              let date;
                              let currProspect = JSON.parse(
                                sessionStorage.getItem("currProspect")
                              );
                              // let { composite, manual, calculatedComposite } =
                              //   this.createCompositeAndManualRateData();

                              try {
                                let preparedData = data;
                                date = currentDate;

                                preparedData = extractQuoteKeys(preparedData);

                                if (
                                  currProspect &&
                                  date &&
                                  uuid &&
                                  preparedData
                                ) {
                                  let email =
                                    currProspect.companyProfile.emailId.value.toLowerCase();
                                  let effectiveDate = moment
                                    .utc(
                                      currProspect.companyProfile.effectiveDate
                                        .value
                                    )
                                    .format("YYYY-MM-DD");

                                  let dataToSend = {
                                    client_id: email,
                                    effective_date: effectiveDate,
                                    quoteid: uuid,
                                    lastGenerated: date,
                                    quoteData: preparedData,
                                    status: "quote_generated",
                                    payrollData: childrenLoc,
                                    historicalClaims,
                                    historicalClaimsCheck,
                                    emodStatesData,
                                    listOfUuidCarrier: sortKeyList,
                                    charge_rate,
                                    cost_rate,
                                    manual_rate,
                                    charge_program_class,
                                    modifiedBy:
                                      loggedInUserDetails.attributes.email,
                                    modifiedByName:
                                      loggedInUserDetails.attributes.name,
                                    // composite_charge_rate: composite,
                                    // program_manual_rate: manual,
                                  };

                                  if (costEppInputValue) {
                                    dataToSend.charge_epp =
                                      "" +
                                      (costEppInputValue?.chargePEPM || "");
                                    dataToSend.cost_epp =
                                      "" + (costEppInputValue?.costPEPM || "");

                                    if (
                                      dataToSend.charge_epp &&
                                      dataToSend.charge_epp.indexOf("$") === -1
                                    ) {
                                      dataToSend.charge_epp =
                                        "$" + dataToSend.charge_epp;
                                    }

                                    if (
                                      dataToSend.cost_epp &&
                                      dataToSend.cost_epp.indexOf("$") === -1
                                    ) {
                                      dataToSend.cost_epp =
                                        "$" + dataToSend.cost_epp;
                                    }
                                  }

                                  let res = await axios.post(
                                    awsUrl2 + "/api/uploadRenewalData",
                                    dataToSend
                                  );
                                }
                              } catch (error) {
                                console.log("error: ", error);
                              }
                            } else if (existingClient) {
                              let date;
                              let currProspect = JSON.parse(
                                sessionStorage.getItem("currProspect")
                              );

                              try {
                                date = currentDate;
                                if (currProspect && date && uuid) {
                                  let email =
                                    currProspect.companyProfile.emailId.value.toLowerCase();
                                  let effectiveDate = moment
                                    .utc(
                                      currProspect.companyProfile.effectiveDate
                                        .value
                                    )
                                    .format("YYYY-MM-DD");

                                  let dataToSend = {
                                    client_id: email,
                                    effective_date: effectiveDate,
                                    payrollData: childrenLoc,
                                    companyProfile,
                                    historicalClaims,
                                    historicalClaimsCheck,
                                    emodStatesData,
                                    charge_rate,
                                    cost_rate,
                                    manual_rate,
                                    charge_program_class,
                                    modifiedBy:
                                      loggedInUserDetails.attributes.email,
                                    modifiedByName:
                                      loggedInUserDetails.attributes.name,
                                    status: "quote_generated",

                                    // composite_charge_rate: composite,
                                    // program_manual_rate: manual,
                                  };

                                  if (costEppInputValue) {
                                    dataToSend.charge_epp =
                                      "" +
                                      (costEppInputValue?.chargePEPM || "");
                                    dataToSend.cost_epp =
                                      "" + (costEppInputValue?.costPEPM || "");

                                    if (
                                      dataToSend.charge_epp &&
                                      dataToSend.charge_epp.indexOf("$") === -1
                                    ) {
                                      dataToSend.charge_epp =
                                        "$" + dataToSend.charge_epp;
                                    }

                                    if (
                                      dataToSend.cost_epp &&
                                      dataToSend.cost_epp.indexOf("$") === -1
                                    ) {
                                      dataToSend.cost_epp =
                                        "$" + dataToSend.cost_epp;
                                    }
                                  }

                                  if (
                                    selectedCarrierSession &&
                                    class_code_composite_rate_match &&
                                    class_code_composite_rate_match[
                                      selectedCarrierSession
                                    ]
                                  ) {
                                    dataToSend.composite_charge_rate =
                                      class_code_composite_rate_match[
                                        selectedCarrierSession
                                      ];
                                  }

                                  if (
                                    selectedCarrierSession &&
                                    existingManualRateData &&
                                    existingManualRateData[
                                      selectedCarrierSession
                                    ]
                                  ) {
                                    for (let state in existingManualRateData[
                                      selectedCarrierSession
                                    ]) {
                                      if (!(state in programManualRate)) {
                                        programManualRate[state] = {};
                                      }
                                      for (let classCode in existingManualRateData[
                                        selectedCarrierSession
                                      ][state]) {
                                        if (
                                          !(
                                            classCode in
                                            programManualRate[state]
                                          )
                                        ) {
                                          programManualRate[state][classCode] =
                                            existingManualRateData[
                                              selectedCarrierSession
                                            ][state][classCode];
                                        }
                                      }
                                    }
                                    dataToSend.program_manual_rate =
                                      programManualRate;
                                  }

                                  if (guaranteed_rates) {
                                    dataToSend.guaranteed_rates =
                                      guaranteed_rates;
                                  }

                                  let res = await axios.post(
                                    awsUrl2 + "/api/uploadExistingData",
                                    dataToSend
                                  );
                                }
                              } catch (error) {
                                console.log("error: ", error);
                              }
                            }

                            // update the api tracking table
                            let apiTrackingParams = {
                              client_id:
                                currProspect.companyProfile.emailId.value.toLowerCase(),
                              category:
                                flag === "first" ? "company_profile" : "apc",
                              email: loggedInUserDetails.attributes.email,
                              name: loggedInUserDetails.attributes.name,
                              button:
                                flag === "first" ? "continue" : "calculate",
                              api_count: 6,
                              client_type: client,
                              dynamo_db_runs: dynamoDbRuns,
                            };

                            try {
                              await axios.post(
                                awsUrl2 + "/api/putApiTrackingData",
                                apiTrackingParams
                              );
                            } catch (error) {
                              console.log("error in api tracking API");
                            }

                            sessionStorage.setItem("formStage", "two");
                            window.location.reload();
                          })
                          .catch((error) => {
                            // if (flag === "first") {
                            this.setState({
                              errorMessage:
                                "Oops!! Timed Out. Please try again",
                            });
                            // }
                            console.log("error in posting data", error);
                          })
                          .finally(() => {
                            // sessionStorage.setItem("teap", teap);
                            $("#loader").css("display", "none");
                          });
                      }
                      // } else {
                      //   console.log("second time");
                      //   sessionStorage.setItem("formStage", "two");
                      //   window.location.reload();
                      //   $("#loader").css("display", "none");
                      // }
                    }
                  } catch (error) {
                    this.setState({
                      errorMessage: "Oops!! Timed Out. Please try again.",
                    });
                    // }
                    console.log("error", error);
                    sessionStorage.setItem("teap", teap);
                    $("#loader").css("display", "none");
                  }
                }
              })
              .catch((error) => {
                console.log("error", error);
              });
          };

          fetchData(id);
        })
        .catch((error) => {
          // if (flag === "first") {
          this.setState({
            errorMessage:
              "Oops!! An error occured while calculating your quote. Please try again.",
          });
          // }
          console.log("error", error);
          sessionStorage.setItem("teap", teap);
          $("#loader").css("display", "none");
          //window.location.reload();
        });
    }
  } catch (error) {
    console.log("error", error);
  }
}
