import React, { Component } from 'react';
import Navbar from '../common/Navbar';
import {showErrAlert} from '../../utils/common';
import Footer from '../common/Footer';
import TablesList from '../common/TablesList';
import FileUpload from '../common/FileUpload';
import MyGrid from '../common/Grid';
import { equal } from 'assert';
import Modal from 'react-awesome-modal';
import {localURL, awsUrl} from '../../config';
import axios from 'axios';
import Loader from '../common/Loader';
var rowsL = 0;
class dataUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileData: '',
      rowsData: '',
      fileName:'',
      isEqual: true,
      responseData:'',
      responceHead:'',
      visible : false
    }
  this.getData = this.getData.bind(this);
  this.getRowsLength = this.getRowsLength.bind(this);
  this.getResponseOfAPI = this.getResponseOfAPI.bind(this);
  // this.confirData = this.confirData.bind(this);
  this.closeModal = this.closeModal.bind(this);
  }
  getData=(val)=>{
    this.setState({fileData: val});
    var fileData = this.state.fileData;
    var rowsData = this.state.rowsData;
    var equalValue = true;

    if(Object.keys(this.state.fileData[0]).length === this.state.rowsData.length){
        for(var key in this.state.fileData[0]){
          if(key !== "carrier"){
          if(!this.state.rowsData.includes(key.toLocaleLowerCase())){
           equalValue = false;
          }
        }
      }
    }else{
      equalValue = false;
    }
    this.setState({
      isEqual: equalValue
    })
  }
  getRowsLength=(val, fileName)=>{
    this.setState({rowsData: val,
                    fileName: fileName
    })
  }
 getResponseOfAPI=(res)=>{
   console.log("responce:", res);
   this.setState({responseData: res['data']});
   if(this.state.responseData === "Successfulley updated"){
    this.setState({
      visible : true
      })
    }
  }
  closeModal() {
    this.setState({
        visible : false
    });
    window.location.reload();
  }

 
  render() {
     return (
       
       <div className = "dataupload-head" >
        <Navbar/>
        {/* <div id='contentHolder'> */}
          <div id = "dataupload-body">
            <TablesList rowsLength = {this.getRowsLength}/>
            { this.state.rowsData.length > 0 &&
            <FileUpload sendData = {this.getData} fileName = {this.state.fileName}/> }
            {console.log("values in parent class:", this.state.fileData )}
            {this.state.rowsData.length > 0 && this.state.fileData !== "" &&
            <MyGrid
            data = {this.state.fileData}
            fileName={this.state.fileName}
            isEqual = {this.state.isEqual}
            columns = {this.state.rowsData}
            confirData = {this.confirData}
            />
            }
          </div>
          {/* <div className='row'>
                  <div className='col-xs-12 text-center'>
                    {this.state.rowsData.length > 0 && this.state.fileData !== "" && this.state.isEqual &&
                      <button type='button' onClick={this.confirData} className='btnGen btnSubmits'>Confirm</button>
                    }
                    {
                      this.state.rowsData.length > 0 && this.state.fileData !== "" && !this.state.isEqual &&
                      <p>Warning: Please be sure your selected table and uploaded table both are same?</p>
                    }
                  </div>
            </div> */}
            {/* <section>
                  <Modal visible={this.state.visible} width="300" height="200" effect="fadeInDown" onClickAway={() => this.closeModal()}>
                      <div id = "successPopUp">
                          <h1 id = "succText">{this.state.responceHead}</h1>
                          <p id = "succData">{this.state.responseData}</p>
                          <button type='button' onClick={this.closeModal} className='btnGen btnClose'>Close</button>
                      </div>
                  </Modal>
              </section> */}  
          <Footer/>
          <Loader/>
        {/* </div> */}
      </div>
    );
  }
}

export default dataUpload;
