import React, { Component } from "react";
import Datepick from "../../common/Datepick";
import { Form } from "react-bootstrap";
import stateMap from "../../../utils/state_map.json";

class Addques extends Component {
  constructor(props) {
    super(props);
    this.state = {
      questionToggle: [],
      displayMoreClicked: this.props.displayMoreClicked,
      quesListAdd: this.props.quesListAdd,
      emodError: this.props.emodError,
    };
  }

  componentDidMount() {
    const {
      setAddUWQues,
      handleDisplayMoreClicked,
      appendAddQuesList,
    } = this.props;
    let { questionToggle } = this.state;
    let obj = JSON.parse(sessionStorage.getItem("currProspect"));
    if (obj && obj.expModStates) {
      let expModStates = obj.expModStates;
      let questionList = [];
      let questionNumber = 29;
      if (
        [...expModStates.ncciStates, ...expModStates.actingNcciStates].length >
        0
      ) {
        let stateListCaps = [];
        [...expModStates.ncciStates, ...expModStates.actingNcciStates].forEach(
          (state) => {
            stateListCaps.push(state.toUpperCase());
          }
        );
        questionList.push({
          name: `q${questionNumber}`,
          ques: `Do you have an experience rating for NCCI states (${stateListCaps}) ? If yes, enter value and effective date of this value.`,
          response: false,
          type: "date",
          rates: {},
          stateList: [
            ...expModStates.ncciStates,
            ...expModStates.actingNcciStates,
          ],
        });
      }

      expModStates.indBureauStates.forEach((state) => {
        questionNumber++;
        questionList.push({
          name: `q${questionNumber}`,
          ques: `Do you have an experience rating for the state of ${
            stateMap[state.toUpperCase()]
          } ? If yes, enter value and effective date of this value.`,
          response: false,
          type: "date",
          rates: {},
          stateList: [state],
        });
      });

      appendAddQuesList(3, questionList);
    }

    if (obj) {
      if (obj.uwQues) {
        setAddUWQues(obj.uwQues);
      }
    }

    var quesObj = [];
    try {
      quesObj = obj.uwQues;
    } catch (error) {}

    if (quesObj) {
      for (let i = 28; i < quesObj.length; i++) {
        if (quesObj[i].resp === "Y") {
          questionToggle[i - 28] = true;
          this.setState({ questionToggle });
          if (quesObj[i].rate2) {
            handleDisplayMoreClicked(i - 28, true);
          }
        }
      }
    }
  }

  componentDidUpdate() {
    if (this.state.quesListAdd !== this.props.quesListAdd) {
      this.setState({ quesListAdd: this.props.quesListAdd });
    }
    if (this.state.displayMoreClicked !== this.props.displayMoreClicked) {
      this.setState({ displayMoreClicked: this.props.displayMoreClicked });
    }
    if (this.state.emodError !== this.props.emodError) {
      this.setState({ emodError: this.props.emodError });
    }
  }

  createTable = () => {
    const { quesListAdd } = this.state;
    const { updateAddQuesList } = this.props;
    let table = [];

    // Outer loop to create parent
    for (let i = 0; i < 3; i++) {
      table.push(
        <tr key={i}>
          <td className="tableQues">{quesListAdd[i].ques}</td>
          <td className="tableAns">
            <Form.Check
              inline
              id={`inputY-${i + 25}`}
              value="Y"
              name={quesListAdd[i].name}
              checked={quesListAdd[i].response}
              label="Yes"
              type="radio"
              onChange={(e) => updateAddQuesList(e, "response", true)}
            />
            <Form.Check
              inline
              id={`inputN-${i + 25}`}
              value="N"
              name={quesListAdd[i].name}
              checked={!quesListAdd[i].response}
              label="No"
              type="radio"
              onChange={(e) => updateAddQuesList(e, "response", false)}
            />
          </td>
          <td className="tableRemarks">
            <input
              id={`remarks-${i + 25}`}
              type="text"
              className={
                !quesListAdd[i].response ? "hidden ques-input" : "ques-input"
              }
              value={
                quesListAdd[i].remarks && quesListAdd[i].remarks !== "none"
                  ? quesListAdd[i].remarks
                  : ""
              }
              disabled={!quesListAdd[i].response}
              onChange={(e) => updateAddQuesList(e, "remarks", e.target.value)}
            />
          </td>
        </tr>
      );
    }
    return table;
  };

  createEmodQuestions = () => {
    const {
      questionToggle,
      displayMoreClicked,
      quesListAdd,
      emodError,
    } = this.state;
    const {
      updateAddQuesList,
      updateEmodRates,
      handleDisplayMoreClicked,
      updateEmodError,
      date,
    } = this.props;

    let questions = [];
    let idNumber = 28;

    for (let i = 3; i < quesListAdd.length; i++) {
      questions.push(
        <tr>
          <td className="tableQues">{quesListAdd[i].ques}</td>
          <td className="tableAns">
            <Form.Check
              inline
              id={`inputY-${idNumber}`}
              value="Y"
              name={quesListAdd[i].name}
              checked={quesListAdd[i].response}
              label="Yes"
              type="radio"
              onChange={(e) => {
                questionToggle[i - 3] = true;
                this.setState({ questionToggle });
                updateAddQuesList(e, "response", true);
              }}
            />
            <Form.Check
              inline
              id={`inputN-${idNumber}`}
              value="N"
              name={quesListAdd[i].name}
              checked={!quesListAdd[i].response}
              label="No"
              type="radio"
              onChange={(e) => {
                questionToggle[i - 3] = false;
                this.setState({ questionToggle });
                updateAddQuesList(e, "response", false);
              }}
            />
          </td>
          <td className="tableRemarks">
            <input
              id="rate1"
              className={
                !quesListAdd[i].response ? "hidden ques-input" : "ques-input"
              }
              type="text"
              style={{
                borderColor: emodError[i] && emodError[i].rate ? "red" : "",
              }}
              onChange={(e) => {
                updateEmodRates(i, e.target.value, "rate1");
                updateEmodError(i, "rate", false);
              }}
              value={
                quesListAdd[i].rates && quesListAdd[i].rates.rate1
                  ? quesListAdd[i].rates.rate1
                  : ""
              }
              disabled={quesListAdd[i].default === "N"}
              placeholder="Enter rate"
            />
            {questionToggle[i - 3] && (
              <div>
                <div
                  id="dateRate1"
                  style={{
                    borderColor: emodError[i] && emodError[i].date ? "red" : "",
                  }}
                  className="ques-input emodDate"
                >
                  <Datepick
                    questionNumber={i}
                    dateNo={"date1"}
                    date={quesListAdd[i].rates.date1}
                    updateEmodRates={updateEmodRates}
                    updateEmodError={updateEmodError}
                  />
                </div>
                {!displayMoreClicked[i - 3] && (
                  <div className="mt-2">
                    <a
                      id="addInput"
                      className="ques-input"
                      onClick={() => {
                        handleDisplayMoreClicked(i - 3, true);
                      }}
                    >
                      <img
                        src={require("../../../images/plus4.png")}
                        alt="add"
                      />
                    </a>
                  </div>
                )}
                {displayMoreClicked[i - 3] && (
                  <div id="extraInput">
                    <input
                      id="rate2"
                      className="ques-input1"
                      type="text"
                      placeholder="Enter rate"
                      onChange={(e) =>
                        updateEmodRates(i, e.target.value, "rate2")
                      }
                      value={
                        quesListAdd[i].rates && quesListAdd[i].rates.rate2
                          ? quesListAdd[i].rates.rate2
                          : ""
                      }
                    />
                    <div id="dateRate2" className="ques-input1 emodDate">
                      <Datepick
                        questionNumber={i}
                        dateNo={"date2"}
                        date={quesListAdd[i].rates.date2}
                        updateEmodRates={updateEmodRates}
                        updateEmodError={updateEmodError}
                      />
                    </div>
                    <div className="mt-2">
                      <a
                        id="addInput"
                        className="ques-input"
                        onClick={() => {
                          handleDisplayMoreClicked(i - 3, false);
                        }}
                      >
                        <img
                          src={require("../../../images/delete4.png")}
                          alt="remove"
                        />
                      </a>
                    </div>
                  </div>
                )}
              </div>
            )}
          </td>
        </tr>
      );
      idNumber++;
    }

    return questions;
  };

  render() {
    return (
      <table className="table table-striped table-borderless table-addnques">
        <thead>
          <tr>
            <th className="tableQues">Questions</th>
            <th className="tableAns">Response</th>
            <th className="tableRem ">Remarks</th>
          </tr>
        </thead>
        <br />
        <tbody>
          {this.createTable()}
          {this.createEmodQuestions()}
        </tbody>
      </table>
    );
  }
}
export default Addques;
