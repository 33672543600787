import React, { Component, forwardRef } from "react";
import $ from "jquery";
import axios from "axios";
import { awsUrl, awsUrl2 } from "../../config";
import Clamp from "react-multiline-clamp";
import { Auth } from "aws-amplify";
import moment from "moment";
import numeral from "numeral";
import MaterialTable, { MTableToolbar } from "material-table";
import { TablePagination } from "@material-ui/core";
import ArrowDownward from "@material-ui/icons/ArrowDownward";
import Search from "@material-ui/icons/Search";
import Clear from "@material-ui/icons/Clear";
import Delete from "@material-ui/icons/Delete";
import FirstPage from "@material-ui/icons/FirstPage";
import LastPage from "@material-ui/icons/LastPage";
import ChevronLeft from "@material-ui/icons/ChevronLeft";
import ChevronRight from "@material-ui/icons/ChevronRight";
import Check from "@material-ui/icons/Check";
import { Link } from "react-router-dom";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import { Container, Row, Col } from "react-bootstrap";

const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  Delete: forwardRef((props, ref) => <Delete {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
};

// const carrierMap = {
//   carrier_a: "CHUBB",
//   carrier_b: "FIC",
//   carrier_o: "State Fund",
// };

// const reverseCarrierMap = {
//   chubb: "carrier_a",
//   fic: "carrier_b",
//   "state fund": "carrier_o",
// };

let selectedRow = [];

const handleClick = (rows) => {
  selectedRow = rows;
};

class DashboardExtensis extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
    };
  }

  componentDidMount = () => {
    sessionStorage.clear();
    let { tabName } = this.props;
    if (tabName === "tab1") {
      this.updateNewDisplay();
    }
  };

  camelCase = (str) => {
    return str
      .trim()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  setTableData = () => {
    let { rowDataList, isLoading } = JSON.parse(JSON.stringify(this.state));
    let { tabName } = this.props;

    var rowCount = 25;

    var rowArray = [25, 50, 75, 100];

    var rowLength = rowDataList ? rowDataList.length : 25;

    if (rowLength < 25) {
      rowCount = rowLength;

      rowArray = [rowCount, ...rowArray];
    }

    let columns = {
      tab1: [
        {
          title: "Button",
          field: "button",
          defaultSort: "desc",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "80px",
            maxWidth: "80px",
          },
        },
        {
          title: "Stage",
          field: "stage",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "80px",
            maxWidth: "80px",
          },
        },
        {
          title: "Client Type",
          field: "clientType",
          cellStyle: {
            padding: "6px 15px 6px 10px",
          },
        },
        {
          title: "# Clicks",
          field: "clicks",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "80px",
          },
        },
        {
          title: "API Count",
          field: "apiCount",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "80px",
          },
        },
        {
          title: "Cost",
          field: "dynamoReadCost",
          cellStyle: {
            padding: "6px 15px 6px 10px",
            minWidth: "80px",
          },
          render: (rowData) => {
            return rowData.dynamoReadCost.toLocaleString("en-US", {
              style: "currency",
              currency: "USD",
              maximumFractionDigits: 20,
            });
          },
        },
      ],
    };

    return (
      <MaterialTable
        isLoading={isLoading}
        icons={tableIcons}
        data={rowDataList}
        columns={columns[tabName]}
        onSelectionChange={(rows) => {
          handleClick(rows);
        }}
        options={{
          paginationPosition: "both",
          padding: "dense",
          showTitle: false,
          thirdSortClick: false,
          pageSize: rowCount,
          pageSizeOptions: rowArray,
          headerStyle: {
            fontSize: "12px",
            backgroundColor: "#003764",
            color: "#FFF",
            padding: tabName !== "tab2" ? "6px 15px 6px 10px" : "",
          },
          rowStyle: {
            fontSize: "12px",
            textTransform: "capitalize",
          },
          searchFieldStyle: {
            fontSize: "12px",
          },
          selection: tabName === "tab2" ? true : false,
        }}
        components={{
          Toolbar: (props) => (
            <div>
              <MTableToolbar {...props} />
            </div>
          ),
          Pagination: (props) => (
            <TablePagination
              {...props}
              labelRowsPerPage={
                <span style={{ fontSize: 12 }}>{props.labelRowsPerPage}</span>
              }
              labelDisplayedRows={(row) => (
                <span style={{ fontSize: 12 }}>
                  {props.labelDisplayedRows(row)}
                </span>
              )}
              SelectProps={{
                style: {
                  fontSize: 12,
                },
              }}
            />
          ),
        }}
      />
    );
  };

  firstCaps = (str) => {
    return str ? str[0].toUpperCase() + "" + str.slice(1).toLowerCase() : "";
  };

  updateNewDisplay = () => {
    axios
      .get(awsUrl2 + "/api/getApiTrackingData")
      .then((res) => {
        let dataFromAPI = res.data;

        this.setState({ rowDataList: dataFromAPI });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  };

  render() {
    let { selected, tabName } = this.props;
    let { dashboardTotal, rowDataList } = this.state;
    console.log(dashboardTotal);
    return (
      <div
        className="Extensis-Dashboard"
        style={
          tabName !== selected ? { display: "none", fontSize: "12px" } : {}
        }
      >
        <div>
          {rowDataList ? (
            this.setTableData()
          ) : (
            <div className="d-flex justify-content-center align-items-center h-100 w-100">
              <div className="blink_me" style={{ fontSize: "35px" }}>
                {" "}
                Loading Tables...{" "}
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}
export default DashboardExtensis;

// const handleClick = (rows) => {
//   selectedRow = rows;
// };

// class DashboardExtensis extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       isLoading: true,
//       selectedRow: [],
//       rowDataList: [],
//       pageSize: 25,
//       currentPage: 1,
//       sortColumn: null,
//       sortDirection: "asc",
//       searchInput: "",
//       hoveredColumn: null,
//     };
//   }

//   componentDidMount = () => {
//     sessionStorage.clear();
//     let { tabName } = this.props;
//     if (tabName === "tab1") {
//       this.updateNewDisplay();
//     }
//   };

//   camelCase = (str) => {
//     return str
//       .trim()
//       .split(" ")
//       .map((word) => {
//         return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
//       })
//       .join(" ");
//   };

//   handleSort = (columnName) => {
//     const { sortColumn, sortDirection } = this.state;
//     if (sortColumn === columnName) {
//       this.setState({
//         sortDirection: sortDirection === "asc" ? "desc" : "asc",
//       });
//     } else {
//       this.setState({ sortColumn: columnName, sortDirection: "asc" });
//     }
//   };

//   handlePageSizeChange = (e) => {
//     this.setState({ pageSize: parseInt(e.target.value), currentPage: 1 });
//   };

//   handlePageChange = (page) => {
//     this.setState({ currentPage: page });
//   };

//   handleSearch = (e) => {
//     this.setState({ searchInput: e.target.value });
//   };

//   handleRowHover = (columnName) => {
//     this.setState({ hoveredColumn: columnName });
//   };

//   setTableData = () => {
//     const {
//       rowDataList,
//       isLoading,
//       pageSize,
//       currentPage,
//       sortColumn,
//       sortDirection,
//       searchInput,
//       hoveredColumn,
//     } = this.state;
//     const { tabName } = this.props;

//     if (rowDataList.length === 0) return null;

//     // Filter data based on search input
//     let filteredData = rowDataList.filter((row) => {
//       for (let key in row) {
//         if (
//           row[key].toString().toLowerCase().includes(searchInput.toLowerCase())
//         ) {
//           return true;
//         }
//       }
//       return false;
//     });

//     // Sort data
//     let sortedData = filteredData;
//     if (sortColumn) {
//       sortedData = [...filteredData].sort((a, b) => {
//         const aValue = a[sortColumn];
//         const bValue = b[sortColumn];
//         if (aValue < bValue) return sortDirection === "asc" ? -1 : 1;
//         if (aValue > bValue) return sortDirection === "asc" ? 1 : -1;
//         return 0;
//       });
//     }

//     // Paginate data
//     const startIndex = (currentPage - 1) * pageSize;
//     const endIndex = startIndex + pageSize;
//     const paginatedData = sortedData.slice(startIndex, endIndex);

//     // Calculate total pages
//     const totalPages = Math.ceil(sortedData.length / pageSize);

//     // Calculate page numbers to display
//     let pageNumbers = [];
//     if (totalPages <= 10) {
//       pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
//     } else {
//       let startPage, endPage;
//       if (currentPage <= 6) {
//         startPage = 1;
//         endPage = 10;
//       } else if (currentPage + 4 >= totalPages) {
//         startPage = totalPages - 9;
//         endPage = totalPages;
//       } else {
//         startPage = currentPage - 5;
//         endPage = currentPage + 4;
//       }
//       pageNumbers = Array.from(
//         { length: endPage - startPage + 1 },
//         (_, i) => startPage + i
//       );
//     }

//     return (
//       <div>
//         <div className="Api_navbar">
//           <div>
//             <button
//               onClick={() => this.handlePageChange(currentPage - 1)}
//               disabled={currentPage === 1}
//             >
//               Previous
//             </button>
//             {pageNumbers.map((page) => (
//               <button
//                 key={page}
//                 onClick={() => this.handlePageChange(page)}
//                 className={currentPage === page ? "active" : ""}
//               >
//                 {page}
//               </button>
//             ))}
//             <button
//               onClick={() => this.handlePageChange(currentPage + 1)}
//               disabled={currentPage === totalPages}
//             >
//               Next
//             </button>
//           </div>

//           <div>
//             <input
//               type="text"
//               value={searchInput}
//               onChange={this.handleSearch}
//               placeholder="Search..."
//               className="search-input"
//             />
//           </div>

//           <div>
//             <select value={pageSize} onChange={this.handlePageSizeChange}>
//               <option value={25}>25</option>
//               <option value={50}>50</option>
//               <option value={75}>75</option>
//               <option value={100}>100</option>
//             </select>
//           </div>
//         </div>

//         <div className="api-container">
//           <table className="table">
//             <thead>
//               <tr>
//                 <th
//                   onClick={() => this.handleSort("button")}
//                   onMouseEnter={() => this.handleRowHover("button")}
//                   onMouseLeave={() => this.handleRowHover(null)}
//                   className={sortColumn === "button" ? "active" : ""}
//                 >
//                   Button
//                   {hoveredColumn === "button" &&
//                     (sortDirection === "asc" ? " ▲" : " ▼")}
//                 </th>
//                 <th
//                   onClick={() => this.handleSort("stage")}
//                   onMouseEnter={() => this.handleRowHover("stage")}
//                   onMouseLeave={() => this.handleRowHover(null)}
//                   className={sortColumn === "stage" ? "active" : ""}
//                 >
//                   Stage
//                   {hoveredColumn === "stage" &&
//                     (sortDirection === "asc" ? " ▲" : " ▼")}
//                 </th>
//                 <th
//                   onClick={() => this.handleSort("clientType")}
//                   onMouseEnter={() => this.handleRowHover("clientType")}
//                   onMouseLeave={() => this.handleRowHover(null)}
//                   className={sortColumn === "clientType" ? "active" : ""}
//                 >
//                   Client Type
//                   {hoveredColumn === "clientType" &&
//                     (sortDirection === "asc" ? " ▲" : " ▼")}
//                 </th>
//                 <th
//                   onClick={() => this.handleSort("clicks")}
//                   onMouseEnter={() => this.handleRowHover("clicks")}
//                   onMouseLeave={() => this.handleRowHover(null)}
//                   className={sortColumn === "clicks" ? "active" : ""}
//                 >
//                   # Clicks
//                   {hoveredColumn === "clicks" &&
//                     (sortDirection === "asc" ? " ▲" : " ▼")}
//                 </th>
//                 <th
//                   onClick={() => this.handleSort("apiCount")}
//                   onMouseEnter={() => this.handleRowHover("apiCount")}
//                   onMouseLeave={() => this.handleRowHover(null)}
//                   className={sortColumn === "apiCount" ? "active" : ""}
//                 >
//                   API Count
//                   {hoveredColumn === "apiCount" &&
//                     (sortDirection === "asc" ? " ▲" : " ▼")}
//                 </th>
//                 <th
//                   onClick={() => this.handleSort("dynamoReadCost")}
//                   onMouseEnter={() => this.handleRowHover("dynamoReadCost")}
//                   onMouseLeave={() => this.handleRowHover(null)}
//                   className={sortColumn === "dynamoReadCost" ? "active" : ""}
//                 >
//                   Cost
//                   {hoveredColumn === "dynamoReadCost" &&
//                     (sortDirection === "asc" ? " ▲" : " ▼")}
//                 </th>
//               </tr>
//             </thead>
//             <tbody>
//               {paginatedData.map((row, index) => (
//                 <tr key={index}>
//                   <td>{row.button}</td>
//                   <td>{row.stage}</td>
//                   <td>{row.clientType}</td>
//                   <td>{row.clicks}</td>
//                   <td>{row.apiCount}</td>
//                   <td>
//                     {row.dynamoReadCost.toLocaleString("en-US", {
//                       style: "currency",
//                       currency: "USD",
//                       maximumFractionDigits: 20,
//                     })}
//                   </td>
//                 </tr>
//               ))}
//             </tbody>
//           </table>
//         </div>
//       </div>
//     );
//   };

//   updateNewDisplay = () => {
//     axios
//       .get(awsUrl2 + "/api/getApiTrackingData")
//       .then((res) => {
//         let dataFromAPI = res.data;
//         this.setState({ rowDataList: dataFromAPI });
//       })
//       .catch((error) => {
//         console.log("error", error);
//       })
//       .finally(() => {
//         this.setState({ isLoading: false });
//       });
//   };

//   render() {
//     let { selected, tabName } = this.props;
//     let { isLoading } = this.state;

//     return (
//       <div
//         className="Extensis-Dashboard"
//         style={
//           tabName !== selected ? { display: "none", fontSize: "12px" } : {}
//         }
//       >
//         <div>
//           {isLoading ? (
//             <div className="d-flex justify-content-center align-items-center h-100 w-100">
//               <div className="blink_me" style={{ fontSize: "35px" }}>
//                 Loading Tables...
//               </div>
//             </div>
//           ) : (
//             this.setTableData()
//           )}
//         </div>
//       </div>
//     );
//   }
// }

// export default DashboardExtensis;
