import { tempURL, apiURL, localURL, awsUrl } from '../config';
import { setCookie } from './session_data';
import { Auth } from 'aws-amplify';
import axios from 'axios';
import $ from 'jquery';
import { from } from 'zen-observable';
import { getUserAuthStatus, showErrAlert } from './common';
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require('bootstrap');
// window.LOG_LEVEL = 'DEBUG';

// export function submitLogin(){
//     let validForm = validateForm();
//     if(validForm){
//         $('#loader').css('display','block');
//         var data={
//             userId: $('#userId').val(),
//             password: $('#userPassword').val()
//         }
//         axios.post(tempURL+'/posts',JSON.stringify(data)).then(function(response){
//         if(response){
//             sessionStorage.setItem('isLoggedIn',true);
//             window.location.href ='../Quote';
//             }
//         });
//     }
// }

export async function signIn(e, userId, userPassword) {
    e.preventDefault();
    console.log("++", userId, userPassword);
    // debugger;

    let validForm = validateForm(userId, userPassword);
    if (validForm) {
        //     // $('#loader').css('display', 'block');
        //     // const username = $('#userId').val();
        //     // const password = $('#userPassword').val();
        const username = userId;
        const password = userPassword;
        try {
            var userTemp = await Auth.signIn(username, password);
            //         // .then(res => {console.log('user:', res.challengeName)

            //         // })
            //         // .catch(err => {console.log('error signing in! :', err)
            //         //         if(err.code === "UserNotFoundException"){
            //         //             $('#loginSubmit').siblings('.errMsg').html('<br /> User does not exist.');
            //         //         }else if(err.code === 'NotAuthorizedException'){
            //         //             $('#loginSubmit').siblings('.errMsg').html('<br /> Incorrect username or password.');
            //         //         }
            //         // })


            console.log("UserTemp: ", userTemp);
            if (userTemp.challengeName === 'NEW_PASSWORD_REQUIRED') {
                //             //     var usernmae = "carrer_c+Hyderabad_12345@gmail.com";
                //             //     var newPassword = "Nowsad19979@";
                //             //     var phone_number = "+919908509874";
                //             //     var email = "nowsad19979@gmail.com";
                //             //  const loggedUser = await userTemp.completeNewPasswordChallenge(newPassword)
                //             //  .then(() => {
                //             //      // winning
                //             //  }).catch(error => {
                //             //    // not winning
                //             // });
                //             // $('#link').click();
                //             // $('#loader').css('display', 'none');
                //             // window.location.href ='#chngpwd';

            } else {

                if (userTemp.signInUserSession !== null && userTemp.signInUserSession.accessToken.payload["cognito:groups"][0] === "Carrier") {
                    console.log("User Role", userTemp.signInUserSession.accessToken.payload["cognito:groups"][0]);
                    sessionStorage.setItem('isLoggedIn', true)
                    window.location.href = './agent-dashboard';
                    // $('#loader').css('display','none');
                } else if (userTemp.signInUserSession !== null && userTemp.signInUserSession.accessToken.payload["cognito:groups"][0] === "User") {
                    let user_email_id = userTemp.username;
                    sessionStorage.setItem('isLoggedIn', true)
                    window.location.href = './agent-dashboard';
                }
                else if (userTemp.signInUserSession !== null && userTemp.signInUserSession.accessToken.payload["cognito:groups"][0] === "Compliance") {
                    // let user_email_id = userTemp.username;
                    sessionStorage.setItem('isLoggedIn', true)
                    window.location.href = './PanelCompliance';
                }
                console.log("In loged in page: ", userTemp);
            }
        } catch (err) {
            // $('#loader').css('display', 'none');
            console.log('error signing in! :', err);
            // $('#loginSubmit').siblings('.errMsg').html('<br /> ' + err.message);
        }
    }
}

function validateForm(userId, userPassword) {
    var form = true;
    if (userId === '' || (document.getElementById('userId').style.borderColor === 'red')) {
        // $('#userId').css('border-color', 'rgb(255, 0, 0)');
        // $('#userId').siblings('.errMsg').html('Email is required');
        // $('#userId').focus();
        form = false;
    }
    if (userPassword === '' || (document.getElementById('userId').style.borderColor === 'red')) {
        // $('#userPassword').css('border-color', 'rgb(255, 0, 0)');
        // $('#userPassword').siblings('.errMsg').html('Password is required');
        // $('#userPassword').focus();
        form = false;
    }
    return form;
}