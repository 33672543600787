import React from "react";
import Navbar from "../component/common/Navbar";
import DropZones from "../component/common/DropZones";
import SideTab from "./common/SideTab";
let ismobile = window.innerWidth < 992;
class UploadRenewalFile extends React.Component {
  render() {
    return (
      <>
        <div>
          <Navbar />
        </div>

        <div className="position-relative" style={{ marginTop: "25px" }}>
          {ismobile ? <SideTab /> : " "}
          <div className="side_bar">
            <div className="upload-heading  ">
              <h4>
                <b>Upload Documents - Renewal Clients</b>
              </h4>
              <div style={{ backgroundColor: "black", marginTop: "-10px" }}>
                <hr />
              </div>
            </div>
            <div className="side_bar">
              <DropZones />
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default UploadRenewalFile;
