import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Navbar from '../common/Navbar'
import ItWorks from '../subcompo/sections-home/ItWorks';
import Footer from '../common/Footer';
import Loader from '../common/Loader';
import $ from 'jquery';

class HowItWorksBrochure extends Component {
  componentDidMount() {
    // ReactDOM.findDOMNode(this).scrollTop = 0
    $("html, body").animate({ scrollTop: 0 }, 250);
  }
  render() {
     return (
       <div>
        <Navbar variant="large-logo" />
        <div className="container pt-5 mt-5">
          <div className="row">
            <div className="col-12">
              <p className="text-center">
              <img src={require('../../images/InsureComp-0.jpg')} style={{width: "100%"}} className="text-center" alt='Brochure Page 1' />
              </p>
              <p className="text-center">
              <img src={require('../../images/InsureComp-1.jpg')} style={{width: "100%"}} className="text-center" alt='Brochure Page 2' />
              </p>
            </div>
          </div>
        </div>
        <ItWorks/>
        <Footer/>
        <Loader/>
      </div>
    );
  }
}

export default HowItWorksBrochure;
