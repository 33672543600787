import React, { Component } from "react";
import axios from "axios";
import { awsUrl2 } from "../../../config";
import numeral from "numeral";

class ClassCodeRateMatch extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  setTableData = () => {
    let {
      setClassCodeRateMatchData,
      setClassCodeCompositeRateMatchData,
      currentSelectedCarrier,
      classCodeCompositeRateMatchData,
      setClassCodeCompositeRateMatchData_copy,
    } = this.props;
    let existingClient = sessionStorage.getItem("existingClient") === "true";
    let { data } = this.state;
    let rowData = [];
    let targetRateData = {};

    if (data && data[currentSelectedCarrier]) {
      for (let state in data[currentSelectedCarrier]) {
        targetRateData[state] = {};
        let {
          chargeCompositeRate,
          costCompositeRate,
          chargeManualRateValues,
          guaranteedManualRateValues,
          errData,
          newClassCodeList,
        } = data[currentSelectedCarrier][state];
        for (let classCode in errData) {
          if (
            data[currentSelectedCarrier][state].classCodeRateMatch &&
            data[currentSelectedCarrier][state].classCodeRateMatch[classCode]
          ) {
            targetRateData[state][classCode] =
              data[currentSelectedCarrier][state].classCodeRateMatch[classCode];
          }

          let chargeRate =
            Math.round(chargeCompositeRate[classCode] * 10000) / 10000;
          let costRate =
            Math.round(costCompositeRate[classCode] * 10000) / 10000;
          let guaranteedManualRate =
            Math.round(guaranteedManualRateValues[classCode] * 100) / 100;
          let chargeManualRate =
            Math.round(chargeManualRateValues[classCode] * 10000) / 10000;
          let floor = Math.round(errData[classCode].floor * 1000) / 1000;
          let ceiling = Math.round(errData[classCode].ceiling * 1000) / 1000;

          rowData.push(
            <tr key={`${state}-${classCode}`}>
              <td>
                {state.toUpperCase()}-{classCode}
              </td>
              <td>{chargeRate.toFixed(4)}</td>
              <td>
                <input
                  className="text-center"
                  type="number"
                  onChange={(e) => {
                    setClassCodeCompositeRateMatchData(
                      e.target.value,
                      state,
                      classCode,
                      currentSelectedCarrier
                    );
                    setClassCodeCompositeRateMatchData_copy(
                      e.target.value,
                      state,
                      classCode,
                      currentSelectedCarrier
                    );
                  }}
                  // disabled={
                  //   existingClient && !newClassCodeList.includes(classCode)
                  // }
                  value={
                    classCodeCompositeRateMatchData &&
                    classCodeCompositeRateMatchData[currentSelectedCarrier] &&
                    classCodeCompositeRateMatchData[currentSelectedCarrier][
                      state
                    ] &&
                    classCodeCompositeRateMatchData[currentSelectedCarrier][
                      state
                    ][classCode]
                      ? classCodeCompositeRateMatchData[currentSelectedCarrier][
                          state
                        ][classCode]
                      : ""
                  }
                />
              </td>
              <td>{costRate.toFixed(4)}</td>
              <td>
                {numeral(chargeRate).subtract(costRate).value().toFixed(4)}
              </td>
              <td>{guaranteedManualRate.toFixed(2)}</td>
              <td>
                {numeral(chargeRate)
                  .subtract(guaranteedManualRate)
                  .value()
                  .toFixed(4)}
              </td>
              <td>
                <span
                  style={{
                    backgroundColor:
                      chargeManualRate >= floor && chargeManualRate <= ceiling
                        ? "#92d050"
                        : "#F14547",
                    borderRadius: "20px",
                    padding: "8px 15px 8px 15px",
                  }}
                >
                  {chargeManualRate.toFixed(4)}
                </span>
              </td>
              <td>
                <input
                  className="text-center"
                  type="number"
                  onChange={(e) =>
                    setClassCodeRateMatchData(e.target.value, state, classCode)
                  }
                  defaultValue={
                    currentSelectedCarrier === "carrier_a" &&
                    targetRateData[state][classCode]
                      ? (
                          Math.round(targetRateData[state][classCode] * 10000) /
                          10000
                        ).toFixed(4)
                      : ""
                  }
                  disabled={
                    currentSelectedCarrier !== "carrier_a" ||
                    (existingClient && !newClassCodeList.includes(classCode))
                  }
                />
              </td>
              <td>{numeral(errData[classCode].err).value().toFixed(3)}</td>
              <td>{floor.toFixed(3)}</td>
              <td>{ceiling.toFixed(3)}</td>
            </tr>
          );
        }
      }
    }

    let tableData = (
      <table className="table table-sm table-bordered">
        <thead>
          <tr>
            <th>State-CC</th>
            <th>
              Charge<br></br>Rate
            </th>
            <th>
              Matched<br></br>Rate
            </th>
            <th>
              Cost<br></br>Rate
            </th>
            <th>Difference</th>
            <th>
              Manual<br></br>Rate
            </th>
            <th>Difference</th>
            <th>
              Charge<br></br>Program<br></br>Class
            </th>
            <th>
              Target<br></br>Rate
            </th>
            <th>
              {process.env.REACT_APP_MODE == "beta" ? "PEO Ref. Rate" : "ERR "}
            </th>
            <th>
              {process.env.REACT_APP_MODE == "beta" ? "PEO Ref. Rate" : "ERR "}
              <br></br>(-0.25)
            </th>
            <th>
              {process.env.REACT_APP_MODE == "beta" ? "PEO Ref. Rate" : "ERR "}
              <br></br>(+0.75)
            </th>
          </tr>
        </thead>
        <tbody>{rowData}</tbody>
      </table>
    );

    setClassCodeRateMatchData(targetRateData);
    this.setState({ tableData });
  };

  componentDidMount = () => {
    let {
      timestamp,
      uuid,
      email,
      setClassCodeCompositeRateMatchData,
      setClassCodeCompositeRateData,
      setClassCodeCompositeRateMatchData_copy,
      setSalesforceMatchedRateData,
    } = this.props;
    axios
      .get(awsUrl2 + `/api/getRateMatchData/${email}/${uuid}/${timestamp}`)
      .then((res) => {
        this.setState({ data: res.data }, () => {
          let { data } = this.state;
          this.setTableData();
          let matchedRateData = {};
          let compositeRateData = {};
          let matchedRateData_copy = {};
          for (let carrier in data) {
            matchedRateData[carrier] = {};
            compositeRateData[carrier] = {};
            matchedRateData_copy[carrier] = {};
            for (let state in data[carrier]) {
              matchedRateData[carrier][state] =
                data[carrier][state].classCodeCompositeRateMatch;
              compositeRateData[carrier][state] =
                data[carrier][state].chargeCompositeRate;
              matchedRateData[carrier][state].cost =
                data[carrier][state].costCompositeRate;
              matchedRateData_copy[carrier][state] =
                data[carrier][state].classCodeCompositeRateMatch_copy;
            }
          }

          setClassCodeCompositeRateMatchData(matchedRateData);
          setClassCodeCompositeRateData(compositeRateData);
          setClassCodeCompositeRateMatchData_copy(matchedRateData_copy);
          setSalesforceMatchedRateData(matchedRateData);
        });
      })
      .catch((error) => {
        console.log("error", error);
      })
      .finally(() => {
        // this.setState({ loaderActive: false });
      });
  };

  componentDidUpdate(prevProps) {
    if (
      this.props.currentSelectedCarrier !== prevProps.currentSelectedCarrier ||
      JSON.stringify(this.props.classCodeCompositeRateMatchData) !==
        JSON.stringify(prevProps.classCodeCompositeRateMatchData)
    ) {
      this.setTableData();
    }
  }

  render() {
    let { tableData } = this.state;
    return (
      <div className="mt-4 rate-match-class-code">
        <label>
          <b>Rate Match - Class Code Level</b>
        </label>
        <div className="table-responsive table-wrapper">{tableData}</div>
      </div>
    );
  }
}

export default ClassCodeRateMatch;
