import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Navbar from '../common/Navbar'
import ItWorks from '../subcompo/sections-home/ItWorks';
import Footer from '../common/Footer';
import Loader from '../common/Loader';
import $ from 'jquery';

class HowItWorksBind extends Component {
  componentDidMount() {
    // ReactDOM.findDOMNode(this).scrollTop = 0
    $("html, body").animate({ scrollTop: 0 }, 250);
  }
  render() {
     return (
       <div>
        <Navbar/>
        <div className="container">
          <div className="row">
            <div className="col-12">
              <h2 className="text-center">Bind</h2>
              <p className="text-center">
              <img src={require('../../images/work_3.jpg')} className="text-center" alt='' />
              </p>
              <p className="secDesc" style={{marginTop: "30px"}}>
              Our integrated platform allows you to electronically upload all required documents and get your binder instantly from your preferred carrier.
              </p>
            </div>
          </div>
        </div>
        <ItWorks/>
        <Footer/>
        <Loader/>
      </div>
    );
  }
}

export default HowItWorksBind;
