import React from "react";
import { Map, GeoJSON, TileLayer } from "react-leaflet";
import Legend from "./Legend";
import statesDataPayouts from "./stateDataPayouts";
import statesDataPremium from "./stateDataPremium";
import statesDataVolume from "./stateDataVolume";
import "./dashboard.css";
import { getHeader } from "../../utils/common";
import { dashboardUrl } from "../../config";
import axios from "axios";
import { Auth } from "aws-amplify";
import { useRef } from "react";
const legendBound = require("../../images/legend_bound.png");
const legendSubmitted = require("../../images/legend_submitted.png");

const mapboxAPIkey =
  "pk.eyJ1IjoiZmVsaXh0YW4iLCJhIjoiY2l0N3RuanV3MGI5ZTJ6cDFwZnl5emMyaCJ9.ODVgVlnonjis9Hkiz_bj9A";
const basemapUrl = `"https://api.mapbox.com/v4/{id}/{z}/{x}/{y}@2x.jpg90?access_token=${mapboxAPIkey}`;
const attribution =
  "Map data © OpenStreetMap contributors, CC-BY-SA, Imagery © Mapbox, Population data © US Census Bureau";

let statesMap = {
  AL: "Alabama",
  AK: "Alaska",
  AS: "American Samoa",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  DC: "District of Columbia",
  FM: "Federated States of Micronesia",
  FL: "Florida",
  GA: "Georgia",
  GU: "Guam",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MH: "Marshall Islands",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  MP: "Northern Mariana Islands",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PW: "Palau",
  PA: "Pennsylvania",
  PR: "Puerto Rico",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VI: "Virgin Islands",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};
let statesMap2 = {
  al: "AL",
  ak: "AK",
  as: "AS",
  az: "AZ",
  ar: "AR",
  ca: "CA",
  co: "CO",
  ct: "CT",
  de: "DE",
  dc: "DC",
  fm: "FM",
  fl: "FL",
  ga: "GA",
  gu: "GU",
  hi: "HI",
  id: "ID",
  il: "IL",
  in: "IN",
  ia: "IA",
  ks: "KS",
  ky: "KY",
  la: "LA",
  me: "ME",
  mh: "MH",
  md: "MD",
  ma: "MA",
  mi: "MI",
  mn: "MN",
  ms: "MS",
  mo: "MO",
  mt: "MT",
  ne: "NE",
  nv: "NV",
  nh: "NH",
  nj: "NJ",
  nm: "NM",
  ny: "NY",
  nc: "NC",
  nd: "ND",
  mp: "MP",
  oh: "OH",
  ok: "OK",
  or: "OR",
  pw: "PW",
  pa: "PA",
  pr: "PR",
  ri: "RI",
  sc: "SC",
  sd: "SD",
  tn: "TN",
  tx: "TX",
  ut: "UT",
  vt: "VT",
  vi: "VI",
  va: "VA",
  wa: "WA",
  wv: "WV",
  wi: "WI",
  wy: "WY",
};
// ca: 2305000
// fl: 28091000.9
// il: 4290000
// mi: 1410000
// tx: 29782000

class UsMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lat: 39.833,
      lng: -98.583,
      zoom: 4,
      filter3: props.filter3,
      prevfilter3: props.filter3,
      prevtype: props.type,
      type: props.type,
      filter: props.filter,
      prevfilter: props.filter,
      dateList: props.dateList,
      prevdateList: props.dateList,
      geodata: JSON.parse(JSON.stringify(statesDataPremium)),
      volume: {},
      pay: {},
      prem: {},
      data: props.data,
    };
    this.mapRef = React.createRef();
  }

  setData = () => {
    const { type, data } = this.props;
    // console.log("statesdatapremium before", statesDataPremium);
    let temp =
      type === "volume"
        ? JSON.parse(JSON.stringify(statesDataVolume))
        : type === "premium"
        ? JSON.parse(JSON.stringify(statesDataPremium))
        : JSON.parse(JSON.stringify(statesDataPayouts));

    Object.keys(data).forEach((e) => {
      const index = temp["features"].indexOf(
        temp["features"].filter(
          (a) => a["properties"]["name"] == statesMap[statesMap2[e]]
        )[0]
      );
      temp["features"][index]["properties"][type] = data[e];
      //   console.log(statesDataPremium["features"][index]["properties"]);
    });
    // console.log("temp ", temp.features);
    // console.log("statesdatapremium after", statesDataPremium);
    this.setState({ geodata: temp });
    let maxv, maxpay, maxprem;
    if (type === "volume") maxv = Object.values(this.sort_object(data))[0];
    if (type === "payroll") maxpay = Object.values(this.sort_object(data))[0];
    if (type === "premium") maxprem = Object.values(this.sort_object(data))[0];

    this.setState({ maxpay, maxprem, maxv });
  };
  async componentDidMount() {
    // delete this.props.data["dc"];
    this.setData();
    // console.log(this.mapRef);
    // const header = await getHeader();
    // await Auth.currentUserInfo()
    //   .then((data) => this.setState({ user: data.username }))
    //   .catch((err) => console.log(err));

    // await Auth.currentSession().then((res) => {
    //   var userRole = res.accessToken.payload["cognito:groups"][0];
    //   this.setState({
    //     user_type: userRole,
    //   });
    // });
    // const { user } = this.state;
    // await axios
    //   .get(dashboardUrl + "/api/getAggregateData/" + user, header)
    //   .then((response) => {
    //     // console.log(response.data);
    //     this.setState({
    //       data: response.data,
    //       map_all: response.data.map_all,
    //     });
    //     // this.updateMapData();
    //   })
    //   .catch((err) => console.log(err));

    // const {data} = this.state
    // const maxv = Object.values(data.stateCount)[0];
    // const maxpay = Object.values(data.statePayroll)[0]
    // const maxprem = Object.values(data.statePremium)[0]
    // this.setState({maxv,maxpay,maxprem})

    // if (this.state.user_type == "Carrier") {
    //   await axios
    //     .get(dashboardUrl + "/api/getMapCarrierAll/" + user, header)
    //     .then((response) => {
    //       this.setState({ data2: response.data.map_carrier_all });
    //     })
    //     .catch((err) => console.log(err));
    // }
    // this.calculateData();
  }

  componentDidUpdate(prevProps) {
    const { type, filter } = this.props;

    this.mapRef.current.leafletElement.invalidateSize();

    if (filter !== prevProps.filter) {
      this.setState(
        {
          filter: this.props.filter,
          prevfilter: prevProps.filter,
          geodata: "",
        },
        () => {
          this.handleFilterChange();
        }
      );
    }
    if (type !== prevProps.type) {
      this.setState(
        {
          type: this.props.type,
          prevtype: prevProps.type,
          geodata: "",
        },
        () => {
          this.handleTypeChange();
        }
      );
    }
    const dateList = this.props.dateList;
    if (dateList !== prevProps.dateList) {
      this.setState({ dateList, prevdateList: prevProps.dateList }, () => {
        this.handleChange();
      });
    }
    const filter3 = this.props.filter3;
    if (filter3 !== prevProps.filter3) {
      this.setState({ filter3, prevfilter3: prevProps.filter3 }, () => {
        this.handlefilter3();
      });
    }
  }

  getColor = (d) => {
    // return d > 10000000 || (d > 70 && d < 101)
    //   ? "#012333"
    //   : d > 5000000 || (d > 60 && d < 71)
    //   ? "#00344D"
    //   : d > 1000000 || (d > 50 && d < 61)
    //   ? "#014666"
    //   : d > 500000 || (d > 40 && d < 51)
    //   ? "#005A9E"
    //   : d > 100000 || (d > 30 && d < 41)
    //   ? "#01608C"
    //   : d > 50000 || (d > 20 && d < 31)
    //   ? "#0272A6"
    //   : d > 10000 || (d > 10 && d < 21)
    //   ? "#03A9F4"
    //   : d > 0
    //   ? "#ADD8E6"
    //   : "lightgrey";
    const { type, maxv, maxprem, maxpay } = this.state;
    var scale = 0;
    if (type == "volume") {
      scale = (d * 100) / maxv;
    } else if (type == "payroll") {
      scale = (d * 100) / maxpay;
    } else if (type == "premium") {
      scale = (d * 100) / maxprem;
    }
    scale = Math.round(scale * 100) / 100;
    if (this.props.filter === "submitted") {
      return scale > 90
        ? "#191970"
        : scale > 80
        ? "#000080"
        : scale > 70
        ? "#0000CD"
        : scale > 60
        ? "#0000FF"
        : scale > 50
        ? "#4169E1"
        : scale > 40
        ? "#1E90FF"
        : scale > 30
        ? "#00BFFF"
        : scale > 20
        ? "#87CEFA"
        : scale > 10
        ? "#B0E0E6"
        : scale > 0
        ? "#B0C4DE"
        : "lightgrey";
    } else {
      return scale > 90
        ? "#006400"
        : scale > 80
        ? "#026117"
        : scale > 70
        ? "#6B8E23"
        : scale > 60
        ? "#009e28"
        : scale > 50
        ? "#079c02"
        : scale > 40
        ? "#12bf02"
        : scale > 30
        ? "#009e28"
        : scale > 20
        ? "#47f403"
        : scale > 10
        ? "#7cf246"
        : scale > 0
        ? "lightgreen"
        : "lightgrey";
    }
  };

  style = (feature) => {
    // value for display on mouseover map
    const { type } = this.state;
    return {
      fillColor: this.getColor(feature.properties[type]),
      weight: 2,
      opacity: 1,
      color: "white",
      dashArray: "3",
      fillOpacity: 0.7,
    };
  };

  mouseoverFeature = (e, feature) => {
    const { type } = this.state;
    // let states = Object.keys[statesMap];
    // let statecodes = Object.values[statesMap];
    // console.log(feature)
    let value = feature.properties[type];
    let state = feature.properties.name;
    this.setState({
      value: value,
      state: state,
    });
    console.log("++++++++++++++++++");
    console.log(value);
  };

  updateMapData = () => {
    let { data2, filter3, type, dateList, map_all } = this.state;
    if (filter3 == "all") {
      map_all = {};
      map_all = data2;
    }
    if (type == "payroll") {
      const { features } = statesDataPayouts;
      if (dateList.length > 0) {
        for (let elem of features) {
          elem.properties[type] = 0;
        }
        dateList.forEach((date) => {
          let l = Object.keys(map_all[date]["payroll"]);
          l.forEach((each) => {
            for (let elem of features) {
              let state = elem.properties.name;
              if (statesMap[state]) {
                if (statesMap[state] == each) {
                  elem.properties[type] += map_all[date]["payroll"][each];
                }
              }
            }
          });
        });
      } else {
        // let l = Object.keys(data.statePayroll)
        // l.forEach(each => {
        //   for (let elem of features) {
        //     let state = elem.properties.name;
        //     if (statesMap[state]) {
        //       if (statesMap[state] == each) {
        //         elem.properties[type] = data.statePayroll[each]
        //       }
        //     }
        //   }
        // })
      }
    } else if (type == "premium") {
      const { features } = statesDataPremium;
      if (dateList.length > 0) {
        for (let elem of features) {
          elem.properties[type] = 0;
        }
        dateList.forEach((date) => {
          let l = Object.keys(map_all[date]["premium"]);
          l.forEach((each) => {
            for (let elem of features) {
              let state = elem.properties.name;
              if (statesMap[state]) {
                if (statesMap[state] == each) {
                  elem.properties[type] += map_all[date]["premium"][each];
                }
              }
            }
          });
        });
      } else {
        // let l = Object.keys(data.statePremium)
        // l.forEach(each => {
        //   for (let elem of features) {
        //     let state = elem.properties.name;
        //     if (statesMap[state]) {
        //       if (statesMap[state] == each) {
        //         elem.properties[type] = data.statePremium[each]
        //       }
        //     }
        //   }
        // })
      }
    } else if (type == "volume") {
      const { features } = statesDataVolume;
      if (dateList.length > 0) {
        for (let elem of features) {
          elem.properties[type] = 0;
        }
        dateList.forEach((date) => {
          let l = Object.keys(map_all[date]["volume"]);
          l.forEach((each) => {
            for (let elem of features) {
              let state = elem.properties.name;
              if (statesMap[state]) {
                if (statesMap[state] == each) {
                  elem.properties[type] += map_all[date]["volume"][each];
                }
              }
            }
          });
        });
      } else {
        // let l = Object.keys(data.stateCount)
        // l.forEach(each => {
        //   for (let elem of features) {
        //     let state = elem.properties.name;
        //     if (statesMap[state]) {
        //       if (statesMap[state] == each) {
        //         elem.properties[type] = data.stateCount[each]
        //       }
        //     }
        //   }
        // })
      }
    }
  };
  showValue = () => {
    const { value } = this.state;
    return value;
  };

  showAmount = () => {
    const { value } = this.state;
    let formattedData = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
    return formattedData;
  };

  showState = () => {
    const { state } = this.state;
    return statesMap2[statesMap[state]];
  };

  toggleMouseoverInfo = () => {
    const { type } = this.state;
    if (type === "volume") {
      return this.showValue();
    } else if (type === "payroll") {
      return this.showAmount();
    } else if (type === "premium") {
      return this.showAmount();
    } else if (type === "inpremium") {
      return "Premium via InsureComp : " + this.showAmount();
    }
  };

  sort_object = (obj) => {
    let items = Object.keys(obj).map(function (key) {
      return [key, obj[key]];
    });
    // console.log("before items ", items);
    items.sort(function (first, second) {
      return second[1] - first[1];
    });
    // console.log("after items ", items);
    let sorted_obj = {};
    for (var i = 0; i < items.length; i++) {
      if (items[i]) {
        let k = items[i][0];
        let v = items[i][1];
        sorted_obj[k] = v;
      }
    }
    return sorted_obj;
  };

  calculateData = () => {
    // calculate data with current filters
    const { data, filter3, type, dateList, data2 } = this.state;
    let { volume, pay, prem, maxv, maxpay, maxprem, map_all } = this.state;
    if (filter3 == "all") {
      map_all = {};
      map_all = data2;
    }
    if (type == "payroll") {
      if (dateList.length > 0) {
        pay = {};
        dateList.forEach((date) => {
          let l = Object.keys(map_all[date]["payroll"]);
          l.forEach((each) => {
            if (!pay[each]) {
              pay[each] = 0;
            }
            pay[each] += map_all[date]["payroll"][each];
          });
        });
      } else {
        pay = {};
        let l = Object.keys(data.statePayroll);
        l.forEach((each) => {
          pay[each] = data.statePayroll[each];
        });
      }
    } else if (type == "premium") {
      if (dateList.length > 0) {
        prem = {};
        dateList.forEach((date) => {
          let l = Object.keys(map_all[date]["premium"]);
          l.forEach((each) => {
            if (!prem[each]) {
              prem[each] = 0;
            }
            prem[each] += map_all[date]["premium"][each];
          });
        });
      } else {
        prem = {};
        let l = Object.keys(data.statePremium);
        l.forEach((each) => {
          prem[each] = data.statePremium[each];
        });
      }
    } else if (type == "volume") {
      if (dateList.length > 0) {
        volume = {};
        dateList.forEach((date) => {
          let l = Object.keys(map_all[date]["volume"]);
          l.forEach((each) => {
            if (!volume[each]) {
              volume[each] = 0;
            }
            volume[each] += map_all[date]["volume"][each];
          });
        });
      } else {
        volume = {};
        let l = Object.keys(data.stateCount);
        l.forEach((each) => {
          volume[each] = data.stateCount[each];
        });
      }
    }
    volume = this.sort_object(volume);
    pay = this.sort_object(pay);
    prem = this.sort_object(prem);
    maxv = Object.values(volume)[0];
    maxpay = Object.values(pay)[0];
    maxprem = Object.values(prem)[0];
    this.setState({ maxpay, maxprem, maxv });
  };
  handleFilterChange = () => {
    const { filter, type } = this.state;
    this.setState({ prevfilter: filter, state: "", value: "" });

    this.setData();
  };
  handleTypeChange = async (e) => {
    // await this.setState({
    //   type: e.target.value
    // });
    const { type } = this.state;
    this.setState({ prevtype: type, state: "", value: "" });
    this.setData();
  };

  handleChange = () => {
    const { dateList } = this.state;
    this.setState({ prevdateList: dateList, state: "", value: "" });
    // this.calculateData();
    this.setData();
  };

  handlefilter3 = () => {
    const { filter3 } = this.state;
    this.setState({ prevfilter3: filter3 });
    // this.calculateData();
    this.setData();
  };

  onEachFeature = (feature, layer) => {
    const { type } = this.state;
    const allStatesData = layer.options.data.features;

    let sum = 0;
    try {
      allStatesData.forEach((e) => {
        sum += e.properties[type];
      });
    } catch (e) {}

    let percentage;
    try {
      percentage = feature.properties[type] / sum;
    } catch (error) {
      percentage = 0;
    }

    if (!percentage || percentage === Infinity) {
      percentage = 0;
    }

    layer.on({
      // mouseover: e => this.mouseoverFeature(e, feature)
      mouseover: (e) => {
        if (feature.properties[type] > 0) {
          let popup = "";
          if (type == "volume") {
            popup =
              "<b>" +
              feature.properties.name +
              "</b>" +
              "<br>" +
              "Value: " +
              Intl.NumberFormat("en-US").format(feature.properties[type]);
          } else {
            popup =
              "<b>" +
              feature.properties.name +
              "</b>" +
              "<br>" +
              "Value: " +
              Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                currencyDisplay: "narrowSymbol",
              }).format(feature.properties[type]);
          }
          popup +=
            "<br>" +
            "Percentage: " +
            Intl.NumberFormat("en-US", {
              style: "percent",
              maximumFractionDigits: 2,
              minimumFractionDigits: 2,
            }).format(percentage);

          layer.bindPopup(popup);
        }
        layer.openPopup();
      },
      mouseout: (e) => {
        layer.closePopup();
        layer.unbindPopup();
      },
    });
  };

  render() {
    const position = [this.state.lat, this.state.lng];
    const {
      filter3,
      prevfilter3,
      type,
      prevtype,
      filter,
      prevfilter,
      dateList,
      prevdateList,
      geodata,
      maxv,
      maxprem,
      maxpay,
    } = this.state;
    let max;

    let legendToShow;
    if (filter === "submitted") {
      legendToShow = legendSubmitted;
    } else if (filter === "bound") {
      legendToShow = legendBound;
    }

    if (type === "volume") {
      max = maxv;
    } else if (type === "payroll") {
      max = maxpay;
    } else if (type === "premium") {
      max = maxprem;
    }

    return (
      <div className="text-center heatmap">
        {/* {
          this.state.payout && this.state.state ?
          <p>State : {this.showState()}, {this.toggleMouseoverInfo()}
          </p> : <p>Hover mouse over map to see data</p>
        } */}
        {/* <div className="dropdown">
          <select className="form-control" onClick={this.handleTypeChange}>
            <option value="bsub">Subscription</option>
            <option value="payroll">Payroll</option>
            <option value="premium">Writtten Premium</option>
          </select>
        </div> */}
        {/* {this.state.value && this.state.state ? (
          <p style={{ fontSize: "small" }} className="text-with-data">
            {this.showState()} {this.toggleMouseoverInfo()}
          </p>
        ) : (
          <p style={{ fontSize: "small" }} className="text-without-data">
            Hover over colored states to see data
          </p>
          )}     */}

        <Map ref={this.mapRef} center={position} zoom={this.state.zoom}>
          <TileLayer
            attribution={attribution}
            url={basemapUrl}
            id="mapbox.satellite"
          />
          <GeoJSON
            data={geodata}
            key={geodata}
            style={this.style}
            onEachFeature={this.onEachFeature}
            ref="geojson"
          />
          {/* <Legend filter={filter} max={max} /> */}
          {legendToShow && (
            <img
              src={legendToShow}
              className="legend-placeholder"
              alt="legend bound"
            />
          )}
        </Map>
        {/* {this.state.type == "payroll" ? (
          <Map center={position} zoom={this.state.zoom}>
            <TileLayer
              attribution={attribution}
              url={basemapUrl}
              id="mapbox.light"
            />
            <GeoJSON
              data={payroll}
              key={payroll}
              style={this.style}
              onEachFeature={this.onEachFeature}
              ref="geojson"
            />
            <Legend />
          </Map>
        ) : (
          <Map center={position} zoom={this.state.zoom}>
            <TileLayer
              attribution={attribution}
              url={basemapUrl}
              id="mapbox.light"
            />
            <GeoJSON
              data={premium}
              key={premium}
              style={this.style}
              onEachFeature={this.onEachFeature}
              ref="geojson"
            />
            <Legend />
          </Map>
        )} */}
      </div>
    );
  }
}

export default UsMap;
