import React, { Component } from "react";

class ActionButtons extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    let {
      handleCalculateButton,
      handleToSalesforceButtonClick,
      handleConfirmButtonClick,
      handleRenewalConfirmButtonClick,
    } = this.props;

    let isSalesForce = sessionStorage.getItem("isSalesforce") ? true : false;

    let existingClient = sessionStorage.getItem("existingClient") === "true";
    let renewalClient = sessionStorage.getItem("renewalClient") === "true";
    return (
      <div className="action-buttons d-flex mt-4 justify-content-center">
        <button
          className="btn btn-primary calculate-button"
          onClick={handleCalculateButton}
          style={{
            borderRadius: "20px",
            marginRight: "15px",
            color: "white",
            padding: "10px 30px",
            fontWeight: "bold",
            backgroundColor: "#1463ac",
          }}
        >
          Calculate
        </button>
        {existingClient ? (
          <button
            className="btn btn-warning"
            onClick={handleConfirmButtonClick}
            style={{
              borderRadius: "20px",
              color: "white",
              padding: "10px 30px",
              fontWeight: "bold",
            }}
          >
            Confirm
          </button>
        ) : renewalClient ? (
          <button
            className="btn btn-warning"
            onClick={handleRenewalConfirmButtonClick}
            style={{
              borderRadius: "20px",
              color: "black",
              padding: "10px 30px",
              fontWeight: "bold",
            }}
          >
            Confirm
          </button>
        ) : (
          <button
            className="btn btn-warning sendToSalesforce-button"
            onClick={handleToSalesforceButtonClick}
            disabled={!isSalesForce && process?.env?.REACT_APP_MODE !== "beta"}
            style={{
              borderRadius: "20px",
              color: "black",
              padding: "10px 30px",
              fontWeight: "bold",
              backgroundColor: "#fcac00",
            }}
          >
            To SalesForce
          </button>
        )}
      </div>
    );
  }
}

export default ActionButtons;
