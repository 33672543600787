import React, { Component, forwardRef } from "react";
import axios from "axios";
import { awsUrl2 } from "../../config";
import { Container, Row, Col } from "react-bootstrap";
import { Doughnut, Pie, Bar, HorizontalBar, Line } from "react-chartjs-2";
import UsMap from "./Map2";
import MaterialTable from "material-table";
import LoadingOverlay from "react-loading-overlay";
import DashboardGrid from "./DashboardGrid";
const numeral = require("numeral");

const carrierMap = {
  carrier_a: "CHUBB",
  carrier_b: "FIC",
  carrier_o: "State Fund",
};

class DashboardExtensisRevamp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      dashboardData: {},
      selectedMetric: "premium",
      selectedFilter: this.props.tabName === "tab3" ? "bound" : "submitted",
    };
  }

  componentDidMount = () => {
    sessionStorage.clear();
    this.getDashboardData();
  };

  fetchData = async (id) => {
    let responseData;
    axios
      .get(awsUrl2 + `/api/getChartsData/${id}`)
      .then((res) => {
        if (res.data === "processing") {
          const myTimeout = setTimeout(() => this.fetchData(id), 5000);
        } else {
          responseData = res.data;
          this.setState({
            dashboardData: responseData,
          });
          this.setState({ isLoading: false });
        }
      })
      .catch((error) => {
        console.log("error", error);
      });
  };

  getDashboardData = async () => {
    let { tabName } = this.props;
    const client =
      tabName === "tab1" ? "new" : tabName === "tab2" ? "renewal" : "existing";
    const res = await axios.get(
      awsUrl2 + `/api/getChartsDataId/${client}/aachman@mobiux.in`
    );

    let id = res.data.id;

    this.fetchData(id);
  };

  getExistingStatesList = (payrollData) => {
    let stateList = [];
    for (let stateIndex in payrollData) {
      let state;
      try {
        state = payrollData[stateIndex].state.value.toLowerCase();
        if (!stateList.includes(state)) {
          stateList.push(state);
        }
      } catch (error) {}
    }

    return stateList;
  };
  camelCase = (str) => {
    return str
      .trim()
      .split(" ")
      .map((word, i) => {
        return i == 0
          ? word.charAt(0).toLowerCase() + word.slice(1).toLowerCase()
          : word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join("");
  };

  formatNumber = (number) => {
    return new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD",
      maximumFractionDigits: 0,
    }).format(number);
  };

  sortObject = (obj) => {
    let labels = [];
    let data = [];
    let objToList = Object.entries(obj).sort(([, a], [, b]) => b - a);

    objToList.forEach((e) => {
      labels.push(e[0]);
      data.push(e[1]);
    });

    return { labels, data };
  };
  setChartData = () => {
    const { dashboardData, selectedMetric, selectedFilter } = this.state;
    const selectedMetricData = dashboardData[selectedMetric];
    let doughnutText =
      selectedMetric === "volume"
        ? selectedMetricData.submitted
        : this.formatNumber(selectedMetricData.submitted);

    // console.log(selectedMetricData.states.submitted);
    const conversionChartDataLabels = ["Quote", "Bound", "In Progress", "Lost"];

    let conversionData = conversionChartDataLabels.map(
      (data) => selectedMetricData[this.camelCase(data)]
    );

    conversionData[0] =
      selectedMetricData["quote"] - selectedMetricData["bound"];

    if (conversionData[0] < 0) {
      conversionData[0] = 0;
    }

    const conversionChartData = {
      labels: conversionChartDataLabels,
      datasets: [
        {
          data: conversionData,
          backgroundColor: ["#ffd600", "#196501", "#00b7eb", "#fb4503"],
        },
      ],
    };

    const conversionChartPlugins = [
      {
        beforeDraw: function(chart) {
          if (
            chart.config.options.centerText.display !== null &&
            typeof chart.config.options.centerText.display !== "undefined" &&
            chart.config.options.centerText.display
          ) {
            var ctx = chart.chart.ctx;

            // Get options from the center object in options
            var centerConfig = chart.config.options.centerText;
            var fontStyle = centerConfig.fontStyle || "sans-serif";
            var txt = centerConfig.text;
            // var maxFontSize = centerConfig.maxFontSize || 30;
            var sidePadding = centerConfig.sidePadding || 20;
            var sidePaddingCalculated =
              (sidePadding / 100) * (chart.innerRadius * 2);
            // Start with a base font of 30px
            let startBaseFont =
              window.innerWidth < 1370
                ? "35px "
                : window.innerWidth > 1510
                ? "45px "
                : window.innerWidth > 1750
                ? "55px "
                : window.innerWidth > 1800
                ? "65px "
                : "40px ";
            ctx.font = startBaseFont + "" + fontStyle;

            // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            var stringWidth = ctx.measureText(txt).width;
            var submittedWidth = ctx.measureText("Submitted").width;
            var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated;

            // Find out how much the font can grow in width.
            var widthRatio = elementWidth / stringWidth;
            var submittedWidthRatio = elementWidth / submittedWidth;
            var maxFontSize = Math.floor(30 * submittedWidthRatio);
            var newFontSize = Math.floor(30 * widthRatio);
            var elementHeight = chart.innerRadius * 2;

            // Pick a new font size so it will not be larger than the height of label.
            var fontSizeToUse = Math.min(
              newFontSize,
              elementHeight,
              maxFontSize
            );
            var minFontSize = centerConfig.minFontSize;
            var lineHeight = centerConfig.lineHeight || 25;
            var wrapText = false;

            if (minFontSize && fontSizeToUse < minFontSize) {
              fontSizeToUse = minFontSize;
              wrapText = true;
            }

            // Set font settings to draw it correctly.
            ctx.textAlign = "center";
            ctx.textBaseline = "middle";
            var centerX = (chart.chartArea.left + chart.chartArea.right) / 2;
            var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2;
            ctx.font = fontSizeToUse + "px " + fontStyle;

            // if (!wrapText) {
            //   ctx.fillText(txt, centerX, centerY);
            //   return;
            // }

            txt = txt + " Submitted";
            var words = txt.split(" ");
            var line = "";
            var lines = [];

            // Break words up into multiple lines if necessary
            for (var n = 0; n < words.length; n++) {
              var testLine = line + words[n] + " ";
              var metrics = ctx.measureText(testLine);
              var testWidth = metrics.width;
              if (
                (testWidth > elementWidth && n > 0) ||
                words[n] === "Submitted"
              ) {
                lines.push(line);
                line = words[n] + " ";
              } else {
                line = testLine;
              }
            }

            // Move the center up depending on line height and number of lines
            centerY -= (lines.length / 2) * lineHeight;

            for (var n = 0; n < lines.length; n++) {
              ctx.fillText(lines[n], centerX, centerY);
              centerY += lineHeight;
            }
            //Draw text in center
            ctx.fillText(line, centerX, centerY);
          }
        },
      },
    ];

    const conversionChartOptions = {
      cutoutPercentage: 70,
      responsive: true,
      centerText: {
        display: true,
        text: doughnutText,
        fontColor: "black",
      },
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 20,
          boxHeight: 30,
          fontColor: "black",
        },
      },
      ticks: {
        fontColor: "black",
      },

      title: {
        display: true,
        text: "Conversion Ratio",
        fontSize: 14,
        fontColor: "black",
      },

      tooltips: {
        callbacks: {
          label: function(item, data) {
            let label = data.labels[item.index];
            return label;
          },
          footer: function(item, data) {
            let label = ["Value: ", "Percentage: "];
            const sum = data["datasets"][0]["data"].reduce((p, c) => p + c);
            const value = data["datasets"][0]["data"][item[0]["index"]];
            const percentage = value / sum;
            label[0] +=
              selectedMetric !== "volume"
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(value)
                : Intl.NumberFormat("en-US").format(value);

            label[1] += Intl.NumberFormat("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(percentage);

            return label;
          },
        },
      },
    };

    const carrierChartOptions = {
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 20,
          boxHeight: 30,
        },
      },

      title: {
        display: true,
        text: "Split by Carrier",
        fontSize: 14,
      },
      tooltips: {
        callbacks: {
          label: function(item, data) {
            let label = data.labels[item.index];
            // console.log(item, data);
            // console.log(label);

            if (label) {
              label += ": ";
            }
            const value = data["datasets"][0]["data"][item["index"]];
            label +=
              selectedMetric !== "volume"
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(value)
                : Intl.NumberFormat("en-US").format(value);

            return label;
          },
        },
      },
    };

    let carrierList = Object.keys(selectedMetricData.split_by_carrier.bound);
    let carrierChartDataLabels = [];
    carrierList.forEach((carrier) => {
      carrierChartDataLabels.push(carrierMap[carrier]);
    });
    const carrierChartData = {
      labels: carrierChartDataLabels,
      datasets: [
        {
          data: carrierList.map((data) => {
            return selectedMetricData.split_by_carrier.bound[data];
          }),
          backgroundColor: ["#195501", "#197501", "#19a501"],
        },
      ],
    };

    let charge_vs_matched_charge_list = Object.keys(
      selectedMetricData.charge_vs_matched_charge
    );
    let charge_vs_matched_charge_labels = [];
    charge_vs_matched_charge_list.forEach((en) => {
      charge_vs_matched_charge_labels.push(
        en === "charge" ? "Charge Premium" : "Matched Premium"
      );
    });
    let charge_vs_matched_charge_colors = ["#195501", "#19a501"];

    let charge_vs_matched_charge_data = {
      labels: charge_vs_matched_charge_labels,
      datasets: [
        {
          data: charge_vs_matched_charge_list.map((data) => {
            return selectedMetricData["charge_vs_matched_charge"][data].bound;
          }),
          backgroundColor: charge_vs_matched_charge_colors,
        },
      ],
    };
    const charge_vs_matched_charge_options = {
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 20,
          boxHeight: 30,
          fontColor: "black",
        },
      },

      title: {
        display: true,
        text: "Charge Premium vs Matched Premium",
        fontSize: 14,
        fontColor: "black",
      },
      tooltips: {
        callbacks: {
          label: function(item, data) {
            let label = data.labels[item.index];
            return label;
          },
          footer: function(item, data) {
            let label = ["Value: ", "Percentage: "];
            const sum = data["datasets"][0]["data"].reduce((p, c) => p + c);
            const value = data["datasets"][0]["data"][item[0]["index"]];
            const percentage = value / sum;
            label[0] +=
              selectedMetric !== "volume"
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(value)
                : Intl.NumberFormat("en-US").format(value);

            label[1] += Intl.NumberFormat("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(percentage);

            return label;
          },
        },
      },
    };

    const eVsNonE_ChartOptions = {
      legend: {
        position: "bottom",
        labels: {
          boxWidth: 20,
          boxHeight: 30,
          fontColor: "black",
        },
      },

      title: {
        display: true,
        text: "E-18 vs Non E-18",
        fontSize: 14,
        fontColor: "black",
      },
      tooltips: {
        callbacks: {
          label: function(item, data) {
            let label = data.labels[item.index];
            return label;
          },
          footer: function(item, data) {
            let label = ["Value: ", "Percentage: "];
            const sum = data["datasets"][0]["data"].reduce((p, c) => p + c);
            const value = data["datasets"][0]["data"][item[0]["index"]];
            const percentage = value / sum;
            label[0] +=
              selectedMetric !== "volume"
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(value)
                : Intl.NumberFormat("en-US").format(value);

            label[1] += Intl.NumberFormat("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(percentage);

            return label;
          },
        },
      },
    };

    let eNonE_List = Object.keys(selectedMetricData.eNonE);
    let eNonE_Labels = [];
    eNonE_List.forEach((en) => {
      eNonE_Labels.push(en === "e" ? "E-18" : "Non E-18");
    });

    let eNonE_Colors =
      selectedFilter === "submitted"
        ? ["#01008a", "#2222c2"]
        : ["#195501", "#19a501"];

    const eNonE_ChartData = {
      labels: eNonE_Labels,
      datasets: [
        {
          data: eNonE_List.map((data) => {
            return selectedMetricData.eNonE[data][selectedFilter];
          }),
          backgroundColor: eNonE_Colors,
          fontColor: "black",
        },
      ],
    };

    const {
      labels: eNonE_non_e_Naics_Label,
      data: eNonE_non_e_Naics_data,
    } = this.sortObject(
      selectedMetricData.eNonE["non_e"]["top_10_naics_code"][selectedFilter]
    );

    const eNonE_non_e_Naics_BarData = {
      labels: eNonE_non_e_Naics_Label,
      datasets: [
        {
          label: "Non E",
          data: eNonE_non_e_Naics_data,
          backgroundColor: eNonE_Colors[1],
          fontColor: "black",
        },
      ],
    };

    const eNonE_non_e_Naics_BarOptions = {
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: false,
              labelString: "Non E",
              fontColor: "black",
            },
            ticks: {
              fontColor: "black",
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString:
                selectedMetric.charAt(0).toUpperCase() +
                selectedMetric.slice(1).toLowerCase(),
              fontColor: "black",
            },
            ticks: {
              fontColor: "black",
              maxTicksLimit: 6,
              beginAtZero: true,
              callback: function(value, index, ticks) {
                let label;
                selectedMetric !== "volume"
                  ? (label = Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      currencyDisplay: "narrowSymbol",
                      maximumFractionDigits: 0,
                      notation:
                        window.innerWidth <= 540 ? "compact" : "standard",
                    }).format(value))
                  : (label = Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 0,
                    }).format(value));

                return label;
              },
            },
          },
        ],
      },
      title: {
        display: true,
        text: "Top 10 Non E-18 NAICS Codes",
        fontColor: "black",
        fontSize: 14,
      },
      tooltips: {
        callbacks: {
          label: function(item, data) {
            let label = data.labels[item.index];
            return label;
          },
          footer: function(item, data) {
            let label = ["Value: ", "Percentage: "];
            const sum = selectedMetricData.eNonE.non_e[selectedFilter];
            const value = data["datasets"][0]["data"][item[0]["index"]];
            const percentage = value / sum;
            label[0] +=
              selectedMetric !== "volume"
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(value)
                : Intl.NumberFormat("en-US").format(value);

            label[1] += Intl.NumberFormat("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(percentage);

            return label;
          },
          title: function() {
            return "";
          },
        },
      },
      legend: { display: false },
      plugins: {},
    };

    const {
      labels: eNonE_e_Naics_Label,
      data: eNonE_e_Naics_data,
    } = this.sortObject(
      selectedMetricData.eNonE["e"]["top_10_naics_code"][selectedFilter]
    );

    const eNonE_e_Naics_BarData = {
      labels: eNonE_e_Naics_Label,
      datasets: [
        {
          label: "E",
          data: eNonE_e_Naics_data,
          fontColor: "black",
          backgroundColor: eNonE_Colors[0],
        },
      ],
    };

    const eNonE_e_Naics_BarOptions = {
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: false,
              labelString: "E",
              fontColor: "black",
            },
            ticks: {
              fontColor: "black",
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              fontColor: "black",
              display: true,
              labelString:
                selectedMetric.charAt(0).toUpperCase() +
                selectedMetric.slice(1).toLowerCase(),
            },
            ticks: {
              maxTicksLimit: 6,
              fontColor: "black",
              beginAtZero: true,
              callback: function(value, index, ticks) {
                let label;
                selectedMetric !== "volume"
                  ? (label = Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      currencyDisplay: "narrowSymbol",
                      maximumFractionDigits: 0,
                      notation:
                        window.innerWidth <= 540 ? "compact" : "standard",
                    }).format(value))
                  : (label = Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 0,
                    }).format(value));

                return label;
              },
            },
          },
        ],
      },
      title: {
        display: true,
        text: "Top 10 E-18 NAICS Codes",
        fontSize: 14,
        fontColor: "black",
      },
      tooltips: {
        callbacks: {
          label: function(item, data) {
            let label = data.labels[item.index];
            return label;
          },
          footer: function(item, data) {
            let label = ["Value: ", "Percentage: "];
            const sum = selectedMetricData.eNonE.e[selectedFilter];
            const value = data["datasets"][0]["data"][item[0]["index"]];
            const percentage = value / sum;
            label[0] +=
              selectedMetric !== "volume"
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(value)
                : Intl.NumberFormat("en-US").format(value);

            label[1] += Intl.NumberFormat("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(percentage);

            return label;
          },
          title: function() {
            return "";
          },
        },
      },
      legend: { display: false },
      plugins: {},
    };

    const {
      labels: sorted_class_code_labels,
      data: sorted_top_10_cc_data,
    } = this.sortObject(selectedMetricData.top_10_class_code[selectedFilter]);

    const classCodeBarData = {
      labels: sorted_class_code_labels,
      datasets: [
        {
          label: "Top 10 WC Codes",
          data: sorted_top_10_cc_data,
          backgroundColor: selectedFilter === "bound" ? "#196501" : "#01008a",
          fontColor: "black",
        },
      ],
    };
    const classCodeBarOptions = {
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: false,
              labelString: "Class Code",
              fontColor: "black",
            },
            ticks: {
              fontColor: "black",
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString:
                selectedMetric.charAt(0).toUpperCase() +
                selectedMetric.slice(1).toLowerCase(),
              fontColor: "black",
            },
            ticks: {
              beginAtZero: true,
              fontColor: "black",
              callback: function(value, index, ticks) {
                let label;
                selectedMetric !== "volume"
                  ? (label = Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      currencyDisplay: "narrowSymbol",
                      maximumFractionDigits: 0,
                      notation:
                        window.innerWidth <= 540 ? "compact" : "standard",
                    }).format(value))
                  : (label = Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 0,
                    }).format(value));

                return label;
              },
            },
          },
        ],
      },
      title: {
        display: true,
        text: "Top 10 WC Codes",
        fontSize: 14,
        fontColor: "black",
      },
      tooltips: {
        callbacks: {
          label: function(item, data) {
            let label = data.labels[item.index];
            return label;
          },
          footer: function(item, data) {
            let label = ["Value: ", "Percentage: "];
            const sum = selectedMetricData[selectedFilter];
            const value = data["datasets"][0]["data"][item[0]["index"]];
            const percentage = value / sum;
            label[0] +=
              selectedMetric !== "volume"
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(value)
                : Intl.NumberFormat("en-US").format(value);

            label[1] += Intl.NumberFormat("en-US", {
              style: "percent",
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }).format(percentage);

            return label;
          },
          title: function() {
            return "";
          },
        },
      },
      legend: { display: false },
      plugins: {},
    };

    const naicsLabels = Object.keys(
      selectedMetricData.top_10_naics_code[selectedFilter]
    );

    const naicsBarData = {
      labels: naicsLabels,
      datasets: [
        {
          label: "Top 10 NAICS Code",
          data: naicsLabels.map((data) => {
            return selectedMetricData.top_10_naics_code[selectedFilter][data];
          }),
          backgroundColor: selectedFilter === "bound" ? "#196501" : "#01008a",
        },
      ],
    };
    const naicsBarOptions = {
      // indexAxis: "y",
      // Elements options apply to all of the options unless overridden in a dataset
      // In this case, we are setting the border of each horizontal bar to be 2px wide
      // elements: {
      //   bar: {
      //     borderWidth: 2,
      //   },
      // },
      // responsive: true,
      title: {
        display: true,
        text: "Top 10 NAICS Code",
        fontSize: 14,
      },
      legend: { display: false },
      scales: {
        xAxes: [
          {
            scaleLabel: {
              display: true,
              labelString: "NAICS Code",
            },
          },
        ],
        yAxes: [
          {
            scaleLabel: {
              display: true,
              labelString:
                selectedMetric.charAt(0).toUpperCase() +
                selectedMetric.slice(1).toLowerCase(),
            },
            ticks: {
              beginAtZero: true,
              callback: function(value, index, ticks) {
                let label;
                selectedMetric !== "volume"
                  ? (label = Intl.NumberFormat("en-US", {
                      style: "currency",
                      currency: "USD",
                      currencyDisplay: "narrowSymbol",
                      maximumFractionDigits: 0,
                      notation:
                        window.innerWidth <= 540 ? "compact" : "standard",
                    }).format(value))
                  : (label = Intl.NumberFormat("en-US", {
                      maximumFractionDigits: 0,
                    }).format(value));

                return label;
              },
            },
          },
        ],
      },
      tooltips: {
        callbacks: {
          label: function(item, data) {
            let value = data["datasets"][0]["data"][item["index"]];
            value =
              selectedMetric !== "volume"
                ? new Intl.NumberFormat("en-US", {
                    style: "currency",
                    currency: "USD",
                  }).format(value)
                : Intl.NumberFormat("en-US").format(value);

            return value;
          },
          title: function() {
            return "";
          },
        },
      },
    };

    const lineChartData = {
      labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
      datasets: [
        {
          label: "dataset",
          data: [33, 25, 35, 51, 54, 76],
          fill: false,
          borderColor: "#a2b2ff",
          borderWidth: 2,
          lineTension: 0,
        },
      ],
    };

    let b_q_value =
      selectedMetricData.bound && selectedMetricData.quote
        ? Math.round(
            numeral(selectedMetricData.bound)
              .divide(selectedMetricData.quote)
              .multiply(100)
              .value()
          )
        : 0;

    let b_s_value =
      selectedMetricData.bound && selectedMetricData.submitted
        ? Math.round(
            numeral(selectedMetricData.bound)
              .divide(selectedMetricData.submitted)
              .multiply(100)
              .value()
          )
        : 0;

    let q_s_value =
      selectedMetricData.quote && selectedMetricData.submitted
        ? Math.round(
            numeral(selectedMetricData.quote)
              .divide(selectedMetricData.submitted)
              .multiply(100)
              .value()
          )
        : 0;

    let total_data_grid = {
      quote: Math.round(selectedMetricData.quote || 0),
      bound: Math.round(selectedMetricData.bound || 0),
      submitted: Math.round(selectedMetricData.submitted || 0),
      b_q: b_q_value,
      b_s: b_s_value,
      q_s: q_s_value,
    };

    let grid_data = [];
    let stateWiseGridData = selectedMetricData["state_wise_grid_data"];
    for (let state in stateWiseGridData) {
      let currRow = stateWiseGridData[state];
      let quote = numeral(Math.round(currRow.quote)).value();
      currRow.quote = numeral(quote).value();
      let submitted = numeral(Math.round(currRow.submitted || 0)).value();
      let bound = numeral(Math.round(currRow.bound || 0)).value();

      let b_q =
        currRow.bound && currRow.quote
          ? Math.round(
              numeral(currRow.bound)
                .divide(currRow.quote)
                .multiply(100)
                .value()
            )
          : numeral(0).value();

      let q_s =
        currRow.quote && currRow.submitted
          ? Math.round(
              numeral(currRow.quote)
                .divide(currRow.submitted)
                .multiply(100)
                .value()
            )
          : numeral(0).value();

      let b_s =
        currRow.bound && currRow.submitted
          ? Math.round(
              numeral(currRow.bound)
                .divide(currRow.submitted)
                .multiply(100)
                .value()
            )
          : numeral(0).value();

      grid_data.push({
        quote,
        submitted,
        bound,
        b_q,
        b_s,
        q_s,
        state: state,
      });
    }
    let pom =
      selectedFilter === "bound" ? dashboardData.pom_bound : dashboardData.pom;
    // console.log("grid_data: ", grid_data);

    return (
      <Container fluid className="px-0">
        {/* First Row */}
        <Row className="py-5">
          {this.props.tabName === "tab1" && (
            <Col md={2} className="d-flex align-items-center">
              <ColoredBox
                className="w-75 big-value mx-auto"
                text={["Average POM"]}
                amount={pom && Number(pom) ? numeral(pom).format("0.00") : 0.0}
                textColor="black"
                bgColor="#3df54a"
                maximumFractionDigits={2}
                isPOM={true}
              />
            </Col>
          )}
          <Col
            md={this.props.tabName === "tab1" ? 10 : 12}
            className={"d-flex justify-content-end"}
            style={{ fontSize: "16px" }}
          >
            <div style={{ width: "25%" }}>
              <p className="mb-0 textBlack">Metrics</p>
              <select
                name="metrics"
                className="w-100 py-1 textBlack"
                onChange={(e) =>
                  this.setState({ selectedMetric: e.target.value })
                }
              >
                <option className="py-2 textBlack" value="premium">
                  Premium
                </option>
                <option className="py-2 textBlack" value="payroll">
                  Payroll
                </option>
                <option className="py-2 textBlack" value="volume">
                  Volume
                </option>
              </select>
            </div>
            {this.props.tabName === "tab1" && (
              <div className="ml-4" style={{ width: "25%" }}>
                <p className="my-0 textBlack">Status</p>
                <select
                  name="metrics"
                  className="w-100 py-1 textBlack"
                  onChange={(e) =>
                    this.setState({ selectedFilter: e.target.value })
                  }
                >
                  <option className="py-2 textBlack" value="submitted">
                    Submitted
                  </option>
                  <option className="py-2 textBlack" value="bound">
                    Bound
                  </option>
                </select>
                <br />
                <span className="small textBlack">
                  This filter will only apply to the "Top 10 WC Codes", "Top 10
                  NAICS Codes" and the "Map" charts.
                </span>
              </div>
            )}
          </Col>
        </Row>

        {/* Second Row Boxes and Chart */}
        {this.props.tabName === "tab1" ? (
          <Row className="py-5">
            <Col
              style={{ gridGap: "1rem" }}
              xl={2}
              className="d-flex flex-xl-column align-items-center"
            >
              <ColoredBox
                text={["Submitted"]}
                amount={selectedMetricData.submitted}
                textColor="white"
                bgColor="#01008a"
                isCurrency={selectedMetric !== "volume"}
                maximumFractionDigits={0}
              />
              <ColoredBox
                text={["Quoted"]}
                amount={selectedMetricData.quote}
                textColor="black"
                bgColor="#ffd600"
                isCurrency={selectedMetric !== "volume"}
                maximumFractionDigits={0}
              />
              <ColoredBox
                text={["Sold"]}
                amount={selectedMetricData.bound}
                textColor="white"
                bgColor="#196501"
                isCurrency={selectedMetric !== "volume"}
                maximumFractionDigits={0}
              />
            </Col>
            <Col className="d-flex flex-column flex-md-row p-0">
              <Col md={6}>
                <Doughnut
                  data={conversionChartData}
                  options={conversionChartOptions}
                  // options={{tooltips:{callbacks:{}}}}
                  plugins={conversionChartPlugins}
                  text={doughnutText}
                  height={200}
                />
              </Col>
              <Col md={6}>
                <Pie
                  data={charge_vs_matched_charge_data}
                  options={charge_vs_matched_charge_options}
                  height={200}
                />
              </Col>
            </Col>
          </Row>
        ) : (
          false && (
            <Row>
              <Col md={6}>
                <Line data={lineChartData} />
              </Col>
              <Col md={6}>
                <MaterialTable
                  data={[
                    {
                      state: "Mehmet",
                      classCode: 100,
                      frequency: 1987,
                      data: {
                        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                        datasets: [
                          {
                            label: "dataset",
                            data: [33, 25, 35, 51, 54, 76],
                            fill: false,
                            borderColor: "#a2b2ff",
                            borderWidth: 2,
                            lineTension: 0,
                          },
                        ],
                      },
                    },
                    {
                      state: "Mehmet",
                      classCode: 100,
                      frequency: 1987,
                      data: {
                        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                        datasets: [
                          {
                            label: "dataset",
                            data: [33, 25, 35, 51, 54, 76],
                            fill: false,
                            borderColor: "#a2b2ff",
                            borderWidth: 2,
                            lineTension: 0,
                          },
                        ],
                      },
                    },
                    {
                      state: "Mehmet",
                      classCode: 100,
                      frequency: 1987,
                      data: {
                        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                        datasets: [
                          {
                            label: "dataset",
                            data: [33, 25, 35, 51, 54, 76],
                            fill: false,
                            borderColor: "#a2b2ff",
                            borderWidth: 2,
                            lineTension: 0,
                          },
                        ],
                      },
                    },
                    {
                      state: "Mehmet",
                      classCode: 100,
                      frequency: 1987,
                      data: {
                        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                        datasets: [
                          {
                            label: "dataset",
                            data: [33, 25, 35, 51, 54, 76],
                            fill: false,
                            borderColor: "#a2b2ff",
                            borderWidth: 2,
                            lineTension: 0,
                          },
                        ],
                      },
                    },
                    {
                      state: "Mehmet",
                      classCode: 100,
                      frequency: 1987,
                      data: {
                        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                        datasets: [
                          {
                            label: "dataset",
                            data: [33, 25, 35, 51, 54, 76],
                            fill: false,
                            borderColor: "#a2b2ff",
                            borderWidth: 2,
                            lineTension: 0,
                          },
                        ],
                      },
                    },
                    {
                      state: "Mehmet",
                      classCode: 100,
                      frequency: 1987,
                      data: {
                        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                        datasets: [
                          {
                            label: "dataset",
                            data: [33, 25, 35, 51, 54, 76],
                            fill: false,
                            borderColor: "#a2b2ff",
                            borderWidth: 2,
                            lineTension: 0,
                          },
                        ],
                      },
                    },
                    {
                      state: "Mehmet",
                      classCode: 100,
                      frequency: 1987,
                      data: {
                        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                        datasets: [
                          {
                            label: "dataset",
                            data: [33, 25, 35, 51, 54, 76],
                            fill: false,
                            borderColor: "#a2b2ff",
                            borderWidth: 2,
                            lineTension: 0,
                          },
                        ],
                      },
                    },
                    {
                      state: "Mehmet",
                      classCode: 100,
                      frequency: 1987,
                      data: {
                        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                        datasets: [
                          {
                            label: "dataset",
                            data: [33, 25, 35, 51, 54, 76],
                            fill: false,
                            borderColor: "#a2b2ff",
                            borderWidth: 2,
                            lineTension: 0,
                          },
                        ],
                      },
                    },
                    {
                      state: "Mehmet",
                      classCode: 100,
                      frequency: 1987,
                      data: {
                        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                        datasets: [
                          {
                            label: "dataset",
                            data: [33, 25, 35, 51, 54, 76],
                            fill: false,
                            borderColor: "#a2b2ff",
                            borderWidth: 2,

                            lineTension: 0,
                          },
                        ],
                      },
                    },
                    {
                      state: "Mehmet",
                      classCode: 100,
                      frequency: 1987,
                      data: {
                        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                        datasets: [
                          {
                            label: "dataset",
                            data: [33, 25, 35, 51, 54, 76],
                            fill: false,
                            borderColor: "#a2b2ff",
                            borderWidth: 2,
                            lineTension: 0,
                          },
                        ],
                      },
                    },
                    {
                      state: "Mehmet",
                      classCode: 100,
                      frequency: 1987,
                      data: {
                        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                        datasets: [
                          {
                            label: "dataset",
                            data: [33, 25, 35, 51, 54, 76],
                            fill: false,
                            borderColor: "#a2b2ff",
                            borderWidth: 2,
                            lineTension: 0,
                          },
                        ],
                      },
                    },
                    {
                      state: "Mehmet",
                      classCode: 100,
                      frequency: 1987,
                      data: {
                        labels: ["Jan", "Feb", "Mar", "Apr", "May", "Jun"],
                        datasets: [
                          {
                            label: "dataset",
                            data: [13, 45, 5, 51, 24, 16],
                            fill: false,
                            borderColor: "#a2b2ff",
                            borderWidth: 2,
                            lineTension: 0,
                          },
                        ],
                      },
                    },
                  ]}
                  columns={[
                    { title: "State", field: "state" },
                    {
                      title: "Class Code",
                      field: "classCode",
                      type: "numeric",
                    },
                    { title: "Frequency", field: "frequency", type: "numeric" },
                    {
                      title: "Range",
                      field: "range",
                      render: (row) => {
                        return (
                          <Line
                            data={row.data}
                            height={30}
                            width={250}
                            options={{
                              legend: false,
                              elements: {
                                point: {
                                  radius: 0,
                                },
                              },
                              responsive: false,
                              maintainAspectRatio: false,
                              scales: {
                                xAxes: [
                                  {
                                    display: false, //this will remove all the x-axis grid lines
                                  },
                                ],
                                yAxes: [
                                  {
                                    display: false, //this will remove all the x-axis grid lines
                                  },
                                ],
                              },
                            }}
                          />
                        );
                      },
                    },
                  ]}
                  options={{
                    padding: "dense",

                    showTitle: false,
                    paging: false,
                    search: false,
                    toolbar: false,
                    headerStyle: {
                      fontSize: "12px",
                      backgroundColor: "#003764",
                      color: "#FFF",
                    },
                    rowStyle: (row) => {
                      if (row.tableData.id % 2 != 0) {
                        return {
                          backgroundColor: "#e2f2ff",
                          fontSize: "12px",
                        };
                      } else {
                        return {
                          fontSize: "12px",
                        };
                      }
                    },
                  }}
                />
              </Col>
            </Row>
          )
        )}
        <Row className="py-5 justify-content-center">
          <Col
            md={6}
            className="d-flex align-items-center flex-column"
            style={{ gridGap: "1rem" }}
          >
            {this.props.tabName === "tab1" && (
              <Row className="w-100" style={{ gridGap: "1rem" }}>
                <ColoredBox
                  text={["Quote/Submitted"]}
                  amount={
                    selectedMetricData.quote && selectedMetricData.submitted
                      ? numeral(selectedMetricData.quote)
                          .divide(selectedMetricData.submitted)
                          .multiply(100)
                          .value()
                      : 0
                  }
                  textColor="white"
                  bgColor="black"
                  isPercent={true}
                  maximumFractionDigits={0}
                />
                <ColoredBox
                  text={["Bound/Quoted"]}
                  amount={
                    selectedMetricData.bound && selectedMetricData.quote
                      ? numeral(selectedMetricData.bound)
                          .divide(selectedMetricData.quote)
                          .multiply(100)
                          .value()
                      : 0
                  }
                  textColor="white"
                  bgColor="#3e3e3e"
                  isPercent={true}
                  maximumFractionDigits={0}
                />
                <ColoredBox
                  text={["Bound/Submitted"]}
                  amount={
                    selectedMetricData.bound && selectedMetricData.submitted
                      ? numeral(selectedMetricData.bound)
                          .divide(selectedMetricData.submitted)
                          .multiply(100)
                          .value()
                      : 0
                  }
                  textColor="white"
                  bgColor="#7a7a7a"
                  isPercent={true}
                  maximumFractionDigits={0}
                />
              </Row>
            )}
            <Row className="w-100">
              <Bar
                data={classCodeBarData}
                options={classCodeBarOptions}
                height={150}
              />
            </Row>
          </Col>
          {this.props.tabName === "tab1" && (
            <Col md={6}>
              <DashboardGrid
                dashboard_data={grid_data}
                selectedMetric={selectedMetric}
                total_data_grid={total_data_grid}
              />
            </Col>
          )}
        </Row>
        <Row className="py-5">
          <div className="text-center mb-4 w-100">
            <div>
              This section will only show quotes {this.state.selectedFilter}{" "}
              with {process.env.REACT_APP_MODE == "beta" ? "LD" : "CHUBB"}
            </div>
          </div>
          <Row className="w-100 m-0 section3">
            <Col md={3} className="">
              <Pie
                data={eNonE_ChartData}
                options={eVsNonE_ChartOptions}
                height={225}
              />
            </Col>
            <Col md={9} className="d-flex">
              <Col md={6}>
                <Bar
                  data={eNonE_e_Naics_BarData}
                  options={eNonE_e_Naics_BarOptions}
                  height={150}
                />
              </Col>
              <Col md={6}>
                <Bar
                  data={eNonE_non_e_Naics_BarData}
                  options={eNonE_non_e_Naics_BarOptions}
                  height={150}
                />
              </Col>
            </Col>
          </Row>
        </Row>
        <Row>
          <UsMap
            type={selectedMetric}
            filter={selectedFilter}
            dateList={[]}
            filter3=""
            data={selectedMetricData.states[selectedFilter]}
            tabName={this.props.tabName}
          />
          {/* {console.log(selectedMetricData.states.submitted)} */}
          {false && (
            <span className="footnote">
              * This filter will only apply to the "Top 10 Class Code", "Top 10
              NAICS Code" and the "Map" charts.
            </span>
          )}
        </Row>
      </Container>
    );
  };
  render() {
    let { selected, tabName } = this.props;
    let { isLoading } = this.state;
    return (
      <div
        className="Extensis-Dashboard"
        style={
          tabName !== selected ? { display: "none", fontSize: "12px" } : {}
          // { display: "none" }
        }
      >
        {/* <LoadingOverlay active={isLoading} spinner> */}
        {/* <div>{this.setTableData()}</div> */}
        {!isLoading ? (
          <div>{this.setChartData()}</div>
        ) : (
          <div className="d-flex justify-content-center align-items-center h-100 w-100">
            <div className="blink_me" style={{ fontSize: "35px" }}>
              {" "}
              Loading Charts...{" "}
            </div>
          </div>
        )}
        {/* </LoadingOverlay> */}
      </div>
    );
  }
}

const ColoredBox = ({
  text,
  amount,
  textColor,
  bgColor,
  isPercent,
  isCurrency,
  maximumFractionDigits,
  className,
  isPOM,
}) => {
  const boxStyle = {
    backgroundColor: bgColor,
    color: textColor ? textColor : "black",
  };
  return (
    <Col className="flex-grow-1 p-0">
      <div
        style={boxStyle}
        className={`px-2 py-4 text-center font-weight-bold colored-box ${className}`}
      >
        <p className={` mb-0 `}>{text}</p>
        <p className="mb-0 value">
          {isCurrency && typeof maximumFractionDigits !== "undefined"
            ? Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                currencyDisplay: "narrowSymbol",
                maximumFractionDigits: 0,
                notation: window.innerWidth <= 540 ? "compact" : "standard",
              }).format(amount)
            : isCurrency
            ? Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                currencyDisplay: "narrowSymbol",
                notation: window.innerWidth <= 540 ? "compact" : "standard",
                maximumFractionDigits,
              }).format(amount)
            : isPOM
            ? amount
            : Intl.NumberFormat("en-US", { maximumFractionDigits }).format(
                amount
              )}
          {isPercent && <>%</>}
        </p>
      </div>
    </Col>
  );
};

const Box = ({
  color,
  text,
  value,
  upArrow,
  textColor,
  padding,
  maximumFractionDigits,
  type,
}) => {
  return (
    <Col style={{ maxWidth: 130 }} className={`${padding}`}>
      <div
        style={{ backgroundColor: color }}
        className="rounded text-light p-2 h-100"
      >
        <p className="mb-1" style={{ color: textColor }}>
          {text}
        </p>
        <p className="mb-1" style={{ color: textColor }}>
          {type === "value"
            ? value.toFixed(maximumFractionDigits)
            : type === "percent"
            ? Intl.NumberFormat("en-US", {
                maximumFractionDigits,
              }).format(value) + "%"
            : Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
                maximumFractionDigits,
              }).format(value)}
        </p>
        {/* <div className="clearfix">
          {upArrow ? (
            <ArrowDropUpIcon
              className="float-right"
              style={{ color: "green" }}
              fontSize="large"
            />
          ) : (
            <ArrowDropDownIcon
              className="float-right"
              style={{ color: "red" }}
              fontSize="large"
            />
          )}
        </div> */}
      </div>
    </Col>
  );
};

export default DashboardExtensisRevamp;
