import React, { Component } from 'react';
import {clearErr} from '../../utils/common';
import { stat } from 'fs';
var tables = require('../../utils/tables.json');

class TableList extends Component {
    constructor(props){
        super(props);
        this.state = {selectValue:"Choose the Table",
                      rowsLength: 0
                  };
        this.handleChange = this.handleChange.bind(this);
      }
      handleChange=(e)=>{
        this.setState({selectValue:e.target.value});
        if(e.target.value !== "Choose the Table" && e.target.value !== "") {
          const rowsData = tables[e.target.value];
          this.props.rowsLength(rowsData, e.target.value);
        }
      }
      
      render(){
          
        const TableList = [<option key={0} value='' >{"Choose the Table"}</option>];
        for(var item in tables) {
            TableList.push(<option key={item} value={item}>{item}</option>);
        };
        let rows = [];
        if(this.state.selectValue !== "Choose the Table" && this.state.selectValue !== "") {
            for (var i = 0; i < 1; i++){
              let rowID = `row${i}`
              let cell = []
              for (var idx = 0; idx < tables[this.state.selectValue].length; idx++){
                let cellID = `cell${i}-${idx}`
                cell.push(<th className = "tablesTd" key={cellID} id={cellID}>{tables[this.state.selectValue][idx]}</th>)
              }
              rows.push(<tr key={i} id={rowID}>{cell}</tr>)
            }
        }
        return(
       
            <div className = "tableList">
                <h5 className = "tableList">Choose the table which one you want to upload!</h5>
                <select className='chooseTable' onChange={this.handleChange}>{TableList}</select>
                <div className= "">
                        <table id="simple-board" className="tablesTable">
                        <thead>
                            {rows}
                        </thead>
                        </table>
                       {rows.length > 0&&
                        <p>Note: Please upload your Data with respective this column names</p>
                       }
                </div>
          </div>
        )
      }
}
export default TableList;