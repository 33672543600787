import React, { Component } from "react";
import { Link } from "react-router-dom";
import { logout } from "../../utils/common";
import { Auth } from "aws-amplify";

class HeaderSubPart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userRole: [],
      userName: "",
      displayProfileMenu: false,
      displayQuotesMenu: false,
      displayQuoteButton: false,
      displayLoginButton: true,
      displayHowItWorks: false,
      displayResources: false,
      displayUnderWriterLink: false,
      displayComplianceLink: false,
      displayDashboard: false,
      selectedItem: sessionStorage.getItem("selectedItem") || "dashboard",
    };
  }

  componentDidMount() {
    let userRole = [];

    Auth.currentSession()
      .then((res) => {
        console.log("Current Authenticated Response User: ", res);
        userRole = res.accessToken.payload["cognito:groups"];
        let userName =
          res.idToken.payload.name || res.idToken.payload.email.split("@")[0];

        this.setState({
          userRole: userRole,
          userName: userName,
          userEmail: res.idToken.payload.email,
        });

        if (userName !== "" && userRole && userRole.length > 0) {
          this.setState({
            displayLoginButton: false,
            displayProfileMenu: true,
            displayQuoteButton: false,
          });
          sessionStorage.setItem("isLoggedIn", true);

          // Example user role handling logic (commented out in original)
          // if (userRole.includes(process.env.REACT_APP_EXTENSIS_USER_GROUP)) {
          //   this.setState({
          //     displayQuotesMenu: true,
          //     displayDashboard: true,
          //   });
          // } else if (userRole.includes("Carrier")) {
          //   this.setState({
          //     displayHowItWorks: false,
          //     displayResources: false,
          //     displayUnderWriterLink: true,
          //     displayDashboard: true,
          //   });
          // } else if (userRole.includes("Compliance") || userRole.includes("ComplianceAdmin")) {
          //   this.setState({
          //     displayHowItWorks: false,
          //     displayResources: false,
          //     displayComplianceLink: true,
          //   });
          // }
        } else {
          console.log("User could not log in.");
          this.setState({
            displayHowItWorks: true,
            displayResources: true,
          });
        }
      })
      .catch((err) => {
        if (err === "No current user") {
          console.log("Current Authenticated User: ", err);
        }
        this.setState({
          displayHowItWorks: true,
          displayResources: true,
        });
      });
  }

  handleItemClick = (item) => {
    this.setState({ selectedItem: item });
    sessionStorage.setItem("selectedItem", item);
  };

  isSelected = (item) => {
    return this.state.selectedItem === item
      ? {
          backgroundColor: "rgb(255, 90, 84)",
          borderRadius: "20px 0 20px 0",
          padding: "8px 15px 8px 15px",
          marginTop: "-5px",
          marginBottom: "-5px",
        }
      : {};
  };

  render() {
    let pathName = window.location.pathname;
    const { userRole } = this.state;
    let displayUploadDropdown,
      displayDownloadDropdown,
      displayDashboardLink,
      displayQuoteLink,
      displayComplianceLink,
      displayApiTrackingLink;

    let uploadGroups = [
      process.env.REACT_APP_UPLOAD_EXISTING_GROUP,
      process.env.REACT_APP_UPLOAD_RENEWAL_GROUP,
      process.env.REACT_APP_UPLOAD_NEW_GROUP,
    ];
    let downloadGroups = [process.env.REACT_APP_DOWNLOAD_EXISTING_GROUP];

    displayUploadDropdown = userRole.some((e) => uploadGroups.includes(e));
    displayDownloadDropdown = userRole.some((e) => downloadGroups.includes(e));

    if (
      pathName.toLowerCase() !== "/apitracking" &&
      process.env.REACT_APP_MODE === "dev" &&
      (userRole.includes(process.env.REACT_APP_DASHBOARD_TABLES_GROUP) ||
        userRole.includes(process.env.REACT_APP_DASHBOARD_STATS_GROUP))
    ) {
      displayApiTrackingLink = true;
    }

    if (
      pathName.toLowerCase() !== "/dashboard" &&
      (userRole.includes(process.env.REACT_APP_DASHBOARD_TABLES_GROUP) ||
        userRole.includes(process.env.REACT_APP_DASHBOARD_STATS_GROUP))
    ) {
      displayDashboardLink = true;
    }

    if (
      pathName.toLowerCase() !== "/quote" &&
      userRole.includes(process.env.REACT_APP_EXTENSIS_USER_GROUP)
    ) {
      displayQuoteLink = true;
    }

    if (
      pathName.toLowerCase() !== "/panelcompliance" &&
      userRole.includes(process.env.REACT_APP_COMPLIANCE_GROUP)
    ) {
      displayComplianceLink = true;
    }

    return (
      <ul className="h-100 headerSubMenuMainContent justify-content-between text-dark">
        <div>
          {pathName.toLowerCase() === "/upload-e" && (
            <li
              className="upload-guide"
              style={this.isSelected("upload-e")}
              onClick={() => this.handleItemClick("upload-e")}
            >
              <Link
                to={"/Existing Client Upload - Guidelines.pdf"}
                target="_blank"
                download
              >
                Existing Client Upload - Guidelines
              </Link>
            </li>
          )}
          {pathName.toLowerCase() === "/upload-r" && (
            <li
              className="upload-guide"
              style={this.isSelected("upload-r")}
              onClick={() => this.handleItemClick("upload-r")}
            >
              <Link
                to={"/Renewal Client Upload - Guidelines.pdf"}
                target="_blank"
                download
              >
                Renewal Client Upload - Guidelines
              </Link>
            </li>
          )}
        </div>

        <div className="d-flex headerSubMenuContent">
          <div>
            <li
              style={this.isSelected("apiTracking")}
              onClick={() => this.handleItemClick("apiTracking")}
            >
              <Link to={"/apiTracking"}>API Tracking</Link>
            </li>
          </div>

          <div>
            {displayDownloadDropdown && (
              <li
                id="Dropprofile"
                className="d-flex align-items-center"
                style={this.isSelected("downloadDropdown")}
                onClick={() => this.handleItemClick("downloadDropdown")}
              >
                <span
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Download
                </span>
                <div className="dropdown-menu">
                  {userRole.includes(
                    process.env.REACT_APP_DOWNLOAD_EXISTING_GROUP
                  ) && (
                    <Link className="dropdown-item" to={"/Download-e"}>
                      Existing
                    </Link>
                  )}
                  {userRole.includes(
                    process.env.REACT_APP_DOWNLOAD_EXISTING_GROUP
                  ) && (
                    <Link className="dropdown-item" to={"/Download-new"}>
                      New Business
                    </Link>
                  )}
                </div>
              </li>
            )}
          </div>

          <div>
            {displayUploadDropdown && (
              <li
                id="Dropprofile"
                className="d-flex align-items-center"
                style={this.isSelected("uploadDropdown")}
                onClick={() => this.handleItemClick("uploadDropdown")}
              >
                <span
                  className="dropdown-toggle"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Upload
                </span>
                <div className="dropdown-menu">
                  {userRole.includes(
                    process.env.REACT_APP_UPLOAD_EXISTING_GROUP
                  ) && (
                    <Link className="dropdown-item" to={"/upload-e"}>
                      Existing Clients
                    </Link>
                  )}
                  {userRole.includes(
                    process.env.REACT_APP_UPLOAD_RENEWAL_GROUP
                  ) && (
                    <Link className="dropdown-item" to={"/upload-r"}>
                      Renewal Clients
                    </Link>
                  )}
                  {userRole.includes(
                    process.env.REACT_APP_UPLOAD_NEW_GROUP
                  ) && (
                    <Link className="dropdown-item" to={"/upload-n"}>
                      NB-Existing Mapping
                    </Link>
                  )}
                </div>
              </li>
            )}
          </div>

          <div>
            {displayComplianceLink && (
              <li
                style={this.isSelected("compliancePortal")}
                onClick={() => this.handleItemClick("compliancePortal")}
              >
                <Link to={"/PanelCompliance"}>Compliance Portal</Link>
              </li>
            )}
          </div>

          <div>
            <li
              style={this.isSelected("dashboard")}
              onClick={() => this.handleItemClick("dashboard")}
            >
              <Link to={"/dashboard"}>Dashboard</Link>
            </li>
          </div>

          <div>
            <li
              style={this.isSelected("quote")}
              onClick={() => this.handleItemClick("quote")}
            >
              <Link to={"/Quote"}>Get Quote</Link>
            </li>
          </div>

          <div>
            {this.state.displayProfileMenu && (
              <li
                id="Dropprofile"
                className="d-flex align-items-center"
                style={this.isSelected("profileMenu")}
                onClick={() => this.handleItemClick("profileMenu")}
              >
                <span
                  className="dropdown-toggle text-capitalize"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {this.state.userName}
                </span>

                <div className="dropdown-menu">
                  <a className="dropdown-item" onClick={logout}>
                    Logout
                  </a>
                </div>
              </li>
            )}
          </div>
        </div>
      </ul>
    );
  }
}

export default HeaderSubPart;
