import axios from "axios";
import React, { Component } from "react";
import { awsUrl2 } from "../../../config";

class ProgramExpenses extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  createTable = () => {
    let { data } = this.state;
    let { clientRelationClass } = JSON.parse(JSON.stringify(this.props));
    if (clientRelationClass) {
      if (typeof clientRelationClass.carrier_a === "string") {
        clientRelationClass = clientRelationClass.carrier_a;
      } else {
        let tempClass;
        for (let state in clientRelationClass.carrier_a) {
          if (
            !tempClass ||
            clientRelationClass.carrier_a[state] === tempClass
          ) {
            tempClass = clientRelationClass.carrier_a[state];
          } else if (clientRelationClass.carrier_a[state] !== tempClass) {
            tempClass = undefined;
            break;
          }
        }
        clientRelationClass = tempClass;
      }
    }
    let {
      chubb_program_expenses,
      extensis_risk_margin,
      extensis_program_expenses,
    } = data;
    let tableData;

    tableData = (
      <div className="table-responsive">
        <table className="table table-sm table-bordered">
          <thead>
            <tr>
              <th rowSpan={2} className="main-header">
                Program Expenses
              </th>
              <th colSpan={4} className="main-header">
                Rate
              </th>
            </tr>
            <tr>
              <th
                className="text-center"
                style={
                  clientRelationClass === "super_preferred"
                    ? { backgroundColor: "#92d050" }
                    : {}
                }
              >
                Super Preferred
              </th>
              <th
                className="text-center"
                style={
                  clientRelationClass === "preferred"
                    ? { backgroundColor: "#92d050" }
                    : {}
                }
              >
                Preferred
              </th>
              <th
                className="text-center"
                style={
                  clientRelationClass === "standard"
                    ? { backgroundColor: "#92d050" }
                    : {}
                }
              >
                Standard
              </th>
              <th
                className="text-center"
                style={
                  clientRelationClass === "sub_standard"
                    ? { backgroundColor: "#92d050" }
                    : {}
                }
              >
                Sub Standard
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <th>
                {process.env.REACT_APP_MODE === "beta"
                  ? "Carrier Program Expenses"
                  : "Chubb Program Expenses"}
              </th>
              <td
                style={
                  clientRelationClass === "super_preferred"
                    ? { backgroundColor: "#ffff00" }
                    : {}
                }
              >
                {chubb_program_expenses
                  ? `${chubb_program_expenses.super_preferred}%`
                  : ""}
              </td>
              <td
                style={
                  clientRelationClass === "preferred"
                    ? { backgroundColor: "#ffff00" }
                    : {}
                }
              >
                {chubb_program_expenses
                  ? `${chubb_program_expenses.preferred}%`
                  : ""}
              </td>
              <td
                style={
                  clientRelationClass === "standard"
                    ? { backgroundColor: "#ffff00" }
                    : {}
                }
              >
                {chubb_program_expenses
                  ? `${chubb_program_expenses.standard}%`
                  : ""}
              </td>
              <td
                style={
                  clientRelationClass === "sub_standard"
                    ? { backgroundColor: "#ffff00" }
                    : {}
                }
              >
                {chubb_program_expenses
                  ? `${chubb_program_expenses.sub_standard}%`
                  : ""}
              </td>
            </tr>
            <tr>
              <th>
                {process.env.REACT_APP_MODE === "beta"
                  ? "PEO Program Expenses"
                  : "Extensis Program Expenses"}
              </th>
              <td
                style={
                  clientRelationClass === "super_preferred"
                    ? { backgroundColor: "#ffff00" }
                    : {}
                }
              >
                {extensis_program_expenses
                  ? `${extensis_program_expenses.super_preferred}%`
                  : ""}
              </td>
              <td
                style={
                  clientRelationClass === "preferred"
                    ? { backgroundColor: "#ffff00" }
                    : {}
                }
              >
                {extensis_program_expenses
                  ? `${extensis_program_expenses.preferred}%`
                  : ""}
              </td>
              <td
                style={
                  clientRelationClass === "standard"
                    ? { backgroundColor: "#ffff00" }
                    : {}
                }
              >
                {extensis_program_expenses
                  ? `${extensis_program_expenses.standard}%`
                  : ""}
              </td>
              <td
                style={
                  clientRelationClass === "sub_standard"
                    ? { backgroundColor: "#ffff00" }
                    : {}
                }
              >
                {extensis_program_expenses
                  ? `${extensis_program_expenses.sub_standard}%`
                  : ""}
              </td>
            </tr>
            <tr>
              <th>
                {process.env.REACT_APP_MODE === "beta"
                  ? "PEO Risk Margin"
                  : "Extensis Risk Margin"}
              </th>
              <td
                style={
                  clientRelationClass === "super_preferred"
                    ? { backgroundColor: "#ffff00" }
                    : {}
                }
              >
                {extensis_risk_margin
                  ? `${extensis_risk_margin.super_preferred}%`
                  : ""}
              </td>
              <td
                style={
                  clientRelationClass === "preferred"
                    ? { backgroundColor: "#ffff00" }
                    : {}
                }
              >
                {extensis_risk_margin
                  ? `${extensis_risk_margin.preferred}%`
                  : ""}
              </td>
              <td
                style={
                  clientRelationClass === "standard"
                    ? { backgroundColor: "#ffff00" }
                    : {}
                }
              >
                {extensis_risk_margin
                  ? `${extensis_risk_margin.standard}%`
                  : ""}
              </td>
              <td
                style={
                  clientRelationClass === "sub_standard"
                    ? { backgroundColor: "#ffff00" }
                    : {}
                }
              >
                {extensis_risk_margin
                  ? `${extensis_risk_margin.sub_standard}%`
                  : ""}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    );

    this.setState({ tableData });
  };

  componentDidMount = () => {
    try {
      axios
        .get(awsUrl2 + "/api/getProgramExpenseTableData")
        .then((response) => {
          this.setState({ data: response.data }, () => {
            this.createTable();
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  componentDidUpdate = (prevProps) => {
    let { clientRelationClass } = this.props;
    if (prevProps.clientRelationClass !== clientRelationClass) {
      this.createTable();
    }
  };
  render() {
    let { tableData } = this.state;
    return <div>{tableData}</div>;
  }
}

export default ProgramExpenses;
