let mode;

if (process.env.REACT_APP_MODE) {
  mode = process.env.REACT_APP_MODE;
} else {
  mode = "dev";
}

console.log("env", process.env);

var localURL = "http://localhost:3000/dev";

export var demoAuthUrl =
  "https://toyg4ps5yl.execute-api.us-east-1.amazonaws.com/dev";

// server_coreSetURL1;
export var awsUrl =
  "https://cnr8io6si7.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlProd = "https://6sst0ya3u4.execute-api.us-east-1.amazonaws.com/prod";
var awsUrlBeta = "https://5dp8xw5go5.execute-api.us-east-1.amazonaws.com/beta";

// server_coreSetURL2;
export var awsUrl2 =
  "https://jjx42vh2ob.execute-api.us-east-1.amazonaws.com/dev";
var awsUrlProd2 = "https://bbpkl9vqnb.execute-api.us-east-1.amazonaws.com/prod";
var awsUrlBeta2 = "https://1sreeheagf.execute-api.us-east-1.amazonaws.com/beta";

// dashboard URL
export var dashboardUrl = "";
var dashboardUrlProd = "";

if (mode === "local1") {
  awsUrl = localURL;
} else if (mode === "local2") {
  awsUrl2 = localURL;
} else if (mode === "locald") {
  dashboardUrl = localURL;
} else if (mode === "beta") {
  awsUrl = awsUrlBeta;
  awsUrl2 = awsUrlBeta2;
} else if (mode === "production") {
  awsUrl = awsUrlProd;
  awsUrl2 = awsUrlProd2;
  dashboardUrl = dashboardUrlProd;
}

export const versionNumber = "4.0.0.t6";
