import React, { Component } from "react";
import Statements from "./Statements";
import BinAndTable from "./BinAndTable";
import ProgramExpenses from "./ProgramExpenses";
import EppProgramSection from "./EppProgramSection";

class TabsSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: 0,
    };
  }
  render() {
    let { selectedTab } = this.state;
    let {
      quoteTable,
      programFactorData,
      tableSelectionData,
      binSelectionData,
      clientRelationClass,
      protectionLevelData,
      currProspect,
    } = this.props;
    let tabHeaders = [
      "Information Statements",
      "Table Bin Section",
      "Program Expenses",
      "EPP Program",
    ];
    return (
      <div className="tabs-section">
        <nav className="tabs-headers nav nav-pills nav-justified">
          {tabHeaders.map((header, index) => (
            <a
              className={
                selectedTab === index
                  ? "nav-item nav-link active"
                  : "nav-item nav-link"
              }
              key={index}
              onClick={() => this.setState({ selectedTab: index })}
            >
              {header}
            </a>
          ))}
        </nav>
        <div className="tabs-body">
          <div style={selectedTab === 0 ? {} : { display: "none" }}>
            {quoteTable && currProspect && (
              <Statements quoteTable={quoteTable} currProspect={currProspect} />
            )}
          </div>
          <div style={selectedTab === 1 ? {} : { display: "none" }}>
            <BinAndTable
              programFactorData={programFactorData}
              tableSelectionData={tableSelectionData}
              binSelectionData={binSelectionData}
            />
          </div>
          <div style={selectedTab === 2 ? {} : { display: "none" }}>
            <ProgramExpenses clientRelationClass={clientRelationClass} />
          </div>
          <div style={selectedTab === 3 ? {} : { display: "none" }}>
            <EppProgramSection protectionLevelData={protectionLevelData} />
          </div>
        </div>
      </div>
    );
  }
}

export default TabsSection;
