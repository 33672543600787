import React from "react";
import CustomDropZone from "./CustomDropZone";
import axios from "axios";
import { awsUrl2 } from "../../config";
import LoadingOverlay from "react-loading-overlay";
import { Auth } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
const moment = require("moment");

export default class DropZones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: {},
      uploadingInProgress: false,
      uploadMsg: undefined,
      errMsg: undefined,
      histFileList: {},
      histUploadingInProgress: false,
      histUploadMsg: undefined,
      histErrMsg: undefined,
      eppFileList: {},
      eppUploadingInProgress: false,
      eppUploadMsg: undefined,
      eppErrMsg: undefined,
      selectedTab: "profile",
      dataFromAPI: {
        first: [],
        second: [],
        third: [],
      },
      intervalID: undefined,
    };
  }

  async componentDidMount() {
    try {
      let intervalID = setInterval(async () => {
        await this.fetchFilesData();
      }, 5000);
      this.setState({ intervalID });
    } catch (error) {
      console.log("error in re-calling the table API");
    }
  }

  componentWillUnmount() {
    try {
      clearInterval(this.state.intervalID);
    } catch (error) {
      console.log("error in unmount interval removal");
    }
  }

  updateFileList = (newFile, flag) => {
    let { fileList, histFileList, eppFileList } = this.state;
    let files;
    if (flag === "first") {
      files = fileList;
    } else if (flag == "second") {
      files = histFileList;
    } else if (flag == "third") {
      files = eppFileList;
    }
    if (files[newFile.name] && files[newFile.name] === "#empty") {
      if (flag === "first") {
        this.setState({ errMsg: "File already exist", uploadMsg: undefined });
      } else if (flag == "second") {
        this.setState({
          histErrMsg: "File already exist",
          histUploadMsg: undefined,
        });
      } else if (flag == "third") {
        this.setState({
          eppErrMsg: "File already exist",
          eppUploadMsg: undefined,
        });
      }

      return;
    }
    files[newFile.name] = newFile;

    if (flag === "first") {
      fileList = files;
    } else if (flag == "second") {
      histFileList = files;
    } else if (flag == "third") {
      eppFileList = files;
    }

    this.setState({
      fileList,
      histFileList,
      eppFileList,
      errMsg: undefined,
      uploadMsg: undefined,
      histErrMsg: undefined,
      histUploadMsg: undefined,
      eppErrMsg: undefined,
      eppUploadMsg: undefined,
    });
  };

  removeFile = (fileName, flag) => {
    let { fileList, histFileList, eppFileList } = this.state;
    let files;

    if (flag === "first") {
      files = fileList;
    } else if (flag == "second") {
      files = histFileList;
    } else if (flag == "third") {
      files = eppFileList;
    }

    delete files[fileName];

    if (flag === "first") {
      fileList = files;
    } else if (flag == "second") {
      histFileList = files;
    } else if (flag == "third") {
      eppFileList = files;
    }

    this.setState({ fileList, histFileList, eppFileList });
  };

  uploadFile = async (fileData, postDataS3) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/uploadRenewalFile", postDataS3)
        .then((response) => {
          let returnData = response.data;
          let lossSignedURL = returnData.lossSignedURL;
          let options = { header: { "Content-Type": postDataS3.fileType } };
          axios
            .put(lossSignedURL, fileData, options)
            .then((result) => {
              console.log("file upload res: ", result);
              resolve(returnData.url);
            })
            .catch((error) => {
              console.log("error in uploading", error);
              reject();
            });
        })
        .catch((error) => {
          console.log("error in uploadLossForm ", error);
          reject();
        });
    });
  };

  putExtensisRenewalTable = (dataToSend, flag) => {
    return new Promise((resolve, reject) => {
      axios
        .post(awsUrl2 + "/api/putExtensisRenewalTable", dataToSend)
        .then((res) => {
          if (flag === "first") {
            this.setState({
              uploadMsg: "files uploaded",
              errMsg: undefined,
            });
          } else if (flag == "second") {
            this.setState({
              histUploadMsg: "files uploaded",
              histErrMsg: undefined,
            });
          } else if (flag == "third") {
            this.setState({
              eppUploadMsg: "files uploaded",
              eppErrMsg: undefined,
            });
          }
          resolve();
        })
        .catch((error) => {
          console.log("error in API-putExtensisRenewalTable", error);
          reject();
        });
    });
  };

  handleUpload = async (flag) => {
    try {
      let { fileList, histFileList, eppFileList } = this.state;
      let isLoggedIn = await Auth.currentAuthenticatedUser();
      let userEmail = isLoggedIn.attributes.email;
      let date = moment.utc().format("x");

      let files;
      if (flag == "first") {
        files = fileList;
        this.setState({ uploadingInProgress: true });
      } else if (flag == "second") {
        files = histFileList;
        this.setState({ histUploadingInProgress: true });
      } else if (flag == "third") {
        files = eppFileList;
        this.setState({ eppUploadingInProgress: true });
      }

      for (let currFileName in files) {
        if (currFileName && files[currFileName]) {
          let fileType = currFileName.split(".").reverse()[0];

          const postDataS3 = {
            fileName: currFileName,
            fileType,
            flag: `${flag}/${userEmail}`,
          };

          let url = await this.uploadFile(files[currFileName], postDataS3);

          await this.putExtensisRenewalTable(
            {
              uuid: uuidv4(),
              status: "NEW",
              filename: `${currFileName}`,
              timestamp: date,
              url,
              flag: `${flag}/${userEmail}`,
              emailTo: userEmail,
            },
            flag
          );

          await axios.post(
            awsUrl2 + `/api/upload_file_status/putData`,
            JSON.stringify({
              email: userEmail,
              client: "renewal",
              date: date,
              key: `${flag}/${userEmail}/${currFileName}`,
              status: "validating",
              folder: flag,
            })
          );

          await this.fetchFilesData();
          files[currFileName] = "#empty";
        }
      }

      if (flag === "first") {
        this.setState({ uploadingInProgress: false, fileList: files });
      } else if (flag == "second") {
        this.setState({
          histUploadingInProgress: false,
          histFileList: files,
        });
      } else if (flag == "third") {
        this.setState({
          eppUploadingInProgress: false,
          eppFileList: files,
        });
      }
    } catch (error) {
      console.log("error: ", error);
      if (flag === "first") {
        this.setState({
          uploadingInProgress: false,
          uploadMsg: undefined,
          errMsg: "error in uploading",
        });
      } else if (flag == "second")
        this.setState({
          histUploadingInProgress: false,
          histUploadMsg: undefined,
          histErrMsg: "error in uploading",
        });
      else if (flag == "third") {
        this.setState({
          eppUploadingInProgress: false,
          eppUploadMsg: undefined,
          eppErrMsg: "error in uploading",
        });
      }
    }
  };

  validate = (file, flag) => {};

  setSelectedState = (val) => {
    this.setState({ selectedTab: val });
  };

  fetchFilesData = async () => {
    let isLoggedIn = await Auth.currentAuthenticatedUser();
    let userEmail = isLoggedIn.attributes.email;
    let res = await axios.post(
      awsUrl2 + `/api/upload_file_status/getData`,
      JSON.stringify({
        email: userEmail,
        client: "renewal",
        uploadType: ["first", "second", "third"],
      })
    );
    this.setState({ dataFromAPI: res.data });
  };

  render() {
    let {
      fileList,
      uploadingInProgress,
      uploadMsg,
      errMsg,
      histFileList,
      histUploadingInProgress,
      histUploadMsg,
      histErrMsg,
      eppFileList,
      eppUploadingInProgress,
      eppUploadMsg,
      eppErrMsg,
      dataFromAPI,
      selectedTab,
    } = this.state;
    return (
      <>
        <div className="dropZonesContainer">
          <div className="row">
            <div className="col-sm-12 col-md-6 p-4">
              <LoadingOverlay active={uploadingInProgress} spinner>
                <CustomDropZone
                  fileList={fileList}
                  updateFileList={this.updateFileList}
                  removeFile={this.removeFile}
                  heading={<b>Company Profile Data</b>}
                  uploadMsg={uploadMsg}
                  flag={"first"}
                  downloadFileName={"renewal_company_profile_data.csv"}
                />
                <DropButton
                  uploadMsg={uploadMsg}
                  errMsg={errMsg}
                  handleUpload={this.handleUpload}
                  uploadingInProgress={uploadingInProgress}
                  flag={"first"}
                />
              </LoadingOverlay>
            </div>
            <div className="col-sm-12 col-md-6 p-4">
              <LoadingOverlay active={histUploadingInProgress} spinner>
                <CustomDropZone
                  fileList={histFileList}
                  updateFileList={this.updateFileList}
                  removeFile={this.removeFile}
                  heading={<b>Historical Loss Data</b>}
                  uploadMsg={histUploadMsg}
                  flag={"second"}
                  downloadFileName={"renewal_historical_data.csv"}
                />
                <DropButton
                  uploadMsg={histUploadMsg}
                  errMsg={histErrMsg}
                  handleUpload={this.handleUpload}
                  uploadingInProgress={histUploadingInProgress}
                  flag={"second"}
                />
              </LoadingOverlay>
            </div>
          </div>
          <div className="row">
            <div className="col-md-3"></div>
            <div className="col-sm-12 col-md-6 p-4">
              <LoadingOverlay active={eppUploadingInProgress} spinner>
                <CustomDropZone
                  fileList={eppFileList}
                  updateFileList={this.updateFileList}
                  removeFile={this.removeFile}
                  heading={<b>Epp Data</b>}
                  uploadMsg={eppUploadMsg}
                  flag={"third"}
                  downloadFileName={"EppRenewal.csv"}
                />
                <DropButton
                  uploadMsg={eppUploadMsg}
                  errMsg={eppErrMsg}
                  handleUpload={this.handleUpload}
                  uploadingInProgress={eppUploadingInProgress}
                  flag={"third"}
                />
              </LoadingOverlay>
            </div>
          </div>
        </div>
        <TabsForDropzones
          setSelectedState={this.setSelectedState}
          selectedTab={selectedTab}
          dataFromAPI={dataFromAPI}
        />
      </>
    );
  }
}

const DropButton = (props) => {
  return (
    <div className="d-flex flex-column">
      <pre
        className={
          "text-center text-capitalize " +
          (props.uploadMsg ? "text-success" : "text-danger")
        }
      >
        {props.uploadMsg || props.errMsg}
      </pre>
      <button
        className="btn upload-docs"
        style={{
          width: "100%",
          backgroundColor: "#1463AC",
          alignSelf: "center",
          color: "WHITE",
        }}
        onClick={() => props.handleUpload(props.flag)}
        disabled={props.uploadingInProgress}
      >
        Upload
      </button>
    </div>
  );
};

const TabsForDropzones = (props) => {
  return (
    <div className="row table-tabs mx-5 mt-5 mb-0 tabs-for-dropzones">
      <ul
        className="nav justify-content-center nav-pills mb-4"
        id="myTab"
        role="tablist"
      >
        <li className="nav-item mr-3 mx-2 my-2" role="presentation">
          <button
            className={"nav-link active"}
            id="company-profile-tab "
            type="button"
            role="tab"
            data-bs-toggle="tab"
            data-bs-target="#company-profile"
            aria-controls="company-profile"
            aria-selected={props.selectedTab === "profile" ? "true" : "false"}
            onClick={() => props.setSelectedState("profile")}
          >
            Company Profile Data
          </button>
        </li>
        <li className="nav-item mr-3 mx-2 my-2" role="presentation">
          <button
            className={"nav-link active"}
            id="historical-tab"
            type="button"
            role="tab"
            data-bs-toggle="tab"
            data-bs-target="#historical"
            aria-controls="historical"
            aria-selected={
              props.selectedTab === "historical" ? "true" : "false"
            }
            onClick={() => props.setSelectedState("historical")}
          >
            Historical Loss Data
          </button>
        </li>
        <li className="nav-item mr-3 mx-2 my-2" role="presentation">
          <button
            className={"nav-link active"}
            id="epp-tab"
            type="button"
            role="tab"
            data-bs-toggle="tab"
            data-bs-target="#epp"
            aria-controls="epp"
            aria-selected={props.selectedTab === "epp" ? "true" : "false"}
            onClick={() => props.setSelectedState("epp")}
          >
            Epp Data
          </button>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          class={
            props.selectedTab === "profile"
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
          id="company-profile"
          role="tabpanel"
          aria-labelledby="company-profile-tab"
        >
          <TableForTab data={props.dataFromAPI["first"]} />
        </div>
        <div
          class={
            props.selectedTab === "historical"
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
          id="historical"
          role="tabpanel"
          aria-labelledby="historical-tab"
        >
          <TableForTab data={props.dataFromAPI["second"]} />
        </div>
        <div
          class={
            props.selectedTab === "epp"
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
          id="epp"
          role="tabpanel"
          aria-labelledby="epp-tab"
        >
          <TableForTab data={props.dataFromAPI["third"]} />
        </div>
      </div>
    </div>
  );
};

const TableForTab = (props) => {
  let rowsData = props.data
    ? props.data.map((row, indx) => {
        return (
          <tr>
            <th scope="row">{indx + 1}</th>
            <td>
              {moment
                .utc(Number(row.date))
                .utcOffset("-0500")
                .format("M/D/YYYY - hh:mm A")}
            </td>
            <td>{row.email_client.split("_")[0]}</td>
            <td>{row.key.split("/").reverse()[0]}</td>
            <td>{row.status}</td>
          </tr>
        );
      })
    : [];

  return (
    <div className="table-responsive">
      <table class="table table-striped">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">Date</th>
            <th scope="col">Email</th>
            <th scope="col">File name</th>
            <th scope="col">Status</th>
          </tr>
        </thead>
        <tbody>{rowsData}</tbody>
      </table>
    </div>
  );
};
