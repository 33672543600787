import { awsUrl } from '../config'
import $ from 'jquery';
import axios from 'axios';
import { showErrAlert, getHeader, blobPdfFromBase64String } from './common';
import { Auth } from "aws-amplify";
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require('bootstrap');

export async function proceedBind(event, userName) {
  $('#loader').css('display', 'block');
  sessionStorage.setItem('require', event.target.id);
  let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
  if (!currProspect) {
    currProspect = {};
    currProspect.emailAddress = userName;
  }
  let tableName = "ProspectTable";
  currProspect.formStage = 'five';
  currProspect.require = event.target.id;

  const header = await getHeader();
  axios.post(awsUrl + "/api/postTablesData/" + tableName, JSON.stringify(currProspect), header).then(res => {
    sessionStorage.setItem('formStage', 'five');
    if (window.location.pathname === "/QuotesHistory") {
      window.location.href = './Quote'
    } else {
      window.location.reload();
    }
    $('#loader').css('display', 'none');
  }).catch(err => {
    $('#loader').css('display', 'none');
    console.log("error in propspect post", err);
    showErrAlert(err);
  });
}

export async function quoteView(quoteTable, currProspectData, userName, quoteStatusCarrier) {
  let validFlag = false;
  let getQuoteDetails = {};
  for (let carrier in quoteTable) {
    for (let state in quoteTable[carrier]) {
      if (quoteTable[carrier][state].selected === true) {
        validFlag = true;
      }
    }
  }

  if (validFlag) {
    this.setState({ showUploadProgress: true });
    for (let carrier in quoteTable) {
      getQuoteDetails[carrier] = { state: [] };
      for (let state in quoteTable[carrier]) {
        if (quoteStatusCarrier[carrier][state] !== "bound" && quoteTable[carrier][state].selected) {
          getQuoteDetails[carrier].carrier = carrier.split(" ").join("_");
          getQuoteDetails[carrier].email = userName;
          getQuoteDetails[carrier].timestamp = currProspectData.date;
          getQuoteDetails[carrier].state.push(state);
        }
      }
      if (getQuoteDetails[carrier].state.length === 0) {
        delete getQuoteDetails[carrier];
      }
    }
    console.log("++data view quote", getQuoteDetails);

  }

  Auth.currentSession().then(async res => {
    const USER_TOKEN = res.idToken.jwtToken;
    const header = { headers: { Authorization: "Bearer " + USER_TOKEN } };
    let quotePromises = [];
    for (let carrier in getQuoteDetails) {
      quotePromises.push(
        axios
          .post(
            awsUrl + "/api/generateQuotePDF",
            JSON.stringify(getQuoteDetails[carrier]),
            header
          )
          .then(res => {
            this.setState({ showUploadProgress: false });
            let pdf = res.data.data;
            let fileName = "Quote.pdf";
            let file = blobPdfFromBase64String(pdf);
            var fileURL = URL.createObjectURL(file);
            return {carrier, fileURL};
          })
          .catch(err => {
            return {carrier, error: "Could not generate quote."}
            this.setState({ showUploadProgress: false });
            console.log("Err", err);
          })
      );
    }
    let quoteFiles = await Promise.all(quotePromises);
    this.props.onQuoteReady(quoteFiles);
  });
}

export async function proceedBind2(data, userName, currProspectData, isHistoryVariant) {
  let validFlag = false;
  //new block
  const quoteTable = data;
  let require = "";
  for (let carrier in quoteTable) {
    for (let state in quoteTable[carrier]) {
      if (quoteTable[carrier][state].selected === true) {
        validFlag = true;
        if (require === "") {
          require = quoteTable[carrier][state].id;
        }
        else {
          require += `!!${quoteTable[carrier][state].id}`;
        }
      }
    }
  }

  if (validFlag) {
    $('#loader').css('display', 'block');
    //prev block
    sessionStorage.setItem('require', require);
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    if (!currProspect) {
      currProspect = currProspectData;
      currProspect.emailAddress = userName;
    }
    let tableName = "ProspectTable";
    currProspect.formStage = 'five';
    currProspect.require = require;
    currProspect.historicalQuote = isHistoryVariant;

    const header = await getHeader();
    axios.post(awsUrl + "/api/postTablesData/" + tableName, JSON.stringify(currProspect), header).then(res => {
      sessionStorage.setItem('formStage', 'five');
      sessionStorage.setItem('currProspect', JSON.stringify(currProspect));
      if (window.location.pathname === "/QuotesHistory") {
        window.location.href = './Quote'
      } else {
        window.location.reload();
      }
      $('#loader').css('display', 'none');
    }).catch(err => {
      $('#loader').css('display', 'none');
      console.log("error in propspect post", err);
      showErrAlert(err);
    });
  }
}
