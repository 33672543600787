import axios from "axios";
import numeral from "numeral";
import React from "react";
import { awsUrl2 } from "../../../config";
import LoadingOverlay from "react-loading-overlay";

class Statements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loaderActive: false,
      data: [],
    };
  }

  processData = () => {
    const { data } = this.state;
    const { quoteTable, currProspect } = this.props;
    let emodData;
    var processedData = [],
      emodFlag = false;

    emodData = currProspect.emodStatesData;
    let appendSaicStmt = "";
    if (
      quoteTable &&
      quoteTable.carrier_b &&
      quoteTable.carrier_b.state.quote
    ) {
      let chargeSaic = quoteTable.carrier_b.state.quote.charge;
      let range = numeral(chargeSaic).divide(4).value();
      let values = [
        Math.round(numeral(chargeSaic).subtract(range).value()),
        Math.round(numeral(chargeSaic).add(range).value()),
      ];
      appendSaicStmt = `(${numeral(values[0]).format("$0,0")} to ${numeral(
        values[1]
      ).format("$0,0")})`;
    }

    for (let emod in emodData) {
      let emodOj = emodData[emod];
      let flag1 =
        emodOj.rate1 && emodOj.response
          ? Number(emodOj.rate1.value) >= 1.25
          : false;
      let flag2 =
        emodOj.rate2 && emodOj.response && emodOj.displayMore
          ? Number(emodOj.rate2.value) >= 1.25
          : false;
      if (flag1 || flag2) {
        emodFlag = true;
        break;
      }
    }

    for (var section of ["section2", "section1", "section3", "section4"]) {
      var sectionValue = data[section];
      for (var sectionValueIndex in sectionValue) {
        var sectionValueObject = sectionValue[sectionValueIndex];
        if (section === "section1") {
          let { notes, payroll_requirements, sole_proprietors } =
            sectionValueObject;
          processedData.push(notes);
          processedData.push(payroll_requirements);
          processedData.push(sole_proprietors);
        } else if (section === "section2") {
          for (var stmtIndex in sectionValueObject) {
            if (sectionValueIndex === "flag_statements")
              processedData.unshift(sectionValueObject[stmtIndex].statement);
            else processedData.push(sectionValueObject[stmtIndex].statement);
          }
        } else if (section === "section3") {
          if (typeof quoteTable === undefined) continue;
          if (
            sectionValueIndex === "chubb_statements" &&
            quoteTable.carrier_a &&
            quoteTable.carrier_a.state.selected
          ) {
            for (var stmtIndex in sectionValueObject) {
              processedData.push(sectionValueObject[stmtIndex].statement);
            }
          } else if (
            sectionValueIndex === "saic_statements" &&
            quoteTable.carrier_b &&
            quoteTable.carrier_b.state.selected
          ) {
            for (var stmtIndex in sectionValueObject) {
              if (sectionValueObject[stmtIndex].statement_number === "13") {
                sectionValueObject[stmtIndex].statement += "" + appendSaicStmt;
              }
              processedData.push(sectionValueObject[stmtIndex].statement);
            }
          }
        } else if (section === "section4") {
          if (emodFlag) {
            for (var stmtIndex in sectionValueObject) {
              processedData.unshift(sectionValueObject[stmtIndex].statement);
            }
          }
        }
      }
    }

    return processedData;
  };

  isOkToPrint(obj) {
    return obj && obj.trim() !== "0" && obj.trim !== "";
  }

  componentDidMount = () => {
    try {
      this.setState({ loaderActive: true });
      let currProspect = this.props.currProspect,
        childrenLoc = currProspect.childrenLoc,
        stateClassList = [];

      for (let childrenLocIndex in childrenLoc) {
        let classCodesInfo = childrenLoc[childrenLocIndex].classCodesInfo;
        for (let classCodesInfoIndex in classCodesInfo) {
          let desc =
            classCodesInfo[classCodesInfoIndex].classCodeDescription.value;
          let descSplit = desc.split(":");
          stateClassList.push({
            state: childrenLoc[childrenLocIndex].state.value.toLowerCase(),
            class_code: descSplit[0],
            description: descSplit[1].trim().toLowerCase(),
          });
        }
      }

      let entityType =
        currProspect.companyProfile.entityType.value.toLowerCase();
      entityType =
        entityType === "sole proprietor" ? "sole_proprietor" : entityType;

      let dataToSend = {
        entity_type: entityType,
        state_class_codes: stateClassList,
      };

      axios
        .post(awsUrl2 + "/api/getStatementSection", dataToSend)
        .then((response) => {
          this.setState({ data: response.data });
        })
        .catch((error) => {
          console.log("error", error);
        })
        .finally(() => {
          this.setState({ loaderActive: false });
        });
    } catch (error) {
      this.setState({ loaderActive: false });
      console.log("unable to fetch information statements: ", error);
    }
  };

  render = () => {
    let { loaderActive } = this.state;
    var processedData = this.processData();

    return (
      <LoadingOverlay active={loaderActive} spinner>
        <section id="statements" className="overflow-auto">
          {processedData &&
            processedData.length > 0 &&
            processedData.map((stmt, index) => {
              if (this.isOkToPrint(stmt))
                return (
                  <div key={index} className="col-12 m-2">
                    {"* " + stmt}
                    <br />
                  </div>
                );
            })}
        </section>
      </LoadingOverlay>
    );
  };
}
export default Statements;
