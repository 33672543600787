import React, { Component } from "react";

class ImageBlock extends Component {
  constructor(props) {
    super(props);
  }

  handleScrollIntoView = (elementName) => {
    document.querySelector(elementName).scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  };

  render() {
    let { scrollToElement, imageURL, altText } = this.props;

    return (
      <div
        className="image-block d-flex align-items-center w-100 mb-5"
        onClick={() => this.handleScrollIntoView(scrollToElement)}
      >
        <img src={imageURL} alt={altText || ""} />
        <div className="w-100 d-flex align-items-center justify-content-center image-text-container">
          <span className="image-text">HR Solutions Tailored for You</span>
        </div>
      </div>
    );
  }
}

export default ImageBlock;
