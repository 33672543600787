import React, { Component } from "react";
import axios from "axios";
import { awsUrl2 } from "../../../config";

class EppProgramSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
    };
  }

  createTable = () => {
    let { data } = this.state;
    let { protectionLevelData } = this.props;
    let { charge, cost } = data;
    let {
      charge: levelCharge,
      cost: levelCost,
      credit_debit,
      status,
    } = protectionLevelData ? protectionLevelData : {};
    let {
      durationData,
      pricingRisk,
      protectionLevels: chargeProtectionLevels,
    } = charge ? charge : {};
    let { protectionLevels: costProtectionLevels } = cost ? cost : {};
    let tableData;

    tableData = (
      <div class="table-responsive">
        <table className="table table-sm table-bordered">
          <thead>
            <tr>
              <th colSpan={6} className="main-header">
                EPP Program
              </th>
            </tr>
            <tr>
              <th colSpan={6} className="sub-header">
                Charges
              </th>
            </tr>
            <tr>
              <th colSpan={6}>Client Relationship Duration:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td></td>
              <td
                style={status === "new" ? { backgroundColor: "#92d050" } : {}}
              >
                New
              </td>
              <td
                style={
                  status === "legacy" ? { backgroundColor: "#92d050" } : {}
                }
              >
                Legacy
              </td>
              {/* <td
              style={
                status === "renewal_2" ? { backgroundColor: "#92d050" } : {}
              }
            >
              2nd Renewal
            </td>
            <td
              style={
                status === "renewal_3" ? { backgroundColor: "#92d050" } : {}
              }
            >
              3rd Renewal
            </td> */}
              {/* <td></td> */}
            </tr>
            <tr>
              <td></td>
              <td
                className="text-capitalize"
                style={status === "new" ? { backgroundColor: "#ffff00" } : {}}
              >
                {durationData ? durationData.new : ""}
              </td>
              <td
                className="text-capitalize"
                style={
                  status === "legacy" ? { backgroundColor: "#ffff00" } : {}
                }
              >
                {durationData ? durationData.legacy : ""}
              </td>
              {/* <td className="text-capitalize">
              {durationData ? durationData.renewal_2 : ""}
            </td>
            <td className="text-capitalize">
              {durationData ? durationData.renewal_3 : ""}
            </td>
            <td></td> */}
            </tr>
            <tr>
              <th colSpan={6}>Protection Levels:</th>
            </tr>
            <tr>
              <td rowSpan={2} className="left-header">
                Per WSE per month (PEPM):
              </td>
              <td
                style={
                  levelCharge === "primary"
                    ? { backgroundColor: "#92d050" }
                    : {}
                }
              >
                Primary
              </td>
              <td
                style={
                  levelCharge === "secondary"
                    ? { backgroundColor: "#92d050" }
                    : {}
                }
              >
                Secondary
              </td>
              {/* <td
              style={
                levelCharge === "advanced" ? { backgroundColor: "#92d050" } : {}
              }
            >
              3-Advanced
            </td>
            <td
              style={
                levelCharge === "basic_excess"
                  ? { backgroundColor: "#92d050" }
                  : {}
              }
            >
              4-Basic Excess
            </td>
            <td
              style={
                levelCharge === "limited" ? { backgroundColor: "#92d050" } : {}
              }
            >
              5-Limited
            </td> */}
            </tr>
            <tr>
              <td
                style={
                  levelCharge === "primary"
                    ? { backgroundColor: "#ffff00" }
                    : {}
                }
              >{`$${
                chargeProtectionLevels ? chargeProtectionLevels.primary : ""
              }`}</td>
              <td
                style={
                  levelCharge === "secondary"
                    ? { backgroundColor: "#ffff00" }
                    : {}
                }
              >{`$${
                chargeProtectionLevels ? chargeProtectionLevels.secondary : ""
              }`}</td>
              {/* <td
              style={
                levelCharge === "advanced" ? { backgroundColor: "#ffff00" } : {}
              }
            >{`$${chargeProtectionLevels ? chargeProtectionLevels.advanced : ""
              }`}</td>
            <td
              style={
                levelCharge === "basic_excess"
                  ? { backgroundColor: "#ffff00" }
                  : {}
              }
            >{`$${chargeProtectionLevels ? chargeProtectionLevels.basic_excess : ""
              }`}</td>
            <td
              style={
                levelCharge === "limited" ? { backgroundColor: "#ffff00" } : {}
              }
            >{`$${chargeProtectionLevels ? chargeProtectionLevels.limited : ""
              }`}</td> */}
            </tr>
            <tr>
              <th colSpan={6}>Pricing and Risk Factor:</th>
            </tr>
            <tr>
              <td rowSpan={2} className="left-header">
                PEPM rate credit / debit
              </td>
              <td
                style={
                  credit_debit === "super_preferred"
                    ? { backgroundColor: "#92d050" }
                    : {}
                }
              >
                1-Super Preferred
              </td>
              <td
                style={
                  credit_debit === "preferred"
                    ? { backgroundColor: "#92d050" }
                    : {}
                }
              >
                2-Preferred
              </td>
              <td
                style={
                  credit_debit === "standard"
                    ? { backgroundColor: "#92d050" }
                    : {}
                }
              >
                3-Standard
              </td>
              <td
                style={
                  credit_debit === "sub_standard"
                    ? { backgroundColor: "#92d050" }
                    : {}
                }
              >
                4-Sub Standard
              </td>
            </tr>
            <tr>
              <td
                style={
                  credit_debit === "super_preferred"
                    ? { backgroundColor: "#ffff00" }
                    : {}
                }
              >
                {pricingRisk ? `${pricingRisk.super_preferred}%` : ""}
              </td>
              <td
                style={
                  credit_debit === "preferred"
                    ? { backgroundColor: "#ffff00" }
                    : {}
                }
              >
                {pricingRisk ? `${pricingRisk.preferred}%` : ""}
              </td>
              <td
                style={
                  credit_debit === "standard"
                    ? { backgroundColor: "#ffff00" }
                    : {}
                }
              >
                {pricingRisk ? `${pricingRisk.standard}%` : ""}
              </td>
              <td
                style={
                  credit_debit === "sub_standard"
                    ? { backgroundColor: "#ffff00" }
                    : {}
                }
              >
                {pricingRisk ? `${pricingRisk.sub_standard}%` : ""}
              </td>
            </tr>
            <tr>
              <th colSpan={6} className="sub-header">
                Cost
              </th>
            </tr>
            <tr>
              <td rowSpan={2} className="left-header">
                Per WSE per month (PEPM):
              </td>
              <td
                style={
                  levelCharge === "primary"
                    ? { backgroundColor: "#92d050" }
                    : {}
                }
              >
                Primary
              </td>
              <td
                style={
                  levelCharge === "secondary"
                    ? { backgroundColor: "#92d050" }
                    : {}
                }
              >
                Secondary
              </td>
              {/* <td
              style={
                levelCost === "advanced" ? { backgroundColor: "#92d050" } : {}
              }
            >
              3-Advanced
            </td>
            <td
              style={
                levelCost === "basic_excess"
                  ? { backgroundColor: "#92d050" }
                  : {}
              }
            >
              4-Basic Excess
            </td>
            <td
              style={
                levelCost === "limited" ? { backgroundColor: "#92d050" } : {}
              }
            >
              5-Limited
            </td> */}
            </tr>
            <tr>
              <td
                style={
                  levelCharge === "primary"
                    ? { backgroundColor: "#ffff00" }
                    : {}
                }
              >{`$${
                chargeProtectionLevels ? chargeProtectionLevels.primary : ""
              }`}</td>
              <td
                style={
                  levelCharge === "secondary"
                    ? { backgroundColor: "#ffff00" }
                    : {}
                }
              >{`$${
                chargeProtectionLevels ? chargeProtectionLevels.secondary : ""
              }`}</td>
              {/* <td
              style={
                levelCost === "advanced" ? { backgroundColor: "#ffff00" } : {}
              }
            >{`$${costProtectionLevels ? costProtectionLevels.advanced : ""
              }`}</td>
            <td
              style={
                levelCost === "basic_excess"
                  ? { backgroundColor: "#ffff00" }
                  : {}
              }
            >{`$${costProtectionLevels ? costProtectionLevels.basic_excess : ""
              }`}</td>
            <td
              style={
                levelCost === "limited" ? { backgroundColor: "#ffff00" } : {}
              }
            >{`$${costProtectionLevels ? costProtectionLevels.limited : ""
              }`}</td> */}
            </tr>
          </tbody>
        </table>
      </div>
    );

    this.setState({ tableData });
  };

  componentDidMount = () => {
    try {
      axios
        .get(awsUrl2 + "/api/getEppProgramTableData")
        .then((response) => {
          this.setState({ data: response.data }, () => {
            this.createTable();
          });
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  componentDidUpdate = (prevProps) => {
    let { protectionLevelData } = this.props;
    if (
      JSON.stringify(protectionLevelData) !==
      JSON.stringify(prevProps.protectionLevelData)
    ) {
      this.createTable();
    }
  };

  render() {
    let { tableData } = this.state;
    return <div>{tableData}</div>;
  }
}

export default EppProgramSection;
