import React, { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";
import Navbar from "../common/Navbar";
import Footer from "../common/Footer";
import Loader from "../common/Loader";
import SmallLoader from "../common/SmallLoader";
import Papa from "papaparse";
import axios from "axios";
import { awsUrl2, versionNumber } from "../../config";
import { getHeader } from "../../utils/common";
import { Auth } from "aws-amplify";
import { CSVLink } from "react-csv";
import { v4 as uuidv4 } from "uuid";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import LoadingOverlay from "react-loading-overlay";

const csvjson = require("csvjson");
const state_map = require("../../utils/state_map.json");

const stateList = [
  "AK",
  "AL",
  "AR",
  "AZ",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "IA",
  "ID",
  "IL",
  "IN",
  "KS",
  "KY",
  "LA",
  "MA",
  "MD",
  "ME",
  "MI",
  "MN",
  "MO",
  "MS",
  "MT",
  "NC",
  "ND",
  "NE",
  "NH",
  "NJ",
  "NM",
  "NV",
  "NY",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VA",
  "VT",
  "WA",
  "WI",
  "WV",
  "WY",
];

const carrierMap = {
  carrier_a: "ExtensisHR-CHUBB",
  carrier_b: "ExtensisHR-FIC",
  carrier_o: "State Fund",
};
class PanelCompliance extends Component {
  constructor(props) {
    super(props);
    this.downloadBtn = React.createRef();
    this.state = {
      error: false,
      validationDone: false,
      tableName: "eligibility",
      downloadDataList: [
        { a: 1, b: 2 },
        { a: 2, b: 3 },
      ],
      downloadLinkActive: false,
      downloadedTableName: "",
      maxStateError: false,
    };
  }

  componentDidMount() {
    ReactDOM.findDOMNode(this).scrollTop = 0;
    let userRole;
    Auth.currentSession().then((res) => {
      let email = res.idToken.payload.email;
      userRole = res.accessToken.payload["cognito:groups"];
      userRole = userRole.includes("Compliance")
        ? "Compliance"
        : userRole.includes("ComplianceAdmin")
        ? "ComplianceAdmin"
        : userRole[0];
      this.setState({ userRole, email });
      if (userRole.includes("Compliance")) {
        this.getCarrierName();
      }
    });
    if (this.state.tableName === "eligibility") {
      let statesDropDownList = [{ value: "Add all", label: "Add all" }];
      statesDropDownList = [
        ...statesDropDownList,
        ...stateList.map((state) => {
          return { value: state.toLowerCase(), label: state };
        }),
      ];
      this.setState({ statesDropDownList });
    }
  }

  getTableName = (e) => {
    let tableName = e.target.value;
    const { csvTextArray } = this.state;
    if (csvTextArray) {
      try {
        // let textValidation = this.validateFileText(csvTextArray, tableName);

        // if (textValidation.length === 0) {
        this.setState({
          textValidation: false,
          error: false,
          validationDone: true,
        });
        // } else {
        //   this.setState({
        //     textValidation,
        //     error: true,
        //     errorMessage: `Data is not valid. ${textValidation.length} rows have issues`,
        //     validationDone: false,
        //   });
        // }
      } catch (e) {
        console.log(e);
        this.setState({
          textValidation: false,
          error: true,
          errorMessage: "Please select a valid CSV file",
          validationDone: false,
        });
      }
    }
    if (tableName === "eligibility") {
      let statesDropDownList = [{ value: "Add all", label: "Add all" }];
      statesDropDownList = [
        ...statesDropDownList,
        ...stateList.map((state) => {
          return { value: state.toLowerCase(), label: state };
        }),
      ];
      this.setState({ statesDropDownList, tableName });
      return;
    }
    this.setState({
      tableName: tableName,
    });
  };

  readFile = (file) => {
    let fileReader = new FileReader();
    return new Promise((resolve, reject) => {
      fileReader.onload = () => {
        // let txt = fileReader.result;
        resolve(fileReader.result);
      };
      fileReader.readAsText(file);
    });
  };

  parseFileText = (text) => {
    let papa = Papa.parse(text, { header: true });
    return papa.data;
  };

  validateFileText = (array, tableName) => {
    const { userRole } = this.state;
    let headers, dateHeadersList, response, uniqureResponseSet, dataValidator;
    let keyCheckMap = {};
    let numHeadersList = [];
    let numExceptionValuesList = [];

    if (tableName === "eligibility") {
      headers = [
        "class_code",
        "state",
        "description",
        "eligibility",
        "hazard",
        "naics",
        "sic",
      ];
      numHeadersList = ["class_code"];
      keyCheckMap["eligibility"] = ["excluded", "restricted", "approved"];
      // keyCheckMap["key"] = ["key", "non_key"];
      if (userRole === "ComplianceAdmin") {
        headers.push("carrier");
        keyCheckMap["carrier"] = Object.keys(carrierMap);
      }
    } else if (tableName === "lcm") {
      headers = ["lcm", "lcm_date", "state"];
      dateHeadersList = ["lcm_date"];
      numHeadersList = ["lcm"];
      if (userRole === "ComplianceAdmin") {
        headers.push("carrier");
        keyCheckMap["carrier"] = Object.keys(carrierMap);
      }
    } else if (tableName === "generalFactor") {
      headers = ["value", "effective_date", "state", "factor_name"];
      dateHeadersList = ["effective_date"];
      numHeadersList = ["value"];
      numExceptionValuesList = ["ncci", "nycirb", "wi", "njcrib"];
      if (userRole === "ComplianceAdmin") {
        headers.push("carrier");
        keyCheckMap["carrier"] = Object.keys(carrierMap);
      }
    } else if (tableName === "adoption") {
      headers = ["lc_date", "adoption_date", "state"];
      dateHeadersList = ["lc_date", "adoption_date"];
      if (userRole === "ComplianceAdmin") {
        headers.push("carrier");
        keyCheckMap["carrier"] = Object.keys(carrierMap);
      }
    } else if (tableName === "lossCost") {
      headers = ["lc_date", "class_alpha", "class_code", "state", "loss_cost"];
      dateHeadersList = ["lc_date"];
      numHeadersList = ["loss_cost", "class_code"];
    } else if (tableName === "stateSpecific") {
      headers = ["effective_date", "factor_name", "state", "value"];
      dateHeadersList = ["effective_date"];
      numHeadersList = ["value"];
    } else if (tableName === "limits") {
      headers = [
        "effective_date",
        "afela",
        "afela_min",
        "state",
        "min_prem",
        "rate",
        "el_limits",
      ];
      dateHeadersList = ["effective_date"];
    } else if (tableName === "taxes") {
      headers = ["effective_date", "state", "description", "factor", "value"];
      dateHeadersList = ["effective_date"];
      numHeadersList = ["value"];
    }

    dataValidator = this.dataValidator(
      array,
      headers,
      dateHeadersList,
      keyCheckMap,
      numHeadersList,
      numExceptionValuesList
    );
    return dataValidator;
  };

  dataValidator = (
    array,
    headers,
    dateHeadersList,
    keyCheckMap,
    numHeadersList,
    numExceptionValuesList
  ) => {
    let missingDataIndexList = [];
    if (!dateHeadersList) {
      dateHeadersList = [];
    }
    let index = 0;
    for (let dict of array) {
      delete dict[""];
      let haveError = false;
      let keys = Object.keys(dict);
      // let values = Object.values(dict);
      for (let key in dict) {
        if (headers.includes(key) && (dict[key] === "" || !dict[key])) {
          console.log("++ here1");
          if (!haveError) {
            missingDataIndexList.push(dict);
            haveError = true;
          }
        }
      }
      for (let header of headers) {
        if (!keys.includes(header) && index !== array.length) {
          console.log("++ here2");
          if (!haveError) {
            missingDataIndexList.push(dict);
            haveError = true;
          }
        }
        if (keyCheckMap[header] && index !== array.length) {
          if (!keyCheckMap[header].includes(dict[header])) {
            console.log("++ here3");
            if (!haveError) {
              missingDataIndexList.push(dict);
              haveError = true;
            }
          }
        }
        if (numHeadersList.includes(header)) {
          if (isNaN(dict[header])) {
            // if (
            // 	!numExceptionValuesList.includes(
            // 		dict[header]
            // 	)
            // ) {
            console.log("++ here4");
            if (!haveError) {
              missingDataIndexList.push(dict);
              haveError = true;
            }
            // }
          }
        }
        if (dateHeadersList.includes(header)) {
          let date = dict[header];
          if (
            !moment.utc(date, "M-D-YYYY", true).isValid() &&
            !moment.utc(date, "M/D/YYYY", true).isValid() &&
            index !== array.length - 1
          ) {
            console.log("++ here5");
            if (!haveError) {
              missingDataIndexList.push(dict);
              haveError = true;
            }
          }
        }
      }
      if (haveError) {
        dict.delete = true;
      }
      index++;
    }

    return missingDataIndexList;
  };

  getDropdownButtonLabel = (labelData) => {
    const { value } = labelData;
    const { eligibilityState, statesDropDownList } = this.state;
    if (
      !value ||
      !eligibilityState ||
      (eligibilityState && eligibilityState.length === 0)
    )
      return "Select...";

    let placeHolder;
    if (
      eligibilityState &&
      value &&
      value.length === statesDropDownList.length
    ) {
      placeHolder = "All selected";
    } else {
      let selectedCount = 0;
      value.forEach((e) => {
        if (e.value !== "Add all") {
          selectedCount++;
        }
      });
      placeHolder = `${selectedCount.toString()} selected`;
    }
    return placeHolder;
  };

  handleStateDropDownChange = (selected) => {
    let { selectedOption } = this.state;
    let eligibilityState = [];
    let isAllSelected = false;
    let previousAll = false;

    selectedOption = selectedOption ? selectedOption : [];

    selectedOption.forEach((e) => {
      if (e.label === "Add all") {
        previousAll = true;
      }
    });

    if (previousAll) {
      this.setState({
        selectedOption: [],
        eligibilityState: [],
      });
      return;
    }

    selectedOption = [];

    selected.forEach((e) => {
      if (e.label === "Add all") {
        isAllSelected = true;
      }
    });
    if (isAllSelected) {
      this.state.statesDropDownList.forEach((e) => {
        if (e.label !== "Add all") eligibilityState.push(e.value);
        if (selectedOption.length <= 40) selectedOption.push(e);
        else this.setState({ maxStateError: true });
      });
    } else {
      selected.forEach((e) => {
        if (e.label !== "Add all") eligibilityState.push(e.value);
        if (selectedOption.length <= 40) selectedOption.push(e);
        else this.setState({ maxStateError: true });
      });
    }
    if (eligibilityState.length > 0)
      this.setState({
        eligibilityState,
        stateCarrierRequired: false,
        selectedOption,
      });
  };

  validateFormat = async (e) => {
    const { tableName } = this.state;
    this.setState({ fileUploadSuccess: false });
    try {
      e.persist();
      let fileType = e.target.files[0].name.split(".").reverse()[0];
      let files = e.target.files[0];
      if (fileType === "csv") {
        // see if the file has any text
        let text = await this.readFile(files);
        if (text.trim()) {
          // now convert the text to array of objects
          let parsedData = this.parseFileText(text);
          parsedData.length--;
          // if (parsedData.length < 100) {
          // let textValidation = this.validateFileText(parsedData, tableName);

          this.setState({
            textValidation: false,
            csvTextArray: parsedData,
            error: false,
            validationDone: true,
          });

          // } else {
          //   this.setState({
          //     error: true,
          //     errorMessage: `CSV file cannot exceed 100 rows.`,
          //     validationDone: false,
          //   });
          // }
        } else {
          this.setState({
            textValidation: false,
            error: true,
            errorMessage: "This file is empty. Select a valid file.",
            validationDone: false,
          });
        }
      } else {
        this.setState({
          textValidation: false,
          error: true,
          errorMessage: "Please select a valid CSV file",
          validationDone: false,
        });
      }
    } catch (error) {
      console.log("error", error);
      this.setState({
        textValidation: false,
        error: true,
        errorMessage: "Error in selected file",
        validationDone: false,
      });
    }
  };

  uploadFileS3 = async (fileData, postDataS3) => {
    return new Promise(async (resolve, reject) => {
      const header = await getHeader();
      await axios
        .post(awsUrl2 + "/api/compFile/file", postDataS3, header)
        .then(async (response) => {
          let returnData = response.data;
          let lossSignedURL = returnData.url;
          let options = {
            header: { "Content-Type": "csv" },
          };
          const csvData = csvjson.toCSV(fileData, { headers: "key" });
          await axios
            .put(lossSignedURL, csvData, options)
            .then((result) => {
              console.log("file upload res: ", result);
              resolve(returnData.url);
            })
            .catch((error) => {
              console.log("error in uploading", error);
              reject();
            });
        })
        .catch((error) => {
          console.log("error in uploadLossForm ", error);
          reject();
        });
    });
  };

  uploadFile = async (e) => {
    const { validationDone, userName } = this.state;
    this.setState({ fileUploadSuccess: false, fileUploadFail: false });
    if (validationDone) {
      this.setState({ showLoaderUpload: true });
      const { csvTextArray } = this.state;
      // get the name of the table and upload the data to that table
      let { tableName, userRole, email } = this.state;
      const header = await getHeader();
      if (csvTextArray) {
        let body = {
          data: csvTextArray,
        };
        let uuid = uuidv4();

        let url = await this.uploadFileS3(csvTextArray, {
          fileName: `${uuid}.csv`,
        });
        await axios
          .post(
            awsUrl2 + "/api/compFile/put",
            JSON.stringify({
              uuid,
              tableName,
              url,
              groups:
                userRole === "Compliance"
                  ? ["Compliance"]
                  : ["ComplianceAdmin"],
              userName: userName,
              type: "upload",
              carrier: userName,
              emailTo: email,
            }),
            header
          )
          .then(async (res) => {
            let response_comp;

            while (!response_comp) {
              await axios
                .post(
                  awsUrl2 + "/api/compFile/get",
                  JSON.stringify({
                    uuid,
                    tableName,
                  }),
                  header
                )
                .then((res) => {
                  let data = res.data[0];
                  console.log("-data: ", data);
                  if (data.status) {
                    response_comp = data.status;
                    this.setState({
                      showLoaderUpload: false,
                      fileUploadSuccess:
                        response_comp.toLowerCase() === "success"
                          ? true
                          : false,
                      fileUploadFail:
                        response_comp.toLowerCase() !== "success"
                          ? true
                          : false,
                    });
                  }
                })
                .catch((err) => {
                  console.log("error: ", err);
                });

              let timeID = await new Promise((resolve, reject) => {
                let myTimeout = setTimeout(() => {
                  resolve(myTimeout);
                }, 5000);
              });
              clearTimeout(timeID);
            }
            if (response_comp.toLowerCase().includes("error")) {
              console.log("error in upload");
            }
          })
          .catch((error) => {
            console.log("Error in api", error);
            this.setState({
              showLoaderUpload: false,
              error: true,
              errorMessage:
                "Error in uploading CSV file. Please contact admin@insurecomp.com for details.",
            });
          });

        // await axios
        //   .post(awsUrl2 + apiEndPoint, JSON.stringify(body), header)
        //   .then((res) => {
        //     this.setState({ showLoaderUpload: false, fileUploadSuccess: true });
        //     if (res.data.not_uploaded && res.data.not_uploaded.length > 0) {
        //       this.setState({
        //         error: true,
        //         errorMessage: "Some rows were not updated.",
        //         textValidation: res.data.not_uploaded,
        //       });
        //     }
        //     console.log(res);
        //   })
        //   .catch((err) => {
        //     this.setState({
        //       showLoaderUpload: false,
        //       error: true,
        //       errorMessage:
        //         "Error in uploading CSV file. Please contact admin@insurecomp.com for details.",
        //     });
        //     console.log(err);
        //   });
      }
    } else {
      this.setState({
        error: true,
        errorMessage: "Please select a valid CSV file",
      });
    }
  };

  getCarrierName = async () => {
    Auth.currentSession().then((res) => {
      let userName = res.idToken.payload.email.split("@")[0];
      this.setState({
        userName: userName,
      });
      console.log("Logging the user name from compliance: " + userName);
    });
  };

  downloadFile = async (event) => {
    let {
      tableName,
      userName,
      userRole,
      eligibilityState,
      eligibilityCarrier,
    } = this.state;
    this.setState({ fileUploadSuccess: false, fileUploadFail: false });
    if (
      tableName === "eligibility" &&
      (!eligibilityCarrier ||
        !eligibilityState ||
        (eligibilityState && eligibilityState.length === 0))
    ) {
      this.setState({ stateCarrierRequired: true });
      return;
    }
    this.setState({ showLoaderDownload: true, stateCarrierRequired: false });
    let apiEndPoint;
    apiEndPoint =
      userRole === "Compliance"
        ? "/api/compFileDownload/" + tableName + "/" + userName
        : "/api/compFileDownload/" + tableName;
    let uuid = uuidv4();
    const header = await getHeader();
    axios
      .post(
        awsUrl2 + "/api/compFile/put",
        JSON.stringify({
          uuid,
          tableName,
          groups:
            userRole === "Compliance" ? ["Compliance"] : ["ComplianceAdmin"],
          userName: userName,
          type: "download",
          carrier: userName,
          eligibilityState,
          eligibilityCarrier,
        }),
        header
      )
      .then(async (res) => {
        let signURL, status;

        while (!signURL) {
          await axios
            .post(
              awsUrl2 + "/api/compFile/get",
              JSON.stringify({
                uuid,
                tableName,
              }),
              header
            )
            .then((res) => {
              let data = res.data[0];
              console.log("-data: ", data);
              if (data.signURL || data.status) {
                signURL = data.signURL;
                status = data.status;
              }
            })
            .catch((err) => {
              console.log("error: ", err);
            });

          let timeID = await new Promise((resolve, reject) => {
            let myTimeout = setTimeout(() => {
              resolve(myTimeout);
            }, 5000);
          });
          clearTimeout(timeID);
        }

        console.log("status for download: ", status);

        if (signURL) {
          await axios
            .get(signURL)
            .then((res2) => {
              this.setState(
                {
                  downloadDataList: res2.data,
                  downloadLinkActive: true,
                  downloadedTableName: tableName,
                },
                () => {
                  setTimeout(() => {
                    if (this.isCsvFileReady()) {
                      this.setState({ showLoaderDownload: false });
                      this.downloadBtn.current.link.click();
                    }
                  }, 3000);
                }
              );
            })
            .catch((error) => {
              this.setState({
                showLoaderDownload: false,
                errorMessage: "Error while downloading file!",
              });
              console.log(error);
            });
        }
      })
      .catch((err) => {
        this.setState({
          showLoaderDownload: false,
          errorMessage: "Error while downloading file!",
        });
        console.log(err);
      });
  };

  isCsvFileReady = () => {
    return (
      this.downloadBtn &&
      this.downloadBtn.current &&
      this.downloadBtn.current.link &&
      this.downloadBtn.current.link.click &&
      typeof this.downloadBtn.current.link.click === "function"
    );
  };

  downloadTxtFile = (textValidation) => {
    let data = [];
    let othersFlag = false;
    let errorListLength = textValidation.length;
    if (errorListLength > 50) {
      textValidation.length = 50;
      othersFlag = true;
    }
    data = textValidation.reduce((r, a) => r.concat(a + 2, ","), []);
    if (othersFlag) {
      data.push(`... and ${errorListLength - 50} other(s)`);
    }
    const element = document.createElement("a");
    const file = new Blob(data, {
      type: "text/plain",
      endings: "native",
    });
    element.href = URL.createObjectURL(file);
    element.download = "invalidRows.txt";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
  };

  render() {
    const {
      error,
      errorMessage,
      downloadDataList,
      downloadLinkActive,
      showLoaderDownload,
      showLoaderUpload,
      textValidation,
      validationDone,
      fileUploadSuccess,
      userRole,
      downloadedTableName,
      tableName,
      stateCarrierRequired,
      fileUploadFail,
      statesDropDownList,
      selectedOption,
    } = this.state;

    let authorizedForUpload = false;
    let commmonTablesList = [
      "lcm",
      "generalFactor",
      "adoption",
      "limits",
      "taxes",
      "lossCost",
      "stateSpecific",
    ];
    if (!commmonTablesList.includes(tableName)) {
      authorizedForUpload = true;
    }

    return (
      <div className="compliance-panel">
        <Navbar />
        <div className="tab-content tab-content-compliance">
          <LoadingOverlay
            active={showLoaderUpload || showLoaderDownload}
            text={
              showLoaderUpload
                ? "Upload in progress"
                : showLoaderDownload
                ? "Download in progress"
                : ""
            }
          >
            <div
              id="compliancePortal"
              // className="container m-auto"
            >
              <div
                className="upload-heading my-0 mx-auto text-center mt-5"
                style={{
                  marginBottom: "100px !important",
                }}
              >
                <h1 className="m-0">Compliance Portal</h1>
              </div>
              <div className="row" style={{ marginTop: "50px" }}>
                <div
                  className="col-lg-8 col-md-12 d-flex justify-content-end"
                  style={{
                    paddingRight: "45px",
                  }}
                >
                  <div
                    className="card"
                    style={{
                      maxWidth: "400px",
                      border: "none",
                    }}
                  >
                    <div className="card-body">
                      <table>
                        <tr>
                          <td>
                            <b>
                              Table<span className="mandate">*</span>
                            </b>
                          </td>
                          <td>
                            <select onChange={this.getTableName}>
                              <option value="eligibility">Eligibility</option>
                              <option value="lcm">LCM</option>
                              <option value="generalFactor">
                                General Factors
                              </option>
                              <option value="adoption">Adoption</option>
                              <option value="limits">Limits</option>
                              <option value="taxes">Taxes</option>
                              {userRole === "ComplianceAdmin" && (
                                <>
                                  <option value="lossCost">Loss Cost</option>
                                  <option value="stateSpecific">
                                    State Specific
                                  </option>
                                </>
                              )}
                              <option value="estaterisk">
                                ExtensisStateRisk
                              </option>
                              <option value="ebinmultiplier">
                                ExtensisBinMultiplier
                              </option>
                              <option value="ebinweightage">
                                ExtensisBinWeightage
                              </option>
                              <option value="ehazardgroup">
                                ExtensisHazardGroup
                              </option>
                              <option value="estandardbinValues">
                                ExtensisStandardBinValues
                              </option>
                              <option value="etablemultiplier">
                                ExtensisTableMultiplier
                              </option>
                              <option value="etableweightage">
                                ExtensisTableWeightage
                              </option>
                              <option value="elffm">ExtensisLFFM</option>
                              <option value="ebmp">ExtensisBMP</option>
                              <option value="eexpenseconstant">
                                ExtensisExpenseConstant
                              </option>
                              <option value="esmalldeductible">
                                ExtensisSmallDeductible
                              </option>
                              <option value="eclericalclasscodes">
                                ExtensisClericalClassCodes
                              </option>
                              <option value="eclientrelationship">
                                ExtensisClientRelationship
                              </option>
                              <option value="estatementscollection">
                                ExtensisStatementsCollection
                              </option>
                              <option value="estateentitystatements">
                                ExtensisStateEntityStatements
                              </option>
                              <option value="esicstatementnumber">
                                ExtensisSicStatementNumber
                              </option>
                              <option value="eprotectionlevelfactors">
                                ExtensisProtectionLevelFactors
                              </option>
                              <option value="eprogramexpensefactor">
                                ExtensisProgramExpenseFactor
                              </option>
                              <option value="ebinfrequency">
                                ExtensisBinFrequency
                              </option>
                              <option value="ebinpremiumsize">
                                ExtensisBinPremiumSize
                              </option>
                            </select>
                          </td>
                        </tr>
                        {tableName === "eligibility" && (
                          <tr>
                            <td>
                              <b>
                                {"Carrier"}
                                <span className="mandate">*</span>
                              </b>
                            </td>
                            <td>
                              <select
                                id="carrier-type"
                                onChange={(e) => {
                                  this.setState({
                                    eligibilityCarrier: e.target.value,
                                    stateCarrierRequired: false,
                                  });
                                }}
                              >
                                <option
                                  selected
                                  disabled
                                  value={"Please Select"}
                                >
                                  {"Please Select"}
                                </option>
                                {Object.keys(carrierMap).map((carrier) => {
                                  return (
                                    <option value={carrier}>
                                      {carrierMap[carrier]}
                                    </option>
                                  );
                                })}
                              </select>
                            </td>
                          </tr>
                        )}
                        {tableName === "eligibility" && (
                          <tr className="multi-select-state">
                            <td>
                              <b>
                                {"State"}
                                <span className="mandate">*</span>
                              </b>
                            </td>
                            <td>
                              <ReactMultiSelectCheckboxes
                                className="react-select-checkbox"
                                classNamePrefix="react-select-checkbox"
                                getDropdownButtonLabel={(labelData) =>
                                  this.getDropdownButtonLabel(labelData)
                                }
                                value={selectedOption || []}
                                options={statesDropDownList}
                                onChange={this.handleStateDropDownChange}
                              />
                            </td>
                          </tr>
                        )}
                      </table>

                      {stateCarrierRequired && (
                        <span
                          className="required-errMsg"
                          style={{ color: "red" }}
                        >
                          {"*State and Carrier are required."}
                        </span>
                      )}
                      {this.state.maxStateError && (
                        <span
                          className="required-errMsg"
                          style={{ color: "red" }}
                        >
                          {"Selection only upto 40 states."}
                        </span>
                      )}
                      <br />
                      <div className="d-flex justify-content-center">
                        <input
                          type="file"
                          name="file"
                          disabled={!authorizedForUpload}
                          multiple
                          style={{ width: "14rem" }}
                          onChange={(e) => this.validateFormat(e)}
                        />
                      </div>
                      <br />
                      <br />
                      <br />
                      <button
                        type="button"
                        disabled={
                          !validationDone ||
                          error ||
                          showLoaderUpload ||
                          !authorizedForUpload
                        }
                        className="btn btn-warning"
                        onClick={this.uploadFile}
                      >
                        Upload
                      </button>
                      <button
                        type="button"
                        onClick={this.downloadFile}
                        className="btn btn-danger"
                      >
                        Download Now
                      </button>
                      {/* {(showLoaderUpload || showLoaderDownload) && (
                        <div className="ml-2">
                          <SmallLoader />
                        </div>
                      )} */}
                      {downloadLinkActive && (
                        <CSVLink
                          data={downloadDataList}
                          download={`${downloadedTableName}.csv`}
                          ref={this.downloadBtn}
                        />
                      )}
                      {error && <p id="upload-error">{errorMessage}</p>}
                      {fileUploadSuccess && !error && (
                        <p id="upload-success">Data successfully uploaded! </p>
                      )}
                      {fileUploadFail && (
                        <p id="upload-failure" style={{ color: "red" }}>
                          Data upload failed!{" "}
                        </p>
                      )}
                      {error && textValidation && (
                        <a
                          href="javascript:void(0)"
                          onClick={() => {
                            this.downloadTxtFile(textValidation);
                          }}
                        >
                          Click here to view invalid row numbers
                        </a>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-md-12">
                  <table className="carier-info-table">
                    <thead>
                      <th colspan="2">Carrier Mapping</th>
                    </thead>
                    <tbody>
                      <tr>
                        <td>Name</td>
                        <td>Value</td>
                      </tr>
                      <tr>
                        <td>CHUBB</td>
                        <td>chubb</td>
                      </tr>
                      <tr>
                        <td>FIC</td>
                        <td>fic</td>
                      </tr>
                      <tr>
                        <td>STATE FUND</td>
                        <td>state_fund</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </LoadingOverlay>
          <Loader />
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default PanelCompliance;
