import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import Loader from '../common/Loader'
import Navbar from '../common/Navbar'
import DashFormLogin from '../subcompo/sections-login/DashFormLogin';
import FormReset from '../subcompo/sections-login/FormReset';
import Footer from '../common/Footer';
import FormChangePass from '../subcompo/sections-login/FormChangePass';

class DashboardLogin extends Component {
  componentDidMount() {
    ReactDOM.findDOMNode(this).scrollTop = 0
  }
  render() {
     return (
       <div>
        <Navbar/>
        <div className='tab-content'>
          <DashFormLogin/>
          <FormReset/>
          <FormChangePass/>
          <Footer/>
          <Loader/>
        </div>
      </div>
    );
  }
}

export default DashboardLogin;