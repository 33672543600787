import React, { Component } from "react";
import ReactDOM from "react-dom";
import { downloadPDF, printDiv, startFresh } from "../../../utils/common";
import { setProspectDetails } from "../../../utils/session_data";
import BinderDownload from "../../common/BinderDownload";
import axios from "axios";
import { Auth } from "aws-amplify";
import { awsUrl } from "../../../config";
import NewProgress from "../../../newcomponent/common/NewProgress";

class Binder extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let compName = "";
    try {
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
      compName = currProspect.companyName;
    } catch (err) {
      console.log(err);
    }

    return (
      <div id="binder" className="container">
        <NewProgress />
        <div id="binderForm">
          {/* <BinderDownload /> */}
          <div className="row">
            <div className="col-sm-12">
              <div className="row row-binder-heading">
                <div className="formTitle">Bind</div>
                <div className="formTitle compName">{compName}</div>
              </div>
              <div className="text-center">
                <img src={require("../../../images/binder-tick.png")} alt="" />
              </div>
              <div className="col-sm-6 text-center green">
                <p>Congratulations!</p>
                <p>Here’s your WC policy binder.</p>
              </div>
              {/* <div className="acord">
                <button
                  onClick={downloadPDF}
                  type="button"
                  className="btnGen transpButton"
                  data-toggle="tooltip"
                  title="Download"
                >
                  <img src={require("../../../images/pdf2.png")} alt="" />
                </button>{" "}
                Download
                <button
                  type="button"
                  className="btnGen endForm transpButton"
                  data-toggle="tooltip"
                  title="Print"
                  onClick={printDiv}
                >
                  <img src={require("../../../images/print2.png")} alt="" />
                </button>
                Print
              </div>
              <br /> */}
              <div className="row no-gutters row-iframe">
                <div className="col col-iframe">
                  <div className="row no-gutters acord">
                    <button
                      onClick={downloadPDF}
                      type="button"
                      className="btnGen transpButton"
                      data-toggle="tooltip"
                      title="Download"
                    >
                      <img src={require("../../../images/pdf2.png")} alt="" />
                    </button>{" "}
                    <span>Download</span>
                    <button
                      type="button"
                      className="btnGen endForm transpButton"
                      data-toggle="tooltip"
                      title="Print"
                      onClick={printDiv}
                    >
                      <img src={require("../../../images/print2.png")} alt="" />
                    </button>
                    <span>Print</span>
                  </div>
                  <div className="row no-gutters row-iframe-preview">
                    <iframe src="./BinderDownload" frameBorder="1"></iframe>
                  </div>
                </div>
              </div>
              <div className="newQuoteLink text-center">
                <button
                  type="button"
                  className="linkLike btnInvisible btnSubmits"
                  onClick={startFresh}
                >
                  START NEW QUOTE
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default Binder;
