import React, { Component } from "react";
import SmallLoader from "../../common/SmallLoader";
class ParseStatusCheck extends Component {
  render() {
    const { showLoader, parseStatus, id } = this.props;
    return (
      <div className="d-flex">
        <span id={id} style={{ marginRight: "1rem" }}>
          {parseStatus}
        </span>
        {showLoader && (
          <span>
            <SmallLoader />
          </span>
        )}
      </div>
    );
  }
}

export default ParseStatusCheck;
