import React, { Component } from "react";
import axios from "axios";
import ComputationTable from "../../common/ComputationTable";
import { awsUrl2 } from "../../../config";
import LoadingOverlay from "react-loading-overlay";

class EppFactors extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      loaderActive: false,
    };
  }

  getEppData = (lifeCycle) => {
    let {
      totalEmployees,
      totalPayroll,
      startDateWithExtensis,
      pricingRiskFactor,
      protectionLevel,
      setProtectionLevelData,
      costPEMPValue,
      ChargePEMPValue,
      EppCostValuesHandler,
      EppChargeValuesHandler,
    } = this.props;

    let requestData = {
      employees: totalEmployees,
      payroll: totalPayroll,
      extensis_start_date: startDateWithExtensis,
      costPEMPValue: costPEMPValue,
      ChargePEMPValue: ChargePEMPValue,
    };

    if (pricingRiskFactor !== "-") {
      requestData.pricing_risk_factor = pricingRiskFactor;
    }
    if (protectionLevel !== "-") {
      requestData.protection_level = protectionLevel;
    }

    if (costPEMPValue !== undefined || ChargePEMPValue !== undefined) {
      try {
        requestData.epp_override_cost = costPEMPValue;
        requestData.epp_override_charge = ChargePEMPValue;
        requestData.epp_override_cost = costPEMPValue.split("$")[1];
        requestData.epp_override_charge = ChargePEMPValue.split("$")[1];
      } catch (error) {}
    }
    // if (ChargePEMPValue) {
    //   try {
    //     requestData.epp_override_charge = ChargePEMPValue;
    //     // requestData.epp_override_charge = ChargePEMPValue.split("$")[1];
    //   } catch (error) {}
    // }

    this.setState({ loaderActive: true });
    axios
      .post(awsUrl2 + "/api/eppCalculatedFactors", requestData)
      .then((response) => {
        let data = response.data;
        let formatter1 = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 0,
        });

        let formatter2 = new Intl.NumberFormat("en-US", {
          style: "currency",
          currency: "USD",
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        });

        if (lifeCycle === "mount") {
          let clone = { ...data };
          EppCostValuesHandler(clone.costPEPM, "costPEPM");
          EppChargeValuesHandler(clone.chargePEPM, "chargePEPM");
        }

        data.annual_cost = formatter1.format(data.annual_cost);
        data.annual_charge = formatter1.format(data.annual_charge);

        data.costPEPM = formatter2.format(Number(data.costPEPM));
        data.chargePEPM = formatter2.format(Number(data.chargePEPM));

        data.toolCost = formatter2.format(Number(data.toolCost));
        data.toolCharge = formatter2.format(Number(data.toolCharge));

        this.setState({ data });
        setProtectionLevelData(response.data.protectionLevel);
      })
      .catch((error) => {
        console.log("error while calling post method ", error);
      })
      .finally(() => {
        this.setState({ loaderActive: false });
      });
  };

  componentDidUpdate = (prevProps) => {
    const {
      protectionLevel,
      pricingRiskFactor,
      costPEMPValue,
      ChargePEMPValue,
    } = this.props;
    if (
      protectionLevel !== prevProps.protectionLevel ||
      pricingRiskFactor !== prevProps.pricingRiskFactor
    ) {
      this.getEppData();
    }

    if (
      costPEMPValue !== prevProps.costPEMPValue ||
      ChargePEMPValue !== prevProps.ChargePEMPValue
    ) {
      this.getEppData();
    }
  };

  componentDidMount = () => {
    this.getEppData("mount");
  };

  render() {
    let { data, loaderActive } = this.state;
    let tableHeaders = ["EPP Factors", "Value"];
    let keyMap = {
      annual_cost: "EPP Annual Cost",
      annual_charge: "EPP Annual Charge",
      cost_rate: "EPP Cost Rate",
      charge_rate: "EPP Charge Rate",
      toolCost: "EPP Cost PEPM-Tool",
      toolCharge: "EPP Charge PEPM-Tool",
    };
    let {
      costPEMPValue,
      ChargePEMPValue,
      costEppInputValue,
      EppCostValuesHandler,
      EppChargeValuesHandler,
    } = this.props;
    return (
      <div>
        <LoadingOverlay active={loaderActive} spinner>
          <ComputationTable
            headers={tableHeaders}
            data={data}
            isEpp={true}
            keyMap={keyMap}
            costEppInputValue={costEppInputValue}
            EppCostValuesHandler={EppCostValuesHandler}
            EppChargeValuesHandler={EppChargeValuesHandler}
            costPEMPValue={costPEMPValue}
            ChargePEMPValue={ChargePEMPValue}
          />
        </LoadingOverlay>
      </div>
    );
  }
}

export default EppFactors;
