import React, { Component } from "react";
import axios from "axios";
import { awsUrl2 } from "../../../config";
import BinSection from "./BinSection";
import TableSection from "./TableSection";

class BinAndTable extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    try {
      axios
        .get(awsUrl2 + "/api/getTableBin")
        .then((response) => {
          this.setState({ data: response.data });
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      console.log("error", error);
    }
  };

  render() {
    let { data } = this.state;
    let { programFactorData, tableSelectionData, binSelectionData } =
      this.props;
    return (
      <div className="bin-and-table">
        {data && (
          <BinSection
            data={data.binData}
            bin={programFactorData.bin}
            binSelectionData={binSelectionData}
          />
        )}
        <TableSection
          table={programFactorData.table}
          tableSelectionData={tableSelectionData}
        />
      </div>
    );
  }
}

export default BinAndTable;
