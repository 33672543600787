import React, { useState } from "react";
import moment from "moment";
import BindQuote from "../subcompo/sections-quote/BindQuote";
import { onlyNum, printDiv } from "../../utils/common";
import { Modal, Tabs, Tab } from "react-bootstrap";
import DetailedQuoteData from "../subcompo/sections-quote/DetailedQuoteData";
import EppData from "../subcompo/sections-quote/EppData";
import numeral from "numeral";
import {
  validateAll,
  submit,
  updateRowInOpportunityTable,
} from "../../utils/SubmitCompanyProfileData.js";
import { Form, InputGroup, FormControl, Button, Col } from "react-bootstrap";
import QuoteComparision from "../subcompo/sections-quote/QuoteComparision";
import Computation from "../subcompo/sections-quote/Computation";
import PriceModifier from "../subcompo/sections-quote/PriceModifier";
import ActionButtons from "../subcompo/sections-quote/ActionButtons";
import TabsSection from "../subcompo/sections-quote/TabsSection";
import RateMatch from "../subcompo/sections-quote/RateMatch";
import { awsUrl, awsUrl2 } from "../../config";
import axios from "axios";
import $ from "jquery";
import { Auth } from "aws-amplify";

class QuoteTable extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      locations: [],
      carriers: [],
      quotes: {},
      quoteDetails: {},
      displayQuotePDFs: false,
      quotePDFs: [],
      objectForEpp: {},
      sentToSalesforce: false,
      priceModifierDropDowns: {
        protectionLevel: null,
        pricingRiskFactor: null,
      },
      rateMatchValues: {
        targetType: "target_margin_$",
      },
      questions: {
        carrier_a: {
          sdr_factor: null,
          creditChecked: false,
          classDropDown: null,
          safetyCreditDown: 0,
          alcoholDropDown: 0,
          ccpapDropDown: 0,
          wosDropDown: 1,
        },
        carrier_b: {
          sdr_factor: null,
          creditChecked: false,
          classDropDown: null,
          safetyCreditDown: 0,
          alcoholDropDown: 0,
          ccpapDropDown: 0,
          wosDropDown: 1,
        },
        carrier_o: {
          sdr_factor: null,
          creditChecked: false,
          classDropDown: null,
          safetyCreditDown: 0,
          alcoholDropDown: 0,
          ccpapDropDown: 0,
          wosDropDown: 1,
        },
      },
      governingData: {
        naics: undefined,
        classCode: undefined,
        state: undefined,
        desc: undefined,
      },
      costEppInputValue: {
        costPEPM: null,
        chargePEPM: null,
      },
    };
    this.submit = submit.bind(this);
  }

  updateGoverningData = (governingData) => {
    this.setState({ governingData: governingData });
  };

  componentDidMount() {
    this.setState({ isOverallLevel: this.props.isOverallLevel });
    let excludedClassCodes = sessionStorage.getItem("excludedClassCodes");
    let deletedDataList = sessionStorage.getItem("deletedDataList");
    let { priceModifierDropDowns, governingData } = this.state;
    let data = this.props.data,
      salesForceFlag = undefined,
      selectedCarrier = undefined;
    let selectedCarrierSession = sessionStorage.getItem("selectedCarrier");

    let chargeManualRateMap = {};

    for (let carrier in data) {
      for (let line in data[carrier]) {
        selectedCarrier = data[carrier][line].defaultCarrier;
        if (data[carrier][line].questions) {
          let questions = data[carrier][line].questions;
          let carrier_location_data = data[carrier][line].carrier_location_data;

          let governing_class_code_data =
            carrier_location_data.cost.governing_class_code_data;

          if (governing_class_code_data && governingData.classCode == null) {
            governingData = {
              naics: governing_class_code_data.naics,
              classCode: governing_class_code_data.classCode,
              state: governing_class_code_data.state,
              desc: governing_class_code_data.description,
            };
          }

          if (data[carrier][line].priceModifierDropDowns) {
            priceModifierDropDowns.protectionLevel = data[carrier][line]
              .priceModifierDropDowns.protectionLevel
              ? data[carrier][line].priceModifierDropDowns.protectionLevel
              : priceModifierDropDowns.protectionLevel;

            priceModifierDropDowns.pricingRiskFactor = data[carrier][line]
              .priceModifierDropDowns.pricingRiskFactor
              ? data[carrier][line].priceModifierDropDowns.pricingRiskFactor
              : priceModifierDropDowns.pricingRiskFactor;
          }

          sessionStorage.setItem("questions", JSON.stringify(questions));
          sessionStorage.setItem(
            "priceModifierDropDowns",
            JSON.stringify(priceModifierDropDowns)
          );
          this.setState({ questions, priceModifierDropDowns, governingData });
        }

        if (data[carrier][line].costEppInputValue) {
          let { costEppInputValue } = JSON.parse(JSON.stringify(this.state));
          costEppInputValue.costPEPM =
            data[carrier][line].costEppInputValue.costPEPM;
          costEppInputValue.chargePEPM =
            data[carrier][line].costEppInputValue.chargePEPM;
          this.setState({ costEppInputValue });
        }

        if (
          selectedCarrierSession &&
          carrier === selectedCarrierSession &&
          data[carrier][line].carrier_location_data &&
          data[carrier][line].carrier_location_data.charge
        ) {
          let tempChargeObj = data[carrier][line].carrier_location_data.charge;
          chargeManualRateMap[tempChargeObj.state] =
            tempChargeObj.manual_rate_values;
        }

        salesForceFlag = !salesForceFlag
          ? data[carrier][line].sendToSalesforce
          : salesForceFlag;
        selectedCarrier = data[carrier][line].selectedCarrier
          ? data[carrier][line].selectedCarrier
          : selectedCarrier;
      }
      if (salesForceFlag && selectedCarrier) break;
    }
    this.setState({
      salesForceFlag,
      selectedCarrier,
      chargeManualRateMap,
    });

    if (excludedClassCodes === "true" && deletedDataList) {
      deletedDataList = JSON.parse(deletedDataList);
      this.props.updatePopUpData({
        show: true,
        title: "Some class codes have been excluded!",
        disableNoButton: true,
        children: (
          <>
            <br></br>
            <span style={{ fontWeight: "bold" }}>
              {deletedDataList.map((e) => (
                <>
                  <div>{e}</div>
                </>
              ))}
            </span>
          </>
        ),
        acceptBtn: "OK",
        acceptBtnCallback: () => {
          console.log("okay");
        },
      });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { data } = this.props;
    let { idData, classCodeCompositeRateMatchData } = this.state;
    if (
      (!idData || data !== prevProps.data) &&
      typeof data !== "undefined" &&
      data !== null &&
      data !== ""
    ) {
      this.setGoalSeekConfig(data);
      this.prepCurrProspectsData(data);
      this.prepTableData(data);
      this.setQuoteStatus(data);
    }

    if (
      JSON.stringify(classCodeCompositeRateMatchData) !==
      JSON.stringify(prevState.classCodeCompositeRateMatchData)
    ) {
      this.prepTableData(data);
    }
  }

  setGoalSeekConfig = (data) => {
    let { rateMatchValues } = JSON.parse(JSON.stringify(this.state));
    let goalSeekConfig = { states: {} };
    for (let location in data.carrier_a) {
      let stateConfig =
        data.carrier_a[location].carrier_location_data.charge.goalSeekConfig;
      let state =
        data.carrier_a[location].carrier_location_data.guaranteed.state;
      goalSeekConfig.states[state] = stateConfig;
      if (data.carrier_a[location].rateMatchValues) {
        rateMatchValues = data.carrier_a[location].rateMatchValues;
      }
    }
    this.setState({ goalSeekConfig, rateMatchValues });
  };

  prepCurrProspectsData = (data) => {
    let currProspectData = {};
    let firstCareerKey = Object.keys(data)[0];
    let firstCareerValue = data[firstCareerKey];
    let firstLineKey = Object.keys(firstCareerValue)[0];
    let firstLineValue = firstCareerValue[firstLineKey];
    let prospectAddressesObject = firstLineValue.prospect_addresses;
    let total_payroll = 0,
      total_employees = 0;

    let keys = {
      businessTypeId: "bussiness_type_id",
      companyName: "company_name",
      contactPerson: "contact_person",
      phoneNumber: "phone_number",
      description: "description",
      effectiveDate: "effective_date",
      expirationDate: "expiration_date",
      federalEmployeeId: "fein",
      liabilityLimitId: "liability_limitId",
      date: "date",
      email: "user_email_id",
    };
    for (let carrier in data) {
      for (let location in data[carrier]) {
        for (let key in keys) {
          currProspectData[key] = data[carrier][location][keys[key]];
        }
      }
    }

    for (let prospectItemIndex in prospectAddressesObject) {
      let classCode = prospectAddressesObject[prospectItemIndex].classCodes;
      for (let classCodeIndex in classCode) {
        total_employees = numeral(total_employees)
          .add(classCode[classCodeIndex].pt)
          .add(classCode[classCodeIndex].ft)
          .value();

        total_payroll = numeral(total_payroll)
          .add(classCode[classCodeIndex].payroll)
          .value();
      }
    }

    let objectForEpp = {};
    objectForEpp.startDateWithExtensis =
      firstLineValue.start_date_with_extensis;
    objectForEpp.totalEmployees = total_employees;
    objectForEpp.totalPayroll = total_payroll;

    this.setState({ currProspectData, objectForEpp: objectForEpp });
  };

  setQuoteStatus = (data) => {
    let quoteStatusCarrier = {};
    let quoteStatusState = {};
    let status;
    let carrierName;
    let allBoundFlag = true;
    for (let carrier in data) {
      let state;
      carrierName = carrier;
      if (!(carrierName in quoteStatusCarrier)) {
        quoteStatusCarrier[carrierName] = {};
      }
      let isAllowed = true;
      for (let location in data[carrier]) {
        state = data[carrier][location].carrier_location_data.state;
        status = data[carrier][location].quote_status;
        if (!quoteStatusState[state] || quoteStatusState[state] !== "bound") {
          quoteStatusState[state] = status;
        }
        quoteStatusCarrier[carrierName][state] = status;
        if (!["uwportal", "hold", "reject", "bound"].includes(status)) {
          isAllowed = false;
        }
      }
      if (isAllowed) {
        quoteStatusCarrier[carrierName].disableCheckBox = true;
      }
    }
    for (let state in quoteStatusState) {
      if (quoteStatusState[state] !== "bound") {
        allBoundFlag = false;
        break;
      }
    }
    this.setState({ quoteStatusCarrier, quoteStatusState, allBoundFlag });
  };

  prepTableData = (data) => {
    let { classCodeCompositeRateMatchData_copy } = this.state;
    let userName;
    let carrierList = [];
    let stateList = [];
    let quotesData = {};
    let idData = {};
    let carrierName;
    let quoteDetails = {};
    for (let carrier in data) {
      carrierName = carrier;
      if (!(carrierName in quotesData)) {
        quotesData[carrierName] = {};
      }
      if (!(carrierName in idData)) {
        idData[carrierName] = {};
      }
      carrierList.push(carrierName);
      for (let location in data[carrier]) {
        quoteDetails.companyName = data[carrier][location].company_name;
        quoteDetails.fein = data[carrier][location].fein;
        quoteDetails.effectiveDate = data[carrier][location].effective_date;
        quoteDetails.expirationDate = data[carrier][location].expiration_date;

        if (!userName) {
          userName = data[carrier][location].user_email_id;
          this.setState({ userName });
        }
        let state;
        for (let calculationType in data[carrier][location]
          .carrier_location_data) {
          state =
            data[carrier][location].carrier_location_data[calculationType]
              .state;
          let tmp =
            data[carrier][location].carrier_location_data[calculationType]
              .total_estimated_annual_premium;
          if (!(state in quotesData[carrierName])) {
            quotesData[carrierName][state] = {};
          }
          if (!(calculationType in quotesData[carrierName][state])) {
            quotesData[carrierName][state][calculationType] = 0;
          }
          if (!("rateVal" in quotesData[carrierName][state])) {
            quotesData[carrierName][state]["rateVal"] = 0;
          }

          if (!(state in idData[carrierName])) {
            idData[carrierName][state] = data[carrier][location].uuid_carrier;
          } else {
            idData[carrierName][
              state
            ] += `!!${data[carrier][location].uuid_carrier}`;
          }

          quotesData[carrierName][state][calculationType] += tmp;
          if (!stateList.includes(state)) {
            stateList.push(state);
          }
          if (
            calculationType === "charge" &&
            !quotesData[carrierName][state]["rateVal"]
          ) {
            if (classCodeCompositeRateMatchData_copy) {
              let childrenLoc =
                data[carrier][location].currProspect.childrenLoc;
              let rateVal = 0;
              for (let childrenLocIndx in childrenLoc) {
                if (
                  childrenLoc[childrenLocIndx].state.value.toLowerCase() ===
                  state.toLowerCase()
                ) {
                  for (let classCodesInfoIndx in childrenLoc[childrenLocIndx]
                    .classCodesInfo) {
                    let classCodeVal =
                      childrenLoc[childrenLocIndx].classCodesInfo[
                        classCodesInfoIndx
                      ];
                    let code =
                      classCodeVal.classCodeDescription.value.split(":")[0];
                    let payroll = classCodeVal.payroll.value;
                    let classCodeCompositeRateMatchValue_copy =
                      classCodeCompositeRateMatchData_copy[carrier][
                        state.toLowerCase()
                      ][code];
                    let tempCalc = numeral(payroll)
                      .multiply(
                        !Number(classCodeCompositeRateMatchValue_copy)
                          ? 0
                          : classCodeCompositeRateMatchValue_copy
                      )
                      .divide(100)
                      .value();
                    rateVal = numeral(rateVal).add(tempCalc).value();
                  }
                }
              }
              quotesData[carrierName][state]["rateVal"] = Math.round(rateVal);
            }
          }
        }
      }
    }
    this.setState(
      {
        carriers: carrierList,
        locations: stateList,
        quotes: quotesData,
        idData,
        quoteDetails,
      },
      () => {
        this.createQuoteTable(carrierList, stateList, quotesData);
      }
    );
  };

  createQuoteTable = (carriers, locations, quotes) => {
    let tempObj = {};
    let tempQuoteTable = {};
    let { salesForceFlag, selectedCarrier, quoteTable } = this.state;

    // console.log("++", carriers, locations, quotes);
    for (let i = 0; i < locations.length; i++) {
      let location = locations[i];
      let locationQuotes = [];
      for (let j = 0; j < carriers.length; j++) {
        let carrier = carriers[j];
        if (quotes[carrier][location]) {
          locationQuotes.push(quotes[carrier][location]);
        } else {
          locationQuotes.push(Infinity);
        }
      }
      // let lowest_quote = Math.min(...locationQuotes);
      for (let j = 0; j < carriers.length; j++) {
        let carrier = carriers[j];
        tempObj[carrier] || (tempObj[carrier] = { selected: false });
        tempObj[carrier][location] = {
          quoted: locationQuotes[j] !== Infinity,
          quote: {},
          selected: false,
        };
        if (locationQuotes[j] !== Infinity) {
          for (let calculationType in locationQuotes[j]) {
            tempObj[carrier][location].quote[calculationType] =
              locationQuotes[j][calculationType];
          }
        }
      }
    }

    let firstCarrierSelected =
      (salesForceFlag ? salesForceFlag : selectedCarrier) || "carrier_a";

    for (let carrier in tempObj) {
      if (!(carrier in tempQuoteTable)) {
        let currSelectedStatus;
        try {
          currSelectedStatus = quoteTable[carrier].state.selected;
        } catch (error) {}
        tempQuoteTable[carrier] = {
          state: {
            quote: { guaranteed: 0, charge: 0, cost: 0, rateVal: 0 },
            selected:
              currSelectedStatus !== undefined
                ? currSelectedStatus
                : carrier === firstCarrierSelected
                ? true
                : false,
          },
        };
      }
      for (let state in tempObj[carrier]) {
        tempQuoteTable[carrier].state.quoted = true;
        if (tempObj[carrier][state].quoted) {
          for (let key in tempObj[carrier][state].quote) {
            if (!isNaN(tempObj[carrier][state].quote[key])) {
              tempQuoteTable[carrier].state.quote[key] +=
                tempObj[carrier][state].quote[key];
            }
          }
        }
      }
    }

    this.setState({ quoteTable: tempQuoteTable });
  };

  handleCarrierChecked = (event) => {
    let { quoteTable, idData, quoteStatusCarrier } = this.state;
    let quoteTableCopy = JSON.parse(JSON.stringify(quoteTable));
    let carrier = event.target.name;
    let checked = event.target.checked;

    let carriers = Object.keys(quoteTableCopy);
    if (checked) {
      for (let i = 0; i < carriers.length; i++) {
        let selectedVal = false;
        if (carrier === carriers[i] && !quoteTableCopy[carrier].selected) {
          selectedVal = true;
        }
        let states = Object.keys(quoteTableCopy[carriers[i]]);
        for (let j = 0; j < states.length; j++) {
          if (
            states[j] === "selected" ||
            !quoteTableCopy[carriers[i]][states[j]].quoted
          ) {
            continue;
          }
          let status = ["uwportal", "hold", "reject"];
          let isSelected = selectedVal;

          if (
            selectedVal &&
            quoteStatusCarrier[carrier[i]] &&
            quoteStatusCarrier[carrier[i]][states[j]] &&
            quoteStatusCarrier[carrier[i]] &&
            status.includes(quoteStatusCarrier[carrier[i]][states[j]])
          ) {
            isSelected = false;
          }
          quoteTableCopy[carriers[i]][states[j]].selected = isSelected;
          if (
            isSelected &&
            !(
              quoteStatusCarrier[carrier[i]] &&
              quoteStatusCarrier[carrier[i]][states[j]] &&
              quoteStatusCarrier[carrier[i]][states[j]] === "bound"
            )
          ) {
            quoteTableCopy[carriers[i]][states[j]].id =
              idData[carriers[i]][states[j]];
          }
          if (
            isSelected &&
            quoteStatusCarrier[carrier[i]] &&
            quoteStatusCarrier[carrier[i]][states[j]] &&
            quoteStatusCarrier[carrier[i]][states[j]] === "bound"
          ) {
            delete quoteTableCopy[carriers[i]][states[j]].id;
          }
        }
        quoteTableCopy[carriers[i]].selected = selectedVal;
      }
    }
    this.setState({ quoteTable: quoteTableCopy });
  };

  handleQuoteChecked = (event) => {
    const { currProspectData } = this.state;
    let carrierName;
    let stateName;
    const id = event.target.id;
    [carrierName, stateName] = event.target.name.split(":");
    let quoteTableCopy = JSON.parse(JSON.stringify(this.state.quoteTable));
    // Validations
    // If we are selecting one carrier for a given state, deselect all
    // other carriers for that state.
    let selected = !quoteTableCopy[carrierName][stateName].selected;
    if (selected) {
      let carriers = Object.keys(quoteTableCopy);
      for (let i = 0; i < carriers.length; i++) {
        quoteTableCopy[carriers[i]][stateName] &&
          (quoteTableCopy[carriers[i]][stateName].selected = false);
      }
    }
    quoteTableCopy[carrierName][stateName].selected =
      !quoteTableCopy[carrierName][stateName].selected;
    quoteTableCopy[carrierName][stateName].id = id;

    let addList = [];
    let addKeys = [
      "streetLineOne",
      "streetLineTwo",
      "city",
      "stateCode",
      "zipCode",
    ];
    for (let carrier in quoteTableCopy) {
      let selectedStateCount = 0;
      let countOfQuotes = 0;
      for (let state in quoteTableCopy[carrier]) {
        if (
          quoteTableCopy[carrier][state] &&
          quoteTableCopy[carrier][state].quoted
        ) {
          countOfQuotes += 1;
        }
        if (quoteTableCopy[carrier][state].selected) {
          selectedStateCount += 1;
          let x = quoteTableCopy[carrier][state].id;
          let obj = {};
          x.split("!!").map((val, index1) => {
            obj = {};
            val
              .split("+")
              .reverse()[0]
              .split("_")
              .map((add, index2) => {
                obj[addKeys[index2]] = add;
              });
            obj.addressId = index1;
            addList.push(obj);
          });
        }
      }
      if (countOfQuotes === selectedStateCount) {
        quoteTableCopy[carrier].selected = true;
      } else {
        quoteTableCopy[carrier].selected = false;
      }
    }
    currProspectData.prospectAddresses = addList;
    this.setState({ quoteTable: quoteTableCopy, currProspectData });
  };

  isDisabled() {
    let { quoteTable, quoteStatusCarrier } = this.state;
    let flag = false;
    for (let carrier in quoteTable) {
      for (let state in quoteTable[carrier]) {
        if (
          quoteStatusCarrier[carrier][state] !== "bound" &&
          quoteTable[carrier][state].selected === true
        ) {
          flag = true;
          break;
        }
      }
      if (flag) {
        break;
      }
    }
    return !flag;
  }

  //sorting the quote-table based on best deals and uwportal criteria
  sortByBestDeals = (obj, obj2) => {
    let newObj = {};
    let bestDealsCount = 0;
    let { locations } = this.state;
    let { carriers } = this.state;
    for (const c of carriers) {
      for (const l of locations) {
        if (obj[c][l].diff.value === 0) {
          bestDealsCount = bestDealsCount + 1;
        }
        if (obj2[c][l] === "uwportal" || obj2[c][l] === "hold") {
          bestDealsCount = bestDealsCount - 1;
        }
      }
      newObj[c] = bestDealsCount;
      bestDealsCount = 0;
    }
    let keys = Object.keys(newObj);
    let values = Object.values(newObj);
    values = values.sort().reverse();
    let sortedCarriers = [];
    for (let v of values) {
      for (let k of keys) {
        if (newObj[k] === v) {
          sortedCarriers.push(k);
          keys = keys.filter((e) => e !== k);
        }
      }
    }
    return sortedCarriers;
  };

  onQuoteReady = (quotes) => {
    this.setState({ displayQuotePDFs: true, quotePDFs: quotes });
  };

  getDetailedQuoteData = (data) => {
    let doNotDisplayList = [
      "carrier_email",
      "carriers",
      "charge_manual_premium_sum",
      "charge_manual_premium_values",
      "charge_manual_rate_values",
      "classCodePayroll",
      "class_code_description",
      "cost_manual_premium_sum",
      "cost_manual_premium_values",
      "cost_manual_rate_values",
      "employees",
      "manual_premium_sum_lffm",
      "manual_premium_values_lffm",
      "manual_rate_values_lffm",
      "name",
      "questions",
      "split_sum",
    ];
    let result = {};
    for (let carrier in data) {
      result[carrier] = {};
      for (let location in data[carrier]) {
        result[carrier][location] = {};
        for (let calculationType in data[carrier][location]
          .carrier_location_data) {
          result[carrier][location][calculationType] = {};
          for (let key in data[carrier][location].carrier_location_data[
            calculationType
          ]) {
            if (!doNotDisplayList.includes(key)) {
              result[carrier][location][calculationType][key] =
                data[carrier][location].carrier_location_data[calculationType][
                  key
                ];
            }
          }
        }
      }
    }

    return result;
  };

  getTimestampAndUuid = (uuid_carrier) => {
    let timestamp, uuid;
    for (let element in uuid_carrier) {
      try {
        let splitData = uuid_carrier[element].split("@");
        timestamp = splitData[0];
        uuid = splitData[1].split("_")[0];
      } catch (error) {
        continue;
      }
      if (timestamp && uuid) {
        break;
      }
    }

    return { timestamp, uuid };
  };

  getSendToSalesforceFlag = () => {
    let { data } = this.props;
    let flag = false;
    for (let carrier in data) {
      for (let line in data[carrier]) {
        if (data[carrier][line].sendToSalesforce) {
          flag = true;
          break;
        }
      }
      break;
    }
    return flag;
  };

  handleCalculateButton = async () => {
    $("#loader").css("display", "block");
    let {
      childrenLoc,
      companyProfile,
      emodStatesData,
      historicalClaims,
      historicalClaimsCheck,
    } = JSON.parse(sessionStorage.getItem("currProspect"));
    let renewalConfirmed =
      sessionStorage.getItem("renewalConfirmed") === "true";
    let renewalClient = sessionStorage.getItem("renewalClient") === "true";
    let existingClient = sessionStorage.getItem("existingClient") === "true";

    let loggedInUserDetails;
    try {
      loggedInUserDetails = await Auth.currentAuthenticatedUser();
    } catch (error) {}

    let {
      sentToSalesforce,
      questions,
      selectedCarrier,
      quoteTable,
      rateMatchValues,
      priceModifierDropDowns,
      classCodeRateMatchData,
      classCodeCompositeRateMatchData_copy,
      isOverallLevel,
      governingData,
      costEppInputValue,
    } = this.state;

    this.setState({ errorMessage: undefined });

    let { flag, data } = validateAll(
      childrenLoc,
      companyProfile,
      emodStatesData,
      historicalClaims,
      historicalClaimsCheck
    );

    childrenLoc = data.childrenLoc;
    companyProfile = data.companyProfile;
    historicalClaims = data.historicalClaims;
    historicalClaimsCheck = data.historicalClaimsCheck;

    sessionStorage.setItem("questions", JSON.stringify(questions));
    sessionStorage.setItem(
      "priceModifierDropDowns",
      JSON.stringify(priceModifierDropDowns)
    );
    sessionStorage.setItem(
      "costEppInputValue",
      JSON.stringify(costEppInputValue)
    );

    for (let carrier in quoteTable) {
      if (quoteTable[carrier].state && quoteTable[carrier].state.selected) {
        selectedCarrier = carrier;
        break;
      }
    }

    let client_relation_class = {},
      sdr_factor = {};
    for (let carrier of ["carrier_a", "carrier_b", "carrier_o"]) {
      if (questions[carrier]) {
        client_relation_class[carrier] =
          questions[carrier].classDropDown &&
          questions[carrier].classDropDown !== "Please Select"
            ? questions[carrier].classDropDown
            : null;
        sdr_factor[carrier] =
          questions[carrier].sdr_factor *
          (questions[carrier].creditChecked ? -1 : 1);
      }
    }

    let firstSecondPage = "second";
    if (sentToSalesforce || this.getSendToSalesforceFlag()) {
      firstSecondPage = "first";
      sessionStorage.removeItem("questions");
      sessionStorage.removeItem("getButtonClicked");
      sessionStorage.removeItem("priceModifierDropDowns");
      sessionStorage.removeItem("costEppInputValue");
    }

    let { uuid_carrier } = this.props;
    let { timestamp, uuid } = this.getTimestampAndUuid(uuid_carrier);
    let quoteData = { date: timestamp, uuid };

    if (flag) {
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
      if (renewalClient && !renewalConfirmed) {
        let data = this.props.data;
        let date, uuid;
        let { quoteTable } = this.state;
        let { composite, manual, calculatedComposite } =
          this.createCompositeAndManualRateData();

        let carrierSelected;

        for (let carrier in quoteTable) {
          for (let state in quoteTable[carrier]) {
            if (quoteTable[carrier][state].selected) {
              carrierSelected = carrier;
              break;
            }
          }
          if (carrierSelected !== undefined) break;
        }

        try {
          let preparedData = {};
          for (let carrier in data) {
            for (let line in data[carrier]) {
              if (!preparedData[line]) preparedData[line] = {};
              if (!preparedData[line][carrier])
                preparedData[line][carrier] = {};
              preparedData[line][carrier] =
                data[carrier][line].carrier_location_data;
              if (!date) date = data[carrier][line].date;
              if (!uuid) uuid = data[carrier][line].uuid;
            }
          }

          preparedData = this.extractQuoteKeys(preparedData);

          if (currProspect && date && uuid && preparedData) {
            let email = currProspect.companyProfile.emailId.value;
            let effectiveDate = moment
              .utc(currProspect.companyProfile.effectiveDate.value)
              .format("YYYY-MM-DD");

            let dataToSend = {
              client_id: email,
              effective_date: effectiveDate,
              quoteid: uuid,
              lastGenerated: date,
              quoteData: preparedData,
              selectedCarrier: carrierSelected,
              modifiedBy: loggedInUserDetails.attributes.email,
              modifiedByName: loggedInUserDetails.attributes.name,
            };

            if (isOverallLevel) {
              dataToSend.composite_charge_rate = composite;
              dataToSend.program_manual_rate = manual;
            }

            axios
              .post(awsUrl2 + "/api/uploadRenewalData", dataToSend)
              .then((response) => {
                console.log(response);
              })
              .catch((err) => {
                console.log("error: ", err);
              });
          }
        } catch (error) {
          console.log("error: ", error);
        }
      } else if (existingClient) {
        if (currProspect) {
          let email = currProspect.companyProfile.emailId.value;
          let effectiveDate = moment
            .utc(currProspect.companyProfile.effectiveDate.value)
            .format("YYYY-MM-DD");

          let dataToSend = {
            client_id: email,
            effective_date: effectiveDate,
            modifiedBy: loggedInUserDetails.attributes.email,
            modifiedByName: loggedInUserDetails.attributes.name,
            // composite_charge_rate: composite,
            // program_manual_rate: manual,
          };

          let res = await axios.post(
            awsUrl2 + "/api/uploadExistingData",
            dataToSend
          );
        }
      }
      // else if (renewalClient && renewalConfirmed) {
      //   sessionStorage.removeItem("renewalClient");
      //   sessionStorage.removeItem("renewalConfirmed");
      // }
      this.submit(
        childrenLoc,
        companyProfile,
        emodStatesData,
        historicalClaims,
        historicalClaimsCheck,
        client_relation_class,
        questions,
        sdr_factor,
        firstSecondPage,
        selectedCarrier ? selectedCarrier : undefined,
        quoteData,
        rateMatchValues,
        priceModifierDropDowns,
        classCodeRateMatchData,
        classCodeCompositeRateMatchData_copy,
        isOverallLevel,
        !governingData.classCode ? undefined : governingData,
        costEppInputValue ? costEppInputValue : undefined
      );
    }
  };

  setPriceModifierDropDowns = (value, dropDownName, carrier) => {
    let { priceModifierDropDowns, questions } = JSON.parse(
      JSON.stringify(this.state)
    );
    if (value !== undefined || value !== null || value !== "Please Select") {
      if (carrier) {
        questions[carrier][dropDownName] = value;
        this.setState({ questions });
      } else {
        priceModifierDropDowns[dropDownName] = value;
        this.setState({ priceModifierDropDowns });
      }
    }
  };

  EppCostValuesHandler = (value, name) => {
    let { costEppInputValue } = JSON.parse(JSON.stringify(this.state));
    // let value = e.target.value;
    if (value !== undefined || value !== null || name == "costPEPM") {
      costEppInputValue.costPEPM = value;
      this.setState({ costEppInputValue });
    }
  };
  EppChargeValuesHandler = (value, name) => {
    let { costEppInputValue } = JSON.parse(JSON.stringify(this.state));
    // let value = e.target.value;
    if (value !== undefined || value !== null || name == "chargePEPM") {
      costEppInputValue.chargePEPM = value;
      this.setState({ costEppInputValue });
    }
  };

  generateProgramFactorData() {
    const { data } = this.props;
    let programFactorData = {};
    let flag = false;
    try {
      if (data && data.carrier_a) {
        // for (let carrier in data) {
        for (let line in data["carrier_a"]) {
          var carrierLocdata = data["carrier_a"][line].carrier_location_data;
          if (
            !flag &&
            carrierLocdata &&
            carrierLocdata.charge &&
            carrierLocdata.charge.tableNumber &&
            carrierLocdata.charge.binNumber
          ) {
            programFactorData.table = carrierLocdata.charge.tableNumber
              ? carrierLocdata.charge.tableNumber
              : "-";
            programFactorData.bin = carrierLocdata.charge.binNumber
              ? carrierLocdata.charge.binNumber
              : "-";
          }

          if (
            carrierLocdata &&
            carrierLocdata.charge &&
            carrierLocdata.charge.programExpenseFactors
          ) {
            var programObjectFactors =
              carrierLocdata.charge.programExpenseFactors;
            if (
              programObjectFactors.chubb_program_expenses &&
              programObjectFactors.extensis_program_expenses &&
              programObjectFactors.extensis_risk_margin &&
              programObjectFactors.lff
            ) {
              programFactorData.chubb_program_expenses = Object.keys(
                programObjectFactors.chubb_program_expenses
              )[0]
                ? Math.round(
                    Number(
                      programObjectFactors.chubb_program_expenses[
                        Object.keys(
                          programObjectFactors.chubb_program_expenses
                        )[0]
                      ].percentage
                    ) * 100
                  ) /
                    100 +
                  "" +
                  "%"
                : "-";
              programFactorData.extensis_program_expenses = Object.keys(
                programObjectFactors.extensis_program_expenses
              )[0]
                ? Math.round(
                    Number(
                      programObjectFactors.extensis_program_expenses[
                        Object.keys(
                          programObjectFactors.extensis_program_expenses
                        )[0]
                      ].percentage
                    ) * 100
                  ) /
                    100 +
                  "" +
                  "%"
                : "-";
              programFactorData.extensis_risk_margin = Object.keys(
                programObjectFactors.extensis_risk_margin
              )[0]
                ? Math.round(
                    Number(
                      programObjectFactors.extensis_risk_margin[
                        Object.keys(
                          programObjectFactors.extensis_risk_margin
                        )[0]
                      ].percentage
                    ) * 100
                  ) /
                    100 +
                  "" +
                  "%"
                : "-";
              programFactorData.lossFundingFactor = Object.keys(
                programObjectFactors.lff
              )[0]
                ? Math.round(
                    Number(
                      programObjectFactors.lff[
                        Object.keys(programObjectFactors.lff)[0]
                      ].percentage
                    ) * 1000
                  ) /
                    10 +
                  "%"
                : "-";
              flag =
                programFactorData.extensis_program_expenses &&
                programFactorData.chubb_program_expenses &&
                programFactorData.extensis_risk_margin &&
                programFactorData.lossFundingFactor;
              break;
            }
          }
          // }
          if (flag) break;
        }
      }
    } catch (error) {
      console.log("error in generateProgramFactorData");
    } finally {
      return programFactorData;
    }
  }

  generateBinAndTableSelectionData = () => {
    let { data } = this.props;
    let response = {
      tableSelectionData: {},
      binSelectionData: {},
    };
    let tableBreakFlag = false;
    let binBreakFlag = false;

    try {
      for (let location in data.carrier_a) {
        for (let calculationType in data.carrier_a[location]
          .carrier_location_data) {
          if (
            "tableSelectionData" in
            data.carrier_a[location].carrier_location_data[calculationType]
          ) {
            tableBreakFlag = true;
            response.tableSelectionData =
              data.carrier_a[location].carrier_location_data[calculationType][
                "tableSelectionData"
              ];
          }

          if (
            "binSelectionData" in
            data.carrier_a[location].carrier_location_data[calculationType]
          ) {
            binBreakFlag = true;
            response.binSelectionData =
              data.carrier_a[location].carrier_location_data[calculationType][
                "binSelectionData"
              ];
          }

          if (tableBreakFlag && binBreakFlag) {
            break;
          }
        }
        if (tableBreakFlag && binBreakFlag) {
          break;
        }
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      return response;
    }
  };

  getClientRelationClass = () => {
    let { data } = this.props;
    let response;
    let breakFlag = false;

    try {
      for (let location in data.carrier_a) {
        for (let calculationType in data.carrier_a[location]
          .carrier_location_data) {
          if (
            "client_relation_class" in
            data.carrier_a[location].carrier_location_data[calculationType]
          ) {
            breakFlag = true;
            response =
              data.carrier_a[location].carrier_location_data[calculationType]
                .client_relation_class;
          }
        }
      }
    } catch (error) {
    } finally {
      return response;
    }
  };

  setProtectionLevelData = (protectionLevelData) => {
    this.setState({ protectionLevelData });
  };

  extractQuoteKeys = (quoteData) => {
    let modifiedData = {};
    for (let place in quoteData) {
      if (!modifiedData[place]) modifiedData[place] = {};
      for (let carrier in quoteData[place]) {
        if (!modifiedData[place][carrier]) modifiedData[place][carrier] = {};
        for (let type in quoteData[place][carrier]) {
          let costType = quoteData[place][carrier][type];
          if (!modifiedData[place][carrier][type])
            modifiedData[place][carrier][type] = {};
          if (costType.eNonE) {
            modifiedData[place][carrier][type].eNonE = costType.eNonE;
          }
          if (costType.manual_premium_sum) {
            modifiedData[place][carrier][type].manual_premium_sum =
              costType.manual_premium_sum;
          }
          if (costType.total_estimated_annual_premium) {
            modifiedData[place][carrier][type].total_estimated_annual_premium =
              costType.total_estimated_annual_premium;
          }
          if (costType.programExpenseFactors) {
            modifiedData[place][carrier][type].programExpenseFactors =
              costType.programExpenseFactors;
          }
        }
      }
    }
    return modifiedData;
  };

  handleRenewalConfirmButtonClick = async () => {
    let data = this.props.data;
    let date, uuid;
    let { uuid_carrier } = this.props;
    let { quoteTable } = this.state;
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    let companyProfile = currProspect.companyProfile;
    let { composite, manual, calculatedComposite } =
      this.createCompositeAndManualRateData();

    let carrierSelected;

    for (let carrier in quoteTable) {
      for (let state in quoteTable[carrier]) {
        if (quoteTable[carrier][state].selected) {
          carrierSelected = carrier;
          break;
        }
      }
      if (carrierSelected !== undefined) break;
    }

    try {
      $("#loader").css("display", "block");
      let preparedData = {};
      for (let carrier in data) {
        for (let line in data[carrier]) {
          if (!preparedData[line]) preparedData[line] = {};
          if (!preparedData[line][carrier]) preparedData[line][carrier] = {};
          preparedData[line][carrier] =
            data[carrier][line].carrier_location_data;
          if (!date) date = data[carrier][line].date;
          if (!uuid) uuid = data[carrier][line].uuid;
        }
      }

      preparedData = this.extractQuoteKeys(preparedData);

      if (currProspect && date && uuid && preparedData) {
        let email = currProspect.companyProfile.emailId.value;
        let effectiveDate = moment
          .utc(currProspect.companyProfile.effectiveDate.value)
          .format("YYYY-MM-DD");

        let dataToSend = {
          client_id: email,
          effective_date: effectiveDate,
          quoteid: uuid,
          lastGenerated: date,
          quoteData: preparedData,
          renewalConfirmed: true,
          selectedCarrier: carrierSelected,
          composite_charge_rate: composite,
          program_manual_rate: manual,
        };

        axios
          .post(awsUrl2 + "/api/uploadRenewalData", dataToSend)
          .then((response) => {
            sessionStorage.setItem("renewalConfirmed", true);
            if (
              !uuid_carrier ||
              uuid_carrier === null ||
              uuid_carrier === undefined
            ) {
              $("#loader").css("display", "none");
              return;
            }

            for (let uuidCar in uuid_carrier) {
              let params = {
                Key: {
                  user_email_id: companyProfile.emailId.value.toLowerCase(),
                  uuid_carrier: uuid_carrier[uuidCar],
                },
                UpdateExpression:
                  "set renewalConfirmed= :flg, selectedCarrier= :flgg",
                ExpressionAttributeValues: {
                  ":flg": true,
                  ":flgg": carrierSelected,
                },
                ReturnValues: "UPDATED_NEW",
              };
              axios
                .post(awsUrl + "/api/updateUsersData/", params)
                .then((response) => {
                  $("#loader").css("display", "none");
                })
                .catch((error) => {
                  console.log("error in API call(updateUserData) ", error);
                  $("#loader").css("display", "none");
                });
            }

            $("#loader").css("display", "none");
          })
          .catch((error) => {
            console.log("error in API uploadRenewalData", error);
            $("#loader").css("display", "none");
          });
      } else {
        $("#loader").css("display", "none");
      }
    } catch (error) {
      console.log("error in handleRenewalConfirmButtonClick");
      $("#loader").css("display", "none");
    }
  };

  handleConfirmButtonClick = async () => {
    try {
      $("#loader").css("display", "block");

      let { chargeManualRateMap } = this.state;
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
      let quoteData = JSON.parse(sessionStorage.getItem("quoteData"));

      let loggedInUserDetails;
      try {
        loggedInUserDetails = await Auth.currentAuthenticatedUser();
      } catch (error) {}

      if (quoteData && currProspect) {
        let { composite, manual, calculatedComposite } =
          this.createCompositeAndManualRateData();
        let email = currProspect.companyProfile.emailId.value;
        let effectiveDate = moment
          .utc(currProspect.companyProfile.effectiveDate.value)
          .format("YYYY-MM-DD");
        let date = quoteData.date;
        let uuid = quoteData.uuid;

        let dataToSend = {
          effectiveDate,
          email,
          uuid,
          date,
          composite,
          manual,
          calculatedManual: chargeManualRateMap,
          calculatedComposite,
          modifiedBy: loggedInUserDetails.attributes.email,
          modifiedByName: loggedInUserDetails.attributes.name,
        };

        axios
          .post(awsUrl2 + "/api/updateExtensisMainTable/", dataToSend)
          .then((response) => {
            $("#loader").css("display", "none");
          })
          .catch((error) => {
            console.log("error in API call-update main table ", error);
            $("#loader").css("display", "none");
          });
      } else {
        $("#loader").css("display", "none");
      }
    } catch (error) {
      console.log("error in updating main table", error);
      $("#loader").css("display", "none");
    }
  };

  handleToSalesforceButtonClick = async (event) => {
    try {
      $("#loader").css("display", "block");
      let client;
      if (sessionStorage.getItem("renewalClient") === "true") {
        client = "renewal";
      } else if (sessionStorage.getItem("existingClient") === "true") {
        client = "existing";
      } else {
        client = "new";
      }
      let { uuid_carrier } = this.props;
      if (
        !uuid_carrier ||
        uuid_carrier === null ||
        uuid_carrier === undefined
      ) {
        $("#loader").css("display", "none");
        return;
      }
      let { quoteTable, classCodeCompositeRateMatchData } = this.state;
      let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
      let isSalesForce = sessionStorage.getItem("isSalesforce") ? true : false;
      let {
        childrenLoc,
        companyProfile,
        emodStatesData,
        historicalClaimsCheck,
        historicalClaims,
      } = currProspect;
      let carrierSelected;
      let sendToSalesforceDate = moment.utc().format("x");

      for (let carrier in quoteTable) {
        for (let state in quoteTable[carrier]) {
          if (quoteTable[carrier][state].selected) {
            carrierSelected = carrier;
            break;
          }
        }
        if (carrierSelected !== undefined) break;
      }

      for (let uuidCar in uuid_carrier) {
        let params = {
          Key: {
            user_email_id: companyProfile.emailId.value.toLowerCase(),
            uuid_carrier: uuid_carrier[uuidCar],
          },
          UpdateExpression:
            "set sendToSalesforce= :flg, selectedCarrier= :flgg, sendToSalesforceDate= :flggg, #cld.#chrg.#cccrm= :crm",
          ExpressionAttributeNames: {
            "#cld": "carrier_location_data",
            "#chrg": "charge",
            "#cccrm": "class_code_composite_rate_match",
          },
          ExpressionAttributeValues: {
            ":flg": carrierSelected,
            ":flgg": carrierSelected,
            ":flggg": sendToSalesforceDate,
            ":crm": classCodeCompositeRateMatchData,
          },
          ReturnValues: "UPDATED_NEW",
        };
        await axios
          .post(awsUrl + "/api/updateUsersData/", params)
          .then((response) => {
            this.setState({ sentToSalesforce: true });
            // $("#loader").css("display", "none");
          })
          .catch((error) => {
            console.log("error in API call(updateUserData) ", error);
            // $("#loader").css("display", "none");
          });
      }
      let loggedInUserDetails;
      try {
        loggedInUserDetails = await Auth.currentAuthenticatedUser();
      } catch (error) {
        console.log("error in loggedInUserDetails from AUTH");
      }

      let dataToSend = {
        opportunity_id: companyProfile.emailId.value.toLowerCase(),
        effective_date: moment
          .utc(companyProfile.effectiveDate.value)
          .format("YYYY-MM-DD"),
        modifiedBy: loggedInUserDetails.attributes.email,
        modifiedByName: loggedInUserDetails.attributes.name,
        selectedCarrier: carrierSelected,
        status: "sent_to_salesforce",
        sendToSalesforceDate,
        payrollData: childrenLoc,
        companyProfile,
        emodStatesData,
        historicalClaimsCheck,
        historicalClaims,
      };

      await updateRowInOpportunityTable(dataToSend, "salesforce");

      // prepare data to be sent to salesforce
      let salesforceData = this.prepareSalesforceData(carrierSelected);

      console.log("++sfd", salesforceData);
      // debugger;

      let apiTrackingParams = {
        client_id: companyProfile.emailId.value.toLowerCase(),
        category: "apc",
        email: loggedInUserDetails.attributes.email,
        name: loggedInUserDetails.attributes.name,
        button: "to_salesforce",
        api_count: 4,
        client_type: client,
      };

      try {
        await axios.post(
          awsUrl2 + "/api/putApiTrackingData",
          apiTrackingParams
        );
      } catch (error) {
        console.log("error in api tracking API");
      }

      if (process?.env?.REACT_APP_MODE !== "beta") {
        try {
          if (isSalesForce) {
            await axios.post(awsUrl2 + "/api/sendToSalesforce", salesforceData);
            this.setState({
              successMessage: "Data sent to salesforce successfully!",
            });
          }
        } catch (error) {
          throw "error in sendToSalesforce API";
        } finally {
          $("#loader").css("display", "none");
        }
      } else {
        this.setState({
          successMessage: "Data sent to salesforce successfully!",
        });
        $("#loader").css("display", "none");
      }
    } catch (error) {
      console.log("error in updateUserData", error);
      $("#loader").css("display", "none");
    }
  };

  prepareSalesforceData = (selectedCarrier) => {
    let { currProspect } = this.props;
    let {
      classCodeCompositeRateData,
      classCodeCompositeRateMatchData,
      quoteTable,
    } = this.state;
    let carrierMap = {
      carrier_a: "chubb",
      carrier_b: "fic",
      carrier_o: "state fund",
    };
    let {
      companyProfile,
      emodStatesData,
      childrenLoc,
      historicalClaimsCheck,
      historicalClaims,
    } = currProspect;
    let response = { data: [] };

    let emod = [];
    let payrollData = [];
    let claimsData = [];

    // prepare matched charge and cost premium values
    let chargePremium = 0;
    let costPremium = 0;
    try {
      chargePremium = quoteTable[selectedCarrier]?.state?.quote?.charge
        ? quoteTable[selectedCarrier].state.quote.charge.toString()
        : 0;
      costPremium = quoteTable[selectedCarrier].state.quote.cost.toString();
    } catch (error) {}

    // prepare emod data
    for (let key in emodStatesData) {
      if (emodStatesData[key].response) {
        if (key === "ncci") {
          for (let state of emodStatesData[key].stateList) {
            emod.push({
              state: state.toUpperCase(),
              value: emodStatesData[key].rate1.value,
              effective_date: moment
                .utc(emodStatesData[key].date1.value, "YYYY-MM-DD")
                .format("MM/DD/YYYY"),
            });
          }
        } else {
          emod.push({
            state: key,
            value: emodStatesData[key].rate1.value,
            effective_date: moment
              .utc(emodStatesData[key].date1.value, "YYYY-MM-DD")
              .format("MM/DD/YYYY"),
          });
        }
      }
    }

    // prepare payroll data
    for (let stateIndex in childrenLoc) {
      for (let classCodeIndex in childrenLoc[stateIndex].classCodesInfo) {
        let ccArr =
          childrenLoc[stateIndex].classCodesInfo[
            classCodeIndex
          ].classCodeDescription.value.split(":");
        let classCode = ccArr[0].trim();
        let description = ccArr.splice(1).join(":").trim();

        console.log("RESULT", JSON.stringify(classCodeCompositeRateMatchData));
        payrollData.push({
          state: childrenLoc[stateIndex].state.value.toUpperCase(),
          class_code: classCode,
          description,
          full_time_employees:
            childrenLoc[stateIndex].classCodesInfo[
              classCodeIndex
            ].ft.value.trim(),
          part_time_employees:
            childrenLoc[stateIndex].classCodesInfo[
              classCodeIndex
            ].pt.value.trim(),
          wages: String(
            numeral(
              childrenLoc[stateIndex].classCodesInfo[classCodeIndex].payroll
                .value
            ).value()
          ),
          suta_rate: childrenLoc[stateIndex].suta
            ? String(childrenLoc[stateIndex].suta.value)
            : "",
          charge_rate: classCodeCompositeRateMatchData[selectedCarrier][
            childrenLoc[stateIndex].state.value.toLowerCase()
          ]
            ? classCodeCompositeRateMatchData[selectedCarrier][
                childrenLoc[stateIndex].state.value.toLowerCase()
              ][classCode]
            : "0",
          charge_rate_original: classCodeCompositeRateData[selectedCarrier][
            childrenLoc[stateIndex].state.value.toLowerCase()
          ]
            ? classCodeCompositeRateData[selectedCarrier][
                childrenLoc[stateIndex].state.value.toLowerCase()
              ][classCode].toFixed(4)
            : "0",
          cost_rate: classCodeCompositeRateMatchData[selectedCarrier][
            childrenLoc[stateIndex].state.value.toLowerCase()
          ]
            ? classCodeCompositeRateMatchData[selectedCarrier][
                childrenLoc[stateIndex].state.value.toLowerCase()
              ]["cost"][classCode].toFixed(4)
            : "0",
        });
      }
    }
    console.log(payrollData);
    // prepare claims data
    for (let year in historicalClaims) {
      if (!historicalClaimsCheck[year]) {
        claimsData.push({
          Loss_history_Period__c: year,
          Loss_history_Carrier__c: historicalClaims[year].carrier,
          Loss_history_Premium__c: String(
            numeral(historicalClaims[year].annPre).value()
          ),
          Loss_history_Claims__c: historicalClaims[year].claim,
          Loss_history_Incurred__c: String(
            numeral(historicalClaims[year].totInc).value()
          ),
          Loss_history_Paid__c: String(
            numeral(historicalClaims[year].totPaid).value()
          ),
          Loss_history_Reserves__c: String(
            numeral(historicalClaims[year].totRes).value()
          ),
        });
      }
    }

    response.data.push({
      opportunity_id: companyProfile.emailId.value,
      effective_date: moment
        .utc(companyProfile.effectiveDate.value, "YYYY-MM-DD")
        .format("MM/DD/YYYY"),
      company_name: companyProfile.companyName.value,
      selected_carrier: carrierMap[selectedCarrier],
      fein: companyProfile.fein.value,
      wc_program: companyProfile.wcProgram.value,
      payroll_in_oh_nd_wa_wy: companyProfile.payroll_In_OH_ND_WA_WY.value,
      entity_type: companyProfile.entityType.value,
      WC_List_Premium__c: chargePremium,
      WC_Cost__c: costPremium,
      year_business_established: moment
        .utc(companyProfile.yearBusinessEstablished.value, "YYYY-MM-DD")
        .format("MM/DD/YYYY"),
      start_date_with_extensis: moment
        .utc(companyProfile.startDateWithExtensis.value, "YYYY-MM-DD")
        .format("MM/DD/YYYY"),
      emod,
      payroll_data: payrollData,
      claims_data: claimsData,
    });

    return response;
  };

  clearRateMatchValues = () => {
    let { rateMatchValues } = JSON.parse(JSON.stringify(this.state));

    delete rateMatchValues.targetValue;
    delete rateMatchValues.excessRiskMarginFactor;

    this.setState({ rateMatchValues });
  };

  setRateMatchValues = (key, value) => {
    let { rateMatchValues } = JSON.parse(JSON.stringify(this.state));
    rateMatchValues[key] = value;
    this.setState({ rateMatchValues });
  };

  setClassCodeRateMatchData = (value, state, classCode) => {
    let { classCodeRateMatchData } = JSON.parse(JSON.stringify(this.state));
    if (state && classCode) {
      classCodeRateMatchData[state][classCode] = value;
    } else {
      classCodeRateMatchData = value;
    }
    this.setState({ classCodeRateMatchData });
  };

  setClassCodeCompositeRateMatchData = (value, state, classCode, carrier) => {
    let { classCodeCompositeRateMatchData } = JSON.parse(
      JSON.stringify(this.state)
    );
    if (state && classCode && carrier) {
      if (!classCodeCompositeRateMatchData[carrier]) {
        classCodeCompositeRateMatchData[carrier] = {};
      }
      if (!classCodeCompositeRateMatchData[carrier][state]) {
        classCodeCompositeRateMatchData[carrier][state] = {};
      }
      classCodeCompositeRateMatchData[carrier][state][classCode] = value;
    } else {
      classCodeCompositeRateMatchData = value;
    }
    this.setState({ classCodeCompositeRateMatchData });
  };

  setClassCodeCompositeRateMatchData_copy = (
    value,
    state,
    classCode,
    carrier
  ) => {
    let { classCodeCompositeRateMatchData_copy } = JSON.parse(
      JSON.stringify(this.state)
    );
    if (state && classCode && carrier) {
      if (!classCodeCompositeRateMatchData_copy[carrier]) {
        classCodeCompositeRateMatchData_copy[carrier] = {};
      }
      if (!classCodeCompositeRateMatchData_copy[carrier][state]) {
        classCodeCompositeRateMatchData_copy[carrier][state] = {};
      }
      classCodeCompositeRateMatchData_copy[carrier][state][classCode] = value;
    } else {
      classCodeCompositeRateMatchData_copy = value;
    }
    this.setState({ classCodeCompositeRateMatchData_copy });
  };

  setSalesforceMatchedRateData = (value, state, classCode, carrier) => {
    let { salesforceMatchedRateData } = JSON.parse(JSON.stringify(this.state));
    if (state && classCode && carrier) {
      if (!salesforceMatchedRateData[carrier]) {
        salesforceMatchedRateData[carrier] = {};
      }
      if (!salesforceMatchedRateData[carrier][state]) {
        salesforceMatchedRateData[carrier][state] = {};
      }
      salesforceMatchedRateData[carrier][state][classCode] = value;
    } else {
      salesforceMatchedRateData = value;
    }
    this.setState({ salesforceMatchedRateData });
  };

  setClassCodeCompositeRateData = (compositeRateData) => {
    this.setState({ classCodeCompositeRateData: compositeRateData });
  };

  setIsOverallLevel = () => {
    let { isOverallLevel } = this.state;
    isOverallLevel
      ? this.setState({ isOverallLevel: false })
      : this.setState({ isOverallLevel: true });
  };

  createCompositeAndManualRateData = () => {
    let {
      classCodeCompositeRateMatchData,
      classCodeRateMatchData,
      chargeManualRateMap,
      classCodeCompositeRateData,
    } = this.state;
    let selectedCarrierSession = sessionStorage.getItem("selectedCarrier");
    let composite = {},
      manual = {},
      calculatedComposite = {};
    if (selectedCarrierSession) {
      calculatedComposite = classCodeCompositeRateData[selectedCarrierSession];
      composite = classCodeCompositeRateMatchData[selectedCarrierSession];
      manual = {};
      for (let state in chargeManualRateMap) {
        for (let code in chargeManualRateMap[state]) {
          if (!classCodeRateMatchData[state]) {
            manual[state] = chargeManualRateMap[state];
          } else if (classCodeRateMatchData[state][code] == null) {
            if (!manual[state]) {
              manual[state] = {};
            }
            manual[state][code] = chargeManualRateMap[state][code];
          } else if (classCodeRateMatchData[state][code]) {
            if (!manual[state]) {
              manual[state] = {};
            }
            manual[state][code] = classCodeRateMatchData[state][code];
          }
        }
      }
    }
    return { composite, manual, calculatedComposite };
  };

  render() {
    let {
      quoteTable,
      idData,
      quoteStatusCarrier,
      quoteStatusState,
      allBoundFlag,
      quoteDetails,
      carriers,
      userName,
      currProspectData,
      objectForEpp,
      priceModifierDropDowns,
      questions,
      protectionLevelData,
      rateMatchValues,
      goalSeekConfig,
      isOverallLevel,
      errorMessage,
      governingData,
      classCodeCompositeRateMatchData,
      successMessage,
      costEppInputValue,
    } = JSON.parse(JSON.stringify(this.state));

    let currentSelectedCarrier = "carrier_a";
    for (let carrier in quoteTable) {
      if (quoteTable[carrier]["state"].selected) {
        currentSelectedCarrier = carrier;
        break;
      }
    }

    const { data, uuid_carrier, currProspect } = this.props;

    let programFactorData = this.generateProgramFactorData();

    let { tableSelectionData, binSelectionData } =
      this.generateBinAndTableSelectionData();

    let clientRelationClass = this.getClientRelationClass();

    let { timestamp, uuid } = this.getTimestampAndUuid(uuid_carrier);

    let detailedQuoteData = this.getDetailedQuoteData(data);

    let isHistoryVariant = this.props.variant === "history";
    let sortedCarriers;
    if (quoteTable && quoteStatusCarrier) {
      sortedCarriers = Object.keys(quoteTable);
    }

    let effectiveDate, expirationDate, fein;

    if (Object.entries(quoteDetails).length !== 0) {
      effectiveDate = moment.utc(quoteDetails.effectiveDate).format("M-D-YYYY");
      expirationDate = moment
        .utc(quoteDetails.expirationDate)
        .format("M-D-YYYY");
      fein = String(quoteDetails.fein).split("");
      fein.splice(2, 0, "-");
      fein = fein.join("");
    }
    let viewQuoteDisable = this.isDisabled();
    return (
      <div className="quote-table" id="quotetable">
        <QuoteComparision
          currProspectData={currProspectData}
          quoteTable={quoteTable}
          sortedCarriers={sortedCarriers}
          quoteStatusCarrier={quoteStatusCarrier}
          allBoundFlag={allBoundFlag}
          handleCarrierChecked={this.handleCarrierChecked}
        />
        {governingData &&
          (governingData.naics != null || governingData.classCode) && (
            <Computation
              updateGoverningData={this.updateGoverningData}
              governingData={governingData}
              carriers={carriers}
              timestamp={timestamp}
              uuid={uuid}
              quoteTable={quoteTable}
              currProspectData={currProspectData}
              objectForEpp={objectForEpp}
              costEppInputValue={costEppInputValue}
              EppCostValuesHandler={this.EppCostValuesHandler}
              EppChargeValuesHandler={this.EppChargeValuesHandler}
              priceModifierDropDowns={priceModifierDropDowns}
              setPriceModifierDropDowns={this.setPriceModifierDropDowns}
              programFactorData={programFactorData}
              setProtectionLevelData={this.setProtectionLevelData}
            />
          )}
        <PriceModifier
          quoteTable={quoteTable}
          questions={questions}
          setPriceModifierDropDowns={this.setPriceModifierDropDowns}
        />
        <RateMatch
          goalSeekConfig={goalSeekConfig}
          rateMatchValues={rateMatchValues}
          setRateMatchValues={this.setRateMatchValues}
          clearRateMatchValues={this.clearRateMatchValues}
          timestamp={timestamp}
          uuid={uuid}
          isOverallLevel={isOverallLevel}
          setIsOverallLevel={this.setIsOverallLevel}
          currProspectData={currProspectData}
          classCodeCompositeRateMatchData={classCodeCompositeRateMatchData}
          setClassCodeRateMatchData={this.setClassCodeRateMatchData}
          setClassCodeCompositeRateMatchData={
            this.setClassCodeCompositeRateMatchData
          }
          setClassCodeCompositeRateMatchData_copy={
            this.setClassCodeCompositeRateMatchData_copy
          }
          setSalesforceMatchedRateData={this.setSalesforceMatchedRateData}
          setClassCodeCompositeRateData={this.setClassCodeCompositeRateData}
          currentSelectedCarrier={currentSelectedCarrier}
        />
        <ActionButtons
          handleCalculateButton={this.handleCalculateButton}
          handleToSalesforceButtonClick={this.handleToSalesforceButtonClick}
          handleConfirmButtonClick={this.handleConfirmButtonClick}
          handleRenewalConfirmButtonClick={this.handleRenewalConfirmButtonClick}
        />
        {successMessage && (
          <div className="d-flex mt-4 justify-content-center">
            <span className="text-dark">{successMessage}</span>
          </div>
        )}
        {errorMessage && (
          <div className="d-flex mt-4 justify-content-center">
            <span className="errMsg">{errorMessage}</span>
          </div>
        )}
        <hr style={{ backgroundColor: "black", margin: "2rem 0" }} />
        {currProspect && (
          <TabsSection
            currProspect={currProspect}
            quoteTable={quoteTable}
            programFactorData={programFactorData}
            tableSelectionData={tableSelectionData}
            binSelectionData={binSelectionData}
            clientRelationClass={clientRelationClass}
            protectionLevelData={protectionLevelData}
          />
        )}
        {process.env.REACT_APP_MODE === "dev" && (
          <div className="row no-gutters body-row">
            <div className="d-flex w-100">
              <div className="quote-detailed-data">
                <h5>Detailed view</h5>
                <DetailedQuoteData detailedQuoteData={detailedQuoteData} />
              </div>
            </div>
          </div>
        )}
        {/* <Modal
          show={this.state.displayQuotePDFs}
          onHide={() => this.setState({ displayQuotePDFs: false })}
          dialogClassName="modal-full"
          style={{
            width: "100%",
            height: "100%",
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title>Quotes</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Tabs defaultActiveKey="quote-0" id="uncontrolled-tab-example">
              {this.state.quotePDFs.map((quote, i) => (
                <Tab
                  key={"quote-" + i}
                  eventKey={"quote-" + i}
                  title={quote.carrier}
                >
                  <iframe
                    src={quote.fileURL}
                    style={{ width: "100%", height: "100%" }}
                  />
                </Tab>
              ))}
            </Tabs>
          </Modal.Body>
        </Modal> */}
      </div>
    );
  }
}

export default QuoteTable;
