import React from "react";
import { useEffect, useState } from "react";
import { Redirect, Route } from "react-router-dom";
import { Auth } from "aws-amplify";
import { selectComponentToRender } from "../../utils/log_in";

const AntiPrivateRoute = ({ component: Component, ...rest }) => {
  useEffect(() => {
    async function checkLoggedInStatus() {
      let isLoggedIn;
      try {
        isLoggedIn = await Auth.currentAuthenticatedUser();
        if (isLoggedIn) {
          let componentToRender = selectComponentToRender(
            isLoggedIn.signInUserSession.accessToken.payload["cognito:groups"]
          );
          setLoggedInDetails({ isLoggedIn: true, componentToRender });
        }
      } catch (error) {
        sessionStorage.clear();
        localStorage.clear();
      }
    }
    checkLoggedInStatus();
  }, []);

  let [loggedInDetails, setLoggedInDetails] = useState({
    isLoggedIn: false,
    componentToRender: "/",
  });
  return (
    <Route
      {...rest}
      render={(props) =>
        !loggedInDetails.isLoggedIn ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: loggedInDetails.componentToRender,
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
};

export default AntiPrivateRoute;
